import { useEffect, useRef } from 'react';

export const usePrevious = <T>(value: T, condition?: boolean) => {
  const ref = useRef(value);
  useEffect(() => {
    if (condition !== false) {
      ref.current = value;
    }
  });
  return ref.current;
};
