export enum ProjectGetOperation {
  buildingAddress = 'buildingAddress',
  customerAddress = 'customerAddress',
  delivery = 'delivery',
  discount = 'discount',
  dishConfigType = 'dishConfigType',
  dishConfig = 'dishConfig',
  dishInstance = 'dishInstance',
  dish = 'dish',
  ingredientCategory = 'ingredientCategory',
  ingredient = 'ingredient',
  kitchenWorkStoppage = 'kitchenWorkStoppage',
  mealType = 'mealType',
  menuItem = 'menuItem',
  office = 'office',
  order = 'order',
  pack = 'pack',
  profile = 'profile',
  transaction = 'transaction',
}

export enum ProjectFindOperation {
  buildingAddresses = 'buildingAddresses',
  customerAddresses = 'customerAddresses',
  deliveries = 'deliveries',
  discounts = 'discounts',
  dishConfigTypes = 'dishConfigTypes',
  dishConfigs = 'dishConfigs',
  dishInstances = 'dishInstances',
  dishes = 'dishes',
  ingredientCategories = 'ingredientCategories',
  ingredients = 'ingredients',
  kitchenWorkStoppages = 'kitchenWorkStoppages',
  mealTypes = 'mealTypes',
  menuItems = 'menuItems',
  offices = 'offices',
  orders = 'orders',
  packs = 'packs',
  profiles = 'profiles',
  transactions = 'transactions',
}
