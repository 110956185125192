import {
  ANON,
  coreSubjects,
  geographySubjects,
  getGroupFn,
  Permissions,
  storageSubjects,
  USER,
} from '@mono/shared';
import { ProjectRoleKey } from './role';
import { Action, Subject } from './user-ability';

const group = getGroupFn<Subject>();

export const menuSubjects = group(
  'Dish',
  'DishConfig',
  'DishConfigType',
  'DishInstance',
  'Ingredient',
  'IngredientCategory',
  'MealType',
  'MenuItem',
  'Pack',
);

export const allSubjects = group(
  ...coreSubjects,
  ...storageSubjects,
  ...geographySubjects,
  ...menuSubjects,
  'BuildingAddress',
  'CustomerAddress',
  'Delivery',
  'Discount',
  'KitchenWorkStoppage',
  'Office',
  'Order',
  'Profile',
  'Transaction',
);

export const permissions: Permissions<ProjectRoleKey, Action, Subject> = {
  [ANON]({ can }) {
    can('manage', 'all');
    // can('create', ['User', 'BuildingAddress']);
    // can('get', allSubjects);
    // can('find', ['Pack', 'DishInstance', 'Discount', 'KitchenWorkStoppage']);
  },
  [USER]({ can }, { principal }) {
    can('create', ['CustomerAddress', 'Order', 'Delivery']);
    can('update', 'User', { id: principal?.id });
    can('update', 'Order'); // TODO: Allow to update own orders only
    can('update', 'Delivery'); // TODO: Allow to update deliveries of own orders only
  },
  ADMIN({ can }) {
    can('manage', 'all');
  },
  OWNER({ can }) {
    can('manage', allSubjects);
  },
  MANAGER({ can }) {
    can('manage', allSubjects);
  },
  NUTRITIONIST({ can }) {
    can('manage', menuSubjects);
  },
};
