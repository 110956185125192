import { useEffect, useState } from 'react';
import { storageUtils } from '../storage-utils';

export const useStorageValue = <V = unknown>(key: string): [V | undefined, boolean] => {
  const [value, setValue] = useState<V>();
  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(() => {
    const read = async () => {
      const value = await storageUtils.getOptional<V>(key);
      setValue(value);
      setLoaded(true);
    };
    read();
  }, []);
  return [value, loaded];
};
