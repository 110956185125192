import { ComponentProps, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

const defaultFormat = '(###) ###-##-##';
const defaultPlaceholder = '(___) ___-__-__';

const customFormat = '######-##-##';
const customPlaceholder = '______-__-__';

interface Props {
  onSetValue: (value?: string) => void;
  countryCode?: number;
}

export default (props: Props & ComponentProps<typeof NumberFormat>) => {
  const { onSetValue, countryCode, ...rest } = props;
  const [format, setFormat] = useState(defaultFormat);
  const [placeholder, setPlaceholder] = useState(defaultPlaceholder);

  useEffect(() => {
    if (props.countryCode) {
      const len = (Math.log(props.countryCode) * Math.LOG10E + 1) | 0;
      if (len > 1) {
        let count = 0;
        const positions: number[] = [];
        for (let i = 0; i < customFormat.length; i++) {
          if (customFormat[i] === '#') {
            positions[count] = i;
            count++;
          }
        }
        setPlaceholder(customPlaceholder.substr(positions[len - 1]));
        setFormat(customFormat.substr(positions[len - 1]));
      } else {
        setPlaceholder(defaultPlaceholder);
        setFormat(defaultFormat);
      }
    }
  }, [props.countryCode]);

  return (
    <NumberFormat
      {...rest}
      allowLeadingZeros
      onValueChange={values => onSetValue(values.value.length ? values.value : undefined)}
      format={format}
      mask="_"
      placeholder={placeholder}
    />
  );
};
