import { useState } from 'react';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import {
  advantagesPhoto,
  bottomCurve,
  imgCheck,
  imgCross,
  paprikaGreen,
  peaPod,
  peas,
  vegSketchAdvantages,
} from '../../../assets/images';
import Certificates from './certificates';

export default () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Styles>
      <Certificates isOpen={isOpen} setOpen={setOpen} />
      <IonIcon className="top-curve" icon={bottomCurve} />
      <div className="container">
        <img className="paprika" src={paprikaGreen} alt="Паприка, нарезаная кольцами" />
        <img className="center peas" src={peas} alt="Горошек" />
        <img className="center peapod" src={peaPod} alt="Стручок гороха" />
        <div className="item-container">
          <div className="item cross">
            <img src={imgCross} alt="Красный крестик (диагональный)" />
            <p>Хватит питаться однообразно, не получая необходимых витаминов и минералов</p>
          </div>

          <div className="item cross">
            <img src={imgCross} alt="Красный крестик (диагональный)" />
            <p>Не тратьте свое время на магазины, приготовление еды и мытье посуды</p>
          </div>

          <div className="item cross">
            <img src={imgCross} alt="Красный крестик (диагональный)" />
            <p>Забудте, что значит считать калории. Мы сделали все расчеты за вас</p>
          </div>

          <div className="item">
            <img src={imgCheck} alt="Зелёная галочка" />
            <p>Мы закупаем продукты каждое утро, чтобы вечером привезти вам свежие блюда</p>
          </div>

          <div className="item">
            <img src={imgCheck} alt="Зелёная галочка" />
            <p>Мы готовим в соответствии с нормами СанПиН</p>
            <span className="cert-link link" onClick={() => setOpen(true)}>
              посмотреть сертификаты
            </span>
          </div>

          <div className="item">
            <img src={imgCheck} alt="Зелёная галочка" />
            <p>С заботой о вашем здоровье. У нас действует услуга «бесконтактной доставки»</p>
          </div>
        </div>
        <div className="image-block">
          <img
            className="main"
            src={advantagesPhoto}
            alt="Девушка держит за спиной бумажный пакет с едой от Хэпифуд"
          />
          <img className="right" src={vegSketchAdvantages} alt="Рисунки овощей на заднем плане" />
        </div>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  position: relative;
  background: var(--ion-background-color-second);
  img {
    max-width: unset;
  }
  .top-curve {
    z-index: 0;
    position: absolute;
    top: -2px;
    left: 0;
    color: white;
    height: 2.5vw;
    width: 100%;
    transform: rotate(180deg);
  }
  .container {
    z-index: 1;
    display: flex;
    position: relative;
  }
  .container > img {
    position: absolute;
    &.paprika {
      bottom: -50px;
      max-width: 100px;
      margin-left: 40px;
    }
    &.center {
      top: -5%;
      left: 48%;
    }
    &.peapod {
      max-width: 65px;
    }
    &.peas {
      max-width: 140px;
      margin-left: -90px;
      margin-top: -70px;
    }
  }
  .item-container {
    --padding: 18px;
    width: calc(75% + 2 * var(--padding));
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0;
    margin-left: calc(var(--padding) * -1);
  }
  .item {
    width: 33.333%;
    padding: var(--padding);
    padding-right: max(var(--padding), calc(30% - 205px));
    img {
      height: 25px;
      margin-bottom: 10px;
    }
    &.cross img {
      height: 30px;
    }
    p {
      margin: 0;
    }
  }
  .cert-link {
    font-size: 0.9em;
  }

  .image-block {
    position: relative;
    width: 25%;
    display: flex;
    .main {
      position: relative;
      z-index: 1;
      align-self: flex-end;
      margin-top: -50%;
      margin-bottom: -11.5%;
      max-width: 130%;
    }
    .right {
      z-index: 0;
      position: absolute;
      height: 100%;
      left: 70%;
      bottom: 0;
      max-width: unset;
    }
  }
  @media screen and (min-width: 900px) {
    .item-container {
      max-width: 950px;
    }
  }
  @media screen and (max-width: 900px) {
    .item {
      width: 50%;
      max-width: unset;
    }
    .image-block {
      width: 40%;
    }
    .item-container {
      width: calc(60% + 2 * 18px);
    }
  }
  @media screen and (max-width: 768px) {
    .container {
      flex-direction: column;
    }
    .item-container {
      width: calc(100% + 2 * 16px);
      margin-left: -16px;
    }
    .item {
      padding: 16px;
    }
    .image-block {
      width: 100%;
      justify-content: center;
      .main {
        max-height: 420px;
        margin-bottom: -27px;
        margin-top: 0;
        margin-right: -80px;
      }
      .right {
        display: none;
      }
    }
    .container {
      > img {
        &.center {
          top: unset;
          bottom: 100px;
          left: calc(40% - 150px);
        }
        &.peas {
          margin-bottom: 100px;
          margin-left: -50px;
        }
        &.paprika {
          z-index: 10;
          bottom: 250px;
          right: calc(40% - 150px);
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .image-block {
      .main {
        max-width: 100%;
        margin-bottom: max(-8.5%, -27px);
      }
    }
    .container > img {
      &.paprika {
        width: 25%;
        right: unset;
        left: 60%;
        bottom: min(68vw, 250px);
      }
      &.peapod {
        width: 16%;
        left: unset;
        right: 75%;
        bottom: min(30vw, 100px);
      }
      &.peas {
        margin-left: 0;
        margin-bottom: 0;
        width: min(30%, 130px);
        left: unset;
        right: 67%;
        bottom: min(55vw, 190px);
      }
    }
  }
  @media screen and (max-width: 400px) {
    .cert-link {
      font-size: 0.8em;
    }
  }
`;
