import { ErrorInfo } from 'react';
import { storageUtils } from '@mono/frontend';

type ErrorInterface = { [K in keyof Error]: string };

export interface ReactError {
  error: Error;
  errorInfo: ErrorInfo;
  storageCleared?: boolean;
}

export interface StorageReactError {
  error: ErrorInterface;
  errorInfo: ErrorInfo;
}

export type ErrorStorage = {
  reactErrors?: StorageReactError[];
};

const replaceError = (error: ReactError): StorageReactError => {
  const { name, message, stack } = error.error;
  return { error: { name, message, stack }, errorInfo: error.errorInfo };
};

export const storeErrors = async (errors: ReactError[]) => {
  const replaced = errors.map(error => replaceError(error));
  await storageUtils.set('reactErrors', replaced.length ? replaced : undefined);
};
