import { css } from 'styled-components';
import { gradientGreen } from './gradient';

export const FormStyle = css`
  padding: 20px;
  border-radius: 12px;
  --padding: 25px;
  --color-placeholder: #aaa;
  display: flex;
  flex-wrap: wrap;

  > * {
    width: 100%;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  .input-wrapper {
    .input,
    > input,
    ion-input {
      border-radius: 25px;
      min-height: 50px;
      padding: 5px 0;
      padding-left: var(--padding);
      padding-right: var(--padding);
      background: white;
    }
  }

  ion-label,
  .label {
    padding-left: var(--padding);
    margin-top: -10px;
  }

  ion-input {
    --padding-start: var(--padding);
    --padding-end: var(--padding);
  }

  .error {
    --error-color: rgb(245, 0, 20);
    ion-label,
    .label {
      text-shadow: 0 0 0 white;
      color: #dc032a;
    }
    .input,
    > input,
    ion-input {
      box-shadow: 0 0 0px 1px rgb(245 0 20 / 40%) inset;
      background: #f7ebeb;
    }
    > span {
      padding: 0 10px;
      border-radius: 20px;
      position: absolute;
      bottom: -9px;
      left: 0;
      right: 0;
      width: fit-content;
      margin: auto;

      font-size: 9pt;
      color: white;
      background: var(--error-color);
      box-shadow: -1px 1px 2px 0 rgb(0 0 0 / 30%), 0px 2px 5px 1px rgb(255 255 255 / 40%) inset;
    }
  }

  h4 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  input {
    outline: none;
    border: none;
    background: none;
    &::placeholder {
      color: var(--color-placeholder);
    }
  }

  input[type='submit'] {
    border-radius: 25px;
    height: 50px;
    width: 100%;
    margin: 0;
    letter-spacing: 0;
    margin-top: 2px;
  }

  ion-button {
    --border-radius: 25px;
    --background: ${gradientGreen};
    --box-shadow: 0 2px 10px 0 #00000026;
    height: 50px;
    width: 100%;
  }

  ion-select,
  ion-input {
    --placeholder-opacity: 1;
    --placeholder-color: var(--color-placeholder);
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset;
    border-radius: 25px;
  }
  ion-input {
    input:-webkit-autofill {
      margin: 0 10px;
      padding: 0 15px;
    }
  }
  .privacy {
    font-size: 0.7em;
    line-height: 1.5em;
    margin: 0;
    text-align: center;
    a {
      border-bottom: 1px solid #b1b1b1;
      color: #848484;
      text-decoration: none;
    }
  }
`;
