import { gql } from '@apollo/client';

export const CONTACT = gql`
  query Contact($phone: String) {
    contact(by: { value: $phone }) {
      id
      owner {
        id
      }
    }
  }
`;
