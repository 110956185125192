import { DataI18n, i18n } from '../../translation';
import { BaseEntityData } from '../base-entity';

export interface CbsaCodeData extends BaseEntityData {
  __typename: 'CbsaCode';
  number: string;
}

export const cbsaCodeI18n = i18n<DataI18n<CbsaCodeData, 'postalCodes'>>({
  'CbsaCode': {
    en: 'CBSA code',
    ru: 'Код CBSA',
  },
  'CbsaCode:Plural': {
    en: 'CBSA codes',
    ru: 'Коды CBSA',
  },
  'CbsaCode.number': {
    en: 'Number',
    ru: 'Номер',
  },
  'CbsaCode:postalCodes': {
    en: 'Postal codes',
    ru: 'Почтовые индексы',
  },
});
