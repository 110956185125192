import { ReactNode, useState } from 'react';
import * as uuid from 'uuid';
import { Notification, NotificationState } from './notification';
import { RootLayoutContext } from './root-layout-context';

export const RootLayout = ({ children }: { children?: ReactNode }) => {
  const [notifications, setNotifications] = useState<NotificationState[]>([]);
  const context: RootLayoutContext = {
    pushNotification: data => {
      // TODO: Works wrong if called from callback like onClick (removes other notifications). Why?
      setNotifications(notifications => [
        ...notifications,
        {
          id: uuid.v4(),
          show: true,
          data,
        },
      ]);
    },
  };
  return (
    <RootLayoutContext.Provider value={context}>
      {children}
      <div className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6">
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {notifications.map(({ id, show, data }) => (
            <Notification
              key={id}
              id={id}
              show={show}
              data={data}
              notifications={notifications}
              setNotifications={setNotifications}
            />
          ))}
        </div>
      </div>
    </RootLayoutContext.Provider>
  );
};
