import { BaseEntityData, DataI18n, i18n } from '@mono/shared';
import { OrderData } from './order';

export interface TransactionData extends BaseEntityData {
  __typename: 'Transaction';
  amount: string;
  order: OrderData;
}

export const transactionI18n = i18n<DataI18n<TransactionData>>({
  'Transaction': {
    en: 'Transaction',
    ru: 'Транзакция',
  },
  'Transaction:Plural': {
    en: 'Transactions',
    ru: 'Транзакции',
  },
  'Transaction.amount': {
    en: 'Amount',
    ru: 'Сумма',
  },
  'Transaction.order': {
    en: 'Order',
    ru: 'Заказ',
  },
});
