import { Dispatch, SetStateAction, useState } from 'react';

export type OnRect = <T>(fn: (rect: DOMRect) => T) => T | undefined;

export const useRect = (): {
  setElement: Dispatch<SetStateAction<HTMLDivElement | null>>;
  onValue: OnRect;
} => {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const rect = element?.getBoundingClientRect();
  return {
    setElement,
    onValue: fn => (rect ? fn(rect) : undefined),
  };
};
