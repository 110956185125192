import { createContext, useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  SEND_TELEGRAM_MESSAGE_TO_DEVELOPERS,
  SendTelegramMessageToDevelopers,
  SendTelegramMessageToDevelopersVariables,
  storageUtils,
} from '@mono/frontend';
import { ErrorStorage, ReactError, storeErrors } from './utils-storage';

const createErrorMessage = (error: ReactError) => {
  return `${error.storageCleared ? '[storage cleared]\n' : ''}${
    error.error.stack
  }\n\nReactComponent stack:${error.errorInfo.componentStack}`;
};

const useLogger = () => {
  const [doSendTelegramMessageToDevelopers] = useMutation<
    SendTelegramMessageToDevelopers,
    SendTelegramMessageToDevelopersVariables
  >(SEND_TELEGRAM_MESSAGE_TO_DEVELOPERS);

  const [notSent, setNotSent] = useState<ReactError[]>([]);
  const [sendAttempts, setSendAttempts] = useState(0);

  const logToService = useCallback(
    async (error: ReactError) => {
      try {
        await doSendTelegramMessageToDevelopers({
          variables: { text: 'Error on React client:\n\n' + createErrorMessage(error) },
        });
      } catch (err) {
        setNotSent(it => [...it, error]);
        setSendAttempts(0);
        return false;
      }
      return true;
    },
    [doSendTelegramMessageToDevelopers],
  );

  const logToServiceMultiple = useCallback(
    async (errors: ReactError[]) => {
      const promises: Promise<boolean>[] = [];
      errors.forEach(error => {
        const message = `Error on react client:\n\n` + createErrorMessage(error);
        promises.push(
          new Promise(resolve => {
            doSendTelegramMessageToDevelopers({
              variables: { text: message },
            })
              .then(() => resolve(true))
              .catch(() => {
                resolve(false);
              });
          }),
        );
      });
      const res = await Promise.all(promises);
      return errors.filter((it, i) => !res[i]);
    },
    [doSendTelegramMessageToDevelopers],
  );

  useEffect(() => {
    storageUtils.getOptional<ErrorStorage['reactErrors']>('reactErrors').then(async errors => {
      if (errors) {
        const leftUnsent = await logToServiceMultiple(errors);
        setNotSent(leftUnsent);
      }
    });
  }, [logToServiceMultiple]);

  useEffect(() => {
    storeErrors(notSent);
  }, [notSent]);

  useEffect(() => {
    const resend = async () => {
      const leftUnsent = await logToServiceMultiple(notSent);
      setNotSent(leftUnsent);
      setSendAttempts(it => (leftUnsent.length ? it + 1 : 0));
    };

    if (notSent.length) {
      let timeoutId: number;
      if (sendAttempts <= 3) {
        timeoutId = window.setTimeout(resend, 3000);
      } else {
        timeoutId = window.setTimeout(resend, 60000);
      }
      return () => clearTimeout(timeoutId);
    } else {
      setSendAttempts(0);
    }
  }, [sendAttempts, notSent, logToServiceMultiple]);

  return logToService;
};

type LoggerHook = ReturnType<typeof useLogger>;

export const LoggerContext = createContext({} as LoggerHook);

export default useLogger;
