import { HTMLProps, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  active?: boolean;
  setActive: (active: boolean) => void;
  headerComponent: ReactNode;
  contentComponent: ReactNode;
}

const DropdownExtendable = (props: Props & HTMLProps<HTMLDivElement> & { className?: string }) => {
  const { active, setActive, headerComponent, contentComponent, ...divProps } = props;

  return (
    <div
      {...divProps}
      className={(props.className ? props.className + ' ' : '') + (active ? 'active' : '')}
    >
      <div
        className="header"
        onClick={e => {
          window.setTimeout(() => setActive(!active), 100);
        }}
      >
        {headerComponent}
      </div>
      <div className="content" onClick={e => e.stopPropagation()}>
        <div className="content-inner">{contentComponent}</div>
      </div>
    </div>
  );
};

export default styled(DropdownExtendable)`
  .header,
  .content {
    display: flex;
  }
  .header {
    cursor: pointer;
  }
  .content {
    overflow: hidden;
    max-height: 0;
    transition: max-height ease-out 0.25s;
    .content-inner {
      border-left: 1px solid #f1f3f6;
      border-right: 1px solid #f1f3f6;
      border-bottom: 1px solid #f1f3f6;
      border-radius: 0 0 5px 5px;
      width: 100%;
    }
  }
  &.active {
    .content {
      max-height: 300px;
      transition: max-height ease-in 0.25s 0.1s;
    }
    .header {
      transition: ease 0.1s;
    }
  }
`;
