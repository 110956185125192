import { BaseEntityData, DataI18n, i18n, UserData } from '@mono/shared';
import { OfficeData } from './office';
import { PackData } from './pack';
import { PaymentMethodKey } from './payment-method';

export const ORDER_DEFAULT_PAYMENT_ON_DELIVERY_ALLOWED = false;
export const ORDER_DEFAULT_SELF_DELIVERY = false;
export const ORDER_DEFAULT_COMMENT_FOR_CHIEF = '';
export const ORDER_DEFAULT_COMMENT_FOR_COURIER = '';
export const ORDER_DEFAULT_VERIFIED = false;

export interface OrderData extends BaseEntityData {
  __typename: 'Order';
  number: number;
  office: OfficeData;
  customer: UserData;
  paymentMethod: PaymentMethodKey;
  paymentOnDeliveryAllowed: boolean;
  selfDelivery: boolean;
  commentForChief: string;
  commentForCourier: string;
  promoCode: string | null;
  customDiscountRate: number | null;
  fixedPrice: string | null;
  priceFixationMoment: string | null;
}

export const orderI18n = i18n<
  DataI18n<
    OrderData,
    | 'customerProfile'
    | 'items'
    | 'itemsCount'
    | 'deliveries'
    | 'deliveriesCount'
    | 'firstDeliveryDay'
    | 'lastDeliveryDay'
    | 'recipientAddress'
    | 'itemsDeliveries'
    | 'numberOfCutlery'
    | 'printedMenuRequired'
    | 'transactions'
    | 'transactionsCount'
    | 'totalFixedDeliveryPrice'
    | 'totalFixedPrice'
    | 'balance'
    | 'paymentStatus'
    | 'applicableDiscounts'
    | 'currentDiscountRate'
    | 'currentBasePrice'
    | 'currentDiscountedPrice'
  >
>({
  'Order': {
    en: 'Order',
    ru: 'Заказ',
  },
  'Order:Plural': {
    en: 'Orders',
    ru: 'Заказы',
  },
  'Order.office': {
    en: 'Office',
    ru: 'Офис',
  },
  'Order.number': {
    en: 'Number',
    ru: 'Номер',
  },
  'Order.customer': {
    en: 'Customer',
    ru: 'Клиент',
  },
  'Order:customerProfile': {
    en: 'Customer profile',
    ru: 'Клиент',
  },
  'Order.paymentMethod': {
    en: 'Payment method',
    ru: 'Способ оплаты',
  },
  'Order.paymentOnDeliveryAllowed': {
    en: 'Payment on delivery allowed',
    ru: 'Оплата при получении разрешена',
  },
  'Order.selfDelivery': {
    en: 'Self-pickup',
    ru: 'Самовывоз',
  },
  'Order.commentForChief': {
    en: 'Comment for chief',
    ru: 'Комент повару',
  },
  'Order.commentForCourier': {
    en: 'Comment for courier',
    ru: 'Комент курьеру',
  },
  'Order.promoCode': {
    en: 'Promo code',
    ru: 'Промо-код',
  },
  'Order.customDiscountRate': {
    en: 'Сustom discount rate',
    ru: 'Спец. скидка',
  },
  'Order.fixedPrice': {
    en: 'Fixed price',
    ru: 'Цена еды',
  },
  'Order.priceFixationMoment': {
    en: 'Price fixation moment',
    ru: 'Дата фиксации цены',
  },
  'Order:items': {
    en: 'Items',
    ru: 'Позиции',
  },
  'Order:itemsCount': {
    en: 'Items count',
    ru: 'Кол-во позиций',
  },
  'Order:deliveries': {
    en: 'Deliveries',
    ru: 'Доставки',
  },
  'Order:deliveriesCount': {
    en: 'Deliveries count',
    ru: 'Кол-во доставок',
  },
  'Order:firstDeliveryDay': {
    en: 'First delivery day',
    ru: 'Первый день доставки',
  },
  'Order:lastDeliveryDay': {
    en: 'Last delivery day',
    ru: 'Последний день доставки',
  },
  'Order:recipientAddress': {
    en: 'Recipient address',
    ru: 'Адрес получателя',
  },
  'Order:itemsDeliveries': {
    en: 'Items and delivery days',
    ru: 'Позиции и дни доставки',
  },
  'Order:numberOfCutlery': {
    en: 'Number of cutlery',
    ru: 'Количество приборов',
  },
  'Order:printedMenuRequired': {
    en: 'Printed menu required',
    ru: 'Нужно ли печатное меню?',
  },
  'Order:transactions': {
    en: 'Transactions',
    ru: 'Транзакции',
  },
  'Order:transactionsCount': {
    en: 'Transactions count',
    ru: 'Кол-во транзакций',
  },
  'Order:totalFixedDeliveryPrice': {
    en: 'Delivery price',
    ru: 'Цена доставок',
  },
  'Order:totalFixedPrice': {
    en: 'Total price',
    ru: 'Итоговая цена',
  },
  'Order:balance': {
    en: 'Balance',
    ru: 'Баланс',
  },
  'Order:paymentStatus': {
    en: 'Payment status',
    ru: 'Статус оплаты',
  },
  'Order:applicableDiscounts': {
    en: 'Applicable discounts',
    ru: 'Применимые скидки (на сегодня)',
  },
  'Order:currentDiscountRate': {
    en: 'Current discount rate',
    ru: 'Процент скидки (на сегодня)',
  },
  'Order:currentBasePrice': {
    en: 'Current base price',
    ru: 'Базовая цена (на сегодня)',
  },
  'Order:currentDiscountedPrice': {
    en: 'Current discounted price',
    ru: 'Цена со скидкой (на сегодня)',
  },
});

export interface OrderItemData extends BaseEntityData {
  __typename: 'OrderItem';
  order: OrderData;
  pack: PackData;
  quantity: number;
}

export const orderItemI18n = i18n<DataI18n<OrderItemData>>({
  'OrderItem': {
    en: 'Order item',
    ru: 'Позиция заказа',
  },
  'OrderItem:Plural': {
    en: 'Order items',
    ru: 'Позиции заказов',
  },
  'OrderItem.order': {
    en: 'Order',
    ru: 'Заказ',
  },
  'OrderItem.pack': {
    en: 'Pack',
    ru: 'Пакет',
  },
  'OrderItem.quantity': {
    en: 'Quantity',
    ru: 'Количество',
  },
});
