import { DataI18n, i18n } from '../../translation';
import { BaseEntityData } from '../base-entity';
import { CbsaCodeData } from './cbsa-code';
import { CountryData } from './country';

export interface PostalCodeData extends BaseEntityData {
  __typename: 'PostalCode';
  country: CountryData;
  number: string;
  cbsaCode?: CbsaCodeData | null;
}

export const postalCodeI18n = i18n<DataI18n<PostalCodeData, 'locations' | 'centroidGeoPoint'>>({
  'PostalCode': {
    en: 'Postal code',
    ru: 'Почтовый индекс',
  },
  'PostalCode:Plural': {
    en: 'Postal codes',
    ru: 'Почтовые индексы',
  },
  'PostalCode.country': {
    en: 'Country',
    ru: 'Страна',
  },
  'PostalCode.number': {
    en: 'Number',
    ru: 'Номер',
  },
  'PostalCode.cbsaCode': {
    en: 'CBSA code',
    ru: 'Код CBSA',
  },
  'PostalCode:locations': {
    en: 'Locations',
    ru: 'Местоположения',
  },
  'PostalCode:centroidGeoPoint': {
    en: 'Centroid geo point',
    ru: 'Координаты центра',
  },
});
