import { gql } from '@apollo/client';

export const DISH_INSTANCES = gql`
  query DishInstances($firstDate: DayUnit, $lastDate: DayUnit) {
    dishInstances(
      where: { day: { ge: $firstDate, le: $lastDate } }
      orderBy: { day: ASC }
      useCache: true
    ) {
      id
      day
      menuItem {
        id
        name
        mealType {
          name
          position
        }
        image {
          id
          name
          url
        }
      }
      dish {
        ...dishData
      }
    }
  }
  fragment dishData on Dish {
    name
    image {
      id
      name
      url
    }
    description
    ingredients {
      name
    }
    configs {
      type {
        position
        name
        id
      }
      nutritionFacts {
        proteins
        fats
        carbohydrates
        calories
      }
    }
  }
`;
