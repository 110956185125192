import { call, locationSharp, logoInstagram, logoVk, logoWhatsapp, mail } from 'ionicons/icons';
import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { getDefinedOrThrow } from '@mono/shared';
import { car, logoMetro, logoTelegramBw } from '../../../assets/icon';
import { OfficeContext } from '../../context/office/office';

export default () => {
  const {
    officeUiData: {
      city,
      address,
      latitude,
      longitude,
      metro,
      phoneNumber,
      emailAddress,
      instagramUrl,
      vkontakteUrl,
      whatsappUrl,
      telegramUrl,
    },
  } = getDefinedOrThrow(useContext(OfficeContext));

  useEffect(() => {
    document.title = 'Контакты | Happy Food';
    const description = `
    Контактная информация - Happy Food. Наш адрес: ${city}, ${address}.
    Оформление заказов 24/7. Доставка ежедневно с 19:00 до 23:00. Тел. ${phoneNumber}.`;
    document.querySelector('meta[name="description"]')?.setAttribute('content', description);
  }, [city, address, phoneNumber]);

  return (
    <Styles className="header-padding">
      <div className="container">
        <div className="title-links">
          <h1>Наши контакты</h1>
          <h3>
            <a href={instagramUrl} target="_blank" rel="noreferrer">
              <IonIcon icon={logoInstagram} />
            </a>
            <a href={whatsappUrl} target="_blank" rel="noreferrer">
              <IonIcon icon={logoWhatsapp} />
            </a>
            <a href={telegramUrl} target="_blank" rel="noreferrer">
              <IonIcon icon={logoTelegramBw} />
            </a>
            <a href={vkontakteUrl} target="_blank" rel="noreferrer">
              <IonIcon icon={logoVk} />
            </a>
          </h3>
        </div>
        <div className="contacts-section">
          <div className="column">
            <div className="row">
              <IonIcon icon={locationSharp} />
              {`${city}, ${address}`} (самовывоз)
            </div>
            <div className="row">
              <IonIcon icon={logoMetro} />
              {`${metro}`}
            </div>
          </div>
          <div className="column">
            <div className="row">
              <IonIcon icon={call} />
              {phoneNumber}
            </div>
            <div className="row">
              <IonIcon icon={mail} />
              {emailAddress}
            </div>
          </div>
          <div className="column">
            <div className="row">
              <IonIcon icon={call} />
              Заказы принимаются 24/7
            </div>
            <div className="row">
              <IonIcon icon={car} />
              Ежедневно c 19:00 до 23:00
            </div>
          </div>
        </div>
        <iframe
          title="Google Map"
          src={`https://www.google.com/maps/d/embed?mid=1EfImWxIvITlnkjpArx7xxiEoIvrEzNQ&ll=${latitude}%2C${longitude}&z=9`}
          width="100%"
          height="620"
        ></iframe>
        <div className="requisites">
          <div>
            <span>ИП: Князева Дарья Александровна</span>
            <span>ИНН: 041104637144</span>
            <span>ОГРН: 320040000001888</span>
          </div>

        </div>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  .container > * {
    margin-bottom: 20px;
  }
  .title-links {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0 0;
    h1,
    h2 {
      margin-right: 10px;
    }
    ion-icon {
      margin: 0 4px;
      color: black;
    }
  }
  .contacts-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -10px;
    .column {
      margin: 0 10px 20px;
    }
    .row {
      display: flex;
      margin-bottom: 3px;
      ion-icon {
        margin-right: 5px;
        font-size: 20px;
      }
    }
  }
  .requisites {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > div {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }
`;
