import { FC, ReactElement, ReactNode } from 'react';
import { Translation } from '@mono/shared';

const LAYOUT_SETTINGS = 'layoutSettings';
const LAYOUT_FN = 'layOutFn';

type LayOutFn = (element: ReactElement) => ReactElement;

type LayoutSettings = {
  pageTitleTranslation?: Translation;
};

export const layOut = (component: FC, layOut: LayOutFn, settings?: LayoutSettings) => {
  component[LAYOUT_FN] = layOut;
  component[LAYOUT_SETTINGS] = settings;
  return component;
};

export const getLayOutFn = (node: ReactNode): LayOutFn => {
  return node?.[LAYOUT_FN] ?? (node => node);
};

export const getLayoutSettings = (node: ReactNode): LayoutSettings => {
  return node?.[LAYOUT_SETTINGS] ?? {};
};
