import { dayjs } from '@mono/shared';
import { getEuWeekday, getLocaleDateStrings } from './dates';

const weekdayWordEndings = [
  'ий', // Пн
  'ий',
  'ую',
  'ий',
  'ую',
  'ую',
  'ее', // Вс
];

const wordClosest = 'ближайш';
const wordNext = 'следующ';

export function getWeekdayRusAccusative(weekday: string) {
  if (weekday.endsWith('а')) {
    return weekday.slice(0, -1) + 'у';
  }
  return weekday;
}

export function getRusWordForWeekday(
  weekday: number,
  todayWeekday: number,
  firstMenuWeekday: number,
) {
  if (todayWeekday > firstMenuWeekday) {
    firstMenuWeekday += 7;
  }
  if (weekday < todayWeekday) {
    weekday += 7;
  }
  if (weekday >= todayWeekday && weekday < firstMenuWeekday) {
    return wordNext + weekdayWordEndings[weekday % 7];
  } else {
    return wordClosest + weekdayWordEndings[weekday % 7];
  }
}

export function getWeekdayStatus(
  date: string,
  today: string,
  firstMenuDate: string,
  short?: boolean,
) {
  const weekday = getEuWeekday(date);
  const todayWeekday = getEuWeekday(today);
  const firstMenuWeekday = getEuWeekday(firstMenuDate);

  const word = getRusWordForWeekday(weekday, todayWeekday, firstMenuWeekday);
  const { weekdayLong, weekdayShort } = getLocaleDateStrings(dayjs(date), 'ru');
  return word + ' ' + getWeekdayRusAccusative(short ? weekdayShort : weekdayLong);
}
