import { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { IonImg } from '@ionic/react';
import { round } from '@mono/shared';
import { dishPhotoNotFound, noDishPhoto } from '../../../../../assets/images';
import { STORAGE_OBJECT } from '../../../../apollo/api/queries/storage-object';
import {
  DishInstances_dishInstances,
  DishInstances_dishInstances_dish_configs,
  StorageObject,
  StorageObjectVariables,
} from '../../../../apollo/api/types';
import base64 from 'react-native-base64'
import Spinner from '../../../../components/spinner';
import Tooltip from '../../../../components/tooltip';

interface Props {
  dishInstance: DishInstances_dishInstances;
  dishConfig: DishInstances_dishInstances_dish_configs;
  setLightboxImage: (url?: string) => void;
}

const Dish = (props: Props) => {
  const { calories, proteins, fats, carbohydrates } = props.dishConfig.nutritionFacts;

  const imageObj = props.dishInstance.dish.image ?? props.dishInstance.menuItem.image;

  const dishThumb = useQuery<StorageObject, StorageObjectVariables>(STORAGE_OBJECT, {
    variables: imageObj
      ? {
          id: imageObj.id,
          width: 500,
        }
      : undefined,
    skip: !imageObj,
  });

  const b64Url = imageObj?.url.split('https://cdn.happyfood.pro/')[1];
  const imgObj = JSON.parse(base64.decode(b64Url));

  // const hasDishImage = !!props.dishInstance.dish.image;
  const hasDishImage = !!imgObj;
  const hasThumb = dishThumb.data ? !!dishThumb.data.storageObject : true;
  const [thumbLoaded, setThumbLoaded] = useState(false);
  const [loadError, setLoadError] = useState(false);

  //console.log(imgObj.key);

  return (
    <Styles>
      <div className="card">
        <div className="card-title">
          <span>{props.dishInstance.menuItem.mealType?.name ?? ''}</span>
          <Tooltip>
            {hasThumb ? (
              <p>{`На фото изображено блюдо “${
                dishThumb.data?.storageObject?.name
              }“. Это лишь пример одного из множества блюд, которые мы готовим как блюдо на ${
                props.dishInstance.menuItem.mealType?.name.toLowerCase() ?? ''
              }. Изображение может не совпадать с тем, что у нас в меню на выбранный вами день. Мы готовим огромное количество блюд, поэтому пока не можем сделать фото для каждого из них.`}</p>
            ) : (
              <p>Не удалось найти фото для данного блюда</p>
            )}
          </Tooltip>
        </div>

        {!loadError ? (
          hasThumb ? (
            <div
              className={
                'image-wrap' + (hasDishImage ? '' : ' menu-item') + (thumbLoaded ? '' : ' loading')
              }
              //onClick={() => props.setLightboxImage(imageObj?.url)}
              onClick={() => props.setLightboxImage('https://cdn.happyfood.pro/'+ imgObj.key)}
            >
              <IonImg
                //src={dishThumb.data?.storageObject?.url}
                src={'https://cdn.happyfood.pro/'+ imgObj?.key}
                alt={''}
                onIonImgDidLoad={() => setThumbLoaded(true)}
                onIonError={() => setLoadError(true)}
              />
              {thumbLoaded && !hasDishImage && <span>Нет фото</span>}
              {!thumbLoaded && <Spinner />}
            </div>
          ) : (
            <div className={'image-wrap placeholder' + (thumbLoaded ? '' : ' loading')}>
              <IonImg src={noDishPhoto} alt={''} onIonImgDidLoad={() => setThumbLoaded(true)} />
              {!thumbLoaded && <Spinner />}
            </div>
          )
        ) : (
          <div className={'image-wrap placeholder' + (thumbLoaded ? '' : ' loading')}>
            <IonImg src={dishPhotoNotFound} alt={''} onIonImgDidLoad={() => setThumbLoaded(true)} />
            {!thumbLoaded && <Spinner />}
          </div>
        )}
      </div>
      <div>
        <h5>{props.dishInstance.dish.name}</h5>
        <p>
          {props.dishInstance.dish.ingredients.map((ingred, index) =>
            index === 0 ? ingred.name : ', ' + ingred.name,
          )}
        </p>
        <span>{`К ${round(calories, 0)} / Б ${round(proteins, 1)} / Ж ${round(fats, 1)} / У ${round(
          carbohydrates,
          1,
        )}`}</span>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  .card {
    height: 256px;
    width: 256px;
    .card-title {
      height: 30px;
      padding: 5px 0;
      background: transparent;
      display: flex;
      justify-content: center;
      font-size: 12pt;
      .tooltip {
        margin-left: 4px;
      }
    }
    .image-wrap {
      position: relative;
      height: calc(100% - 30px);
      box-shadow: 1px 5px 10px 2px rgb(0 0 0 / 60%);
      overflow: hidden;
      cursor: pointer;

      ion-img {
        height: 100%;
        width: 100%;
        &::part(image) {
          object-fit: cover;
        }
      }

      ion-spinner {
        position: absolute;
        inset: 0;
        margin: auto;
      }

      span {
        position: absolute;
        inset: 0;
        margin: auto;
        color: #33333391;
        height: 20px;
        width: fit-content;
        font-size: 15px;
        font-weight: 500;
        z-index: 1;
      }

      &.loading {
        box-shadow: none;
        background: linear-gradient(135deg, #e4e4e4, #f1f1f1);
        ion-img {
          visibility: hidden;
        }
      }

      &.menu-item {
        ion-img {
          filter: blur(2px);
          opacity: 0.2;
        }
      }

      &.placeholder {
        box-shadow: none;
        ion-img {
          opacity: 0.2;
        }
      }
    }
  }
  h5 {
    font-weight: 800;
    font-size: 12pt;
    margin: 16px 0;
  }
  @media screen and (max-width: 600px) and (min-width: 501px) {
    .card {
      height: 45vw;
      width: unset;
    }
  }
  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    .card {
      align-self: center;
      width: 85%;
      min-width: 256px;
    }
  }
`;

export default Dish;
