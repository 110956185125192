import { BaseEntityData, DataI18n, i18n } from '@mono/shared';
import { CustomerAddressData } from './customer-address';
import { OrderData, OrderItemData } from './order';

export const DELIVERY_DEFAULT_NUMBER_OF_CULTERY = 1;
export const DELIVERY_DEFAULT_PRINTED_MENU_REQUIRED = true;
export const DELIVERY_DEFAULT_COMMENT = '';

export interface DeliveryData extends BaseEntityData {
  __typename: 'Delivery';
  order: OrderData;
  recipientAddress: CustomerAddressData;
  day: string;
  numberOfCutlery: number;
  printedMenuRequired: boolean;
  comment: string;
  fixedPrice: string | null;
  priceFixationMoment: string | null;
}

export const deliveryI18n = i18n<
  DataI18n<
    DeliveryData,
    'customerProfile' | 'orderOffice' | 'orderNumber' | 'courierWage' | 'currentPrice' | 'items'
  >
>({
  'Delivery': {
    en: 'Delivery',
    ru: 'Доставка',
  },
  'Delivery:Plural': {
    en: 'Deliveries',
    ru: 'Доставки',
  },
  'Delivery.order': {
    en: 'Order',
    ru: 'Заказ',
  },
  'Delivery:customerProfile': {
    en: 'Customer',
    ru: 'Заказчик',
  },
  'Delivery.recipientAddress': {
    en: 'Address',
    ru: 'Адрес',
  },
  'Delivery.day': {
    en: 'Day',
    ru: 'День',
  },
  'Delivery.numberOfCutlery': {
    en: 'Number of cutlery',
    ru: 'Количество приборов',
  },
  'Delivery.printedMenuRequired': {
    en: 'Printed menu required',
    ru: 'Нужно печатное меню',
  },
  'Delivery.comment': {
    en: 'Comment',
    ru: 'Комент',
  },
  'Delivery.fixedPrice': {
    en: 'Price',
    ru: 'Цена',
  },
  'Delivery.priceFixationMoment': {
    en: 'Price fixation moment',
    ru: 'Момент фиксации цены',
  },
  'Delivery:orderOffice': {
    en: 'Office',
    ru: 'Офис',
  },
  'Delivery:orderNumber': {
    en: 'Order number',
    ru: 'Номер заказа',
  },
  'Delivery:courierWage': {
    en: 'Wage',
    ru: 'ЗП',
  },
  'Delivery:currentPrice': {
    en: 'Price (for today)',
    ru: 'Цена (на сегодня)',
  },
  'Delivery:items': {
    en: 'Items',
    ru: 'Позиции',
  },
});

export interface DeliveryItemData extends BaseEntityData {
  __typename: 'DeliveryItem';
  delivery: DeliveryData;
  orderItem: OrderItemData;
  quantity: number;
}

export const deliveryItemI18n = i18n<DataI18n<DeliveryItemData>>({
  'DeliveryItem': {
    en: 'Delivery item',
    ru: 'Позиция доставки',
  },
  'DeliveryItem:Plural': {
    en: 'Delivery items',
    ru: 'Позиции доставки',
  },
  'DeliveryItem.delivery': {
    en: 'Delivery',
    ru: 'Доставка',
  },
  'DeliveryItem.orderItem': {
    en: 'Order item',
    ru: 'Позиция заказа',
  },
  'DeliveryItem.quantity': {
    en: 'Quantity',
    ru: 'Количество',
  },
});
