import { createContext, useContext } from 'react';
import { Ability, AbilityBuilder, AnyAbility } from '@casl/ability';
import { createContextualCan, useAbility } from '@casl/react';
import { AuthData, O, Principal, SharedAbility } from '@mono/shared';
import { throwFunctionIsNotProvidedError } from './errors';

const noAbilities = new AbilityBuilder(Ability).build();

export type AppAbility = SharedAbility | AnyAbility;

export const AbilityContext = createContext<AppAbility>(noAbilities);

export const Can = createContextualCan(AbilityContext.Consumer);

export type FrontendAuthData = AuthData & {
  jwt: O<string>;
};

export const AuthContext = createContext<{
  readonly principal: Principal | null;
  readonly getPrincipalOrThrow: () => Principal;
  readonly logIn: (authData: FrontendAuthData) => Promise<void>;
  readonly logOut: () => Promise<void>;
}>({
  principal: null,
  getPrincipalOrThrow: () => throwFunctionIsNotProvidedError('getPrincipalOrThrow'),
  logIn: () => throwFunctionIsNotProvidedError('logIn'),
  logOut: () => throwFunctionIsNotProvidedError('logOut'),
});

export type Me<A extends AppAbility = AppAbility> = Principal & A;

export const useMe = (): Me => {
  const { getPrincipalOrThrow } = useContext(AuthContext);
  const principal = getPrincipalOrThrow();
  const ability = useAbility(AbilityContext);
  return Object.assign(Object.create(ability), principal);
};
