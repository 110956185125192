import { useEffect } from 'react';
import { FieldValues, Resolver } from 'react-hook-form';

export const useRevalidationOnResolverChange = <T extends FieldValues>(
  resolver: Resolver<T>,
  {
    formState,
    trigger,
  }: {
    formState: { isSubmitted: boolean };
    trigger: () => void;
  },
) => {
  useEffect(() => {
    if (formState.isSubmitted) {
      trigger();
    }
  }, [formState.isSubmitted, trigger, resolver]);
};
