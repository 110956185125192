import { i18n } from '../../translation';

export enum Gender {
  UNKNOWN = 'UNKNOWN',
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export type GenderKey = keyof typeof Gender;

export const genderI18n = i18n<GenderKey>({
  [Gender.FEMALE]: {
    en: 'Female',
    ru: 'Женский',
  },
  [Gender.MALE]: {
    en: 'Male',
    ru: 'Мужской',
  },
  [Gender.OTHER]: {
    en: 'Other',
    ru: 'Другой',
  },
  [Gender.UNKNOWN]: {
    en: 'Unknown',
    ru: 'Неизвестный',
  },
});
