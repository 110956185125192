import { closeCircleOutline, informationCircleOutline } from 'ionicons/icons';
import { ComponentProps } from 'react';
import Popup from 'reactjs-popup';
import styled, { css } from 'styled-components';
import { IonButton, IonIcon } from '@ionic/react';
import { gradientGreen } from '../../styles/gradient';
import { IonButtonStyle } from '../../styles/ion-button-style';

type Props = Omit<ComponentProps<typeof Popup>, 'children'> & {
  icon?: string;
  title: JSX.Element | string;
  message: JSX.Element | string;
} & { className?: string };

const AlertDefault = (props: Props) => {
  const { icon, title, message: Message } = props;

  return (
    <Popup {...props} modal>
      <IonIcon icon={icon ?? informationCircleOutline} />
      <h3>{title}</h3>
      <div>{Message}</div>
      <IonButton mode="md" onClick={props.onClose}>
        Ок
      </IonButton>
    </Popup>
  );
};

export const AlertStyles = css`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  &-content {
    font-family: var(--ion-font-family);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    max-width: 500px;
    min-width: 400px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 12px;
    ion-icon {
      font-size: 75px;
      color: #67c13b;
    }
    h3 {
      text-align: center;
      color: #383838;
      font-size: 22pt;
      line-height: 24pt;
      margin: 20px 0 0;
    }
    > div {
      text-align: center;
      line-height: 18px;
      color: #6d6d6d;
      margin: 30px 0 40px;
      max-width: 400px;
      font-size: 12pt;
    }
    ion-button {
      ${IonButtonStyle}
      --background: ${gradientGreen};
      --color: white;
      width: 80px;
      height: 35px;
      margin-bottom: 10px;
    }
  }
`;

export const ErrorStyles = css`
  &-content {
    ion-icon {
      color: #f52a2a;
    }
  }
`;
export const Alert = styled(AlertDefault)`
  ${AlertStyles}
`;

const ErrorAlert = (props: Props) => {
  return <AlertDefault {...props} icon={closeCircleOutline} />;
};

export const Error = styled(ErrorAlert)`
  ${AlertStyles}
  ${ErrorStyles}
`;
export default AlertDefault;
