import {
  BaseEntityData,
  BuildingAddressUid,
  BuildingAddressUidPartKey,
  DataI18n,
  GeoPointOption,
  i18n,
  JobStatusKey,
  LocationData,
} from '@mono/shared';

export interface BuildingAddressData extends BaseEntityData, BuildingAddressUid {
  __typename: 'BuildingAddress';
  regionType: string;
  regionName: string;
  districtType: string;
  districtName: string;
  cityType: string;
  cityName: string;
  streetOwnerType: string;
  streetOwnerName: string;
  streetType: string;
  streetName: string;
  buildingType: string;
  buildingName: string;
  buildingPostalCodeNumber: string;
  processJobStatus: JobStatusKey | null;
  invalidPart: BuildingAddressUidPartKey | null;
  geoPointOptions: GeoPointOption[] | null;
  location?: LocationData | null;
  customDeliveryPrice: string | null;
}

export const buildingAddressI18n = i18n<
  DataI18n<
    BuildingAddressData,
    'undoubtfulGeoPoint' | 'customerAddresses' | 'office' | 'customized' | 'deliveryPrice'
  >
>({
  'BuildingAddress': {
    en: 'Building address',
    ru: 'Адрес здания',
  },
  'BuildingAddress:Plural': {
    en: 'Building addresses',
    ru: 'Адреса зданий',
  },
  'BuildingAddress.regionType': {
    en: 'Region type',
    ru: 'Тип региона',
  },
  'BuildingAddress.regionName': {
    en: 'Region name',
    ru: 'Регион',
  },
  'BuildingAddress.districtType': {
    en: 'District type',
    ru: 'Тип района',
  },
  'BuildingAddress.districtName': {
    en: 'District name',
    ru: 'Район',
  },
  'BuildingAddress.cityType': {
    en: 'City type',
    ru: 'Тип города',
  },
  'BuildingAddress.cityName': {
    en: 'City name',
    ru: 'Город',
  },
  'BuildingAddress.streetOwnerType': {
    en: 'Street owner type',
    ru: 'Тип владельца улицы',
  },
  'BuildingAddress.streetOwnerName': {
    en: 'Street owner name',
    ru: 'Владелец улицы',
  },
  'BuildingAddress.streetType': {
    en: 'Street type',
    ru: 'Тип улицы',
  },
  'BuildingAddress.streetName': {
    en: 'Street name',
    ru: 'Улица',
  },
  'BuildingAddress.buildingType': {
    en: 'Building type',
    ru: 'Тип здания',
  },
  'BuildingAddress.buildingName': {
    en: 'Building name',
    ru: 'Здание',
  },
  'BuildingAddress.buildingPostalCodeNumber': {
    en: 'Building postal code number',
    ru: 'Почтовый индекс',
  },
  'BuildingAddress.processJobStatus': {
    en: 'Process job status',
    ru: 'Статус обработки',
  },
  'BuildingAddress.invalidPart': {
    en: 'Invalid part',
    ru: 'Неверная часть',
  },
  'BuildingAddress.geoPointOptions': {
    en: 'Geo-point options',
    ru: 'Возможные координаты',
  },
  'BuildingAddress:undoubtfulGeoPoint': {
    en: 'Undoubtful geo-point',
    ru: 'Точные координаты',
  },
  'BuildingAddress.location': {
    en: 'Location',
    ru: 'Местоположение',
  },
  'BuildingAddress:customized': {
    en: 'Customized',
    ru: 'Настроено вручную',
  },
  'BuildingAddress:customerAddresses': {
    en: 'Customer addresses',
    ru: 'Адреса клиентов',
  },
  'BuildingAddress:office': {
    en: 'Office',
    ru: 'Офис',
  },
  'BuildingAddress.customDeliveryPrice': {
    en: 'Custom delivery price',
    ru: 'Ручная цена доставки',
  },
  'BuildingAddress:deliveryPrice': {
    en: 'Delivery price',
    ru: 'Цена доставки',
  },
});
