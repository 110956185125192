import { ContactType } from './data/core/contact-type';
import { getContactTypeOf } from './data/core/contact-type-utils';

export const formatContactValue = (contactValue: string): string => {
  switch (getContactTypeOf(contactValue)) {
    case ContactType.EMAIL:
      return contactValue;
    case ContactType.PHONE: {
      const match = contactValue.match(/^(\+\d+)(\d{3})(\d{3})(\d{4})$/);
      if (match === null) {
        break;
      }
      return match[1] + ' ' + match[2] + ' ' + match[3] + ' ' + match[4];
    }
  }
  return contactValue;
};
