import { css } from 'styled-components';
import { FormStyle } from './form-style';

export const PopupFormStyle = css`
  &-overlay {
    padding: 2vw 0;
    overflow-y: scroll;
    overflow-x: hidden;
    background: rgba(0, 0, 0, 0.5);
  }

  &-content {
    width: 96%;
    max-width: 900px;
    border-radius: 12px;
    padding: 50px;
    display: flex;
    justify-content: space-between;
    background: white;
    font-family: var(--ion-font-family);

    .close {
      position: absolute;
      right: 35px;
      top: 35px;
      height: 30px;
      cursor: pointer;
    }
    .text {
      color: #383838;
      padding: 20px;
      > div {
        display: flex;
        justify-content: space-between;
      }
      h3 {
        color: #59ae30;
        font-size: 11pt;
        line-height: 13pt;
        margin-bottom: 20px;
      }
    }
    .form {
      display: flex;
      align-items: center;
      margin-top: 30px;
      width: 40%;
    }
    form {
      ${FormStyle}
      height: fit-content;
      width: 100%;
      min-width: 265px;
      input,
      .input {
        border: 1px solid lightgrey;
      }
      .privacy,
      ion-label {
        color: #b1b1b1;
      }
    }
    .error {
      input,
      .input {
        border: none;
      }
    }

    .basil-leaves {
      position: absolute;
      right: 78px;
      top: 15px;
      transform: scale(0.8);
      img:last-child {
        margin-left: 20px;
        margin-bottom: -10px;
      }
    }
    .middle-images {
      position: relative;
      height: 0;
      top: 120px;
      right: -100px;

      img:first-child {
        position: absolute;
        right: 0;
        bottom: -20px;
        min-width: 160px;
        height: 160px;
      }
      img:last-child {
        position: absolute;
        right: -9px;
        bottom: 60px;
        min-width: 28px;
        height: 50px;
      }
    }
    .text {
      width: 46%;
    }
    p {
      font-size: 13px;
      line-height: 17px;
      margin-right: 30px;
    }

    @media screen and (max-width: 1024px) {
      .text {
        width: 46%;
      }
      p {
        font-size: 13px;
        line-height: 17px;
        margin-right: 30px;
      }
    }
    @media screen and (max-width: 900px) {
      .text {
        min-width: 46%;
        width: 46%;
      }
      .form {
        width: 40%;
      }
      .middle-images {
        top: 140px;
      }
    }
    @media screen and (max-width: 700px) {
      flex-direction: column;
      padding: 10px 2%;
      .text,
      .form {
        width: 100%;
        min-width: unset;
        max-width: unset;
      }
      .text {
        h3 {
          margin-right: 40px;
        }
      }
      .form {
        margin-top: 0;
      }
      .close {
        top: 6vw;
        right: 6vw;
      }
      .text > div {
        align-items: center;
      }
      .text p {
        margin: 0;
        width: calc(100% - 130px);
        max-width: 240px;
      }
      .middle-images {
        right: 0;
        top: 50px;
      }
      .basil-leaves {
        display: none;
      }

      @media screen and (max-width: 600px) {
        .middle-images {
          right: 0;
          top: 60px;
          transform: scale(0.9);
        }
      }
      @media screen and (max-width: 500px) {
        .middle-images {
          transform: scale(0.8);
        }
      }
    }
  }
`;
