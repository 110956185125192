import { chevronDownOutline } from 'ionicons/icons';
import { ReactNode, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { getDefinedOrThrow } from '@mono/shared';
import { spinachBig, spinachSmall } from '../../../assets/images';
import Spoiler from '../../components/spoiler';
import { OfficeContext } from '../../context/office/office';

const questions = [
  'Почему рацион без мяса дороже стандартного рациона?',
  'Подходит ли меню для людей с аллергией и тех, кто не ест мясо?',
  'Возможна ли замена одного блюда на другое?',
  'Содержат ли блюда и напитки сахар или сахарозаменители?',
  'Можно ли греть ваши контейнеры в микроволновке?',
  'Не буду ли я засорять окружающую среду, получая еду в контейнерах?',
  'Можно ли отложить доставку еды, временно "заморозив" заказ?',
];
const answers = [
  'Мы не просто убрали мясо, а заменили его на качественные морепродукты. К сожалению, дары моря в наших краях стоят даже дороже халяльного мяса.',
  'Да. Мы можем произвести замену мяса, рыбы, яиц, молочных, кисломолочных и некоторых других продуктов на альтернативные варианты, доступные на нашей кухне в день приготовления блюда. Но такую замену нужно согласовывать заранее.',
  'Да. По предварительному согласованию такую замену мы производим.',
  'Не содержат. Для сладости как блюд, так и напитков мы используем только мед.',
  'Да. Наши контейнеры пригодны для использования в микроволновой печи.',
  'Материалы контейнеров либо биоразлагаемы, либо поддаются переработке.',
  'Да, позвонив нашему менеджеру. Количество дней, на которое можно заморозить заказ, зависит от колчества дней в заказе - на каждые 5 дней в заказе дается 1 день заморозки. Например, оформляя заказ на 19 дней вы получаете возможность замораживать его на 3 дня, а оформляя на 20 - уже на 4.',
];

export default () => {
  const {
    officeUiData: { phoneNumber },
  } = getDefinedOrThrow(useContext(OfficeContext));

  const [items, setItems] = useState<ReactNode[]>([]);
  const [activeIndex, setActiveIndex] = useState<number | undefined>(0);

  useEffect(() => {
    const Items: ReactNode[] = [];
    questions.forEach((question, index) => {
      if (index !== activeIndex) {
        Items.push(
          <Spoiler
            key={question}
            setActive={it => (it ? setActiveIndex(index) : setActiveIndex(undefined))}
            headerComponent={
              <div className="question-header">
                <p>{question}</p>
                <IonIcon icon={chevronDownOutline} />
              </div>
            }
            contentComponent={
              <div className="question-content">
                <p>{answers[index]}</p>
              </div>
            }
          />,
        );
      } else {
        Items.push(
          <Spoiler
            active
            setActive={it => (it ? setActiveIndex(index) : setActiveIndex(undefined))}
            key={question}
            headerComponent={
              <div className="question-header">
                <p>{question}</p>
                <IonIcon icon={chevronDownOutline} />
              </div>
            }
            contentComponent={
              <div className="question-content">
                <p>{answers[index]}</p>
              </div>
            }
          />,
        );
      }
      setItems(Items);
    });
  }, [activeIndex]);

  return (
    <Styles>
      <img className="image-left" src={spinachBig} alt="Листья шпината" />
      <img className="image-right" src={spinachSmall} alt="Листья шпината" />
      <div className="container">
        <div className="title">
          <div className="title-wrapper">
            <h3>Вопрос - ответ</h3>
            <p>
              Если вы не нашли ответ на свой вопрос, обязательно свяжитесь с нашим менеджером по
              телефону
            </p>
            <a href="https://wa.me/79639447474?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D1%83%20%D0%BC%D0%B5%D0%BD%D1%8F%20%D0%B5%D1%81%D1%82%D1%8C%20%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81">{phoneNumber} (WhatsApp)
</a>
          </div>
        </div>
        <div className="item-container">{items}</div>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  position: relative;
  .container {
    position: relative;
    z-index: 1;
    display: flex;
  }
  .title {
    width: 23%;
    min-width: 23%;
    margin-right: 3%;
    p {
      margin-top: 0;
      margin-bottom: 26px;
    }
    a {
      text-decoration: none;
      color: black;
      font-weight: 800;
      font-size: 16px;
    }
  }
  .item-container {
    > div {
      margin-bottom: 10px;
      &.active {
        .question-header {
          transition: border-radius ease 0.1s;
          border-radius: 30px 30px 0 0;
          ion-icon {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }
  .content-inner {
    background: #f9f9f9;
  }
  .question-header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    transition: border-radius ease 0.1s 0.25s;
    padding-left: 6%;
    padding-right: max(12%, 40px);
    background: linear-gradient(to bottom, #60b935 0%, #4d922b 100%);

    color: white;
    font-weight: 800;
    font-size: 11pt;

    &:hover {
      opacity: 0.85;
    }
    ion-icon {
      position: absolute;
      right: 6%;
      top: 50%;
      margin-top: -8px;
      height: 16px;
      width: 16px;
      opacity: 0.9;
      transform: rotate(0);
      transition: transform ease 0.2s;
    }
  }
  .question-content {
    padding: 0 6%;
  }

  .image-left {
    position: absolute;
    left: calc(45% - 640px);
    top: -70px;
    width: min(20%, 250px);
  }
  .image-right {
    position: absolute;
    right: calc(45% - 720px);
    bottom: -70px;
    width: min(20%, 270px);
  }
  @media screen and (max-width: 1400px) {
    .image-left {
      left: calc(40% - 400px);
      top: 55px;
      width: 250px;
    }
    .image-right {
      transform: rotate(-50deg);
      right: calc(40% - 600px);
      bottom: -50px;
    }
    .title-wrapper {
      background: rgba(255, 255, 255, 0.8);
      /* padding-bottom: 20px; */
      box-shadow: 0 0 25px 25px rgb(255 255 255 / 80%);
    }
  }
  @media screen and (max-width: 1140px) {
    .image-left {
      left: 5%;
      top: calc(400px - 30vw);
      width: 22%;
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      flex-direction: column;
    }
    .title {
      width: 70%;
      margin-bottom: 26px;
    }
    .title-wrapper {
      background: none;
      box-shadow: none;
    }
    .image-right {
      top: 20px;
      right: calc(8% - 50px);
      transform: rotate(-20deg);
      width: min(45%, 180px);
    }
    .image-left {
      display: none;
    }
  }

  @media screen and (max-width: 420px) {
    .question-header {
      font-size: 10pt;
    }
  }
`;
