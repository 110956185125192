import { Translation } from '../';

const namePartRegex = new RegExp(/^[\p{L}]+[-]?[\p{L}]+?$/, 'u');

export const validation: Record<
  | 'nickname'
  | 'password'
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'password'
  | 'email'
  | 'phone',
  {
    regex: RegExp;
    error: Translation;
  }
> = {
  nickname: {
    regex: /^[a-z][a-z-]*[a-z]$/,
    error: {
      en: 'Nick must be composed of at lest 2 lowercased latin letters',
      ru: 'Ник должен содержать не менее двух строчных латинских символов',
    },
  },
  password: {
    regex: /^(.){3,}$/,
    error: {
      en: 'Password must contain at least 3 characters',
      ru: 'Пароль должен содержать не менее трех символов',
    },
  },
  firstName: {
    regex: namePartRegex,
    error: {
      en: 'First name must start and end with letters and can contain "-"',
      ru: 'Имя должно начинаться и заканчиваться буквами и может содержать "-"',
    },
  },
  middleName: {
    regex: namePartRegex,
    error: {
      en: 'Middle name must start and end with letters and can contain "-"',
      ru: 'Отчество должно начинаться и заканчиваться буквами и может содержать "-"',
    },
  },
  lastName: {
    regex: namePartRegex,
    error: {
      en: 'Last name must start and end with letters and can contain "-"',
      ru: 'Фамилия должна начинаться и заканчиваться буквами и может содержать "-',
    },
  },
  email: {
    regex: /^.+@.+$/,
    error: {
      en: 'Email must be composed of username and domain separated by "@"',
      ru: 'Email должен состоять из имени пользователя и домена, разделенных "@"',
    },
  },
  phone: {
    regex: /^\+[0-9]{7,15}$/,
    error: {
      en: 'Phone must start from "+" and be composed of 7 to 15 digits',
      ru: 'Телефон должен начинаться с "+" и содержать от 7 до 15 цифр',
    },
  },
};
