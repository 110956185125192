import { storageUtils } from '@mono/frontend';
import { CartFormStorage, cartFormStorageKeys } from '../../pages/cart/utils-form-fields';
import { kladrObjSchema } from '../../pages/cart/utils-kladr';

type CartFormValidationError = Record<keyof CartFormStorage, string>;

export const cartFormValidationError: CartFormValidationError = {
  countryCode: 'номер телефона',
  phonePart: 'номер телефона',
  name: 'Ф.И.О.',
  city: 'город',
  street: 'улицу',
  building: 'номер дома',
  flat: 'квартиру',
  entrance: 'подъезд',
  floor: 'этаж',
  commentForChief: 'комментарий повару',
  commentForCourier: 'комментарий курьеру',
  cutlery: 'наличие одноразовых приборов',
  printedMenu: 'необходимость бумажного меню',
};

export const fetchCartFormStorage = async () => {
  const newState: Partial<Record<keyof CartFormStorage, unknown>> = {};

  const Promises: ReturnType<typeof storageUtils.getOptional>[] = [];
  cartFormStorageKeys.forEach(async key => Promises.push(storageUtils.getOptional(key)));
  const data = await Promise.all(Promises);
  const errors: Array<keyof CartFormStorage> = [];
  let index = 0;
  for (const key of cartFormStorageKeys) {
    if (key === 'building' || key === 'street' || key === 'city') {
      try {
        await kladrObjSchema.validate(data[index]);
      } catch (err) {
        data[index] = undefined;
        errors.push(key);
      }
    }
    newState[key] = data[index++];
  }

  return { data: newState, errors } as { data: CartFormStorage; errors: typeof errors };
};
