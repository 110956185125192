import styled from 'styled-components';
import { dayjs } from '@mono/shared';
import { Dish } from '../../hooks/menu/utils';

interface Props {
  date: string;
  dishesForDay: Dish[];
}

const MenuForDay = (props: Props) => {
  const date = dayjs(props.date);

  return (
    <Styles>
      <p>{`Меню на ${date.format('D MMMM')}:`}</p>
      <ul>
        {props.dishesForDay.map(dish => {
          if (dish) {
            return <li key={dish.dishInstance.id}>{dish.dishInstance.dish.name}</li>;
          } else {
            return null;
          }
        })}
      </ul>
    </Styles>
  );
};

export default MenuForDay;

const Styles = styled.div`
  width: 100%;
  position: relative;
`;
