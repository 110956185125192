import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { QueryLoggedInUser, QueryLoggedInUserVariables } from '../../../../gql-types';
import { AuthContext } from '../../auth';
import { QUERY_LOGGED_IN_USER } from '../../gql/operations/auth/logged-in-user';

export const useLoggedInUser = () => {
  const { principal } = useContext(AuthContext);
  const { data } = useQuery<QueryLoggedInUser, QueryLoggedInUserVariables>(QUERY_LOGGED_IN_USER, {
    variables: {
      userIdentifier: { id: principal?.id },
    },
    skip: principal == null,
  });
  return data?.user;
};
