import { FC } from 'react';
import useLogger, { LoggerContext } from './error-logger-context';

const Logger: FC = props => {
  const logger = useLogger();

  return <LoggerContext.Provider value={logger}>{props.children}</LoggerContext.Provider>;
};

export default Logger;
