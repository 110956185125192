import { DataI18n, i18n } from '../../translation';
import { BaseEntityData } from '../base-entity';
import { StorageObjectTagData } from './storage-object-tag';

export interface StorageObjectData extends BaseEntityData {
  __typename: 'StorageObject';
  name: string;
  mediaType: string;
  tags: StorageObjectTagData[];
}

export const storageObjectI18n = i18n<DataI18n<StorageObjectData, 'mediaData' | 'url' | 'users'>>({
  'StorageObject': {
    en: 'Storage object',
    ru: 'Объект хранилища',
  },
  'StorageObject:Plural': {
    en: 'Storage objects',
    ru: 'Объекты хранилища',
  },
  'StorageObject.name': {
    en: 'Name',
    ru: 'Название',
  },
  'StorageObject.mediaType': {
    en: 'Media type',
    ru: 'Медиа тип',
  },
  'StorageObject:mediaData': {
    en: 'Media data',
    ru: 'Медиа данные',
  },
  'StorageObject.tags': {
    en: 'Tags',
    ru: 'Тэги',
  },
  'StorageObject:url': {
    en: 'URL',
    ru: 'URL',
  },
  'StorageObject:users': {
    en: 'Users',
    ru: 'Пользователи',
  },
});
