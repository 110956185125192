import { validation } from '../../validation';
import { ContactType } from './contact-type';

export const getContactTypeOf = (value: string): ContactType | null => {
  if (validation.email.regex.test(value)) {
    return ContactType.EMAIL;
  }
  if (validation.phone.regex.test(value)) {
    return ContactType.PHONE;
  }
  return null;
};
