import { useEffect, useState } from 'react';
import { eqByElements } from '@mono/shared';

export const useSameArray = <T extends unknown>(value: T[]) => {
  const [saved, save] = useState(value);
  const equal = value === saved || eqByElements(value, saved);
  useEffect(() => {
    if (!equal) {
      save(value);
    }
  }, [value, equal]);
  return equal ? saved : value;
};
