import { gql } from '@apollo/client';

export const LOGGED_IN_USER_FRAGMENT = gql`
  fragment LoggedInUserFragment on User {
    id
    roles
    firstName
    language
  }
`;

export const QUERY_LOGGED_IN_USER = gql`
  ${LOGGED_IN_USER_FRAGMENT}
  query QueryLoggedInUser($userIdentifier: UserIdentifier!) {
    user(by: $userIdentifier) {
      ...LoggedInUserFragment
    }
  }
`;
