import styled from 'styled-components';
import { IonButton } from '@ionic/react';
import { gradientGreen } from '../../styles/gradient';
import { IonButtonOutlineStyle, IonButtonStyle } from '../../styles/ion-button-style';

export const ActionIonButton = styled(IonButton)`
  ${IonButtonStyle}
  --background: ${gradientGreen};
  --color: white;
`;

export const DayIonButton = styled(IonButton)`
  ${IonButtonOutlineStyle}
  width: 60px;
  --background: white;
  --ripple-color: #66c340;
  --color-hover: #66c340;
  --border-color: #66c340;
  &.active {
    --color: white;
    --border-color: #59ae30;
    --border-width: 0;
    --box-shadow: 0px 0px 2px 0px #19481954;
    --background: ${gradientGreen};
    --color-hover: #eee;
    --background-hover: white;
    font-size: 16px;
  }
  &.range-mode:hover {
    --border-width: 2px;
    --border-color: #008000;
  }
  &.range-start {
    --border-width: 2px;
    --border-color: #008000;
  }
  &.button-disabled {
    --border-color: #dfe1e4;
  }
`;

export const ControlIonButton = styled(IonButton)`
  ${IonButtonOutlineStyle}
  --background: white;
  --color-hover: #56b030;
  --color-activated: #56b030;
  border-radius: 20px;
  height: 50px;
  width: 50px;
  --padding-start: 0;
  --padding-end: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 18px;

  ion-icon {
    min-width: 20px;
    align-self: center;
  }

  &.active {
    /* --background: ${gradientGreen};; */
    --color: #56b030;
    --border-color: #66c340;
  }
`;

export const PackIonButton = styled(IonButton)`
  ${IonButtonOutlineStyle}
  --background: white;
  --padding-start: 20px;
  --padding-end: 20px;
  --color-hover: #73ae30;
  width: unset;
  text-transform: none;
  .inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    line-height: 16px;
    font-weight: 700;
  }
  .small {
    font-size: 10pt;
    font-weight: 500;
    opacity: 0.8;
  }
  ion-icon {
    margin-right: -5px;
    margin-left: 5px;
    color: #aaa;
  }
  &:hover ion-icon {
    color: #73ae30;
  }
`;

export const DeleteIonButton = styled(IonButton)`
  ${IonButtonOutlineStyle}
  height: 50px;
  width: 50px;
  --box-shadow: none;
  --background: white;
  --color: #848484;
  --color-hover: #ff6565;
  --ripple-color: #ff6565;
  &.ion-activated {
    --border-color: #ff6565;
  }
  font-size: 12pt;
`;
