import { gql } from '@apollo/client';
import { LOGGED_IN_USER_FRAGMENT } from './logged-in-user';

export const SIGN_UP = gql`
  ${LOGGED_IN_USER_FRAGMENT}
  mutation SignUp($data: UserCreateData!) {
    signUp(data: $data) {
      jwt
      user {
        ...LoggedInUserFragment
      }
    }
  }
`;
