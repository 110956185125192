import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { LandingAnchors } from '../../pages/landing/utils';
import ConsultForm from './consult-form';
import FooterInfo from './footer-info';

const pathNoFooter = ['/cart'];

export default () => {
  const location = useLocation();

  if (pathNoFooter.indexOf(location.pathname) !== -1) {
    return null;
  }
  return (
    <Styles>
      <div className="container">
        <div className="footer-left">
          <div className="footer-info">
            <FooterInfo />
          </div>
          <div className="footer-nav">
            <div className="centered">
              <h4>Разделы</h4>
              <Link to={'/#' + LandingAnchors.calculator}>Калькулятор</Link>
              <Link to={'/#' + LandingAnchors.menu}>Рационы питания</Link>
              <Link to={'/#' + LandingAnchors.delivery}>Оплата и доставка</Link>
              <Link to={'/#' + LandingAnchors.ask}>Вопрос-ответ</Link>
              <Link to={'/#' + LandingAnchors.instagram}>Наш Instagram</Link>
              <Link to={'/#' + LandingAnchors.videos}>Видео о нас</Link>
              <Link to={'/#' + LandingAnchors.reviews}>Отзывы</Link>
            </div>
          </div>
          <div className="footer-nav">
            <div className="centered">
              <h4>Страницы</h4>
              <Link to="/">Главная</Link>
              <Link to="/contacts">Контакты</Link>
              <Link to="/cart">Корзина</Link>
              <Link to="/privacy-policy">Политика конфиденциальности</Link>
	      <Link to="/assets/public_offer.pdf" target="_blank">Публичная оферта</Link>
            </div>
          </div>
        </div>
        <div className="consult-form">
          <ConsultForm />
        </div>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  background: var(--ion-background-color-second);
  .container {
    display: flex;
    .footer-left {
      flex-grow: 1;
    }
  }
  .footer-left {
    display: flex;
    justify-content: space-between;
    > * {
      flex-grow: 1;
      flex-basis: 1px;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  h4 {
    font-weight: 700;
    font-size: 1.2em;
  }
  .footer-info > div,
  .footer-nav .centered {
    padding-top: 50px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    h4 {
      margin: 0;
    }
    > * {
      margin-bottom: 15px;
      &:first-child {
        margin-bottom: 25px;
      }
    }
  }

  .footer-nav {
    display: flex;
    justify-content: center;
    .centered {
      max-width: 180px;
    }
  }
  .consult-form {
    padding: 32px 0;
    display: flex;
    justify-content: flex-end;
  }
  .footer-left {
    padding-bottom: 32px;
    > * {
      margin: 0;
    }
  }
  @media screen and (max-width: 900px) {
    .container {
      margin: 0 max(0px, calc(40vw - 280px));
    }
    .footer-nav {
      justify-content: unset;

      flex-grow: 0;
      min-width: fit-content;
      margin-right: 50px;
      &:last-child {
        margin-right: 0;
      }
    }
    .footer-info {
      min-width: 100%;
      flex-grow: 0;
    }
    .footer-left {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .footer-left {
      padding-left: 20px;
    }
  }

  @media screen and (max-width: 600px) {
    .container {
      flex-wrap: wrap-reverse;
    }
    .footer-nav {
      margin-right: 6vw;
    }
    .consult-form {
      form {
        max-width: unset;
      }
    }
  }
`;
