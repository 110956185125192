import { BaseEntityData, DataI18n, i18n } from '@mono/shared';
import { DishData } from './dish';
import { DishConfigTypeData } from './dish-config-type';
import { IngredientData } from './ingredient';

export interface DishConfigData extends BaseEntityData {
  __typename: 'DishConfig';
  dish: DishData;
  type: DishConfigTypeData;
}

export const dishConfigI18n = i18n<
  DataI18n<
    DishConfigData,
    'ingredientConfigs' | 'calories' | 'proteins' | 'fats' | 'carbohydrates' | 'nutritionFacts'
  >
>({
  'DishConfig': {
    en: 'Dish config',
    ru: 'Конфигурация блюда',
  },
  'DishConfig:Plural': {
    en: 'Dish configs',
    ru: 'Конфигурации блюд',
  },
  'DishConfig.dish': {
    en: 'Dish',
    ru: 'Блюдо',
  },
  'DishConfig.type': {
    en: 'Type',
    ru: 'Тип',
  },
  'DishConfig:ingredientConfigs': {
    en: 'Ingredient configs',
    ru: 'Конфигурации ингредиентов',
  },
  'DishConfig:calories': {
    en: 'Calories',
    ru: 'Калории',
  },
  'DishConfig:proteins': {
    en: 'Proteins',
    ru: 'Белки',
  },
  'DishConfig:fats': {
    en: 'Fats',
    ru: 'Жиры',
  },
  'DishConfig:carbohydrates': {
    en: 'Carbohydrates',
    ru: 'Углеводы',
  },
  'DishConfig:nutritionFacts': {
    en: 'Nutrition facts',
    ru: 'Пищевая ценность',
  },
});

export const INGREDIENT_CONFIG_DEFAULT_POSITION = 0;

export interface IngredientConfigData extends BaseEntityData {
  __typename: 'IngredientConfig';
  position: number;
  weight: number;
  dishConfig: DishConfigData;
  ingredient: IngredientData;
}

export const ingredientConfigI18n = i18n<DataI18n<IngredientConfigData>>({
  'IngredientConfig': {
    en: 'Ingredient config',
    ru: 'Конфигурация ингредиента',
  },
  'IngredientConfig:Plural': {
    en: 'Ingredient configs',
    ru: 'Конфигурации ингредиентов',
  },
  'IngredientConfig.position': {
    en: 'Position',
    ru: 'Положение',
  },
  'IngredientConfig.weight': {
    en: 'Weight',
    ru: 'Вес',
  },
  'IngredientConfig.dishConfig': {
    en: 'Dish config',
    ru: 'Конфигурация',
  },
  'IngredientConfig.ingredient': {
    en: 'Ingredient',
    ru: 'Ингредиент',
  },
});
