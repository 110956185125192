import { Big } from 'big.js';
import { debounce } from 'lodash';
import plural from 'plural-ru';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { IonButton, IonLabel, IonSpinner } from '@ionic/react';
import { storageUtils } from '@mono/frontend';
import { getDefinedOrThrow, O } from '@mono/shared';
import { CONFIRM_CONTACT } from '../../apollo/api/queries/confirm-contact';
import { CONTACT } from '../../apollo/api/queries/contact';
import { REQUEST_CONTACT_CONFIRMATION } from '../../apollo/api/queries/request-contact-confirmation';
import { SIGN_UP } from '../../apollo/api/queries/sign-up';
import {
  ConfirmContact,
  ConfirmContactVariables,
  Contact,
  ContactVariables,
  RequestContactConfirmation,
  RequestContactConfirmationVariables,
  SignUp,
  SignUpVariables,
} from '../../apollo/api/types';
import { Alert, Error } from '../../components/alert/alert';
import Backdrop from '../../components/backdrop';
import PhoneInput from '../../components/phone-input';
import { getInternationalNumber, PhoneParts } from '../../components/phone-input/phone-input';
import Tooltip from '../../components/tooltip';
import CartFormContext from '../../context/cart-form';
import { OfficeContext } from '../../context/office/office';
import OrdersContext from '../../context/orders';
import PricesContext from '../../context/prices';
import { useImperativeQuery } from '../../hooks/use-imperative-query';
import { CheckboxStyle } from '../../styles/checkbox';
import { FormStyle } from '../../styles/form-style';
import { gradientGreen } from '../../styles/gradient';
import { Prefix, ReactSelectStyles } from '../../styles/react-select';
import { isConfirmationCodeMatchError } from '../../utils/api';
import { getCurrency } from '../../utils/currencies';
import useDeliveryData from './delivery-price';
import useOrderCreation, { OrderCreationStatus } from './order-creation';
import { getBuildingAddressFromKladrObj } from './utils-api';
import {
  codeSentError,
  ContactStatus,
  createOption,
  DeliveryPriceStatus,
  ErrorDialogMessage,
  FormStatus,
  getSubmitErrors,
  matchOptions,
  Message,
  SelectOption,
} from './utils-form';
import {
  DEFAULT_COUNTRY_CODE,
  FormFields,
  MAX_CONFIRM_ATTEMPTS,
  MAX_CONFIRM_SECONDS,
  schema,
  ValidFormFields,
} from './utils-form-fields';
import {
  Address,
  findKladrObjectParent,
  KladrErrors,
  KladrFormKey,
  KladrObject,
  kladrRequestBuilder as get,
  syncWithKladr,
} from './utils-kladr';

const currency = getCurrency('RUB');

export default () => {
  const [orders] = useContext(OrdersContext);
  const {
    officeUiKey,
    officeUiData: { regionId, phoneNumber, city },
    switchOffice,
  } = getDefinedOrThrow(useContext(OfficeContext));

  const ordersCount = useMemo(
    () => orders.map(it => it.dates.length).reduce((a, b) => a + b, 0),
    [orders],
  );

  const [prices, setPromoCode, getTransactionsCount, setDeliveryPrice] = useContext(PricesContext);
  const newUserDiscountRate = useMemo(
    () => prices.food?.discounts.find(it => it.userHadNoTransactions !== null)?.rate,
    [prices.food?.discounts],
  );

  const [cart, updateCart, updateCartDebounced] = useContext(CartFormContext);

  const region = useMemo(
    () => (cart.building ? findKladrObjectParent(cart.building, 'region') : undefined),
    [cart.building],
  );

  const phoneFull = useMemo(
    () => getInternationalNumber(cart.countryCode, cart.phonePart),
    [cart.countryCode, cart.phonePart],
  );

  const [deliveryPriceStatus, setDeliveryPriceStatus] = useState<DeliveryPriceStatus>(
    DeliveryPriceStatus.unset,
  );

  const submitErrors = useMemo(
    () =>
      getSubmitErrors({
        officeUiKey,
        noOrders: !ordersCount,
        deliveryPriceStatus,
      }),
    [officeUiKey, ordersCount, deliveryPriceStatus],
  );

  const queryContact = useImperativeQuery<Contact, ContactVariables>(CONTACT);
  const [doSignUp] = useMutation<SignUp, SignUpVariables>(SIGN_UP);
  const [doConfirmContact] = useMutation<ConfirmContact, ConfirmContactVariables>(CONFIRM_CONTACT);
  const [doRequestConfirmation] = useMutation<
    RequestContactConfirmation,
    RequestContactConfirmationVariables
  >(REQUEST_CONTACT_CONFIRMATION);

  const [isNewUserPopupOpen, setNewUserPopupOpen] = useState(false);

  const [contactStatus, setContactStatus] = useState<ContactStatus>();
  const [attemptsLeft, setAttemptsLeft] = useState(MAX_CONFIRM_ATTEMPTS);
  const [secondsLeft, setSecondsLeft] = useState(MAX_CONFIRM_SECONDS);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onSubmit',
  });

  const [formStatus, setFormStatus] = useState(FormStatus.input);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePhoneEntered = useCallback(
    debounce((phone: string) => {
      getTransactionsCount(phone).then(value => {
        return value === 0 && setNewUserPopupOpen(true);
      });
    }, 1000),
    [],
  );

  //
  const handlePhoneChange = useCallback(
    (phone: PhoneParts) => {
      clearErrors(FormFields.phonePart);
      updateCartDebounced({ countryCode: phone.cc, phonePart: phone.pp });
      phone.int && handlePhoneEntered(phone.int);
    },
    [clearErrors, handlePhoneEntered, updateCartDebounced],
  );

  useEffect(() => {
    if (formStatus === FormStatus.contactConfirmation) {
      if (secondsLeft) {
        const timeoutId = window.setTimeout(() => setSecondsLeft(sec => sec - 1), 1000);
        return () => clearTimeout(timeoutId);
      } else {
        setError(FormFields.confirmation, { type: 'manual', message: 'Превышено время ожидания' });
      }
    }
  }, [secondsLeft, formStatus, setError]);

  useEffect(() => {
    register(FormFields.phonePart);
    register(FormFields.countryCode);
    register(FormFields.city);
    register(FormFields.street);
    register(FormFields.building);
  }, [register]);

  useEffect(() => {
    setValue(FormFields.countryCode, cart.countryCode);
  }, [cart.countryCode, setValue]);

  useEffect(() => {
    setValue(FormFields.phonePart, cart.phonePart);
  }, [cart.phonePart, setValue]);

  useEffect(() => {
    setValue(FormFields.city, cart.city);
  }, [cart.city, clearErrors, setValue]);

  useEffect(() => {
    setValue(FormFields.street, cart.street);
  }, [cart.street, clearErrors, setValue]);

  useEffect(() => {
    setValue(FormFields.building, cart.building);
  }, [cart.building, clearErrors, setValue]);

  const [hasValue, setHasValue] = useState({
    building: false,
    flat: false,
    entrance: false,
    floor: false,
    commentForChief: false,
    commentForCourier: false,
  });

  useEffect(() => {
    setHasValue(it => ({ ...it, building: !!cart.building }));
  }, [cart.building]);

  useEffect(() => {
    setHasValue(it => ({ ...it, flat: !!cart.flat }));
  }, [cart.flat]);

  useEffect(() => {
    setHasValue(it => ({ ...it, entrance: !!cart.entrance }));
  }, [cart.entrance]);

  useEffect(() => {
    setHasValue(it => ({ ...it, floor: !!cart.floor }));
  }, [cart.floor]);

  useEffect(() => {
    setHasValue(it => ({ ...it, commentForChief: !!cart.commentForChief }));
  }, [cart.commentForChief]);

  useEffect(() => {
    setHasValue(it => ({ ...it, commentForCourier: !!cart.commentForCourier }));
  }, [cart.commentForCourier]);

  const [isErrorOpen, setErrorOpen] = useState(false);
  const [currentError, setCurrentError] = useState<ErrorDialogMessage>();

  const raiseErrorDialog = useCallback((message: JSX.Element | string, title?: string) => {
    setCurrentError({ message, title });
    setErrorOpen(true);
  }, []);

  const clearErrorDialog = useCallback(() => {
    setCurrentError(undefined);
    setErrorOpen(false);
  }, []);

  const raiseApiError = useCallback(
    () => raiseErrorDialog('Не удалось создать заказ. Попробуйте снова', 'Ошибка!'),
    [raiseErrorDialog],
  );

  const handleCodeSent = useCallback(
    (phone: string, codeSent?: boolean) => {
      if (codeSent) {
        setContactStatus(ContactStatus.codeSent);
        setAttemptsLeft(MAX_CONFIRM_ATTEMPTS);
        setSecondsLeft(MAX_CONFIRM_SECONDS);
      } else {
        raiseErrorDialog(codeSentError(phone));
        setContactStatus(ContactStatus.errorCodeSent);
        setFormStatus(FormStatus.input);
      }
    },
    [raiseErrorDialog],
  );

  const [hasGeocoderError, setGeocoderError] = useState(false);

  const [addressErrors, setAddressErrors] = useState<KladrErrors>({});
  const updateAddressErrors = useCallback(
    (errors: KladrErrors) => setAddressErrors(it => ({ ...it, ...errors })),
    [],
  );

  useEffect(() => updateAddressErrors({ city: undefined }), [cart.city, updateAddressErrors]);
  useEffect(() => updateAddressErrors({ street: undefined }), [cart.street, updateAddressErrors]);
  useEffect(
    () => updateAddressErrors({ building: undefined }),
    [cart.building, updateAddressErrors],
  );

  const clearAddressAndFormError = useCallback(
    (field: KladrFormKey) => {
      clearErrors(FormFields[field]);
      updateAddressErrors({ [field]: undefined });
    },
    [updateAddressErrors, clearErrors],
  );

  const raiseGeocoderErrors = useCallback(() => {
    setGeocoderError(true);
    setAddressErrors({ city: { message: '' }, street: { message: '' }, building: { message: '' } });
    raiseErrorDialog(
      <Message>
        Стоимость доставки по Вашему адресу определяется индивидуально.
        <br />
        Для оформления заказа <br />
        свяжитесь с нами по телефону
        <br />
        <span className="alert-phone">{phoneNumber}</span>
      </Message>,
    );
  }, [raiseErrorDialog, phoneNumber]);

  useEffect(() => {
    if (!secondsLeft) {
      toast('Превышено время ожидания подтверждения контакта', { type: 'error', autoClose: false });
      setFormStatus(FormStatus.input);
      setContactStatus(undefined);
    }
  }, [secondsLeft]);

  const [orderStatus, createOrder] = useOrderCreation();

  useEffect(() => {
    if (
      orderStatus !== OrderCreationStatus.created &&
      orderStatus !== OrderCreationStatus.creating
    ) {
      if (orderStatus) {
        raiseApiError();
        setFormStatus(FormStatus.input);
      }
    }
  }, [orderStatus, raiseApiError]);

  const [showOptions, setShowOptions] = useState<Record<KladrFormKey, boolean>>({
    street: false,
    city: false,
    building: false,
  });

  const loadOptions = useCallback(
    (
      type: KladrFormKey,
      value: string,
      overrides: {
        city?: KladrObject<'city'>;
        street?: KladrObject<'street'>;
        building?: KladrObject<'building'>;
      } = {},
    ) =>
      new Promise((resolve: (val: SelectOption<KladrFormKey>[] | undefined) => void) => {
        get(regionId, value, type, {
          regionId: region?.id,
          cityId: overrides.city?.id ?? cart.city?.id,
          streetId: overrides.street?.id ?? cart.street?.id,
          buildingId: overrides.building?.id ?? cart.building?.id,
        })
          .then(res => {
            const show = value.length > 1 || res?.values.length;
            setShowOptions(it => ({ ...it, [type]: show }));
            resolve(res ? matchOptions(res) : undefined);
          })
          .catch(() => {
            setShowOptions(it => ({ ...it, [type]: false }));
            resolve(undefined);
          });
      }),
    [cart.city, cart.street, cart.building, region, regionId],
  );

  const [delivery, setDeliveryAddress] = useDeliveryData();

  const updateDeliveryPrice = useCallback(
    (value: O<Big>) => {
      value !== undefined &&
        toast(
          value === null ? (
            <div>
              Доставим по Вашему адресу
              <br />
              <span className="bold uppercase">бесплатно</span>
            </div>
          ) : (
            <div>
              Стоимость одной доставки по Вашему адресу{' '}
              <span className="bold">{value.toString() + ' ' + currency.minimal + '.'}</span>
            </div>
          ),
        );
      setDeliveryPrice(value);
    },
    [setDeliveryPrice],
  );

  const [lastSynced, setLastSynced] = useState<{
    city: string | undefined;
    street: string | undefined;
    building: string | undefined;
  }>({
    city: undefined,
    street: undefined,
    building: undefined,
  });

  useEffect(() => {
    const getAddressAndErrors = async () => {
      const res = await syncWithKladr(regionId, cart.city, cart.street, cart.building);
      const address: Address = {
        city: cart.city,
        street: cart.street,
        building: cart.building,
        ...res.updates,
      };

      if (
        address.city &&
        lastSynced.city === address.city.id &&
        address.street &&
        lastSynced.street === address.street.id &&
        address.building &&
        lastSynced.building === address.building.typeShort + address.building.name
      ) {
        return;
      }

      if (
        (!address.city && !lastSynced.city) ||
        (!address.street && !lastSynced.street) ||
        (!address.building && !lastSynced.building)
      ) {
        return;
      }
      if (
        address.city &&
        address.street &&
        address.building &&
        region &&
        !Object.keys(res.errors).length
      ) {
        setDeliveryAddress(
          getBuildingAddressFromKladrObj(region, address.city, address.street, address.building),
        );
      }

      setLastSynced({
        city: address.city?.id,
        street: address.street?.id,
        building: address.building ? address.building.typeShort + address.building.name : undefined,
      });

      updateCart(address);
    };

    getAddressAndErrors();
  }, [
    cart.building,
    cart.street,
    cart.city,
    region,
    lastSynced,
    updateAddressErrors,
    updateCart,
    setGeocoderError,
    raiseGeocoderErrors,
    setDeliveryAddress,
    regionId,
  ]);

  useEffect(() => {
    updateDeliveryPrice(delivery.data?.price);
    setDeliveryPriceStatus(delivery.priceStatus);
    if (delivery.errors) {
      if (delivery.errors.geoPoint) {
        raiseGeocoderErrors();
      } else {
        setAddressErrors(delivery.errors);
        setGeocoderError(false);
      }
    } else {
      setAddressErrors({});
      setGeocoderError(false);
    }
  }, [delivery, raiseGeocoderErrors, setGeocoderError, updateDeliveryPrice]);

  const onSubmit = useCallback(
    (formData: ValidFormFields) => {
      const target = 'Checkout_Clicked';
      globalThis.ym?.(68302765, 'reachGoal', target);
      globalThis.gtag?.('event', target);
      if (!ordersCount) {
        return;
      }
      if (hasGeocoderError) {
        raiseGeocoderErrors();
        return;
      }
      const phone = getInternationalNumber(
        formData[FormFields.countryCode],
        formData[FormFields.phonePart],
      );
      if (!phone) {
        return;
      }
      if (formStatus === FormStatus.contactConfirmation) {
        const code = formData[FormFields.confirmation];
        if (code) {
          setContactStatus(ContactStatus.confirming);
          doConfirmContact({
            variables: {
              value: phone,
              code,
            },
          })
            .then(async res => {
              if (res.data) {
                const userId = res.data.confirmContact.contact.owner.id;
                setContactStatus(ContactStatus.confirmed);
                await storageUtils.set('userId', userId);
                await storageUtils.set('jwt', res.data.confirmContact.jwt);

                const buildingId = delivery?.data?.buildingAddressId;

                if (buildingId) {
                  setFormStatus(FormStatus.orderCreation);
                  await createOrder(formData, userId, buildingId);
                  return;
                }
              }
              raiseApiError();
            })
            .catch(err => {
              if (isConfirmationCodeMatchError(err.message as string)) {
                const newAttemptsLeft = attemptsLeft - 1;
                const message =
                  newAttemptsLeft <= 3
                    ? plural(
                        newAttemptsLeft,
                        'Осталась %d попытка',
                        'Осталось %d попытки',
                        'Осталось %d попыток',
                      )
                    : '';
                setError(FormFields.confirmation, {
                  type: 'manual',
                  message,
                });
                if (!newAttemptsLeft) {
                  setAttemptsLeft(MAX_CONFIRM_ATTEMPTS);
                  setSecondsLeft(MAX_CONFIRM_SECONDS);
                  setFormStatus(FormStatus.input);
                  setContactStatus(ContactStatus.declined);
                  return;
                }
                setAttemptsLeft(newAttemptsLeft);
              }
            });
        } else {
          setError(FormFields.confirmation, {
            type: 'manual',
            message: ``,
          });
        }
      } else if (formStatus === FormStatus.input) {
        setFormStatus(FormStatus.contactConfirmation);
        setContactStatus(ContactStatus.sending);
        queryContact({ phone: phone })
          .then(res => {
            if (res.data.contact) {
              doRequestConfirmation({
                variables: {
                  value: phone,
                },
              })
                .then(({ data }) => {
                  const sent = data?.requestContactConfirmation.confirmationCodeSent;
                  handleCodeSent(phone, sent);
                })
                .catch(err => {
                  handleCodeSent(phone, false);
                });
            } else {
              doSignUp({
                variables: {
                  data: {
                    firstName: formData[FormFields.name],
                    contactsData: [{ value: phone }],
                  },
                },
              })
                .then(res => {
                  handleCodeSent(
                    phone,
                    res.data?.signUp.user.contacts.find(it => it.value === phone)
                      ?.confirmationCodeSent,
                  );
                })
                .catch(err => {
                  handleCodeSent(phone, false);
                });
            }
          })
          .catch(err => {
            handleCodeSent(phone, false);
            setFormStatus(FormStatus.input);
            setContactStatus(ContactStatus.errorGetContact);
          });
      }
    },
    [
      ordersCount,
      hasGeocoderError,
      formStatus,
      raiseGeocoderErrors,
      doConfirmContact,
      raiseApiError,
      createOrder,
      attemptsLeft,
      setError,
      queryContact,
      doRequestConfirmation,
      handleCodeSent,
      doSignUp,
      delivery?.data?.buildingAddressId,
    ],
  );

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Backdrop open={formStatus === FormStatus.orderCreation} />
      <Alert
        open={isNewUserPopupOpen}
        onClose={() => {
          setNewUserPopupOpen(false);
        }}
        title={`Скидка ${newUserDiscountRate}% на 1-й заказ!`}
        message={`Попробуйте один из пакетов со скидкой ${newUserDiscountRate}%. Акция действует только на заказ одного пакета.`}
      />
      {currentError && !isNewUserPopupOpen && (
        <Error
          open={isErrorOpen}
          onClose={clearErrorDialog}
          title={currentError.title ?? 'Внимание!'}
          message={currentError.message}
        />
      )}
      <hr
        style={{
          backgroundColor: '#dfe1e4',
          marginTop: '0px',
          marginBottom: '16px',
        }}
      />
      <div className={'input-wrapper' + (errors[FormFields.phonePart] ? ' error' : '')}>
        {errors[FormFields.phonePart] && errors[FormFields.phonePart].message && (
          <IonLabel>{errors[FormFields.phonePart].message}</IonLabel>
        )}
        <PhoneInput
          defaultCountryCode={DEFAULT_COUNTRY_CODE}
          countryCode={cart.countryCode}
          phonePart={cart.phonePart}
          onSetPhone={handlePhoneChange}
          readOnly={formStatus !== FormStatus.input}
        />
      </div>
      <div className={'input-wrapper' + (errors[FormFields.name] ? ' error' : '')}>
        {errors[FormFields.name] && errors[FormFields.name].message && (
          <IonLabel>{errors[FormFields.name].message}</IonLabel>
        )}
        <input
          {...register(FormFields.name)}
          defaultValue={cart.name}
          placeholder="Имя"
          type="text"
          autoComplete="off"
          onChange={e => {
            clearErrors(FormFields.name);
            updateCartDebounced({ name: e.currentTarget.value });
          }}
        />
      </div>
      <div
        style={{ zIndex: 3 }}
        className={
          'input-wrapper' + (addressErrors.city || errors[FormFields.city] ? ' error' : '')
        }
      >
        {addressErrors.city || errors[FormFields.city] ? (
          <IonLabel>{addressErrors.city?.message ?? errors[FormFields.city]?.message}</IonLabel>
        ) : (
          <IonLabel onClick={() => switchOffice()}>
            <b>{city} / </b>
            <u style={{ cursor: 'pointer' }}>
              {officeUiKey === 'nsk' ? 'Санкт-Петербург' : 'Новосибирск'}
            </u>
          </IonLabel>
        )}

        <StyledAsyncSelect
          components={{ DropdownIndicator: () => null }}
          isClearable
          classNamePrefix={Prefix.default}
          autoComplete="off"
          noOptionsMessage={() => {
            const otherCity = officeUiKey === 'nsk' ? '\nСанкт-Петербурга' : 'Новосибирска';
            return `Не найдено. Если Вы с ${otherCity}, переключите город`;
          }}
          loadingMessage={() => 'Загрузка'}
          value={cart.city ? createOption(cart.city) : null}
          placeholder={`Город ${
            officeUiKey === 'nsk'
              ? '(Новосибирск, Краснообск или др.)'
              : '(Санкт-Петербург, Петергоф или др.)'
          }`}
          name={FormFields.city}
          className={'input' + (showOptions?.city ? '' : ' empty')}
          loadOptions={value => loadOptions('city', value)}
          onFocus={() => setShowOptions(it => ({ ...it, city: false }))}
          onMenuClose={() => setShowOptions(it => ({ ...it, city: false }))}
          onInputChange={val => val && clearAddressAndFormError('city')}
          onChange={(e: SelectOption<'city'> | null) => {
            !e && updateDeliveryPrice(undefined);
            updateCart({ city: e?.value });
          }}
        />
      </div>
      <div
        style={{ zIndex: 2 }}
        className={
          'input-wrapper' + (addressErrors.street || errors[FormFields.street] ? ' error' : '')
        }
      >
        {(addressErrors.street || errors[FormFields.street]) && (
          <IonLabel>{addressErrors.street?.message ?? errors[FormFields.street]?.message}</IonLabel>
        )}

        <StyledAsyncSelect
          components={{ DropdownIndicator: () => null }}
          isClearable
          classNamePrefix={Prefix.default}
          autoComplete="off"
          noOptionsMessage={() => (cart.city ? 'Не найдено' : 'Не указан город')}
          loadingMessage={() => 'Загрузка'}
          value={cart.street ? createOption(cart.street) : null}
          name={FormFields.street}
          placeholder="Улица"
          className={'input' + (showOptions?.street ? '' : ' empty')}
          loadOptions={value => loadOptions('street', value)}
          onFocus={() => setShowOptions(it => ({ ...it, street: false }))}
          onMenuClose={() => setShowOptions(it => ({ ...it, street: false }))}
          onInputChange={val => val && clearAddressAndFormError('street')}
          onChange={(e: SelectOption<'street'> | null) => {
            updateCart({ street: e?.value });
          }}
        />
      </div>
      <div style={{ zIndex: 1 }} className="split-2">
        <div
          className={
            'input-wrapper' +
            (addressErrors.building || errors[FormFields.building] ? ' error' : '')
          }
        >
          {(hasValue.building || addressErrors.building || errors[FormFields.building]) && (
            <IonLabel>
              {addressErrors.building?.message
                ? addressErrors.building?.message
                : errors[FormFields.building]?.message
                ? errors[FormFields.building]?.message
                : hasValue.building
                ? 'Дом'
                : undefined}
            </IonLabel>
          )}

          <StyledAsyncSelect
            components={{ DropdownIndicator: () => null }}
            isClearable
            classNamePrefix={Prefix.default}
            autoComplete="off"
            noOptionsMessage={() =>
              cart.city ? (cart.street ? 'Не найдено' : 'Не указана улица') : 'Не указан город'
            }
            loadingMessage={() => 'Загрузка'}
            value={cart.building ? createOption(cart.building) : null}
            name={FormFields.building}
            placeholder="Дом"
            className={'input' + (showOptions?.building ? '' : ' empty')}
            loadOptions={value => loadOptions('building', value)}
            onFocus={() => setShowOptions(it => ({ ...it, building: false }))}
            onMenuClose={() => setShowOptions(it => ({ ...it, building: false }))}
            onInputChange={val => val && clearAddressAndFormError('building')}
            onChange={(e: SelectOption<'building'> | null) => {
              updateCart({ building: e?.value });
            }}
          />
        </div>
        <div className={'input-wrapper' + (errors[FormFields.flat] ? ' error' : '')}>
          {hasValue.flat && (
            <IonLabel>
              {errors[FormFields.flat] && errors[FormFields.flat].message
                ? errors[FormFields.flat].message
                : 'Квартира'}
            </IonLabel>
          )}
          <input
            {...register(FormFields.flat)}
            defaultValue={cart.flat}
            placeholder="Квартира"
            type="text"
            autoComplete="off"
            onChange={e => {
              const val = e.currentTarget.value;
              setHasValue(it => ({ ...it, flat: !!val }));
              clearErrors(FormFields.flat);
              updateCartDebounced({ flat: val.length ? val : undefined });
            }}
          />
        </div>
      </div>
      <div className="split-2">
        <div className={'input-wrapper' + (errors[FormFields.entrance] ? ' error' : '')}>
          {hasValue.entrance && (
            <IonLabel>
              {errors[FormFields.entrance] && errors[FormFields.entrance].message
                ? errors[FormFields.entrance].message
                : 'Подъезд'}
            </IonLabel>
          )}
          <input
            {...register(FormFields.entrance)}
            defaultValue={cart.entrance}
            placeholder="Подъезд"
            type="text"
            autoComplete="off"
            onChange={e => {
              const val = e.currentTarget.value;
              setHasValue(it => ({ ...it, entrance: !!val }));
              clearErrors(FormFields.entrance);
              updateCartDebounced({ entrance: val.length ? val : undefined });
            }}
          />
        </div>
        <div className={'input-wrapper' + (errors[FormFields.floor] ? ' error' : '')}>
          {hasValue.floor && (
            <IonLabel>
              {errors[FormFields.floor] && errors[FormFields.floor].message
                ? errors[FormFields.floor].message
                : 'Этаж'}
            </IonLabel>
          )}
          <input
            {...register(FormFields.floor)}
            defaultValue={cart.floor}
            placeholder="Этаж"
            type="text"
            autoComplete="off"
            onChange={e => {
              const val = e.currentTarget.value;
              setHasValue(it => ({ ...it, floor: !!val }));
              clearErrors(FormFields.floor);
              updateCartDebounced({ floor: val.length ? val : undefined });
            }}
          />
        </div>
      </div>
      <hr
        style={{
          backgroundColor: '#dfe1e4',
          marginTop: '0px',
          marginBottom: '16px',
        }}
      />
      <div className={'input-wrapper' + (errors[FormFields.commentForChief] ? ' error' : '')}>
        {hasValue.commentForChief && (
          <IonLabel>
            {errors[FormFields.commentForChief] && errors[FormFields.commentForChief].message
              ? errors[FormFields.commentForChief].message
              : 'Комментарий повару (ко всему заказу)'}
          </IonLabel>
        )}
        <input
          {...register(FormFields.commentForChief)}
          defaultValue={cart.commentForChief}
          placeholder="Комментарий повару (ко всему заказу)"
          type="text"
          autoComplete="off"
          onChange={e => {
            const val = e.currentTarget.value;
            clearErrors(FormFields.commentForChief);
            updateCartDebounced({ commentForChief: val.length ? val : undefined });
          }}
        />
      </div>
      <div className={'input-wrapper' + (errors[FormFields.commentForCourier] ? ' error' : '')}>
        {hasValue.commentForCourier && (
          <IonLabel>
            {errors[FormFields.commentForCourier] && errors[FormFields.commentForCourier].message
              ? errors[FormFields.commentForCourier].message
              : 'Комментарий курьеру'}
          </IonLabel>
        )}
        <input
          {...register(FormFields.commentForCourier)}
          defaultValue={cart.commentForCourier}
          placeholder="Комментарий курьеру"
          type="text"
          autoComplete="off"
          onChange={e => {
            const val = e.currentTarget.value;
            clearErrors(FormFields.commentForCourier);
            updateCartDebounced({ commentForCourier: val.length ? val : undefined });
          }}
        />
      </div>
      <hr
        style={{
          backgroundColor: '#dfe1e4',
          marginTop: '0px',
          marginBottom: '16px',
        }}
      />

      <label htmlFor="printedMenu" className="checkbox">
        <input
          {...register(FormFields.printedMenu)}
          defaultChecked={cart.printedMenu}
          id="printedMenu"
          type="checkbox"
          autoComplete="off"
          value="true"
          onChange={e => {
            updateCartDebounced({ printedMenu: e.currentTarget.checked });
          }}
        />
        <span className="checkmark" />
        Положить меню
      </label>

      <label htmlFor="cutlery" className="checkbox">
        <input
          {...register(FormFields.cutlery)}
          defaultChecked={cart.cutlery}
          id="cutlery"
          type="checkbox"
          autoComplete="off"
          value="true"
          onChange={e => {
            updateCartDebounced({ cutlery: e.currentTarget.checked });
          }}
        />
        <span className="checkmark" />
        Положить приборы
      </label>

      <hr
        style={{
          backgroundColor: '#dfe1e4',
          marginTop: '0px',
          marginBottom: '16px',
        }}
      />

      {contactStatus === ContactStatus.sending && (
        <div className="form-spinner">
          <span>Отправка кода подтверждения</span>
          <IonSpinner name="dots" />
        </div>
      )}

      {(contactStatus === ContactStatus.codeSent || contactStatus === ContactStatus.confirming) &&
        !!phoneFull && (
          <div className="confirmation">
            <div className="confirmation-text">
              <span>
                На телефон <em>{phoneFull}</em> был отправлен проверочный код
              </span>
            </div>
            <div className={'input-wrapper' + (errors[FormFields.confirmation] ? ' error' : '')}>
              {errors[FormFields.confirmation] && errors[FormFields.confirmation].message && (
                <IonLabel>{errors[FormFields.confirmation].message}</IonLabel>
              )}
              <div className="button-inside">
                <input
                  {...register(FormFields.confirmation)}
                  className="input"
                  placeholder="Введите код"
                  type="text"
                  autoComplete="one-time-code"
                  onChange={() => secondsLeft && clearErrors(FormFields.confirmation)}
                />
                <IonButton
                  onClick={() => {
                    setFormStatus(FormStatus.input);
                    setContactStatus(undefined);
                    setAttemptsLeft(MAX_CONFIRM_ATTEMPTS);
                    setSecondsLeft(MAX_CONFIRM_SECONDS);
                  }}
                >
                  Отмена
                </IonButton>
              </div>
            </div>
          </div>
        )}

      <Tooltip
        disabled={!submitErrors}
        pureTrigger
        hoverDisabled
        trigger={
          <IonButton mode="md" type="submit" className="submit" disabled={!!submitErrors}>
            {formStatus === FormStatus.contactConfirmation ? (
              'Подтвердить'
            ) : formStatus === FormStatus.orderCreation ? (
              <div className="form-spinner">
                <span>Создание заказа</span>
                <IonSpinner name="dots" />
              </div>
            ) : (
              'Оформить заказ'
            )}
          </IonButton>
        }
      >
        {submitErrors}
      </Tooltip>

      <p className="privacy">
        Оставляя свои данные в форме, вы подтверждаете свое согласие с{' '}
        <a href="/privacy-policy" target="_blank">
          Политикой&nbsp;конфиденциальности
        </a>
      </p>
    </StyledForm>
  );
};

const StyledAsyncSelect = styled(AsyncSelect)`
  ${ReactSelectStyles}
  width: 100%;
  --padding: 16px;
  &.empty {
    .${Prefix.default}__menu {
      display: none;
    }
  }
  .${Prefix.default}__menu {
    width: calc(100% - var(--padding) * 2);
    .match {
      font-weight: 600;
    }
  }
  .parent {
    margin-left: 6px;
  }
`;

const StyledReactSelect = styled(ReactSelect)`
  ${ReactSelectStyles}
  width: 100%;
`;

const StyledForm = styled.form`
  ${FormStyle}
  padding: 20px 0 0 0;

  .input-wrapper {
    z-index: 0;
    position: relative;
    > * {
      z-index: 0;
    }
    ion-input,
    > input,
    .input {
      border: 1px solid #dfe1e4;
    }
  }
  ion-label {
    z-index: 1 !important;
    position: absolute;
    border-radius: 20px 20px 20px 20px;
    padding: 0 10px;
    left: 0;
    right: 0;
    top: -12px;
    line-height: 14pt;
    width: fit-content;
    margin: auto;
    background: #f1f3f7;
    color: #848484;
    border-bottom: 1px solid #dedede;
  }
  .error {
    ion-label {
      box-shadow: 0 2px 1px -1px #ff7b7b;
      border: none;
    }
    ion-input,
    input,
    .input {
      border: none !important;
    }
  }
  .button-inside {
    display: flex;
    input {
      border-radius: 25px 0 0 25px !important;
      padding-right: 0 !important;
      width: 100%;
    }
    ion-button {
      width: fit-content;
      min-width: 90px;
      height: unset;
      margin: 0;
      --box-shadow: none;
      --border-radius: 0 25px 25px 0;
      --padding-start: 5px;
      --padding-end: 5px;
      text-transform: none;
      font-weight: 400;
      font-size: 13px;

      /* --box-shadow: 1px 1px 2px 1px #0000002e; */
      --background: #e6e8ec;
      color: #848484;
      &::part(native) {
        border: 1px solid #dfe1e4;
        border-left: none;
      }
    }
  }
  .confirmation {
    margin-top: 20px;
    input {
      letter-spacing: 4px;
      &::placeholder {
        letter-spacing: 0;
      }
    }
  }
  .confirmation-text {
    text-align: center;
    margin-bottom: 25px;
    em {
      font-style: normal;
      padding: 2px 4px;
      color: #525252;
      background: #e6e6e6;
      border: 1px solid #dfe1e4;
      border-radius: 5px;
    }
  }

  .privacy {
    font-size: 10px;
    line-height: 12px;
    margin-top: 15px;
    text-align: center;
    color: #848484;
    a {
      border-bottom: 1px solid #b1b1b1;
      color: #848484;
      text-decoration: none;
    }
  }
  .form-spinner {
    padding-left: 25px;
    ion-spinner {
      width: 40px;
      height: 14px;
      margin-bottom: -4px;
    }
  }
  .checkbox {
    ${CheckboxStyle}
    margin-left: 10px;
  }

  .submit {
    --background: ${gradientGreen};
    --box-shadow: 0 2px 10px 0 #00000026;
    margin-top: 4px;
  }

  @media screen and (max-width: 600px) {
    .input-wrapper {
      ion-input,
      > input,
      .input {
        min-height: 45px !important;
      }
    }
    .split-2 {
      --space-between: 12px;
    }

    ion-button[type='submit'] {
      height: 45px;
      margin-top: 2px;
    }
  }
`;
