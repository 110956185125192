import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { big } from '@mono/shared';
import { BUILDING_ADDRESS_DELIVERY_DATA } from '../../apollo/api/queries/address-delivery-data';
import { GET_OR_CREATE_BUILDING_ADDRESS } from '../../apollo/api/queries/get-or-create-building-address';
import {
  BuildingAddressCreateData,
  BuildingAddressDeliveryData,
  BuildingAddressDeliveryDataVariables,
  GetOrCreateBuildingAddress,
  GetOrCreateBuildingAddress_getOrCreateBuildingAddressBy_geoPointOptions as GeoPointOption,
  GetOrCreateBuildingAddress_getOrCreateBuildingAddressBy_location as AddressLocation,
  GetOrCreateBuildingAddressVariables,
  JobStatus,
} from '../../apollo/api/types';
import { DeliveryPrice } from '../../hooks/prices/prices';
import { DeliveryPriceStatus } from './utils-form';
import { KladrErrors } from './utils-kladr';

const POLLING_TIMEOUT = 60 * 1000;

type DeliveryAddressErrors = KladrErrors & {
  geoPoint?: { message: string };
};

type DeliveryAddressData = {
  buildingAddressId: string;
  price: DeliveryPrice;
  location: AddressLocation | null;
};

export type DeliveryAddress = {
  data?: DeliveryAddressData;
  errors?: DeliveryAddressErrors;
  geoPointOptions?: GeoPointOption[];
  priceStatus: DeliveryPriceStatus;
};

const getDeliveryAddressData = (
  buildingAddress: BuildingAddressDeliveryData['buildingAddress'],
): DeliveryAddress['data'] => {
  if (buildingAddress && (buildingAddress.deliveryPrice || buildingAddress.customDeliveryPrice)) {
    const price = big(buildingAddress.deliveryPrice ?? buildingAddress.customDeliveryPrice);
    return {
      buildingAddressId: buildingAddress.id,
      price,
      location: buildingAddress.location,
    };
  }
};

const getDeliveryAddressErrors = (
  buildingAddress: BuildingAddressDeliveryData['buildingAddress'],
): DeliveryAddressErrors | undefined => {
  const errors: DeliveryAddressErrors = {};

  if (buildingAddress) {
    if (buildingAddress.invalidPart) {
      switch (buildingAddress.invalidPart) {
        case 'city': {
          errors.city = { message: '' };
          break;
        }
        case 'street': {
          errors.street = { message: '' };
          break;
        }
        case 'building': {
          errors.building = { message: '' };
          break;
        }
      }
    }
    if (!buildingAddress.location && !buildingAddress.customDeliveryPrice) {
      errors.geoPoint = { message: '' };
    }
  }

  if (Object.keys(errors).length) {
    return errors;
  }
};

const getDeliveryPriceStatus = (
  buildingAddress: BuildingAddressDeliveryData['buildingAddress'],
) => {
  const jobStatus = buildingAddress?.processJobStatus ?? undefined;
  let priceStatus = DeliveryPriceStatus.unset;
  if (jobStatus) {
    switch (jobStatus) {
      case JobStatus.Completed: {
        priceStatus =
          buildingAddress?.deliveryPrice || buildingAddress?.customDeliveryPrice
            ? DeliveryPriceStatus.calculated
            : DeliveryPriceStatus.unset;
        break;
      }
      case JobStatus.Failed: {
        priceStatus = DeliveryPriceStatus.unset;
        break;
      }
      case JobStatus.Running: {
        priceStatus = DeliveryPriceStatus.loading;
        break;
      }
    }
  }
  return priceStatus;
};

const isValidationFinished = (
  buildingAddress: BuildingAddressDeliveryData['buildingAddress'],
): boolean => {
  const status = buildingAddress?.processJobStatus;
  if (status) {
    switch (status) {
      case JobStatus.Completed: {
        return true;
      }
      case JobStatus.Failed: {
        return true;
      }
    }
  }
  return false;
};

const useDeliveryAddressData = () => {
  const [variables, setVariables] = useState<BuildingAddressDeliveryDataVariables>();
  const [result, setResult] = useState<DeliveryAddress>({ priceStatus: DeliveryPriceStatus.unset });

  const [doGetOrCreateAddress] = useMutation<
    GetOrCreateBuildingAddress,
    GetOrCreateBuildingAddressVariables
  >(GET_OR_CREATE_BUILDING_ADDRESS);

  const buildingAddressDeliveryDataQuery = useQuery<
    BuildingAddressDeliveryData,
    BuildingAddressDeliveryDataVariables
  >(BUILDING_ADDRESS_DELIVERY_DATA, {
    variables,
    skip: !variables,
  });

  const [hasApiError, setHasApiError] = useState(false);

  const [timeoutId, setTimeoutId] = useState<number>();

  const setPollingTimeout = useCallback(
    () => setTimeoutId(window.setTimeout(() => setHasApiError(true), POLLING_TIMEOUT)),
    [],
  );

  const clearPollingTimeout = useCallback(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(undefined);
    }
  }, [timeoutId]);

  useEffect(() => {
    result.data && clearPollingTimeout();
    return clearPollingTimeout;
  }, [clearPollingTimeout, result.data]);

  useEffect(() => {
    const buildingAddress = buildingAddressDeliveryDataQuery.data?.buildingAddress ?? null;
    let data: DeliveryAddress['data'];
    let errors: DeliveryAddress['errors'];
    const priceStatus: DeliveryAddress['priceStatus'] = getDeliveryPriceStatus(buildingAddress);

    if (isValidationFinished(buildingAddress)) {
      buildingAddressDeliveryDataQuery.stopPolling();
      errors = getDeliveryAddressErrors(buildingAddress);
      data = getDeliveryAddressData(buildingAddress);
    }
    if (hasApiError) {
      setResult({ errors: { geoPoint: { message: '' } }, priceStatus });
    } else {
      setResult({ data, errors, priceStatus });
    }
  }, [buildingAddressDeliveryDataQuery, hasApiError]);

  const setDeliveryAddress = useCallback(
    async (buildingAddress: BuildingAddressCreateData) => {
      clearPollingTimeout();
      try {
        const res = await doGetOrCreateAddress({
          variables: { buildingAddress },
        });
        if (res.data) {
          const deliveryAddress = res.data.getOrCreateBuildingAddressBy;

          if (!isValidationFinished(deliveryAddress)) {
            buildingAddressDeliveryDataQuery.startPolling(500);
            setPollingTimeout();
          }

          setHasApiError(false);
          setVariables({ id: deliveryAddress.id });
        }
      } catch (err) {
        setHasApiError(true);
      }
    },
    [
      doGetOrCreateAddress,
      buildingAddressDeliveryDataQuery,
      setPollingTimeout,
      clearPollingTimeout,
    ],
  );

  return [result, setDeliveryAddress] as [typeof result, typeof setDeliveryAddress];
};

export default useDeliveryAddressData;
