import { useEffect, useState } from 'react';
import { storageUtils } from '@mono/frontend';
import { fetchCartFormStorage } from '../hooks/cart-form/utils-storage';
import { fetchOrdersStorage, OrdersStorage } from '../hooks/orders/utils-storage';
import { CartFormStorage } from '../pages/cart/utils-form-fields';

export default () => {
  const [ordersStorage, setOrdersStorage] = useState<OrdersStorage>();
  const [cartFormStorage, setCartFormStorage] = useState<CartFormStorage>();

  useEffect(() => {
    const effect = async () => {
      fetchOrdersStorage().then(async res => {
        if (res.error) {
          await storageUtils.remove('orders');
        }
        setOrdersStorage({ orders: res.data });
      });
      fetchCartFormStorage().then(async res => {
        if (res.errors.length) {
          await storageUtils.remove(res.errors);
        }
        setCartFormStorage(res.data);
      });
    };
    effect();
  }, []);

  return [ordersStorage, cartFormStorage] as [OrdersStorage, CartFormStorage];
};
