import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IonButton, IonIcon } from '@ionic/react';
import { deliveryPerson, scientist } from '../../../assets/icon';
import { bottomCurve, mainPhoto, vegSketchLeft, vegSketchRight } from '../../../assets/images';
import { gradientGreen } from '../../styles/gradient';
import NewUserCheckPopup from './new-user-check-popup';
import { LandingAnchors } from './utils';

export default () => {
  return (
    <Styles className="header-padding">
      <div className="container">
        <div className="text">
          <h1>
            Доставка <br />
            сбалансированного готового питания <br />
          </h1>
          <div className="split-2 buttons">
            <Link to={'/#' + LandingAnchors.rations}>
              <IonButton mode="md">Перейти к выбору рациона</IonButton>
            </Link>
            <div>
              <NewUserCheckPopup
                discountAmount={25}
                trigger={
                  <IonButton mode="md" className="discount-btn">
                    Попробовать со скидкой 25%
                  </IonButton>
                }
              />
            </div>
          </div>
          <div className="split-2 info">
            <div>
              <IonIcon icon={deliveryPerson} className="with-spot" />
              <span />
              <p>Готовим и доставляем еду каждый день</p>
            </div>
            <div>
              <IonIcon icon={scientist} className="with-spot" />
              <span />
              <p>5-ти разовое питание, разработанное диетологом</p>
            </div>
          </div>
        </div>
        <div className="image-block">
          <img className="main" src={mainPhoto} alt="Даша" />
          <img className="left" src={vegSketchLeft} alt="Рискнки овощей на заднем плане" />
          <img className="right" src={vegSketchRight} alt="Рисунки овощей на заднем плане" />
        </div>
      </div>

      <IonIcon className="bottom-curve" icon={bottomCurve} />
    </Styles>
  );
};

const Styles = styled.div`
  background: var(--ion-background-color-second);
  position: relative;
  width: 100%;
  overflow: hidden;
  .container {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }
  h1,
  h2 {
    min-width: 450px;
  }

  .text {
    position: relative;
    z-index: 2;
    max-width: 40%;
    > * {
      margin-bottom: 30px;
    }
    h1,
    h2 {
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }
  .split-2 {
    margin-right: calc(100px - 10%);
    width: 400px;
  }
  ion-button {
    margin: 0;
    height: fit-content;
    --border-radius: 35px;
    white-space: pre-wrap;
    font-weight: 600;
    --background: ${gradientGreen};
    &::part(native) {
      line-height: 1.4em;
      min-height: 70px;
      padding: 5px 10px;
    }
    &.discount-btn {
      --background: linear-gradient(to bottom, #fd4135 0%, #d00c00 100%);
      &:hover {
        opacity: 0.85;
      }
    }
  }
  .info {
    width: 100%;
    > div {
      &:first-child ion-icon::after {
        right: -10px;
        bottom: -8px;
      }
      &:last-child ion-icon ::after {
        right: -5px;
        bottom: -8px;
      }
    }
    ion-icon {
      font-size: 70px;
    }
  }
  .image-block {
    position: relative;
    z-index: 0;
    height: fit-content;
    margin-top: auto;
    > img {
      position: absolute;
    }
    .main {
      z-index: 1;
      position: relative;
      margin-bottom: -10px;
      max-height: 500px;
    }
    .left {
      top: -5%;
      left: 15%;
      width: 28%;
    }
    .right {
      width: 80%;
      max-width: 300px;
      right: max(-270px, -65%);
      bottom: 40%;
    }
  }
  .bottom-curve {
    position: absolute;
    bottom: -2px;
    left: 0;
    color: white;
    height: 2.5vw;
    width: 100%;
  }
  @media screen and (min-width: 1280px) {
    .image-block {
      margin-top: 100px;
      transform: scale(1.2);
      .main {
        margin-bottom: 40px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    h1,
    h2 {
      min-width: 400px;
    }
    .text ion-icon {
      font-size: 60px;
    }
    .split-2 {
      width: 100%;
    }
  }
  @media screen and (max-width: 900px) {
    h1,
    h2 {
      min-width: 350px;
    }
  }
  @media screen and (max-width: 600px) {
    .container {
      flex-direction: column;
      > * {
        max-width: unset;
      }
    }
    h1,
    h2 {
      margin-top: 16px;
      min-width: unset;
      br {
        display: none;
      }
    }
    .image-block {
      width: calc(100% + 40px);
      align-self: center;
      margin-top: 5vw;
      .right {
        display: none;
      }
    }
    .split-2 {
      width: 100%;
      margin-right: 0;
    }
    .info {
      margin-top: 32px;
      ion-icon {
        padding-left: 5px;
      }
    }
    ion-button {
      font-size: 0.9em;
    }
  }
`;
