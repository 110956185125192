import { SharedDayjs } from '../../dayjs';
import { split } from '../../strings';

export const isDateStringFormattedStrictlyAs = (
  dayjs: SharedDayjs,
  dateString: string,
  format: string,
) => {
  const localDateFormat = format.replace('Z', '');
  const local = format === localDateFormat;
  const [localDate, offset] = local ? [dateString] : split(dateString, 'onLast', /[+-]/);
  if (!dayjs(localDate, localDateFormat, true).isValid()) {
    return false; // local date does not match format
  }
  if (local ? offset : !dayjs(offset, 'HH:mm', true).isValid()) {
    return false; // offset does not match format
  }
  return format;
};

export const getStrictDateStringFormat = (
  dayjs: SharedDayjs,
  dateString: string,
  formats: string[],
): string | undefined => {
  return formats.find(format => isDateStringFormattedStrictlyAs(dayjs, dateString, format));
};

export const isValidDateString = (
  dayjs: SharedDayjs,
  dateString: string,
  formats: string[],
): boolean => {
  return getStrictDateStringFormat(dayjs, dateString, formats) !== null;
};

export const createDate = (dayjs: SharedDayjs, dateString: string, formats: string[]) => {
  return dayjs(dateString, [...formats].reverse());
};

export const areSameDates = (
  dayjs: SharedDayjs,
  formats: string[],
  dateStringA: string,
  dateStringB: string,
) => {
  const dateA = createDate(dayjs, dateStringA, formats);
  const dateB = createDate(dayjs, dateStringB, formats);
  return dateA.isSame(dateB);
};

export const getLosslessDateStringFormats = (
  dayjs: SharedDayjs,
  dateString: string,
  formats: string[],
): string[] => {
  const strictFormat = getStrictDateStringFormat(dayjs, dateString, formats);
  if (strictFormat == null) {
    return [];
  }
  const date = createDate(dayjs, dateString, formats);
  return formats.filter(format => dayjs(dateString, format).isSame(date));
};
