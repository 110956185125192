import { css } from 'styled-components';
import { boxShadowStyle } from './box-shadow';

export const IonButtonStyle = css`
  --background: white;
  --background-activated: white;
  --ripple-color: white;
  --color: var(--ion-text-color);
  --border-radius: 45px;
  --padding-start: 20px;
  --padding-end: 20px;
  --box-shadow: ${boxShadowStyle};
  margin: 0;
  min-width: 45px;
  height: 45px;
  a {
    height: 100%;
    width: 100%;
  }
`;

export const IonButtonIconStyle = css`
  ${IonButtonStyle}
  --padding-start: 0;
  --padding-end: 0;
  ion-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    font-size: 20px;
  }
`;

export const IonButtonOutlineStyle = css`
  margin: 0;
  --height: 60px;
  height: var(--height);
  --padding-start: 0;
  --padding-end: 0;
  --border-radius: calc(var(--height) / 2);
  --border-width: 1px;
  --border-color: #dfe1e4;
  --border-style: solid;
  --background: transparent;
  --background-checked: #f2f4f7;
  --color-checked: #383838;
  --box-shadow: none;
  --color: #848484;
  letter-spacing: 0;
`;
