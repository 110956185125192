import { gql } from '@apollo/client';
import { PACK_DATA_FRAGMENT, PACK_ITEMS_DATA_FRAGMENT } from './fragments-pack';

export const PACKS_WITH_ITEMS = gql`
  ${PACK_DATA_FRAGMENT}
  ${PACK_ITEMS_DATA_FRAGMENT}
  query PacksWithItems {
    packs(where: { position: { gt: 0 } }, orderBy: { position: ASC }, useCache: true) {
      ...PackData
      ...PackItemsData
    }
  }
`;

export const PACKS_FOR_ORDER = gql`
  ${PACK_DATA_FRAGMENT}
  query PacksForOrder {
    packs(where: { position: { gt: 0 } }, orderBy: { position: ASC }, useCache: true) {
      ...PackData
    }
  }
`;
