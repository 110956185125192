import { Component } from 'react';
import { ErrorBoundary, ErrorBoundaryWithLogger } from './error-boundary';
import Logger from './error-logger';

export default class AppErrorBoundary extends Component {
  override render() {
    return (
      <ErrorBoundary>
        <Logger>
          <ErrorBoundaryWithLogger>{this.props.children}</ErrorBoundaryWithLogger>
        </Logger>
      </ErrorBoundary>
    );
  }
}
