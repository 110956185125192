import { BaseEntityData, DataI18n, i18n } from '@mono/shared';

export const MEAL_TYPE_DEFAULT_POSITION = 0;

export interface MealTypeData extends BaseEntityData {
  __typename: 'MealType';
  position: number;
  name: string;
}

export const mealTypeI18n = i18n<DataI18n<MealTypeData>>({
  'MealType': {
    en: 'Meal type',
    ru: 'Прием пищи',
  },
  'MealType:Plural': {
    en: 'Meal types',
    ru: 'Приемы пищи',
  },
  'MealType.position': {
    en: 'Position',
    ru: 'Положение',
  },
  'MealType.name': {
    en: 'Name',
    ru: 'Название',
  },
});
