import { BaseEntityData, DataI18n, i18n } from '@mono/shared';

export interface IngredientCategoryData extends BaseEntityData {
  __typename: 'IngredientCategory';
  parent?: IngredientCategoryData | null;
  name: string;
}

export const ingredientCategoryI18n = i18n<
  DataI18n<IngredientCategoryData, 'children' | 'ingredients' | 'ingredientsCount'>
>({
  'IngredientCategory': {
    en: 'Ingredient category',
    ru: 'Категория ингредиента',
  },
  'IngredientCategory:Plural': {
    en: 'Ingredient categories',
    ru: 'Категории ингредиентов',
  },
  'IngredientCategory.parent': {
    en: 'Parent',
    ru: 'Надкатегория',
  },
  'IngredientCategory.name': {
    en: 'Name',
    ru: 'Название',
  },
  'IngredientCategory:children': {
    en: 'Children',
    ru: 'Подкатегории',
  },
  'IngredientCategory:ingredients': {
    en: 'Ingredients',
    ru: 'Ингредиенты',
  },
  'IngredientCategory:ingredientsCount': {
    en: 'Ingredients count',
    ru: 'Кол-во ингредиентов',
  },
});
