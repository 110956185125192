import { i18n } from '@mono/shared';

export enum ProjectRole {
  OWNER = 'OWNER',
  MANAGER = 'MANAGER',
  NUTRITIONIST = 'NUTRITIONIST',
}

export type ProjectRoleKey = keyof typeof ProjectRole;

export const projectRoleI18n = i18n<ProjectRoleKey>({
  [ProjectRole.OWNER]: {
    en: 'Owner',
    ru: 'Владелец',
  },
  [ProjectRole.MANAGER]: {
    en: 'Manager',
    ru: 'Менеджер',
  },
  [ProjectRole.NUTRITIONIST]: {
    en: 'Nutritionist',
    ru: 'Диетолог',
  },
});
