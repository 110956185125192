import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import styled from 'styled-components';
import { certificates } from '../../../../assets/images';

const images = [certificates.certificate1, certificates.certificate2, certificates.certificate3];

export default (props: { isOpen: boolean; setOpen: (open: boolean) => void }) => {
  const [photoIndex, setPhotoIndex] = useState(0);

  if (props.isOpen) {
    return (
      <StyledLightbox
        mainSrc={images[photoIndex]}
        nextSrc={images[(photoIndex + 1) % images.length]}
        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        onCloseRequest={() => props.setOpen(false)}
        onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
      />
    );
  } else {
    return null;
  }
};

const StyledLightbox = styled(Lightbox)``;
