import { DataI18n, i18n } from '../../translation';
import { BaseEntityData } from '../base-entity';
import { UserData } from './user';

export const CONTACT_DEFAULT_CONFIRMED = false;

export interface ContactData extends BaseEntityData {
  __typename: 'Contact';
  value: string;
  confirmed: boolean;
  confirmationCode: string;
  confirmationCodeSent: boolean;
  confirmationExpirationMoment: string;
  owner: UserData;
}

export const contactI18n = i18n<DataI18n<ContactData>>({
  'Contact': {
    en: 'Contact',
    ru: 'Контакт',
  },
  'Contact:Plural': {
    en: 'Contacts',
    ru: 'Контакты',
  },
  'Contact.value': {
    en: 'Value',
    ru: 'Значение',
  },
  'Contact.confirmationCode': {
    en: 'Confirmation code',
    ru: 'Код подтверждения',
  },
  'Contact.confirmationCodeSent': {
    en: 'Confirmation code sent',
    ru: 'Код подтверждения отправлен',
  },
  'Contact.confirmationExpirationMoment': {
    en: 'Confirmation expiration moment',
    ru: 'Срок действия кода',
  },
  'Contact.confirmed': {
    en: 'Confirmed',
    ru: 'Подтвержден',
  },
  'Contact.owner': {
    en: 'Owner',
    ru: 'Владелец',
  },
});
