import DebounceLink from 'apollo-link-debounce';
import { createUploadLink } from 'apollo-upload-client';
import {
  ApolloClient,
  ApolloLink,
  FieldPolicy,
  InMemoryCache,
  NormalizedCacheObject,
  Reference,
} from '@apollo/client';
import {
  ANON,
  isEmptyObjectLiteral,
  mapValue,
  O,
  removeUndefinedProps,
  stringEnumKeys,
  upperCaseFirstLetter,
} from '@mono/shared';

export const DEBOUNCE_KEY = 'debounceKey';
export const DEBOUNCE_TIMEOUT = 'debounceTimeout';
export const DEBOUNCE_TIMEOUT_DEFAULT_VALUE = 400;

export enum GetOperation {
  user = 'user',
  contact = 'contact',
  storageObject = 'storageObject',
  storageObjectTag = 'storageObjectTag',
  cbsaCode = 'cbsaCode',
  country = 'country',
  postalCode = 'postalCode',
  location = 'location',
}

const getOperationPolicy: FieldPolicy<Reference> = {
  read(existing, { args, toReference, fieldName }) {
    const id = args?.by?.id;
    return id ? toReference({ __typename: upperCaseFirstLetter(fieldName), id }) : existing;
  },
};

export enum FindOperation {
  users = 'users',
  contacts = 'contacts',
  storageObjects = 'storageObjects',
  storageObjectTags = 'storageObjectTags',
  cbsaCodes = 'cbsaCodes',
  countries = 'countries',
  postalCodes = 'postalCodes',
  locations = 'locations',
}

const findOperationPolicy: FieldPolicy<Reference[]> = {
  keyArgs: ['where', 'orderBy'],
  merge(existing = [], incoming, { args }) {
    const noCriteria = isEmptyObjectLiteral(removeUndefinedProps(args?.where ?? {}));
    const noPaginationArgs = args?.skip == null && args?.take == null;
    if (noCriteria && noPaginationArgs) {
      throw Error('Neither criteria nor pagination args were provided for a paginated field');
    }
    const merged = [...existing];
    merged.splice(args?.skip ?? 0, incoming.length, ...incoming);
    return merged;
  },
};

const map = new Map<string, ApolloClient<NormalizedCacheObject>>();

export const getOrCreateApolloClient = ({
  uri,
  jwt,
  getOperations = [],
  findOperations = [],
}: {
  uri: string;
  jwt: O<string>;
  getOperations?: string[];
  findOperations?: string[];
}) => {
  return mapValue(map, `Access API at ${uri} as ${jwt ?? String(ANON)}`, () => {
    return new ApolloClient({
      link: ApolloLink.from([
        new DebounceLink(DEBOUNCE_TIMEOUT_DEFAULT_VALUE),
        createUploadLink({
          uri,
          headers: {
            ...(jwt && { authorization: `Bearer ${jwt}` }),
          },
        }),
      ]),
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: Object.assign(
              {},
              ...[...stringEnumKeys(GetOperation), ...getOperations].map(name => ({
                [name]: getOperationPolicy,
              })),
              ...[...stringEnumKeys(FindOperation), ...findOperations].map(name => ({
                [name]: findOperationPolicy,
              })),
            ),
          },
        },
      }),
    });
  });
};
