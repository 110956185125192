import { DataI18n, i18n, Language } from '../../translation';
import { BaseEntityData } from '../base-entity';
import { Gender } from './gender';
import { StorageObjectData } from './storage-object';

export const USER_DEFAULT_GENDER = Gender.UNKNOWN;
export const USER_DEFAULT_CONTACTS = [];

export interface UserData extends BaseEntityData {
  __typename: 'User';
  nickname: string | null;
  roles: string[];
  firstName: string;
  middleName: string | null;
  lastName: string | null;
  gender: Gender;
  birthday: string | null;
  language: Language | null;
  timezone: string | null;
  avatar: StorageObjectData | null;
}

export const userI18n = i18n<
  DataI18n<
    UserData,
    | 'password'
    | 'fullName'
    | 'addresses'
    | 'contacts'
    | 'contactsCount'
    | 'phones'
    | 'emails'
    | 'mainEmail'
    | 'mainPhone'
  >
>({
  'User': {
    en: 'User',
    ru: 'Пользователь',
  },
  'User:Plural': {
    en: 'Users',
    ru: 'Пользователи',
  },
  'User.nickname': {
    en: 'Nick',
    ru: 'Ник',
  },
  'User.roles': {
    en: 'Roles',
    ru: 'Роли',
  },
  'User.firstName': {
    en: 'First name',
    ru: 'Имя',
  },
  'User.middleName': {
    en: 'Middle name',
    ru: 'Отчество',
  },
  'User.lastName': {
    en: 'Last name',
    ru: 'Фамилия',
  },
  'User:password': {
    en: 'Password',
    ru: 'Пароль',
  },
  'User:fullName': {
    en: 'Full name',
    ru: 'Полное имя',
  },
  'User.gender': {
    en: 'Gender',
    ru: 'Гендер',
  },
  'User.birthday': {
    en: 'Birthday',
    ru: 'День рождения',
  },
  'User.avatar': {
    en: 'Avatar',
    ru: 'Аватар',
  },
  'User.language': {
    en: 'Language',
    ru: 'Язык',
  },
  'User.timezone': {
    en: 'Timezone',
    ru: 'Часовой пояс',
  },
  'User:addresses': {
    en: 'Addresses',
    ru: 'Адреса',
  },
  'User:contacts': {
    en: 'Contacts',
    ru: 'Контакты',
  },
  'User:contactsCount': {
    en: 'Contacts count',
    ru: 'Кол-во контактов',
  },
  'User:emails': {
    en: 'Emails',
    ru: 'Почты',
  },
  'User:phones': {
    en: 'Phones',
    ru: 'Телефоны',
  },
  'User:mainEmail': {
    en: 'Main email',
    ru: 'Почта',
  },
  'User:mainPhone': {
    en: 'Main phone',
    ru: 'Телефон',
  },
});
