import { Ability, AbilityBuilder, AnyAbility, InferSubjects, Subject } from '@casl/ability';
import { SharedEntity } from './shared-entity';
import { i18n } from './translation';
import { Writable } from './utils/types/writable';

export enum SharedAction {
  manage = 'manage',
  create = 'create',
  get = 'get',
  find = 'find',
  update = 'update',
  delete = 'delete',
}
export type SharedActionKey = keyof typeof SharedAction;

export enum SharedRole {
  ADMIN = 'ADMIN',
}

export type SharedRoleKey = keyof typeof SharedRole;

export const sharedRoleI18n = i18n<SharedRoleKey>({
  [SharedRole.ADMIN]: {
    en: 'Admin',
    ru: 'Админ',
  },
});

export type SharedSubject = InferSubjects<'all' | SharedEntity>;

export type SharedAbility = Ability<[SharedActionKey, SharedSubject]>;

export type Principal = {
  id: string;
  roles: string[];
};

export type AuthData = {
  principal: Principal | null;
};

export type GetAbilityOf = (authData: AuthData) => AnyAbility;

export const ANON = Symbol('ANON');
export const USER = Symbol('USER');

type Role<ProjectRoleKey extends string> =
  | typeof ANON
  | typeof USER
  | SharedRoleKey
  | ProjectRoleKey;

const getRolesBy = <ProjectRoleKey extends string>({
  principal,
}: AuthData): Role<ProjectRoleKey>[] => {
  const userRoles = principal?.roles as Role<ProjectRoleKey>[];
  return principal == null ? [ANON] : [ANON, USER, ...userRoles];
};

export type Permissions<
  ProjectRoleKey extends string,
  TAction extends string,
  TSubject extends Subject,
> = Record<
  Role<ProjectRoleKey>,
  (builder: AbilityBuilder<Ability<[TAction, TSubject]>>, data: AuthData) => void
>;

export type CanFn<UserAbility extends AnyAbility> = AbilityBuilder<UserAbility>['can'];

export const getGroupFn = <S extends Subject>() => {
  return <T extends S>(...subjects: readonly T[]) => subjects as Writable<T[]>;
};

const group = getGroupFn<SharedSubject>();

export const coreSubjects = group('User', 'Contact');

export const storageSubjects = group('StorageObjectTag', 'StorageObject');

export const geographySubjects = group('CbsaCode', 'Country', 'PostalCode', 'Location');

export const buildAbilityBy = <
  ProjectRoleKey extends string,
  ProjectAction extends string,
  ProjectSubject extends Subject,
>(
  authData: AuthData,
  permissions: Permissions<ProjectRoleKey, ProjectAction, ProjectSubject>,
): Ability<[ProjectAction, ProjectSubject]> => {
  const builder = new AbilityBuilder<Ability<[ProjectAction, ProjectSubject]>>(Ability);
  for (const role of getRolesBy(authData)) {
    if (typeof permissions[role] !== 'function') {
      throw new Error(`Trying to use unknown role "${String(role)}"`);
    }
    permissions[role](builder, authData);
  }
  return builder.build({
    detectSubjectType: subject => subject.__typename,
  });
};
