import { i18n } from './translation';

export enum JobStatus {
  Running = 'Running',
  Completed = 'Completed',
  Failed = 'Failed',
}

export type JobStatusKey = keyof typeof JobStatus;

export const jobStatusI18n = i18n<JobStatusKey>({
  [JobStatus.Completed]: {
    en: 'Completed',
    ru: 'Выполнена',
  },
  [JobStatus.Failed]: {
    en: 'Failed',
    ru: 'Выполнена с ошибкой',
  },
  [JobStatus.Running]: {
    en: 'Running',
    ru: 'Выполняется',
  },
});
