import { useRouter } from 'next/dist/client/router';
import { useContext } from 'react';
import { ApolloError, FetchResult, MutationResult, useMutation } from '@apollo/client';
import { i18n } from '@mono/shared';
import { ResetPassword, ResetPasswordVariables } from '../../../../gql-types';
import { SharedPublicRoute } from '../../app';
import { frontendI18n } from '../../frontend-i18n';
import { RESET_PASSWORD } from '../../gql/operations/auth/reset-password';
import { RootLayoutContext } from '../../layout/root';
import { useI18n } from '../../translation';

const resetPasswordI18n = i18n({
  ...frontendI18n.translations,
  'ResetPassword operation failed': {
    en: 'Failed to reset password',
    ru: 'Изменить пароль не удалось',
  },
  'Password reset successfully': {
    en: 'Password has been reset successfully',
    ru: 'Пароль успешно изменен',
  },
});

type ResetPasswordMutation = (args: {
  contactValue: string;
  confirmationCode: string;
  newPassword: string;
}) => Promise<FetchResult<ResetPassword>>;

export const useResetPassword = (): [ResetPasswordMutation, MutationResult<ResetPassword>] => {
  const t = useI18n(resetPasswordI18n);
  const rootLayoutContext = useContext(RootLayoutContext);
  const router = useRouter();
  const [resetPassword, resetPasswordResult] = useMutation<ResetPassword, ResetPasswordVariables>(
    RESET_PASSWORD,
    {
      onError: (error: ApolloError) => {
        console.error(error);
        rootLayoutContext?.pushNotification({
          type: 'warning',
          ...(error.networkError
            ? {
                title: t('Network error'),
                text: t('Please, try later'),
              }
            : {
                title: t('ResetPassword operation failed'),
                text: t('Check data and try again'),
              }),
        });
      },
      onCompleted: async () => {
        rootLayoutContext?.pushNotification({
          title: t('Password reset successfully'),
        });
        router.push(SharedPublicRoute.login);
      },
    },
  );
  const mutation: ResetPasswordMutation = async ({
    contactValue,
    confirmationCode,
    newPassword,
  }) => {
    return resetPassword({
      variables: {
        contactIdentifier: { value: contactValue },
        confirmationCode,
        newPassword,
      },
    });
  };
  return [mutation, resetPasswordResult];
};
