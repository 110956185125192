import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Anchor from '../../components/anchor';
import LandingUiContext from '../../context/ui/landing';
import useLandingState from '../../hooks/ui/landing';
import Advantages from './advantages';
import Calculator from './calculator';
import Delivery from './delivery';
import MainInfo from './main-info';
import Questions from './questions';
import Rations from './rations';
import Reviews from './reviews';
import { LandingAnchors, scrollToId, scrollToIdOnLoad } from './utils';
import Videos from './videos';

export default () => {
  const location = useLocation();
  const state = useLandingState();

  useEffect(() => {
    document.title = 'Готовая ПП еда на неделю с доставкой в Новосибирске | Happy Food';
    const description = `
      Доставка готовой здоровой еды на неделю в Новосибирске.
      Заказать сбалансированное правильное питание на дом. Happy Food. Заказы 24/7.
      Доставка ежедневно с 19:00 до 23:00. Тел. ${'+7 (963) 944-74-74'.replace(' ', '\xa0')}.`;
    document.querySelector('meta[name="description"]')?.setAttribute('content', description);
  }, []);

  useEffect(() => {
    scrollToId(location.hash.substring(1), 500);
  }, [location.hash]);

  useEffect(() => {
    scrollToIdOnLoad(location.hash.substring(1), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <LandingUiContext.Provider value={state}>
      <LandingStyles>
        <MainInfo />
        <Anchor id={LandingAnchors.calculator}>
          <Calculator />
        </Anchor>
        <Anchor id={LandingAnchors.rations}>
          <Rations />
        </Anchor>
        <Anchor id={LandingAnchors.advantages} component={Advantages} />
        <Anchor id={LandingAnchors.delivery} component={Delivery} />
        <Anchor id={LandingAnchors.ask} component={Questions} />
        <Anchor
          id={LandingAnchors.instagram}
          component={() => (
            <div className="container">
              <h3>Наш Instagram</h3>
              <div className="elfsight-app-dde18b05-bf1e-472d-866a-da9aa19428ca"></div>
            </div>
          )}
        />
        <Anchor id={LandingAnchors.videos} component={Videos} />
        <Anchor id={LandingAnchors.reviews} component={Reviews} />
      </LandingStyles>
    </LandingUiContext.Provider>
  );
};

const LandingStyles = styled.div`
  > div .container {
    padding-top: 1px;
    margin-top: -1px;
  }
`;
