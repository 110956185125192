export const currencies = {
  RUB: {
    symbol: '₽',
    full: 'рублей',
    short: 'руб',
    minimal: 'р',
  },
};

export const getCurrency = (currency: keyof typeof currencies) => currencies[currency];
