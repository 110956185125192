import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { storageUtils } from '@mono/frontend';
import { environment } from '../../environments/environment';

const httpLink = createHttpLink({ uri: environment.apiUrl + '/graphql' });

const authLink = setContext(async (_, { headers }) => {
  const token = await storageUtils.getOptional<string>('jwt');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
