import { trashOutline } from 'ionicons/icons';
import plural from 'plural-ru';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { IonButton, IonIcon } from '@ionic/react';
import { API_DAY_FORMAT, dayjs } from '@mono/shared';
import DatesContext from '../../../../context/dates';
import OrdersContext from '../../../../context/orders';
import NonWorkingDaysContext from '../../../../context/stoppage-days/stoppage-days';
import LandingUiContext from '../../../../context/ui/landing';
import { shiftDatesOfNewOrder } from '../../../../hooks/orders/utils';
import { gradientGreen } from '../../../../styles/gradient';
import { IonButtonIconStyle, IonButtonStyle } from '../../../../styles/ion-button-style';

const DAYS_IN_NEW_ORDER = 1;

const packsPlural = (number: number) => plural(number, 'Пакет', '%d пакета', '%d пакетов');
const addedPlural = (number: number) => plural(number, 'добавлен', 'добавлены');
const deletedPlural = (number: number) => plural(number, 'удалён', 'удалены');

const toastOptions = { autoClose: 3000, pauseOnHover: false, pauseOnFocusLoss: false };

const ToastAdded = (props: { name: string; amount: number }) => (
  <div>
    {packsPlural(props.amount)} <span className="bold">{props.name}</span>{' '}
    {addedPlural(props.amount)} в корзину
  </div>
);

const ToastDeleted = (props: { name: string; amount: number }) => (
  <div>
    {packsPlural(props.amount)} <span className="bold">{props.name}</span>{' '}
    {deletedPlural(props.amount)} из корзины
  </div>
);

export default () => {
  const [orders, addOrder, deleteOrder, updateOrder] = useContext(OrdersContext);
  const { deliveryStoppageDays } = useContext(NonWorkingDaysContext);
  const { firstMenuDate } = useContext(DatesContext);
  const [landingUi] = useContext(LandingUiContext);

  const [amountAdded, setAmountAdded] = useState(1);
  const [amountDeleted, setAmountDeleted] = useState(1);

  const toastAdded = useRef<ReturnType<typeof toast> | null>(null);
  const toastDeleted = useRef<ReturnType<typeof toast> | null>(null);

  useEffect(() => {
    setAmountAdded(1);
    setAmountDeleted(1);
  }, [landingUi.pack]);

  const selectedOrderItem = useMemo(
    () => orders.find(it => it.pack.id === landingUi.pack?.id),
    [orders, landingUi.pack],
  );

  const handleAddToCart = useCallback(() => {
    const target = 'AddToCart_Clicked';
    globalThis.ym?.(68302765, 'reachGoal', target);
    globalThis.gtag?.('event', target);
    if (landingUi.pack && deliveryStoppageDays) {
      // delete first empty order in cart
      let orderDeleted = false;
      if (orders.length === 1 && !orders[0].dates.length) {
        deleteOrder(orders[0].id);
        orderDeleted = false;
      }

      if (!orderDeleted && selectedOrderItem) {
        const datesOld = selectedOrderItem.dates;
        const dates = datesOld.concat(
          dayjs(datesOld[datesOld.length - 1])
            .add(1, 'days')
            .format(API_DAY_FORMAT),
        );
        updateOrder(shiftDatesOfNewOrder({ ...selectedOrderItem, dates }, deliveryStoppageDays));
      } else {
        // add new
        const dates: string[] = [];
        for (let i = 0; i < DAYS_IN_NEW_ORDER; i++) {
          dates.push(dayjs(firstMenuDate).add(i, 'days').format(API_DAY_FORMAT));
        }
        const newOrder = shiftDatesOfNewOrder(
          { pack: landingUi.pack, comment: '', dates, numWeeks: 2 },
          deliveryStoppageDays,
        );

        addOrder(newOrder);
      }

      // update toast
      const packName = landingUi.pack.name;
      toastAdded.current
        ? toast.update(toastAdded.current, {
            ...toastOptions,
            render: <ToastAdded name={packName} amount={amountAdded} />,
          })
        : (toastAdded.current = toast(<ToastAdded name={packName} amount={amountAdded} />, {
            ...toastOptions,
            onClose: () => {
              toastAdded.current = null;
              setAmountAdded(1);
            },
          }));
      setAmountAdded(it => it + 1);
    }
  }, [
    landingUi.pack,
    addOrder,
    amountAdded,
    firstMenuDate,
    orders,
    deleteOrder,
    deliveryStoppageDays,
    updateOrder,
    selectedOrderItem,
  ]);

  const handleDeleteFromCart = useCallback(() => {
    if (landingUi.pack) {
      const id = landingUi.pack.id;
      let index = orders.length - 1;
      let lastIndex: number | undefined = undefined;
      for (; index >= 0; index--) {
        if (orders[index].pack.id === id) {
          lastIndex = index;
          break;
        }
      }

      if (typeof lastIndex !== 'undefined') {
        deleteOrder(orders[lastIndex].id);
      }

      //update toast
      const packName = landingUi.pack.name;
      toastDeleted.current
        ? toast.update(toastDeleted.current, {
            render: <ToastDeleted name={packName} amount={amountDeleted} />,
          })
        : (toastDeleted.current = toast(<ToastDeleted name={packName} amount={amountDeleted} />, {
            ...toastOptions,
            onClose: () => {
              toastDeleted.current = null;
              setAmountDeleted(1);
            },
          }));

      setAmountDeleted(it => it + 1);
    }
  }, [landingUi.pack, deleteOrder, orders, amountDeleted]);

  if (landingUi.pack) {
    return (
      <Styles>
        <IonButton mode="md" className="add-btn" onClick={handleAddToCart}>
          Добавить
          <span>
            &nbsp;пакет <em>{landingUi.pack.name}</em>
          </span>
          &nbsp;в корзину
        </IonButton>
        {!!selectedOrderItem?.dates.length && (
          <IonButton mode="md" className="delete-btn" onClick={handleDeleteFromCart}>
            <IonIcon icon={trashOutline} />
          </IonButton>
        )}
      </Styles>
    );
  } else {
    return null;
  }
};

const Styles = styled.div`
  align-self: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .add-btn {
    ${IonButtonStyle}
    --background: ${gradientGreen};
    --color: white;
    em {
      font-weight: 800;
      font-style: normal;
    }
  }
  .delete-btn {
    ${IonButtonIconStyle}
    margin: 0;
    --box-shadow: none;
    --border-width: 1px;
    --border-style: solid;
    --border-color: #dfe1e4;
    --color: #848484;
    --ripple-color: #adff2f;
    --color-activated: #383838;
    &:hover {
      --border-color: #cccccc;
    }
  }

  .add-btn {
    margin: 0 16px;
    &:last-child {
      margin: 0;
    }
  }

  @media screen and (max-width: 920px) {
    width: 100%;
    margin-top: 16px;

    .add-btn {
      margin: 0 16px 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .add-btn {
      margin: 0 15px 0px 0;
      width: 100%;
      min-width: fit-content;
      span {
        display: none;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
`;
