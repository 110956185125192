import { i18n } from '@mono/shared';

export enum PaymentStatus {
  PriceNotFixed = 'PriceNotFixed',
  AwaitingPayment = 'AwaitingPayment',
  NotPaid = 'NotPaid',
  Underpaid = 'Underpaid',
  Paid = 'Paid',
  Overpaid = 'Overpaid',
}

export type PaymentStatusKey = keyof typeof PaymentStatus;

export const paymentStatusI18n = i18n<PaymentStatusKey>({
  [PaymentStatus.PriceNotFixed]: {
    en: 'Price not fixed',
    ru: 'Цена не зафиксирована',
  },
  [PaymentStatus.AwaitingPayment]: {
    en: 'Awaiting payment',
    ru: 'Ожидает оплаты',
  },
  [PaymentStatus.NotPaid]: {
    en: 'Not paid',
    ru: 'Не оплачен',
  },
  [PaymentStatus.Underpaid]: {
    en: 'Underpaid',
    ru: 'Недоплата',
  },
  [PaymentStatus.Paid]: {
    en: 'Paid',
    ru: 'Оплачен',
  },
  [PaymentStatus.Overpaid]: {
    en: 'Overpaid',
    ru: 'Переплата',
  },
});
