import { ObjectSchema, ref, string } from 'yup';
import { i18n, validation } from '@mono/shared';
import { loginSchemaErrorI18n } from './login-schema';
import { UsernameSchema, usernameSchema } from './username-schema';

export type SignupSchema = UsernameSchema & {
  password: string;
  passwordConfirmation: string;
  firstName: string;
  lastName: string;
  postalCodeNumber: string;
};

export const signupSchemaErrorI18n = i18n({
  ...loginSchemaErrorI18n.translations,
  'Password confirmation is required': {},
  'Passwords do not match': {},
  'First name is required': {},
  'First name is invalid': validation.firstName.error,
  'Last name is required': {},
  'Last name is invalid': validation.lastName.error,
});

export const signupSchema: ObjectSchema<SignupSchema> = usernameSchema('contact')
  .shape({
    password: string().required(loginSchemaErrorI18n.key('Password is required')),
    passwordConfirmation: string()
      .equals([ref('password')], signupSchemaErrorI18n.key('Passwords do not match'))
      .required(signupSchemaErrorI18n.key('Password confirmation is required')),
    firstName: string()
      .matches(validation.firstName.regex, signupSchemaErrorI18n.key('First name is invalid'))
      .required(signupSchemaErrorI18n.key('First name is required')),
    lastName: string()
      .matches(validation.lastName.regex, signupSchemaErrorI18n.key('Last name is invalid'))
      .required(signupSchemaErrorI18n.key('Last name is required')),
    postalCodeNumber: string().required(),
  })
  .defined();
