import { ComponentProps, useEffect, useMemo, useState } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import { useQuery } from '@apollo/client';
import { IonButton } from '@ionic/react';
import { dayjs } from '@mono/shared';
import { KITCHEN_WORK_STOPPAGE } from '../../apollo/api/queries/kitchen-stoppage';
import { KitchenWorkStoppage } from '../../apollo/api/types';
import { boxShadowStyle } from '../../styles/box-shadow';
import { gradientGreen } from '../../styles/gradient';
import { IonButtonStyle } from '../../styles/ion-button-style';

const WARNING_DAYS = 7;

const Announcement = (props: { message: string; onClick: () => void }) => {
  return (
    <AnnouncementStyles>
      <p>{props.message}</p>
      <IonButton onClick={props.onClick}>ОК</IonButton>
    </AnnouncementStyles>
  );
};

const swiperOptions: ComponentProps<typeof Swiper> = {
  allowTouchMove: false,
};

interface StoppageAnnouncement {
  id: string;
  message: string;
  days: string[];
}

export const Announcements = (props: { today: string }) => {
  const stoppagesQuery = useQuery<KitchenWorkStoppage>(KITCHEN_WORK_STOPPAGE);

  const filteredStoppages: StoppageAnnouncement[] = useMemo(() => {
    const filtered: StoppageAnnouncement[] = [];
    stoppagesQuery.data?.kitchenWorkStoppages?.forEach(it => {
      let message: string | undefined;
      for (const day of it.days) {
        const diff = dayjs(day).diff(dayjs(props.today), 'days');
        if (diff === 0) {
          message = it.description;
          break;
        } else if (diff > 0 && diff <= WARNING_DAYS) {
          message = it.warning;
        }
      }
      message &&
        filtered.push({
          id: it.id,
          days: it.days,
          message,
        });
    });
    return filtered;
  }, [props.today, stoppagesQuery.data]);

  const [shownIds, setShownIds] = useState<string[]>([]);
  const [shownIdsTemp, setShownIdsTemp] = useState<string[]>([]);

  const [swiper, setSwiper] = useState<SwiperClass>();

  const stoppagesToShow = useMemo(
    () => filteredStoppages.filter(it => shownIds.indexOf(it.id) === -1),
    [filteredStoppages, shownIds],
  );
  const [timeouts, setTimeouts] = useState<number[]>([]);

  const deleteTimeouts = () => {
    timeouts.forEach(it => clearTimeout(it));
    setTimeouts([]);
  };

  useEffect(() => {
    const poll = 2000;
    const timeout = 60 * 1000;
    const n = timeout / poll;
    const times: number[] = [];
    for (let i = 1; i < n; i++) {
      times.push(poll * i);
    }
    const timeoutid = times.map(time =>
      window.setTimeout(() => {
        swiper?.update();
      }, time),
    );
    setTimeouts(timeoutid);
    return () => timeoutid.forEach(it => clearTimeout(it));
  }, [swiper]);

  useEffect(() => {
    swiper?.update();
  }, [swiper, stoppagesQuery.data]);

  useEffect(() => {
    if (stoppagesToShow && shownIdsTemp.length >= stoppagesToShow.length) {
      setShownIds(shownIdsTemp);
    }
  }, [shownIdsTemp, stoppagesToShow]);

  const handleClickNext = (stoppageId: string) => {
    deleteTimeouts();
    setShownIdsTemp(it => [...it, stoppageId]);
    swiper?.slideNext();
  };

  return (
    <StyledPopup open={!!stoppagesToShow?.length} closeOnDocumentClick={false}>
      <Swiper {...swiperOptions} onSwiper={setSwiper}>
        {stoppagesToShow?.map(stoppage => (
          <SwiperSlide key={stoppage.id}>
            <Announcement {...stoppage} onClick={() => handleClickNext(stoppage.id)} />
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledPopup>
  );
};

const AnnouncementStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  > p {
    text-align: center;
    line-height: 16px;
    white-space: pre-wrap;
    max-width: 400px;
  }
  ion-button {
    ${IonButtonStyle}
    --background: ${gradientGreen};
    --color: white;
    width: 80px;
    height: 35px;
    margin-bottom: 10px;
  }
`;

const StyledPopup = styled(Popup)`
  &-content {
    width: 500px;
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: ${boxShadowStyle};

    .swiper-slide {
      margin: auto;
    }
  }
  &-overlay {
    background: #00000050;
  }
`;
