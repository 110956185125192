
import { OrderItem } from '../hooks/orders/utils';

export const getOrderItems = (orders: OrderItem[]) => {
  const packs: { pack: OrderItem['pack']; quantity: number }[] = [];
  orders.forEach(order => {
    const index = packs.findIndex(pack => pack.pack.id === order.pack.id);
    if (index === -1) {
      packs.push({ pack: order.pack, quantity: order.dates.length });
    } else {
      packs[index].quantity += order.dates.length;
    }
  });
  return packs;
};

export const countDeliveryDates = (orders: OrderItem[]) => {
  const arr: string[] = [];
  orders.forEach(order => order.dates.forEach(date => arr.push(date)));
  return new Set(arr).size;
};
