import { gql } from '@apollo/client';

export const CONFIRM_CONTACT = gql`
  mutation ConfirmContact($value: String!, $code: String!) {
    confirmContact(contactIdentifier: { value: $value }, confirmationCode: $code) {
      jwt
      contact {
        owner {
          id
        }
      }
    }
  }
`;
