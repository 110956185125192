/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import 'react-image-lightbox/style.css';
import 'react-day-picker/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/swiper-nav.min.css';
import 'swiper/swiper.scss';
import { ComponentProps, useEffect, useRef, useState } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { createGlobalStyle } from 'styled-components';
import { createAnimation, setupConfig } from '@ionic/core';
import { IonApp, IonContent, IonPage, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import AppErrorBoundary from './components/error-boundary/app-error-boundary';
import Footer from './components/footer';
import Header from './components/header';
import { headerHeight, headerTopHeight } from './components/header/header';
import AppContext from './context/context';
import CartPage from './pages/cart';
import ContactsPage from './pages/contacts';
import LandingPage from './pages/landing';
import PrivacyPolicyPage from './pages/privacy-policy';
import { gradientGreen } from './styles/gradient';
import { ToastStyles } from './styles/toast-styles';
import PageIds from './utils/page-ids';

const CustomIonicColorPalette = createGlobalStyle`
  /* Ionic Variables and Theming. For more info, please see:
  http://ionicframework.com/docs/theming/ */

  /** Ionic CSS Variables **/
  :root {
    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #f1f3f7;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-background-color: #fff;
    --ion-background-color-second: #f1f3f7;
  }

`;

const GeneralStyles = createGlobalStyle`
  ${ToastStyles}
  body {
    --ion-font-family: 'Montserrat', sans-serif;
    --ion-text-color: #383838;
    --ion-text-color-rgb: rgb(56, 56, 56);
    color: var(--ion-text-color);
    font-size: 10pt;
    line-height: 15pt;
  }
  .container {
    max-width: 1140px;
    padding: 0 20px;
    margin: 0 auto;
  }
  .header-padding {
    padding-top: ${headerHeight}px;
  }
  .block, .anchor {
    padding-top: 50px;
  }
  .block-no-spacing {
    margin-bottom: -50px;
  }
  h1, h2, h3 {
    font-size: 30pt;
    line-height: 34pt;
    font-weight: 800;
  }
  h1, h2, h3, h5 {
    margin-top: 0;
    margin-bottom: 40px;
  }
  ion-button {
    font-size: 9pt;
    font-weight: 600;
    letter-spacing: 0;
  }
  .with-spot {
    position: relative;
    contain: none;

    &::after {
      content: '';
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: block;
      position: absolute;
      bottom: -3px;
      background: #6bcb3c;
      background: ${gradientGreen};
      opacity: 0.8;
    }
  }
  .split-2 {
    --space-between: 16px;
    display: flex;
    > * {
      width: calc(50% - var(--space-between) / 2);
      margin-left: var(--space-between);
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .half {
    --space-between: 16px;
      width: calc(50% - var(--space-between) / 2) !important;
      margin-left: var(--space-between);
      &:nth-child(2n + 1) {
        margin-left: 0;
      }
  }
  .link {
    color: #59ae30;
    border-bottom: 1px solid #97d67a;
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      opacity: 0.85;
      filter: brightness(1.1);
    }
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset;
    -webkit-text-fill-color: var(--ion-text-color);
  }

  @media screen and (max-width: 1024px) {
    h1, h2, h3 {
      font-size: 26pt;
      line-height: 28pt;
    }
  }
  @media screen and (max-width: 900px) {
    h1, h2, h3 {
      font-size: 22pt;
      line-height: 24pt;
    }
    h1, h2, h3, h5 {
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    .block, .anchor {
      padding-top: 40px;
    }
    .block-no-spacing {
      margin-bottom: -40px;
    }
    
  }
  
  @media screen and (max-width: 600px) {
    .header-padding {
      padding-top: calc(${headerTopHeight}px + 18vw) !important;
    }
    .block, .anchor {
      padding-top: 30px;
    }
    .block-no-spacing {
      margin-bottom: -30px;
    }
    
  }
  @media screen and (max-width: 450px) {
    h1, h2, h3 {
      font-size: 18pt;
      line-height: 20pt;
    }
    h1, h2, h3, h5 {
      margin-bottom: 20px;
    }
    body {
      font-size: 0.8em;
      line-height: 1.2em;
    }
  }
`;

setupConfig({
  navAnimation: () => createAnimation(),
});

export const App = () => {
  return (
    <AppErrorBoundary>
      <IonApp>
        <CustomIonicColorPalette />
        <GeneralStyles />
        <AppContext>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
          />
          <div id="popup-root" />

          <IonReactRouter>
            <IonRouterOutlet>
              <Route exact path={'/privacy-policy'}>
                <object
                  data="assets/privacy-policy.pdf"
                  type="application/pdf"
                  width="100%"
                  height="100%"
                >
                  <PrivacyPolicyPage />
                </object>
              </Route>

              <MainLayout exact path="/" component={LandingPage} pageId={PageIds.landing} />
              <MainLayout exact path="/cart" component={CartPage} />
              <MainLayout exact path="/contacts" component={ContactsPage} />

              <Route render={() => <Redirect to="/" />} />
            </IonRouterOutlet>
          </IonReactRouter>
        </AppContext>
      </IonApp>
    </AppErrorBoundary>
  );
};

const stickLocations = ['/'];

const MainLayout = (props: ComponentProps<typeof Route> & { pageId?: string }) => {
  const { component: Component, pageId, ...rest } = props;
  const [useSticky, setUseSticky] = useState(false);
  const [stick, setStick] = useState(false);
  const [scrollBarWidth, setScrollBarWidth] = useState(-1);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const location = useLocation();

  function updateStickyHeader(position: number) {
    if (position > headerTopHeight) {
      !stick && setStick(true);
    } else {
      stick && setStick(false);
    }
  }

  useEffect(() => {
    setUseSticky(stickLocations.indexOf(location.pathname) !== -1);
  }, [location.pathname]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current
        .getScrollElement()
        .then(el => setScrollBarWidth(el.offsetWidth - el.clientWidth));
    } else {
      setScrollBarWidth(0);
    }
  }, [scrollBarWidth]);

  useEffect(() => {
    if (!useSticky && contentRef.current) {
      contentRef.current.scrollToTop();
      setStick(false);
    }
  }, [useSticky]);

  return (
    <Route
      {...rest}
      render={matchProps => (
        <IonPage id={pageId}>
          <IonContent
            ref={contentRef}
            scrollEvents
            onIonScroll={useSticky ? e => updateStickyHeader(e.detail.currentY) : undefined}
          >
            <Header stick={stick} scrollBarWidth={scrollBarWidth} />
            {Component && <Component {...matchProps} />}
            <Footer />
          </IonContent>
        </IonPage>
      )}
    />
  );
};

export default App;
