import { DataI18n, i18n } from '../../translation';
import { BaseEntityData } from '../base-entity';

export interface CountryData extends BaseEntityData {
  __typename: 'Country';
  code: string;
}

export const countryI18n = i18n<DataI18n<CountryData, 'locations' | 'postalCodes'>>({
  'Country': {
    en: 'Country',
    ru: 'Страна',
  },
  'Country:Plural': {
    en: 'Countries',
    ru: 'Страны',
  },
  'Country.code': {
    en: 'Code',
    ru: 'Код',
  },
  'Country:locations': {
    en: 'Locations',
    ru: 'Местоположения',
  },
  'Country:postalCodes': {
    en: 'Postal codes',
    ru: 'Почтовые индексы',
  },
});
