/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BuildingAddressDeliveryData
// ====================================================

export interface BuildingAddressDeliveryData_buildingAddress_location_geoPoint {
  __typename: "GeoPoint";
  latitude: number;
  longitude: number;
}

export interface BuildingAddressDeliveryData_buildingAddress_location {
  __typename: "Location";
  geoPoint: BuildingAddressDeliveryData_buildingAddress_location_geoPoint;
}

export interface BuildingAddressDeliveryData_buildingAddress_geoPointOptions {
  __typename: "GeoPointOption";
  longitude: number;
  latitude: number;
  doubtful: boolean;
}

export interface BuildingAddressDeliveryData_buildingAddress {
  __typename: "BuildingAddress";
  id: string;
  invalidPart: BuildingAddressUidPart | null;
  location: BuildingAddressDeliveryData_buildingAddress_location | null;
  geoPointOptions: BuildingAddressDeliveryData_buildingAddress_geoPointOptions[] | null;
  deliveryPrice: any | null;
  processJobStatus: JobStatus | null;
  customDeliveryPrice: any | null;
}

export interface BuildingAddressDeliveryData {
  buildingAddress: BuildingAddressDeliveryData_buildingAddress | null;
}

export interface BuildingAddressDeliveryDataVariables {
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BuildingAddresses
// ====================================================

export interface BuildingAddresses_buildingAddresses {
  __typename: "BuildingAddress";
  id: string;
}

export interface BuildingAddresses {
  buildingAddresses: BuildingAddresses_buildingAddresses[];
}

export interface BuildingAddressesVariables {
  regionType?: string | null;
  region?: string | null;
  cityType?: string | null;
  city?: string | null;
  streetType?: string | null;
  street?: string | null;
  streetOwner?: string | null;
  streetOwnerType?: string | null;
  buildingType?: string | null;
  building?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CalorieCalculatorAdvice
// ====================================================

export interface CalorieCalculatorAdvice_calorieCalculatorAdvice_packs {
  __typename: "Pack";
  id: string;
  name: string;
  price: any;
  position: number;
  targetCalorieContent: number | null;
}

export interface CalorieCalculatorAdvice_calorieCalculatorAdvice {
  __typename: "CalorieCalculatorAdvice";
  caloricIntake: number;
  packs: CalorieCalculatorAdvice_calorieCalculatorAdvice_packs[];
}

export interface CalorieCalculatorAdvice {
  /**
   * Based on Mifflin St. Jeor Equation
   */
  calorieCalculatorAdvice: CalorieCalculatorAdvice_calorieCalculatorAdvice;
}

export interface CalorieCalculatorAdviceVariables {
  weight: number;
  height: number;
  age: number;
  sex: Sex;
  activityLevel: ActivityLevel;
  weightGoal: WeightGoal;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmContact
// ====================================================

export interface ConfirmContact_confirmContact_contact_owner {
  __typename: "User";
  id: string;
}

export interface ConfirmContact_confirmContact_contact {
  __typename: "Contact";
  owner: ConfirmContact_confirmContact_contact_owner;
}

export interface ConfirmContact_confirmContact {
  __typename: "ContactAuth";
  jwt: string;
  contact: ConfirmContact_confirmContact_contact;
}

export interface ConfirmContact {
  /**
   * Checks whether confirmation code matches one sent to contact.
   * If confirmation code does match, marks contact as confirmed and authenticates user.
   * 
   * It's second step in two-step process of contact confirmation.
   * See requestContactConfirmation mutation for details about the process.
   */
  confirmContact: ConfirmContact_confirmContact;
}

export interface ConfirmContactVariables {
  value: string;
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContactOwnerInfo
// ====================================================

export interface ContactOwnerInfo_contact_owner_addresses_buildingAddress {
  __typename: "BuildingAddress";
  cityName: string;
  streetName: string;
  buildingName: string;
}

export interface ContactOwnerInfo_contact_owner_addresses {
  __typename: "CustomerAddress";
  buildingAddress: ContactOwnerInfo_contact_owner_addresses_buildingAddress;
  string: string;
  entrance: string;
  floor: string;
  unit: string;
}

export interface ContactOwnerInfo_contact_owner {
  __typename: "User";
  firstName: string;
  lastName: string | null;
  addresses: ContactOwnerInfo_contact_owner_addresses[];
}

export interface ContactOwnerInfo_contact {
  __typename: "Contact";
  id: string;
  value: string;
  owner: ContactOwnerInfo_contact_owner;
}

export interface ContactOwnerInfo {
  contact: ContactOwnerInfo_contact | null;
}

export interface ContactOwnerInfoVariables {
  contactValue?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Contact
// ====================================================

export interface Contact_contact_owner {
  __typename: "User";
  id: string;
}

export interface Contact_contact {
  __typename: "Contact";
  id: string;
  owner: Contact_contact_owner;
}

export interface Contact {
  contact: Contact_contact | null;
}

export interface ContactVariables {
  phone?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateBuildingAddress
// ====================================================

export interface CreateBuildingAddress_createBuildingAddress {
  __typename: "BuildingAddress";
  id: string;
}

export interface CreateBuildingAddress {
  createBuildingAddress: CreateBuildingAddress_createBuildingAddress;
}

export interface CreateBuildingAddressVariables {
  data: BuildingAddressCreateData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCustomerAddress
// ====================================================

export interface CreateCustomerAddress_createCustomerAddress {
  __typename: "CustomerAddress";
  id: string;
}

export interface CreateCustomerAddress {
  createCustomerAddress: CreateCustomerAddress_createCustomerAddress;
}

export interface CreateCustomerAddressVariables {
  data: CustomerAddressCreateData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOrder
// ====================================================

export interface CreateOrder_createOrder {
  __typename: "Order";
  id: string;
}

export interface CreateOrder {
  createOrder: CreateOrder_createOrder;
}

export interface CreateOrderVariables {
  data: OrderCreateData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePaymentUrl
// ====================================================

export interface CreatePaymentUrl_generateOrderBalancePaymentUrl {
  __typename: "PaymentUrl";
  value: string;
}

export interface CreatePaymentUrl {
  generateOrderBalancePaymentUrl: CreatePaymentUrl_generateOrderBalancePaymentUrl | null;
}

export interface CreatePaymentUrlVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CustomerAddresses
// ====================================================

export interface CustomerAddresses_customerAddresses {
  __typename: "CustomerAddress";
  id: string;
  floor: string;
  entrance: string;
}

export interface CustomerAddresses {
  customerAddresses: CustomerAddresses_customerAddresses[];
}

export interface CustomerAddressesVariables {
  unit?: string | null;
  floor?: string | null;
  entrance?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Discounts
// ====================================================

export interface Discounts_discounts {
  __typename: "Discount";
  id: string;
  name: string;
  rate: number;
  cumulative: boolean;
  packsCountLowerLimit: number | null;
  packsCountUpperLimit: number | null;
  priceLowerLimit: any | null;
  priceUpperLimit: any | null;
  daysCountLowerLimit: number | null;
  daysCountUpperLimit: number | null;
  startMoment: any | null;
  endMoment: any | null;
  userHadNoTransactions: boolean | null;
  promoCode: string | null;
  selfDelivery: boolean | null;
}

export interface Discounts {
  discounts: Discounts_discounts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DishInstances
// ====================================================

export interface DishInstances_dishInstances_menuItem_mealType {
  __typename: "MealType";
  name: string;
  position: number;
}

export interface DishInstances_dishInstances_menuItem_image {
  __typename: "StorageObject";
  id: string;
  name: string;
  url: string;
}

export interface DishInstances_dishInstances_menuItem {
  __typename: "MenuItem";
  id: string;
  name: string;
  mealType: DishInstances_dishInstances_menuItem_mealType | null;
  image: DishInstances_dishInstances_menuItem_image | null;
}

export interface DishInstances_dishInstances_dish_image {
  __typename: "StorageObject";
  id: string;
  name: string;
  url: string;
}

export interface DishInstances_dishInstances_dish_ingredients {
  __typename: "Ingredient";
  name: string;
}

export interface DishInstances_dishInstances_dish_configs_type {
  __typename: "DishConfigType";
  position: number;
  name: string;
  id: string;
}

export interface DishInstances_dishInstances_dish_configs_nutritionFacts {
  __typename: "NutritionFacts";
  proteins: number;
  fats: number;
  carbohydrates: number;
  calories: number;
}

export interface DishInstances_dishInstances_dish_configs {
  __typename: "DishConfig";
  type: DishInstances_dishInstances_dish_configs_type;
  nutritionFacts: DishInstances_dishInstances_dish_configs_nutritionFacts;
}

export interface DishInstances_dishInstances_dish {
  __typename: "Dish";
  name: string;
  image: DishInstances_dishInstances_dish_image | null;
  description: string;
  ingredients: DishInstances_dishInstances_dish_ingredients[];
  configs: DishInstances_dishInstances_dish_configs[];
}

export interface DishInstances_dishInstances {
  __typename: "DishInstance";
  id: string;
  day: any;
  menuItem: DishInstances_dishInstances_menuItem;
  dish: DishInstances_dishInstances_dish;
}

export interface DishInstances {
  dishInstances: DishInstances_dishInstances[];
}

export interface DishInstancesVariables {
  firstDate?: any | null;
  lastDate?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FixPrices
// ====================================================

export interface FixPrices_fixPricesOfOrderAndRelatedDeliveries {
  __typename: "Order";
  balance: any | null;
}

export interface FixPrices {
  fixPricesOfOrderAndRelatedDeliveries: FixPrices_fixPricesOfOrderAndRelatedDeliveries;
}

export interface FixPricesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GetOrCreateBuildingAddress
// ====================================================

export interface GetOrCreateBuildingAddress_getOrCreateBuildingAddressBy_location_geoPoint {
  __typename: "GeoPoint";
  latitude: number;
  longitude: number;
}

export interface GetOrCreateBuildingAddress_getOrCreateBuildingAddressBy_location {
  __typename: "Location";
  geoPoint: GetOrCreateBuildingAddress_getOrCreateBuildingAddressBy_location_geoPoint;
}

export interface GetOrCreateBuildingAddress_getOrCreateBuildingAddressBy_geoPointOptions {
  __typename: "GeoPointOption";
  longitude: number;
  latitude: number;
  doubtful: boolean;
}

export interface GetOrCreateBuildingAddress_getOrCreateBuildingAddressBy {
  __typename: "BuildingAddress";
  id: string;
  invalidPart: BuildingAddressUidPart | null;
  location: GetOrCreateBuildingAddress_getOrCreateBuildingAddressBy_location | null;
  geoPointOptions: GetOrCreateBuildingAddress_getOrCreateBuildingAddressBy_geoPointOptions[] | null;
  deliveryPrice: any | null;
  processJobStatus: JobStatus | null;
  customDeliveryPrice: any | null;
}

export interface GetOrCreateBuildingAddress {
  getOrCreateBuildingAddressBy: GetOrCreateBuildingAddress_getOrCreateBuildingAddressBy;
}

export interface GetOrCreateBuildingAddressVariables {
  buildingAddress: BuildingAddressUidInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GetOrCreateCustomerAddress
// ====================================================

export interface GetOrCreateCustomerAddress_getOrCreateCustomerAddressBy {
  __typename: "CustomerAddress";
  id: string;
}

export interface GetOrCreateCustomerAddress {
  getOrCreateCustomerAddressBy: GetOrCreateCustomerAddress_getOrCreateCustomerAddressBy;
}

export interface GetOrCreateCustomerAddressVariables {
  data: CustomerAddressUidInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: KitchenWorkStoppage
// ====================================================

export interface KitchenWorkStoppage_kitchenWorkStoppages {
  __typename: "KitchenWorkStoppage";
  id: string;
  warning: string;
  description: string;
  days: any[];
}

export interface KitchenWorkStoppage {
  kitchenWorkStoppages: KitchenWorkStoppage_kitchenWorkStoppages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Now
// ====================================================

export interface Now {
  now: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Order
// ====================================================

export interface Order_order {
  __typename: "Order";
  transactionsCount: number;
  balance: any | null;
}

export interface Order {
  order: Order_order | null;
}

export interface OrderVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PacksWithItems
// ====================================================

export interface PacksWithItems_packs_items_menuItem {
  __typename: "MenuItem";
  id: string;
  name: string;
  position: number;
}

export interface PacksWithItems_packs_items_dishConfigType {
  __typename: "DishConfigType";
  id: string;
  name: string;
}

export interface PacksWithItems_packs_items {
  __typename: "PackItem";
  menuItem: PacksWithItems_packs_items_menuItem;
  dishConfigType: PacksWithItems_packs_items_dishConfigType;
}

export interface PacksWithItems_packs {
  __typename: "Pack";
  id: string;
  name: string;
  price: any;
  position: number;
  targetCalorieContent: number | null;
  items: PacksWithItems_packs_items[];
}

export interface PacksWithItems {
  packs: PacksWithItems_packs[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PacksForOrder
// ====================================================

export interface PacksForOrder_packs {
  __typename: "Pack";
  id: string;
  name: string;
  price: any;
  position: number;
  targetCalorieContent: number | null;
}

export interface PacksForOrder {
  packs: PacksForOrder_packs[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LocateBuildingAddressById
// ====================================================

export interface LocateBuildingAddressById {
  queueProcessBuildingAddressJob: JobStatus | null;
}

export interface LocateBuildingAddressByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestContactConfirmation
// ====================================================

export interface RequestContactConfirmation_requestContactConfirmation {
  __typename: "Contact";
  confirmationCodeSent: boolean;
}

export interface RequestContactConfirmation {
  /**
   * Sends message with confirmation code to specified contact.
   * 
   * It's first step in two-step process of contact confirmation.
   * 
   * Сontact confirmation process serves two purposes:
   * - confirms contact if it is unconfirmed
   * - allows user to be authenticated by owned contact
   * 
   * See confirmContact mutation for details about second step.
   */
  requestContactConfirmation: RequestContactConfirmation_requestContactConfirmation;
}

export interface RequestContactConfirmationVariables {
  value: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendTelegramMessageToOfficeChat
// ====================================================

export interface SendTelegramMessageToOfficeChat {
  sendTelegramMessageToOfficeChat: boolean;
}

export interface SendTelegramMessageToOfficeChatVariables {
  officeCode: OfficeCode;
  officeChat: TelegramOfficeChat;
  text: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignUp
// ====================================================

export interface SignUp_signUp_user_contacts {
  __typename: "Contact";
  confirmationCodeSent: boolean;
  value: string;
}

export interface SignUp_signUp_user_profile {
  __typename: "Profile";
  id: string;
}

export interface SignUp_signUp_user {
  __typename: "User";
  id: string;
  contacts: SignUp_signUp_user_contacts[];
  profile: SignUp_signUp_user_profile;
}

export interface SignUp_signUp {
  __typename: "UserAuth";
  user: SignUp_signUp_user;
  jwt: string;
}

export interface SignUp {
  /**
   * Creates user and authenticates it.
   * 
   * Each user always has one or more identifiers and must have authentication data.
   * 
   * Identifiers are intended to uniquely identify users.
   * Types of identifiers:
   * - id
   * - nickname
   * - contacts (emails, phones; TODO: identifiers of social networks and messengers)
   * 
   * Authentication data is intended to authenticate users.
   * Different types of authentication data may be used with different types of identifiers:
   * - password may be used with id, nickname or confirmed contact (see logIn mutations)
   * - confirmation code may be used with any contact (see requestContactConfirmation mutation)
   * 
   * Anyway, user cannot be created without a valid data to be authenticated with.
   */
  signUp: SignUp_signUp;
}

export interface SignUpVariables {
  data: UserCreateData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StorageObject
// ====================================================

export interface StorageObject_storageObject {
  __typename: "StorageObject";
  id: string;
  url: string;
  name: string;
}

export interface StorageObject {
  storageObject: StorageObject_storageObject | null;
}

export interface StorageObjectVariables {
  id: string;
  width?: number | null;
  height?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TransactionsCount
// ====================================================

export interface TransactionsCount_contact_owner_profile {
  __typename: "Profile";
  transactionsCount: number;
}

export interface TransactionsCount_contact_owner {
  __typename: "User";
  profile: TransactionsCount_contact_owner_profile;
}

export interface TransactionsCount_contact {
  __typename: "Contact";
  owner: TransactionsCount_contact_owner;
}

export interface TransactionsCount {
  contact: TransactionsCount_contact | null;
}

export interface TransactionsCountVariables {
  value: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUser
// ====================================================

export interface UpdateUser_updateUser {
  __typename: "User";
  id: string;
}

export interface UpdateUser {
  updateUser: UpdateUser_updateUser;
}

export interface UpdateUserVariables {
  id: string;
  data: UserUpdateData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BuildingAddressDeliveryDataFragment
// ====================================================

export interface BuildingAddressDeliveryDataFragment_location_geoPoint {
  __typename: "GeoPoint";
  latitude: number;
  longitude: number;
}

export interface BuildingAddressDeliveryDataFragment_location {
  __typename: "Location";
  geoPoint: BuildingAddressDeliveryDataFragment_location_geoPoint;
}

export interface BuildingAddressDeliveryDataFragment_geoPointOptions {
  __typename: "GeoPointOption";
  longitude: number;
  latitude: number;
  doubtful: boolean;
}

export interface BuildingAddressDeliveryDataFragment {
  __typename: "BuildingAddress";
  id: string;
  invalidPart: BuildingAddressUidPart | null;
  location: BuildingAddressDeliveryDataFragment_location | null;
  geoPointOptions: BuildingAddressDeliveryDataFragment_geoPointOptions[] | null;
  deliveryPrice: any | null;
  processJobStatus: JobStatus | null;
  customDeliveryPrice: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: dishData
// ====================================================

export interface dishData_image {
  __typename: "StorageObject";
  id: string;
  name: string;
  url: string;
}

export interface dishData_ingredients {
  __typename: "Ingredient";
  name: string;
}

export interface dishData_configs_type {
  __typename: "DishConfigType";
  position: number;
  name: string;
  id: string;
}

export interface dishData_configs_nutritionFacts {
  __typename: "NutritionFacts";
  proteins: number;
  fats: number;
  carbohydrates: number;
  calories: number;
}

export interface dishData_configs {
  __typename: "DishConfig";
  type: dishData_configs_type;
  nutritionFacts: dishData_configs_nutritionFacts;
}

export interface dishData {
  __typename: "Dish";
  name: string;
  image: dishData_image | null;
  description: string;
  ingredients: dishData_ingredients[];
  configs: dishData_configs[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PackData
// ====================================================

export interface PackData {
  __typename: "Pack";
  id: string;
  name: string;
  price: any;
  position: number;
  targetCalorieContent: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PackItemsData
// ====================================================

export interface PackItemsData_items_menuItem {
  __typename: "MenuItem";
  id: string;
  name: string;
  position: number;
}

export interface PackItemsData_items_dishConfigType {
  __typename: "DishConfigType";
  id: string;
  name: string;
}

export interface PackItemsData_items {
  __typename: "PackItem";
  menuItem: PackItemsData_items_menuItem;
  dishConfigType: PackItemsData_items_dishConfigType;
}

export interface PackItemsData {
  __typename: "Pack";
  items: PackItemsData_items[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActivityLevel {
  ABOVE_AVERAGE = "ABOVE_AVERAGE",
  AVERAGE = "AVERAGE",
  BELLOW_AVERAGE = "BELLOW_AVERAGE",
  HIGH = "HIGH",
  LOW = "LOW",
  MAXIMAL = "MAXIMAL",
  MINIMAL = "MINIMAL",
}

export enum BuildingAddressUidPart {
  building = "building",
  city = "city",
  district = "district",
  region = "region",
  street = "street",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
  UNKNOWN = "UNKNOWN",
}

export enum JobStatus {
  Completed = "Completed",
  Failed = "Failed",
  Running = "Running",
}

export enum Language {
  en = "en",
  ru = "ru",
}

export enum OfficeCode {
  RU_NOVOSIBIRSK = "RU_NOVOSIBIRSK",
  RU_SAINT_PETERSBURG = "RU_SAINT_PETERSBURG",
}

export enum PaymentMethod {
  CARD = "CARD",
  CASH = "CASH",
  REMITTANCE = "REMITTANCE",
  TERMINAL = "TERMINAL",
}

export enum Sex {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export enum TelegramOfficeChat {
  MANAGER = "MANAGER",
  NUTRITIONIST = "NUTRITIONIST",
  OPERATOR = "OPERATOR",
}

export enum WeightGoal {
  GAIN = "GAIN",
  LOSS = "LOSS",
  MAINTENANCE = "MAINTENANCE",
}

export interface BuildingAddressCreateData {
  buildingName: string;
  buildingPostalCodeNumber: string;
  buildingType: string;
  cityName: string;
  cityType: string;
  customDeliveryPrice?: any | null;
  districtName: string;
  districtType: string;
  locationIdentifier?: LocationIdentifier | null;
  regionName: string;
  regionType: string;
  streetName: string;
  streetOwnerName: string;
  streetOwnerType: string;
  streetType: string;
}

/**
 * Unique by:
 * - regionType, regionName, districtType, districtName, cityType, cityName, streetOwnerType, streetOwnerName, streetType, streetName, buildingType, buildingName, buildingPostalCodeNumber
 */
export interface BuildingAddressIdentifier {
  buildingName?: string | null;
  buildingPostalCodeNumber?: string | null;
  buildingType?: string | null;
  cityName?: string | null;
  cityType?: string | null;
  districtName?: string | null;
  districtType?: string | null;
  id?: string | null;
  regionName?: string | null;
  regionType?: string | null;
  streetName?: string | null;
  streetOwnerName?: string | null;
  streetOwnerType?: string | null;
  streetType?: string | null;
}

export interface BuildingAddressUidInput {
  buildingName: string;
  buildingPostalCodeNumber: string;
  buildingType: string;
  cityName: string;
  cityType: string;
  districtName: string;
  districtType: string;
  regionName: string;
  regionType: string;
  streetName: string;
  streetOwnerName: string;
  streetOwnerType: string;
  streetType: string;
}

export interface ContactCompositionCreateData {
  confirmed?: boolean | null;
  value: string;
}

export interface ContactCompositionUpdateData {
  connect?: ContactIdentifier[] | null;
  create?: ContactCompositionCreateData[] | null;
  delete?: ContactIdentifier[] | null;
}

/**
 * Unique by:
 * - value
 */
export interface ContactIdentifier {
  id?: string | null;
  value?: string | null;
}

export interface CustomerAddressCreateData {
  buildingAddressCustomDeliveryPrice?: any | null;
  buildingAddressIdentifier: BuildingAddressIdentifier;
  comment?: string | null;
  entrance: string;
  floor: string;
  profileIdentifier: ProfileIdentifier;
  unit: string;
}

/**
 * Unique by:
 * - userId, buildingAddressId, entrance, floor, unit
 */
export interface CustomerAddressIdentifier {
  buildingAddressId?: string | null;
  entrance?: string | null;
  floor?: string | null;
  id?: string | null;
  unit?: string | null;
  userId?: string | null;
}

export interface CustomerAddressUidInput {
  buildingAddressIdentifier: BuildingAddressIdentifier;
  entrance: string;
  floor: string;
  unit: string;
  userIdentifier: UserIdentifier;
}

export interface LocationIdentifier {
  id?: string | null;
}

/**
 * Unique by:
 * - code
 * - buildingAddressId
 */
export interface OfficeIdentifier {
  buildingAddressId?: string | null;
  code?: OfficeCode | null;
  id?: string | null;
}

export interface OrderCreateData {
  commentForChief?: string | null;
  commentForCourier?: string | null;
  customDiscountRate?: number | null;
  customerProfileIdentifier: ProfileIdentifier;
  itemsDeliveriesData?: OrderItemDeliveriesData[] | null;
  numberOfCutlery?: number | null;
  officeIdentifier: OfficeIdentifier;
  paymentMethod: PaymentMethod;
  paymentOnDeliveryAllowed?: boolean | null;
  printedMenuRequired?: boolean | null;
  promoCode?: string | null;
  recipientAddressIdentifier: CustomerAddressIdentifier;
  selfDelivery?: boolean | null;
}

export interface OrderItemDeliveriesData {
  comment: string;
  eatingDays: any[];
  orderItemId: string;
  packIdentifier: PackIdentifier;
}

/**
 * Unique by:
 * - name
 */
export interface PackIdentifier {
  id?: string | null;
  name?: string | null;
}

/**
 * Unique by:
 * - userId
 */
export interface ProfileIdentifier {
  id?: string | null;
  userId?: string | null;
}

export interface StorageObjectIdentifier {
  id?: string | null;
}

export interface UserCreateData {
  avatarIdentifier?: StorageObjectIdentifier | null;
  birthday?: any | null;
  contactsData?: ContactCompositionCreateData[] | null;
  firstName: string;
  gender?: Gender | null;
  language?: Language | null;
  lastName?: string | null;
  middleName?: string | null;
  nickname?: string | null;
  password?: string | null;
  timezone?: string | null;
}

/**
 * Unique by:
 * - nickname
 */
export interface UserIdentifier {
  id?: string | null;
  nickname?: string | null;
}

export interface UserUpdateData {
  avatarIdentifier?: StorageObjectIdentifier | null;
  birthday?: any | null;
  contactsData?: ContactCompositionUpdateData | null;
  firstName?: string | null;
  gender?: Gender | null;
  language?: Language | null;
  lastName?: string | null;
  middleName?: string | null;
  nickname?: string | null;
  password?: string | null;
  timezone?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
