import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { IonButton, IonLabel } from '@ionic/react';
import { getDefinedOrThrow, phoneInternationalToCisString, validation } from '@mono/shared';
import { SEND_TELEGRAM_MESSAGE_TO_OFFICE_CHAT } from '../../apollo/api/queries/send-telegram-message-to-office-chat';
import {
  SendTelegramMessageToOfficeChat,
  SendTelegramMessageToOfficeChatVariables,
  TelegramOfficeChat,
} from '../../apollo/api/types';
import PhoneInput from '../../components/phone-input';
import { cisPhoneRegex } from '../../consts';
import ContactContext from '../../context/contact';
import { OfficeContext } from '../../context/office/office';
import { FormStyle } from '../../styles/form-style';
import { gradientGreen } from '../../styles/gradient';
import { ShadowStyle } from '../../styles/shadow-style';
import { PhoneParts } from '../phone-input/phone-input';

enum formFields {
  name = 'XKjld90sdf-name',
  phone = 'XKjld90sdf-phone',
}

interface FormFields {
  [formFields.name]: string;
  [formFields.phone]: string;
}

const schema: yup.ObjectSchema<FormFields> = yup
  .object()
  .shape({
    [formFields.phone]: yup.string().required('').matches(cisPhoneRegex, 'Неверный формат номера'),
    [formFields.name]: yup
      .string()
      .required('')
      .min(2, 'Не менее 2-х символов')
      .matches(validation.firstName.regex, 'Недопустимые символы'),
  })
  .defined();

const ConsultForm = () => {
  const {
    officeUiData: { officeCode },
  } = getDefinedOrThrow(useContext(OfficeContext));

  const [contact] = useContext(ContactContext);
  const [requestSent, setRequestSent] = useState(false);

  const [doSendTelegramMessageToOfficeChat] = useMutation<
    SendTelegramMessageToOfficeChat,
    SendTelegramMessageToOfficeChatVariables
  >(SEND_TELEGRAM_MESSAGE_TO_OFFICE_CHAT);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onSubmit',
  });

  const handlePhoneChange = useCallback(
    (phone: PhoneParts) => {
      clearErrors(formFields.phone);
      setValue(formFields.phone, phone.int);
      setRequestSent(false);
    },
    [setValue, clearErrors],
  );

  useEffect(() => {
    register(formFields.phone);
  }, [register]);

  useEffect(() => {
    setValue(formFields.name, contact.name);
    setValue(formFields.phone, '+' + contact.countryCode + contact.phonePart);
  }, [setValue, contact]);

  const onSubmit = async (data: FormFields) => {
    const target = 'ConsultMe_Submited';
    globalThis.ym?.(68302765, 'reachGoal', target);
    globalThis.gtag?.('event', target);
    setRequestSent(true);
    const message =
      'Заявка на консультацию по питанию: ' +
      data[formFields.name] +
      ', ' +
      phoneInternationalToCisString(data[formFields.phone]);

    try {
      const res = await doSendTelegramMessageToOfficeChat({
        variables: {
          officeCode,
          officeChat: TelegramOfficeChat.NUTRITIONIST,
          text: message,
        },
      });
      if (res.data?.sendTelegramMessageToOfficeChat) {
        toast('Заявка успешно отправлена');
      }
    } catch (err) {
      console.log(err);
      toast('Не удалось отправить заявку', { type: 'warning' });
    }
  };

  return (
    <Styles>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4>Получите бесплатную консультацию по питанию</h4>
        <div className={'input-wrapper' + (errors.name ? ' error' : '')}>
          <IonLabel>{errors.name?.message.length ? errors.name.message : 'Имя'}</IonLabel>
          <input
            {...register(formFields.name)}
            className="input"
            placeholder="Ваше имя"
            autoComplete="off"
            defaultValue={contact.name}
            onChange={() => clearErrors('name')}
          />
        </div>
        <div className={'input-wrapper' + (errors.phone ? ' error' : '')}>
          <IonLabel>
            {errors.phone?.message.length ? errors.phone.message : 'Номер телефона'}
          </IonLabel>
          <PhoneInput
            onSetPhone={handlePhoneChange}
            phonePart={contact.phonePart}
            countryCode={contact.countryCode}
          />
        </div>
        <IonButton mode="md" type="submit" disabled={requestSent}>
          Отправить
        </IonButton>
        <p className="privacy">
          Оставляя свои данные в форме, вы подтверждаете свое согласие с{' '}
          <a href="/privacy-policy" target="_blank">
            Политикой&nbsp;конфиденциальности
          </a>
        </p>
      </form>
    </Styles>
  );
};

const Styles = styled.div`
  form {
    ${ShadowStyle}
    ${FormStyle}
    max-width: 280px;
    min-width: 280px;
    --padding: 15px;
  }
  ion-button {
    --background: ${gradientGreen};
  }
`;

export default ConsultForm;
