import { HTMLProps, useCallback, useEffect, useState } from 'react';

interface Props {
  formatWith?: ' ' | ',';
  autoformat?: boolean;
  value?: number;
  disallowEmpty?: boolean;
  onSetValue: (value: number | undefined) => void;
  onShowError?: (error: { code: number; message: string }) => void;
}

export const IntegerInput = (props: Props & HTMLProps<HTMLInputElement>) => {
  const { formatWith, autoformat, disallowEmpty, onSetValue, onShowError, ...inputProps } = props;
  const [timeoutRef, setTimeoutRef] = useState<number | undefined>(undefined);
  const [valueString, setValueString] = useState('');
  const [lastKnownValue, setLastKnownValue] = useState('');

  function parseNumberOfFormat(str: string, format?: string) {
    const oldValue = format ? str.split(format).join('') : str;
    return parseInt(oldValue, 10);
  }

  const formatNumber = useCallback(
    (number: number) => {
      if (isNaN(number)) {
        return '';
      } else {
        if (props.formatWith) {
          if (props.formatWith === ',') {
            return number.toLocaleString();
          } else {
            return number.toLocaleString().split(',').join(' ');
          }
        } else {
          return number.toString();
        }
      }
    },
    [props.formatWith],
  );

  useEffect(() => {
    if (props.value && valueString === '') {
      setValueString(formatNumber(props.value));
    }
  }, [props.value, valueString, formatNumber]);

  return (
    <input
      {...inputProps}
      type="text"
      inputMode="numeric"
      value={valueString}
      onKeyDown={e => {
        e.currentTarget.value.length && setLastKnownValue(e.currentTarget.value);
        if (e.key.length === 1) {
          const parsedValue = props.formatWith
            ? (e.currentTarget.value + e.key).split(props.formatWith).join('')
            : e.currentTarget.value + e.key;
          const matches = parsedValue.match(/\d+/g);
          const isMatch = matches ? matches[0] === parsedValue : false;
          if (!e.ctrlKey && !(isMatch || props.formatWith === e.key)) {
            e.preventDefault();
          }
        }
      }}
      onChange={e => {
        const number = parseNumberOfFormat(e.currentTarget.value, props.formatWith);
        if (props.max && number > props.max) {
          onShowError && onShowError({ code: 1, message: 'Max value exceeded' });
          return;
        }
        props.onSetValue(isNaN(number) ? undefined : number);
        setValueString(isNaN(number) ? '' : e.currentTarget.value);

        if (props.autoformat) {
          if (timeoutRef) {
            clearTimeout(timeoutRef);
          }
          const target = e.currentTarget;
          const newValue = formatNumber(number);
          setTimeoutRef(window.setTimeout(() => (target.value = newValue), 1000));
        }
      }}
      onBlur={e => {
        if (disallowEmpty && valueString === '') {
          setValueString(lastKnownValue);
          const number = parseInt(lastKnownValue);
          props.onSetValue(isNaN(number) ? undefined : number);
        } else {
          const value = formatNumber(parseNumberOfFormat(e.currentTarget.value, props.formatWith));
          setValueString(value);
          if (timeoutRef) {
            clearTimeout(timeoutRef);
          }
          setTimeoutRef(undefined);
        }
        props.onBlur && props.onBlur(e);
      }}
    />
  );
};
