import { FC } from 'react';

export const withDebugLogs = <T extends unknown>(name: string, component: FC<T>): FC<T> => {
  return (...args) => {
    console.time(name);
    const element = component(...args);
    console.timeEnd(name);
    return element;
  };
};
