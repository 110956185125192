import { AA } from './types/shortcuts';

// Returns all object keys, event nested:
// Based on: https://stackoverflow.com/a/53593328/13869834
const getKeys = (value: unknown): string[] => {
  const keys: string[] = [];
  const seen: AA = {};
  JSON.stringify(value, (key, value) => {
    if (!(key in seen)) {
      keys.push(key);
      seen[key] = null;
    }
    return value;
  });
  return keys;
};

export const stringify = <T>(
  value: T,
  {
    format,
    sortKeys,
    includeFunctions,
  }: { format?: boolean } & (
    | { sortKeys?: boolean; includeFunctions?: never }
    | { sortKeys?: never; includeFunctions?: boolean }
  ),
): string => {
  const space = format ? 2 : undefined;
  if (sortKeys) {
    return JSON.stringify(value, getKeys(value).sort(), space);
  }
  if (includeFunctions) {
    return JSON.stringify(value, (_, v) => (typeof v === 'function' ? String(v) : v), space);
  }
  return JSON.stringify(value, undefined, space);
};

export const eqInJson = (a: unknown, b: unknown) => {
  return stringify(a, { sortKeys: true }) === stringify(b, { sortKeys: true });
};

export const parse = <T>(json: string, { as: type }: { as?: 'object' } = {}): T | null => {
  let value: T;
  try {
    value = JSON.parse(json);
  } catch (error) {
    return null;
  }
  if (type && typeof value !== type) {
    return null;
  }
  return value;
};
