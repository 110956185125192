import { AA } from '@mono/shared';
import { DishInstances, PacksWithItems_packs_items } from '../../apollo/api/types';

export function getDishByIndex(
  packItem: PacksWithItems_packs_items,
  dishInstances: DishInstances['dishInstances'],
) {
  for (const dishInstance of dishInstances) {
    if (packItem.menuItem.id === dishInstance.menuItem.id) {
      for (const dishConfig of dishInstance.dish.configs) {
        if (packItem.dishConfigType.id === dishConfig.type.id) {
          return { dishInstance, dishConfig };
        }
      }
    }
  }
  return undefined;
}

export type Dish = ReturnType<typeof getDishByIndex>;
export type Menu = AA<AA<Dish[]>>;
