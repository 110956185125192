import { Big } from 'big.js';
import { Subtype } from '@mono/shared';
import { TransactionData } from './transaction';

const calculateTotalAmount = (
  transactions: Subtype<TransactionData, { amount: string }>[],
): Big => {
  return transactions.map(it => it.amount).reduce((a, b) => a.plus(b), Big(0));
};

export const transactionDataUtils = {
  calculateTotalAmount,
};
