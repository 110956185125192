import * as yup from 'yup';
import { stringEnumKeys, Subtype, validation } from '@mono/shared';
import { getInternationalNumber } from '../../components/phone-input/phone-input';
import { cisPhoneRegex } from '../../consts';
import { kladrBuildingObjSchema, KladrObject, kladrObjSchema, mismatch } from './utils-kladr';

export const MAX_CONFIRM_ATTEMPTS = 6;
export const MAX_CONFIRM_SECONDS = 300;

export const DEFAULT_COUNTRY_CODE = 7;

export enum FormFields {
  phonePart = 'Xmdf1fads-phonePart',
  countryCode = 'Xmdf1fads-countryCode',
  name = 'Xmdf1fads-name',
  city = 'Xmdf1fads-city',
  street = 'Xmdf1fads-street',
  building = 'Xmdf1fads-building',
  flat = 'Xmdf1fads-flat',
  entrance = 'Xmdf1fads-entrance',
  floor = 'Xmdf1fads-floor',
  commentForChief = 'Xmdf1fads-commentForChief',
  commentForCourier = 'Xmdf1fads-commentForCourier',
  promocode = 'Xmdf1fads-promocode',
  cutlery = 'Xmdf1fads-cutlery',
  printedMenu = 'Xmdf1fads-printedMenu',
  confirmation = 'Xmdf1fads-confirmation',
}

type ValidFormFieldsTemplate = {
  [key in FormFields]?: unknown;
};

export type ValidFormFields = Subtype<
  ValidFormFieldsTemplate,
  {
    [FormFields.countryCode]: number;
    [FormFields.phonePart]: string;
    [FormFields.name]: string;
    [FormFields.city]: KladrObject<'city'>;
    [FormFields.street]: KladrObject<'street'>;
    [FormFields.building]: KladrObject<'building'>;
    [FormFields.flat]: string;
    [FormFields.entrance]?: string;
    [FormFields.floor]?: string;
    [FormFields.commentForChief]?: string;
    [FormFields.commentForCourier]?: string;
    [FormFields.promocode]?: string;
    [FormFields.cutlery]?: boolean;
    [FormFields.printedMenu]?: boolean;
    [FormFields.confirmation]?: string;
  }
>;

export const schema = yup
  .object()
  .shape({
    [FormFields.countryCode]: yup.number().required(''),
    [FormFields.phonePart]: yup.string().required(''),
    [FormFields.name]: yup
      .string()
      .required('')
      .min(2, 'Не менее 2-х символов')
      .matches(validation.firstName.regex, 'Введите имя, без фамилии'),
    [FormFields.city]: kladrObjSchema,
    [FormFields.street]: kladrObjSchema,
    [FormFields.building]: kladrBuildingObjSchema,
    [FormFields.flat]: yup.string().required(''),
    [FormFields.entrance]: yup.string(),
    [FormFields.floor]: yup.string(),
    [FormFields.commentForChief]: yup.string(),
    [FormFields.commentForCourier]: yup.string(),
    [FormFields.promocode]: yup.string(),
    [FormFields.cutlery]: yup.boolean(),
    [FormFields.printedMenu]: yup.boolean(),
    [FormFields.confirmation]: yup.string(),
  })
  .test(function (formData) {
    const cc = formData[FormFields.countryCode];
    const pp = formData[FormFields.phonePart];
    if (getInternationalNumber(cc, pp)?.match(cisPhoneRegex)) {
      return true;
    } else {
      return this.createError({ path: FormFields.phonePart, message: 'Неверный формат номера' });
    }
  })
  .test(function (formData) {
    const city = formData[FormFields.city] as unknown as
      | ValidFormFields[FormFields.city]
      | undefined;
    const street = formData[FormFields.street] as unknown as
      | ValidFormFields[FormFields.street]
      | undefined;
    const building = formData[FormFields.building] as unknown as
      | ValidFormFields[FormFields.building]
      | undefined;
    if (mismatch(street, city)) {
      return this.createError({ path: FormFields.street, message: '\n' });
    }
    if (mismatch(building, street)) {
      return this.createError({ path: FormFields.building, message: '\n' });
    }
    return true;
  })
  .defined();

type FormFieldKey = keyof typeof FormFields;

type IFormKeysTemplate = {
  [key in FormFieldKey]?: unknown;
};

export type CartFormStorage = Subtype<
  IFormKeysTemplate,
  {
    countryCode?: ValidFormFields[FormFields.countryCode];
    phonePart?: ValidFormFields[FormFields.phonePart];
    name?: ValidFormFields[FormFields.name];
    city?: ValidFormFields[FormFields.city];
    street?: ValidFormFields[FormFields.street];
    building?: ValidFormFields[FormFields.building];
    flat?: ValidFormFields[FormFields.flat];
    entrance?: ValidFormFields[FormFields.entrance];
    floor?: ValidFormFields[FormFields.floor];
    commentForChief?: ValidFormFields[FormFields.commentForChief];
    commentForCourier?: ValidFormFields[FormFields.commentForCourier];
    cutlery?: ValidFormFields[FormFields.cutlery];
    printedMenu?: ValidFormFields[FormFields.printedMenu];
  }
>;

export const cartFormStorageKeys: Readonly<(keyof CartFormStorage)[]> = stringEnumKeys(
  FormFields,
) as Readonly<(keyof CartFormStorage)[]>;
