import { call, chatbubbles, logoInstagram, logoVk, logoWhatsapp } from 'ionicons/icons';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { IonBadge, IonButton, IonIcon, IonList } from '@ionic/react';
import { storageUtils } from '@mono/frontend';
import { getDefinedOrThrow } from '@mono/shared';
import { basketOutline, logoTelegramBw } from '../../../assets/icon';
import logo from '../../../assets/images/logo.png';
import CartFormContext from '../../context/cart-form';
import DataStatusContext from '../../context/data-status';
import { OFFICE_STORAGE_KEY, OfficeContext } from '../../context/office/office';
import OrdersContext from '../../context/orders';
import { boxShadowStyle } from '../../styles/box-shadow';
import { gradientGreen } from '../../styles/gradient';
import { IonButtonIconStyle, IonButtonStyle } from '../../styles/ion-button-style';
import CallMePopup from './call-me-popup';

export const headerTopHeight = 60;
export const stickyHeaderHeight = 80;
const stickyHeaderRestingHeight = 100;
export const headerHeight = headerTopHeight + stickyHeaderRestingHeight;

interface Props {
  scrollBarWidth: number;
  stick: boolean;
}

const Header = (props: Props & { className?: string }) => {
  const {
    officeUiKey,
    officeUiData: {
      regionId,
      city,
      phoneNumber,
      instagramUrl,
      vkontakteUrl,
      whatsappUrl,
      telegramUrl,
    },
    switchOffice,
  } = getDefinedOrThrow(useContext(OfficeContext));

  const [cart, updateCart] = useContext(CartFormContext);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSocialOpen, setSocialOpen] = useState(false);
  const [isFormOpen, setFormOpen] = useState(false);

  const [orders] = useContext(OrdersContext);
  const { cartAvailable } = useContext(DataStatusContext);

  const numOrders = useMemo(() => orders.filter(order => order.dates.length).length, [orders]);

  const search = useLocation().search;
  const urlParams = new URLSearchParams(search);
  const officeParam = urlParams.get('office');
  const history = useHistory();

  useEffect(() => {
    storageUtils.getOptional(OFFICE_STORAGE_KEY).then((storedOfficeKey: string) => {
      if ((officeParam === 'nsk' || officeParam === 'spb') && storedOfficeKey !== officeParam) {
        switchOffice(officeParam);
        history.push({ search: '' });
      }
    });
  }, [officeParam, switchOffice, history]);

  useEffect(() => {
    storageUtils.getOptional(OFFICE_STORAGE_KEY).then((storedOfficeKey: string) => {
      if (storedOfficeKey === undefined) {
        switchOffice(officeUiKey);
      }
    });
  }, [officeUiKey, switchOffice]);

  useEffect(() => {
    if (cart.city == null) {
      updateCart({
        city: undefined,
        street: undefined,
        building: undefined,
      });
      return;
    }
    if (cart.city?.id !== regionId && !cart.city?.parents.some(it => it.id === regionId)) {
      updateCart({
        city:
          officeUiKey === 'spb'
            ? {
                contentType: 'city',
                id: '7800000000000',
                name: 'Санкт-Петербург',
                parents: [],
                type: 'Город',
                typeShort: 'г',
                zip: 199004,
              }
            : {
                contentType: 'city',
                id: '5400000100000',
                name: 'Новосибирск',
                parents: [
                  {
                    contentType: 'region',
                    id: '5400000000000',
                    name: 'Новосибирская',
                    type: 'Область',
                    typeShort: 'обл',
                    zip: 630545,
                  },
                ],
                type: 'Город',
                typeShort: 'г',
                zip: 630132,
              },
        street: undefined,
        building: undefined,
      });
    }
  }, [cart.city, regionId, updateCart, officeUiKey]);

  return (
    <div className={props.className}>
      <CallMePopup
        open={isFormOpen}
        onOpen={() => setFormOpen(true)}
        onClose={() => setFormOpen(false)}
      />
      <div className="container">
        <div id="header-top" className="flex-toolbar">
          <span className="office">{`${city}`}</span>
          <span
            className="office-switcher"
            onClick={() => {
              switchOffice();
            }}
          >
            {officeUiKey === 'nsk' ? 'Я в Санкт-Петербурге' : 'Я в Новосибирске'}
          </span>
          <a href={'tel:' + phoneNumber} className="phone-link">
            {phoneNumber} (WhatsApp)
          </a>
          <span className="order-days">Заказы принимаются 24/7</span>
          <span className="delivery-time">Доставка ежедневно с&nbsp;19:00 до&nbsp;23:00</span>
        </div>
        <StyledStickyHeader scrollBarWidth={props.scrollBarWidth} stick={props.stick}>
          <div className={'container'}>
            <Link id="logo" to="/">
              <img alt="Логотип компании" src={logo} />
            </Link>
            <div id="header-nav-block" className={isMenuOpen ? ' active' : ''}>
              <div className="container">
                <div className="flex-toolbar" onClick={() => isMenuOpen && setMenuOpen(false)}>
                  <Link to="/#calculator">
                    <span>Подобрать&nbsp;</span>
                    <span>программу</span>
                  </Link>
                  <Link to="/#delivery">
                    <span>Оплата&nbsp;</span>
                    <span>и доставка</span>
                  </Link>
                  <Link to="/#ask">
                    <span>Вопрос /&nbsp;</span>
                    <span>Ответ</span>
                  </Link>
                  <Link to="/contacts">Контакты</Link>
                  <span id="call-me-back-replacement" onClick={() => setFormOpen(true)}>
                    Заказать звонок
                  </span>
                </div>
              </div>
            </div>
            <IonList id="header-button-block" mode="md">
              <ClickAwayListener
                onClickAway={() => window.setTimeout(() => setMenuOpen(false), 100)}
              >
                <IonButton id="header-menu-btn" onClick={() => setMenuOpen(!isMenuOpen)}>
                  <div className={'menu-btn' + (isMenuOpen ? ' active' : '')}>
                    <span />
                  </div>
                </IonButton>
              </ClickAwayListener>
              <div id="header-social-wrapper">
                <ClickAwayListener onClickAway={() => setSocialOpen(false)}>
                  <div>
                    <IonButton onClick={() => setSocialOpen(!isSocialOpen)}>
                      <IonIcon icon={chatbubbles} />
                    </IonButton>
                    <div
                      id="header-social-popup"
                      className={isSocialOpen ? 'active' : ''}
                      onClick={() => setSocialOpen(false)}
                    >
                      <a href={instagramUrl} target="_blank" rel="noreferrer">
                        <IonIcon icon={logoInstagram} />
                      </a>
                      <a href={vkontakteUrl} target="_blank" rel="noreferrer">
                        <IonIcon icon={logoVk} />
                      </a>
                      <a href={whatsappUrl} target="_blank" rel="noreferrer">
                        <IonIcon icon={logoWhatsapp} />
                      </a>
                      <a href={telegramUrl} target="_blank" rel="noreferrer">
                        <IonIcon icon={logoTelegramBw} />
                      </a>
                    </div>
                  </div>
                </ClickAwayListener>
              </div>
              <IonButton
                id="call-me-back-btn"
                onClick={() => {
                  const target = 'CallMeBack_Clicked';
                  globalThis.ym?.(68302765, 'reachGoal', target);
                  globalThis.gtag?.('event', target);
                  setFormOpen(true);
                }}
              >
                Перезвоните мне
              </IonButton>
              <a id="call-btn" href={'tel:' + phoneNumber}>
                <IonButton>
                  <IonIcon icon={call} />
                </IonButton>
              </a>
              <div className="with-badge">
                {cartAvailable && (
                  <div>
                    <IonBadge mode="ios">{numOrders}</IonBadge>
                  </div>
                )}
                <IonButton id="header-cart-button">
                  <Link to="/cart">
                    <IonIcon icon={basketOutline} />
                  </Link>
                </IonButton>
              </div>
            </IonList>
          </div>
        </StyledStickyHeader>
      </div>
    </div>
  );
};

const StickyHeader: FC<Props & { className?: string }> = props => {
  return (
    <div id="sticky-header" className={props.className}>
      {props.children}
    </div>
  );
};

const StyledStickyHeader = styled(StickyHeader)`
  height: ${stickyHeaderRestingHeight}px;
  transition: height 0.2s ease;
  width: 100%;
  left: 0;
  > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  ${props =>
    props.stick
      ? {
          position: 'fixed',
          height: `${stickyHeaderHeight}px`,
          top: 0,
          width: `calc(100% -  ${props.scrollBarWidth}px)`,
          background: 'rgba(255,255,255,0.7)',
        }
      : {
          position: 'absolute',
        }};
`;

export default styled(Header)`
  z-index: 2000;
  position: absolute;
  width: 100%;
  line-height: 12pt;

  ion-list {
    contain: unset;
    padding: 0;
    background: transparent;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
  .flex-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > * {
      padding-left: 16px;
      &:first-child {
        padding-left: 0;
      }
    }
  }
  .menu-btn {
    width: min(30px, 80%);
    position: absolute;
    span {
      position: relative;
      &::before,
      &::after,
      & {
        content: '';
        width: 100%;
        height: 2px;
        background: #000;
        position: absolute;
        left: 0;
        transition: transform 0.2s;
      }
      &::before {
        top: -8px;
      }
      &::after {
        top: 8px;
      }
    }
    &.active {
      span {
        background: transparent;
        &::before {
          transform: rotate(45deg);
          top: 0;
        }
        &::after {
          transform: rotate(-45deg);
          top: 0;
        }
      }
    }
  }

  #header-top {
    height: ${headerTopHeight}px;
    font-size: 0.95em;
    border-bottom: 1px solid #cdced1;
    .office {
      color: green;
      font-size: 22px;
      font-weight: bold;
    }
    .office-switcher {
      cursor: pointer;
      font-weight: bold;
      text-decoration: underline;
      color: #008000;
    }
  }
  #header-nav-block {
    flex-grow: 1;
    .flex-toolbar {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      > * {
        padding: 0 8px;
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        text-transform: uppercase;
      }
      #call-me-back-replacement {
        display: none;
      }
      span {
        white-space: nowrap;
      }
    }
  }
  #logo {
    padding: 10px 0;
    height: 100%;
    min-width: fit-content;
    img {
      height: inherit;
      max-height: 80px;
    }
  }

  #header-menu-btn {
    display: none;
  }
  #header-social-wrapper {
    position: relative;
    ion-button ion-icon {
      transform: scale(1.2, 0.9);
    }
  }
  #header-social-popup {
    box-shadow: ${boxShadowStyle};
    position: absolute;
    width: 100%;
    min-width: 45px;
    flex-direction: column;
    align-items: center;
    background: white;
    top: calc(100% + 15px);
    padding: 10px;
    border-radius: 12px;
    font-size: 30px;

    display: none;
    &.active {
      display: flex;
    }
    > * {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .with-badge {
    position: relative;
    div {
      z-index: 2;
      position: absolute;
      top: 13%;
      right: 13%;
      width: 0;
      height: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      ion-badge {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 18px;
        height: 18px;
        font-weight: 500;
        line-height: 10px;
        letter-spacing: 0;
        font-size: 11px;
        --background: ${gradientGreen};
        --padding-start: 3px;
        --padding-end: 2px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    #sticky-header {
      height: 20vw;
      > .container {
        #logo {
          width: 35%;
          min-width: unset;
          height: unset;
        }
        #header-button-block {
          width: 65%;
          padding-left: 5%;
          justify-content: space-between;
          ion-button {
            min-width: unset;
            height: 10vw;
            width: 10vw;
            min-width: 10vw;
            margin: 0;
            ion-icon {
              font-size: 5vw;
            }
          }
          #call-btn {
            display: block;
          }
        }
        #header-nav-block {
          #call-me-back-replacement {
            display: flex;
          }
        }
        #call-me-back-btn {
          display: none;
        }
      }
    }
    #header-top {
      height: 60px;
      > * {
        padding: 0;
        padding-bottom: 5px;
        &:last-child {
          padding-bottom: 0;
        }
      }
      .phone-link,
      .order-days,
      .delivery-time {
        display: none;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    #header-menu-btn {
      display: block;
      .menu-btn {
        transform: scale(0.75);
      }
    }
    #header-nav-block {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      display: none;
      background: #fffffff7;
      &.active {
        display: block;
      }
      .flex-toolbar {
        display: flex;
        flex-direction: column;
        padding: 20px;
        > * {
          height: 30px;
          display: flex;
          align-items: center;
          &:hover {
            background: rgba(0, 0, 0, 0.5);
            color: white;
          }
        }
      }
    }
  }

  #header-button-block {
    display: flex;
    > * {
      margin-left: 16px !important;
      &:first-child {
        margin-left: 0 !important;
      }
    }
    #call-btn {
      display: none;
    }
    ion-button {
      ${IonButtonStyle}
      &:not(#call-me-back-btn) {
        ${IonButtonIconStyle}
      }
      &#call-me-back-btn {
        --padding-start: 20px;
        --padding-end: 20px;
        ion-icon {
          display: none;
        }
      }
    }
  }
`;
