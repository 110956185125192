import { BaseEntityData, DataI18n, i18n, UserData } from '@mono/shared';
import { BuildingAddressData } from './building-address';

export const CUSTOMER_ADDRESS_DEFAULT_COMMENT = '';

export interface CustomerAddressData extends BaseEntityData {
  __typename: 'CustomerAddress';
  user: UserData;
  buildingAddress: BuildingAddressData;
  entrance: string;
  floor: string;
  unit: string;
  comment: string;
}

export const customerAddressI18n = i18n<DataI18n<CustomerAddressData, 'profile' | 'deliveries'>>({
  'CustomerAddress': {
    en: 'Customer address',
    ru: 'Адрес клиента',
  },
  'CustomerAddress:Plural': {
    en: 'Customer addresses',
    ru: 'Адреса клиентов',
  },
  'CustomerAddress.user': {
    en: 'User',
    ru: 'Пользователь',
  },
  'CustomerAddress:profile': {
    en: 'Profile',
    ru: 'Клиент',
  },
  'CustomerAddress.buildingAddress': {
    en: 'Building address',
    ru: 'Адрес здания',
  },
  'CustomerAddress.entrance': {
    en: 'Entrance',
    ru: 'Вход',
  },
  'CustomerAddress.floor': {
    en: 'Floor',
    ru: 'Этаж',
  },
  'CustomerAddress.unit': {
    en: 'Unit',
    ru: 'Помещение',
  },
  'CustomerAddress.comment': {
    en: 'Comment',
    ru: 'Комент',
  },
  'CustomerAddress:deliveries': {
    en: 'Deliveries',
    ru: 'Доставки',
  },
});
