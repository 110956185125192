import { i18n } from '@mono/shared';

export enum PaymentMethod {
  CARD = 'CARD',
  TERMINAL = 'TERMINAL',
  REMITTANCE = 'REMITTANCE',
  CASH = 'CASH',
}

export type PaymentMethodKey = keyof typeof PaymentMethod;

export const paymentMethodI18n = i18n<PaymentMethodKey>({
  [PaymentMethod.CARD]: {
    en: 'Card',
    ru: 'Карта',
  },
  [PaymentMethod.TERMINAL]: {
    en: 'Terminal',
    ru: 'Терминал',
  },
  [PaymentMethod.REMITTANCE]: {
    en: 'Remittance',
    ru: 'Перевод',
  },
  [PaymentMethod.CASH]: {
    en: 'Cash',
    ru: 'Наличные',
  },
});
