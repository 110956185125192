import { gql } from '@apollo/client';

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $contactIdentifier: ContactIdentifier!
    $confirmationCode: String!
    $newPassword: String!
  ) {
    resetPassword(
      contactIdentifier: $contactIdentifier
      confirmationCode: $confirmationCode
      newPassword: $newPassword
    )
  }
`;
