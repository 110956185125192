import { useContext } from 'react';
import styled from 'styled-components';
import { IonSegmentButton } from '@ionic/react';
import { PacksForOrder_packs } from '../../../../apollo/api/types';
import LandingUiContext from '../../../../context/ui/landing';

const Pack = ({ pack }: { pack: PacksForOrder_packs | null }) => {
  const kcal = pack?.targetCalorieContent;
  const [landingState, updateLandingState] = useContext(LandingUiContext);
  return (
    <StyledIonSegmentButton
      style={{ visibility: pack ? 'visible' : 'hidden' }}
      mode="md"
      value={pack?.id}
      onClick={() => pack && updateLandingState({ pack })}
    >
      <span>{pack?.name}</span>
      <span className="small">{`${kcal ? `${kcal} ккал за ` : ''}${pack?.price} р`}</span>
    </StyledIonSegmentButton>
  );
};

const StyledIonSegmentButton = styled(IonSegmentButton)`
  font-weight: 800;
  font-size: 12pt;
  text-transform: unset;
  flex: none;
  justify-content: space-between;
  align-items: center;
  .small {
    text-transform: none;
    font-size: 9pt;
    font-weight: 400;
  }
`;

export default Pack;
