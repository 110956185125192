import { BaseEntityData, DataI18n, i18n } from '@mono/shared';
import { IngredientCategoryData } from './ingredient-category';

export interface IngredientData extends BaseEntityData {
  __typename: 'Ingredient';
  name: string;
  calories: number;
  proteins: number;
  fats: number;
  carbohydrates: number;
  category?: IngredientCategoryData | null;
}

export const ingredientI18n = i18n<DataI18n<IngredientData>>({
  'Ingredient': {
    en: 'Ingredient',
    ru: 'Ингредиент',
  },
  'Ingredient:Plural': {
    en: 'Ingredients',
    ru: 'Ингредиенты',
  },
  'Ingredient.name': {
    en: 'Name',
    ru: 'Название',
  },
  'Ingredient.calories': {
    en: 'Calories',
    ru: 'Калории',
  },
  'Ingredient.proteins': {
    en: 'Proteins',
    ru: 'Белки',
  },
  'Ingredient.fats': {
    en: 'Fats',
    ru: 'Жиры',
  },
  'Ingredient.carbohydrates': {
    en: 'Carbohydrates',
    ru: 'Углеводы',
  },
  'Ingredient.category': {
    en: 'Category',
    ru: 'Категория',
  },
});
