import { Big } from 'big.js';

export const toDecimalString = (input: string | Big): string | null => {
  let decimalString: string | null = null;
  try {
    decimalString = Big(input).toFixed();
  } catch (_) {
    decimalString = null;
  }
  return input instanceof Big || decimalString === input ? decimalString : null;
};
