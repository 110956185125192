import { DataI18n, i18n } from '../../translation';
import { BaseEntityData } from '../base-entity';

export interface StorageObjectTagData extends BaseEntityData {
  __typename: 'StorageObjectTag';
  name: string;
}

export const storageObjectTagI18n = i18n<DataI18n<StorageObjectTagData, 'storageObjects'>>({
  'StorageObjectTag': {
    en: 'Storage object tag',
    ru: 'Тэг объекта хранилища',
  },
  'StorageObjectTag:Plural': {
    en: 'Storage object tag',
    ru: 'Тэги объектов хранилища',
  },
  'StorageObjectTag.name': {
    en: 'Name',
    ru: 'Название',
  },
  'StorageObjectTag:storageObjects': {
    en: 'Storage objects',
    ru: 'Объекты хранилища',
  },
});
