export enum Criterion {
  eq = 'eq',
  eqIc = 'eqIc',
  notEq = 'notEq',
  notEqIc = 'notEqIc',

  in = 'in',
  inIc = 'inIc',
  notIn = 'notIn',
  notInIc = 'notInIc',

  gt = 'gt',
  gtIc = 'gtIc',
  notGt = 'notGt',
  notGtIc = 'notGtIc',

  ge = 'ge',
  geIc = 'geIc',
  notGe = 'notGe',
  notGeIc = 'notGeIc',

  lt = 'lt',
  ltIc = 'ltIc',
  notLt = 'notLt',
  notLtIc = 'notLtIc',

  le = 'le',
  leIc = 'leIc',
  notLe = 'notLe',
  notLeIc = 'notLeIc',

  includes = 'includes',
  includesIc = 'includesIc',
  notIncludes = 'notIncludes',
  notIncludesIc = 'notIncludesIc',

  startsWith = 'startsWith',
  startsWithIc = 'startsWithIc',
  notStartsWith = 'notStartsWith',
  notStartsWithIc = 'notStartsWithIc',

  endsWith = 'endsWith',
  endsWithIc = 'endsWithIc',
  notEndsWith = 'notEndsWith',
  notEndsWithIc = 'notEndsWithIc',

  matches = 'matches',
  matchesIc = 'matchesIc',
  notMatches = 'notMatches',
  notMatchesIc = 'notMatchesIc',
}

export type CriterionValue = keyof typeof Criterion;
