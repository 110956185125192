export function distinctById<T extends { id: string }>(values: T[]): T[];
export function distinctById<T extends { id: string }>(values: T[] | undefined): T[] | undefined;
export function distinctById<T extends { id: string }>(values: T[] | undefined): T[] | undefined {
  if (values === undefined) {
    return undefined;
  }
  const ids = values.map(it => it.id);
  return values.filter((value, index) => ids.indexOf(value.id) === index);
}

export const isTruthy = (value: unknown): boolean => !!value;

export const asArray = <T>(input: T | T[]): T[] => (Array.isArray(input) ? input : [input]);

export const copyArray = <T>(
  array: readonly T[],
  { put }: { put?: { element: T; at: number } } = {},
) => {
  const elements = [...array];
  if (put) {
    const { element, at: index } = put;
    elements[index] = element;
  }
  return elements;
};

export const eqByElements = <T>(a: readonly T[], b: readonly T[]) => {
  return a.length === b.length && a.every((element, index) => element === b[index]);
};
