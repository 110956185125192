import { gql } from '@apollo/client';

export const KITCHEN_WORK_STOPPAGE = gql`
  query KitchenWorkStoppage {
    kitchenWorkStoppages(useCache: true) {
      id
      warning
      description
      days
    }
  }
`;
