import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IntegerInput } from '../integer-input';
import PhonePartInput from './phone-part-input';

const DEFAULT_COUNTRY_CODE = 7;

export const getInternationalNumber = (countryCode?: number, phonePart?: string) =>
  countryCode ? '+' + (countryCode + (phonePart ? phonePart.toString() : '')) : undefined;

export interface PhoneParts {
  cc?: number;
  pp?: string;
  int?: string;
}

export default (props: {
  defaultValue?: string;
  defaultCountryCode?: number;
  countryCode?: number;
  phonePart?: string;
  readOnly?: boolean;
  onSetPhone: (values: PhoneParts) => void;
}) => {
  const { defaultCountryCode = DEFAULT_COUNTRY_CODE } = props;

  const [countryCode, setCountryCode] = useState(() => {
    if (props.countryCode) {
      return props.countryCode;
    }
    const code = parseInt(props.defaultValue?.substr(1, 1) ?? '');
    return isNaN(code) ? defaultCountryCode : code;
  });
  const [phonePart, setPhonePart] = useState(() => {
    if (props.phonePart) {
      return props.phonePart;
    }
    if (props.defaultValue) {
      const phone = parseInt(props.defaultValue.substr(1));
      const phonePart = props.defaultValue.substr(2, props.defaultValue.length - 2);
      return isNaN(phone) ? undefined : phonePart;
    }
  });

  const checkCorrectCountryCode = (value?: number) => {
    return true;
  };

  const { onSetPhone } = props;
  useEffect(() => {
    onSetPhone({
      cc: countryCode,
      pp: phonePart,
      int: getInternationalNumber(countryCode, phonePart),
    });
  }, [countryCode, phonePart, onSetPhone]);

  return (
    <PhoneStyles>
      <div className="country-code input">
        <div className="country-code-inner">
          <span className="hidden-span">{'+' + countryCode}</span>
          <span className="plus-symbol">+</span>
          <IntegerInput
            readOnly={props.readOnly}
            value={countryCode}
            onSetValue={value => checkCorrectCountryCode(value) && setCountryCode(value ?? 0)}
            disallowEmpty
            max={999}
          />
        </div>
      </div>
      <PhonePartInput
        readOnly={props.readOnly}
        className="input phone-part"
        value={phonePart}
        onSetValue={setPhonePart}
        countryCode={countryCode}
      />
    </PhoneStyles>
  );
};

const PhoneStyles = styled.div`
  display: flex;
  ion-input {
    &:first-child {
      width: 50px;
    }
    &:last-child {
      width: 200px;
    }
  }
  .country-code {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 45px;
    margin-right: 8px;
  }
  .country-code-inner {
    height: 100%;
    display: flex;
    align-items: center;
    width: fit-content;
    position: relative;
    margin: 0 10px;
    input {
      position: absolute;
      top: 0;
      left: 7px;
      height: 100%;

      outline: none;
      border: none;
      padding: 0;
      background: transparent;
    }
  }
  .plus-symbol {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .hidden-span {
    visibility: hidden;
    padding-right: 4px;
  }
  .phone-part {
    z-index: 2;
    border: none;
    outline: none;
    padding-left: 15px;
    width: 100%;
  }
`;
