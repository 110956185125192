import { Big } from 'big.js';
import { dayjs, Subtype } from '@mono/shared';
import { DeliveryData } from './delivery';
import {
  DiscountData,
  DiscountRequirement,
  discountRequirements,
  DiscountTrigger,
} from './discount';
import { OrderData, OrderItemData } from './order';

const calculateBasePrice = (
  orderItems: Subtype<
    OrderItemData,
    {
      pack: {
        price: string;
      };
      quantity: number;
    }
  >[],
): Big => {
  return orderItems
    .map(item => Big(item.pack.price).times(item.quantity))
    .reduce((a, b) => a.plus(b), Big(0));
};

const calculatePacksCount = (
  orderItems: Subtype<
    OrderItemData,
    {
      quantity: number;
    }
  >[],
) => {
  return orderItems.map(item => item.quantity).reduce((a, b) => a + b, 0);
};

const calculateDaysCount = (
  deliveries: Subtype<
    DeliveryData,
    {
      day: string;
    }
  >[],
) => {
  return Array.from(new Set(deliveries.map(it => it.day))).length;
};

const filterApplicableDiscounts = <T extends DiscountTrigger>(
  triggers: T[],
  data: {
    order: Subtype<
      OrderData,
      {
        creationMoment: string;
        selfDelivery: boolean;
        promoCode: string | null;
      }
    >;
    basePrice: Big;
    daysCount: number;
    packsCount: number;
    userHadNoTransactions?: boolean;
  },
): T[] => {
  return triggers.filter(trigger =>
    discountRequirements()
      .map(requirement => {
        const value = trigger[requirement];
        if (value == null) {
          return true;
        }
        switch (requirement) {
          case DiscountRequirement.SELF_DELIVERY:
            return data.order.selfDelivery === value;
          case DiscountRequirement.USER_HAD_NO_TRANSACTIONS:
            return data.userHadNoTransactions ?? false;
          case DiscountRequirement.PROMO_CODE:
            return data.order.promoCode === value;
          case DiscountRequirement.START_MOMENT:
            return dayjs.utc(data.order.creationMoment).isSameOrAfter(value as string);
          case DiscountRequirement.END_MOMENT:
            return dayjs.utc(data.order.creationMoment).isSameOrBefore(value as string);
          case DiscountRequirement.PRICE_LOWER_LIMIT:
            return data.basePrice.gte(value as string);
          case DiscountRequirement.PRICE_UPPER_LIMIT:
            return data.basePrice.lte(value as string);
          case DiscountRequirement.PACKS_COUNT_LOWER_LIMIT:
            return data.packsCount >= (value as number);
          case DiscountRequirement.PACKS_COUNT_UPPER_LIMIT:
            return data.packsCount <= (value as number);
          case DiscountRequirement.DAYS_COUNT_LOWER_LIMIT:
            return data.daysCount >= (value as number);
          case DiscountRequirement.DAYS_COUNT_UPPER_LIMIT:
            return data.daysCount <= (value as number);
        }
      })
      .reduce((a, b) => a && b),
  );
};

const calculateDiscountRate = (
  order: Subtype<OrderData, { customDiscountRate: number | null }>,
  applicableDiscounts: Subtype<
    DiscountData,
    {
      rate: number;
      cumulative: boolean;
    }
  >[],
): number => {
  if (order.customDiscountRate !== null) {
    return order.customDiscountRate;
  }
  const discounts = applicableDiscounts;
  const nonCumulativeDiscountsMaxRate = discounts
    .filter(discount => !discount.cumulative)
    .map(discount => discount.rate)
    .reduce((a, b) => Math.max(a, b), 0);
  const cumulativeDiscountsSumRate = discounts
    .filter(discount => discount.cumulative)
    .map(discount => discount.rate)
    .reduce((a, b) => a + b, 0);
  return nonCumulativeDiscountsMaxRate + cumulativeDiscountsSumRate;
};

const calculateDiscountedPrice = (basePrice: Big, discountRate: number): Big => {
  return basePrice.times((100 - discountRate) / 100);
};

export const orderDataUtils = {
  calculateBasePrice,
  calculatePacksCount,
  calculateDaysCount,
  filterApplicableDiscounts,
  calculateDiscountRate,
  calculateDiscountedPrice,
};
