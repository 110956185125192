import { useEffect } from 'react';
import { asArray } from '@mono/shared';

const isHotKey = (e: KeyboardEvent) => {
  if (e.metaKey || e.ctrlKey || e.altKey || e.shiftKey) {
    return false;
  }
  switch (document.activeElement?.tagName?.toLowerCase()) {
    case 'input':
    case 'textarea':
      return false;
    default:
      return true;
  }
};

export const useHotKeyHandler = (targetKey: string | string[], handler: () => void): void => {
  const onKeyDown = (e: KeyboardEvent) => {
    if (
      isHotKey(e) &&
      asArray(targetKey)
        .map(it => it.toLowerCase())
        .includes(e.key.toLowerCase())
    ) {
      handler();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, []);
};
