import { ComponentProps } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

export default (props: Omit<ComponentProps<typeof Popup>, 'children'>) => {
  return <BackdropPopup {...props} children={<div></div>} closeOnDocumentClick={false} />;
};

const BackdropPopup = styled(Popup)`
  &-overlay:focus {
    outline: none;
  }
`;
