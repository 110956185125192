import { useContext } from 'react';
import { useScript } from '@mono/frontend';
import { getDefinedOrThrow } from '@mono/shared';
import { OfficeContext } from '../../context/office/office';

export default () => {
  const {
    officeUiData: { flampId },
  } = getDefinedOrThrow(useContext(OfficeContext));

  useScript('//widget.flamp.ru/loader.js');

  return (
    <div className="container">
      <h3>Отзывы</h3>
      <a
        className="flamp-widget"
        href={`//novosibirsk.flamp.ru/firm/${flampId}`}
        data-flamp-widget-type="responsive-new"
        data-flamp-widget-id={flampId}
        data-flamp-widget-width="100%"
        data-flamp-widget-count="5"
      >
        Отзывы о нас на Флампе
      </a>
    </div>
  );
};
