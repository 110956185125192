import { partition, sortBy, sortedIndex } from 'lodash';
import * as yup from 'yup';
import { API_DAY_FORMAT, dayjs } from '@mono/shared';
import { CalorieCalculatorAdvice_calorieCalculatorAdvice_packs as Pack } from '../../apollo/api/types';
import { KitchenStoppageDay } from '../stoppage-days/stoppage-days';

export interface NewOrderItem {
  pack: Pack;
  comment: string;
  dates: string[];
  numWeeks: number;
}

export type OrderIdentifier = number;

export interface OrderItem extends NewOrderItem {
  id: OrderIdentifier;
}

export type Orders = OrderItem[];

export const randomNumber = (max: number) => Math.floor(Math.random() * max);
export const MAX_ID = 100000;

const packSchema = yup.object().shape({
  __typename: yup.string().equals(['Pack'], ''),
  id: yup.string().uuid('').required(''),
  name: yup.string().required(''),
  targetCalorieContent: yup.number().required(''),
  position: yup.number().required(''),
  price: yup.string().required(''),
});

const orderSchema = yup.object().shape({
  id: yup.number().required(''),
  comment: yup.string().required(''),
  pack: packSchema.required(''),
  dates: yup.array().of(yup.string()).required(''),
  numWeeks: yup.number().required(''),
});

export const ordersSchema = yup.array().of(orderSchema);

/**
returns delivery days as [collisions, filtered] 
*/
export const filterInactiveDaysFromOrderItem = <T extends NewOrderItem>(
  orderItem: T,
  deliveryStoppageDays: KitchenStoppageDay[],
) => {
  const filteredDates = partition(
    orderItem.dates,
    date => deliveryStoppageDays.findIndex(day => day.day === date) > -1,
  );
  return filteredDates;
};

const createSortedCalendarDiffsArray = (dates: string[], firstDate: dayjs.Dayjs) => {
  return sortBy(dates.map(date => dayjs(date).diff(firstDate, 'days')));
};

export const shiftDatesOfNewOrder = <T extends NewOrderItem>(
  orderItem: T,
  deliveryStoppageDays: KitchenStoppageDay[],
): T => {
  const inactiveDates = deliveryStoppageDays.map(it => it.day);
  const [collisions, filteredDates] = filterInactiveDaysFromOrderItem(
    orderItem,
    deliveryStoppageDays,
  );
  const now = dayjs();
  let sortedDiffs = createSortedCalendarDiffsArray(filteredDates.concat(inactiveDates), now);

  collisions.forEach(date => {
    let diff = dayjs(date).diff(now, 'days');
    let i = sortedIndex(sortedDiffs, diff);
    while (sortedDiffs[i] === diff) {
      diff++;
      i = sortedIndex(sortedDiffs, diff);
    }
    sortedDiffs = sortedDiffs.slice(0, i).concat(diff).concat(sortedDiffs.slice(i));
    const newDate = now.add(diff + 1, 'days').format(API_DAY_FORMAT);
    filteredDates.push(newDate);
  });
  return { ...orderItem, dates: filteredDates };
};
