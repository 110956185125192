import { useEffect, useState } from 'react';
import { useStorageValue } from '@mono/frontend';
import { API_DAY_FORMAT, dayjs } from '@mono/shared';
import { sleep } from '../../utils/time';
import { getDates, SHOW_NUM_DAYS, TODAY_DELIVERY_TIME_THRESHOLD } from './utils';

const useDates = () => {
  const [today, setToday] = useState<dayjs.Dayjs | undefined>(undefined);
  const [firstMenuDate, setFirstMenuDate] = useState<string | undefined>(undefined);
  const [lastMenuDate, setLastMenuDate] = useState<string | undefined>(undefined);

  const [timer, setTimer] = useState(0);

  useEffect(() => {
    sleep(60 * 1000).then(() => setTimer(it => it + 1));
  }, [timer]);

  useEffect(() => {
    setToday(dayjs());
  }, [timer]);

  const [managerMode] = useStorageValue<boolean>('manager');

  useEffect(() => {
    if (today) {
      const { firstDate, lastDate } = getDates(
        today,
        TODAY_DELIVERY_TIME_THRESHOLD,
        SHOW_NUM_DAYS,
        managerMode,
      );
      setFirstMenuDate(firstDate);
      setLastMenuDate(lastDate);
    }
  }, [today, managerMode]);

  return { today: today?.format(API_DAY_FORMAT), firstMenuDate, lastMenuDate };
};

export type DatesHook = ReturnType<typeof useDates>;

export default useDates;
