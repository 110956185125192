import { BaseEntityData, DataI18n, i18n, stringEnumValues } from '@mono/shared';

export enum DiscountRequirement {
  SELF_DELIVERY = 'selfDelivery',
  /**
   * Means that at order transaction creation moment user had no transactions.
   * It's actually supposed to be used to implement the first-user-order discount.
   */
  USER_HAD_NO_TRANSACTIONS = 'userHadNoTransactions',
  PROMO_CODE = 'promoCode',
  START_MOMENT = 'startMoment',
  END_MOMENT = 'endMoment',
  PRICE_LOWER_LIMIT = 'priceLowerLimit',
  PRICE_UPPER_LIMIT = 'priceUpperLimit',
  PACKS_COUNT_LOWER_LIMIT = 'packsCountLowerLimit',
  PACKS_COUNT_UPPER_LIMIT = 'packsCountUpperLimit',
  DAYS_COUNT_LOWER_LIMIT = 'daysCountLowerLimit',
  DAYS_COUNT_UPPER_LIMIT = 'daysCountUpperLimit',
}

export const discountRequirements = () => {
  return stringEnumValues(DiscountRequirement) as DiscountRequirement[];
};

export interface DiscountTrigger {
  [DiscountRequirement.SELF_DELIVERY]: boolean | null;
  [DiscountRequirement.USER_HAD_NO_TRANSACTIONS]: boolean | null;
  [DiscountRequirement.PROMO_CODE]: string | null;
  [DiscountRequirement.START_MOMENT]: string | null;
  [DiscountRequirement.END_MOMENT]: string | null;
  [DiscountRequirement.PRICE_LOWER_LIMIT]: string | null;
  [DiscountRequirement.PRICE_UPPER_LIMIT]: string | null;
  [DiscountRequirement.PACKS_COUNT_LOWER_LIMIT]: number | null;
  [DiscountRequirement.PACKS_COUNT_UPPER_LIMIT]: number | null;
  [DiscountRequirement.DAYS_COUNT_LOWER_LIMIT]: number | null;
  [DiscountRequirement.DAYS_COUNT_UPPER_LIMIT]: number | null;
}

export interface DiscountData extends BaseEntityData, DiscountTrigger {
  __typename: 'Discount';
  name: string;
  rate: number;
  cumulative: boolean;
}

export const discountI18n = i18n<DataI18n<DiscountData>>({
  'Discount': {
    en: 'Discount',
    ru: 'Скидка',
  },
  'Discount:Plural': {
    en: 'Discounts',
    ru: 'Скидки',
  },
  'Discount.name': {
    en: 'Name',
    ru: 'Название',
  },
  'Discount.rate': {
    en: 'Rate',
    ru: 'Процент',
  },
  'Discount.cumulative': {
    en: 'Cumulative',
    ru: 'Суммируется',
  },
  'Discount.startMoment': {
    en: 'Start moment',
    ru: 'Заказано не ранее',
  },
  'Discount.endMoment': {
    en: 'End moment',
    ru: 'Заказано не позднее',
  },
  'Discount.selfDelivery': {
    en: 'Self-pickup',
    ru: 'Самовывоз',
  },
  'Discount.userHadNoTransactions': {
    en: 'User had no transactions',
    ru: 'Первая покупка',
  },
  'Discount.promoCode': {
    en: 'Promo code',
    ru: 'Указан промо-код',
  },
  'Discount.priceLowerLimit': {
    en: 'Price lower limit',
    ru: 'Цена заказа не менее',
  },
  'Discount.priceUpperLimit': {
    en: 'Price upper limit',
    ru: 'Цена заказа не более',
  },
  'Discount.packsCountLowerLimit': {
    en: 'Packs count lower limit',
    ru: 'Количество пакетов не менее',
  },
  'Discount.packsCountUpperLimit': {
    en: 'Packs count upper limit',
    ru: 'Количество пакетов не более',
  },
  'Discount.daysCountLowerLimit': {
    en: 'Days count lower limit',
    ru: 'Количество дней не менее',
  },
  'Discount.daysCountUpperLimit': {
    en: 'Days count upper limit',
    ru: 'Количество дней не более',
  },
});
