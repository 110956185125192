import { i18n } from '../translation';

export enum BuildingAddressUidPart {
  region = 'region',
  district = 'district',
  city = 'city',
  street = 'street',
  building = 'building',
}

export type BuildingAddressUidPartKey = keyof typeof BuildingAddressUidPart;

export const buildingAddressUidPartI18n = i18n<BuildingAddressUidPartKey>({
  [BuildingAddressUidPart.region]: {
    en: 'Region',
    ru: 'Регион',
  },
  [BuildingAddressUidPart.district]: {
    en: 'District',
    ru: 'Район',
  },
  [BuildingAddressUidPart.city]: {
    en: 'City',
    ru: 'Город',
  },
  [BuildingAddressUidPart.street]: {
    en: 'Street',
    ru: 'Улица',
  },
  [BuildingAddressUidPart.building]: {
    en: 'Building',
    ru: 'Здание',
  },
});
