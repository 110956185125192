import { ObjectSchema, ref, string } from 'yup';
import { i18n } from '@mono/shared';
import { loginSchemaErrorI18n } from './login-schema';
import { usernameSchema } from './username-schema';

export enum PasswordResetStep {
  Identification = 'Identification',
  Reset = 'Reset',
}

export type PasswordResetStepKey = keyof typeof PasswordResetStep;

type IdentificationStepSchema = {
  username: string;
};

type ResetStepSchema = IdentificationStepSchema & {
  confirmationCode: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

export type PasswordResetSchema = IdentificationStepSchema & ResetStepSchema;

export const passwordResetSchemaErrorI18n = i18n({
  ...loginSchemaErrorI18n.translations,
  'Confirmation code is reqired': {},
  'New password is reqired': {},
  'New password confirmation is reqired': {},
  'Passwords do not match': {},
});

const identificationStep: ObjectSchema<IdentificationStepSchema> = usernameSchema('contact');

const resetStep: ObjectSchema<ResetStepSchema> = identificationStep
  .shape({
    confirmationCode: string().required(
      passwordResetSchemaErrorI18n.key('Confirmation code is reqired'),
    ),
    newPassword: string().required(passwordResetSchemaErrorI18n.key('New password is reqired')),
    newPasswordConfirmation: string()
      .equals([ref('newPassword')], passwordResetSchemaErrorI18n.key('Passwords do not match'))
      .required(passwordResetSchemaErrorI18n.key('New password confirmation is reqired')),
  })
  .defined();

export const passwordResetSchema = {
  [PasswordResetStep.Identification]: identificationStep,
  [PasswordResetStep.Reset]: resetStep,
};
