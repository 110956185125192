import { groupBy, sortBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { API_DAY_FORMAT, dayjs } from '@mono/shared';
import { DISH_INSTANCES } from '../../apollo/api/queries/dish-instances';
import { PACKS_WITH_ITEMS } from '../../apollo/api/queries/packs';
import { DishInstances, DishInstancesVariables, PacksWithItems } from '../../apollo/api/types';
import { getDaysDiff } from '../../utils/dates';
import { Dish, getDishByIndex, Menu } from './utils';

interface Props {
  firstMenuDate?: string;
  lastMenuDate?: string;
  selectedFirstDate?: string;
  numWeeks?: number;
}

interface MenuState {
  data: Menu;
  dishesLoaded: boolean | undefined;
  packsLoaded: boolean | undefined;
}

const useMenu = (props: Props) => {
  const { firstMenuDate, lastMenuDate, selectedFirstDate, numWeeks } = props;

  const lastQueryDate = useMemo(
    () =>
      numWeeks
        ? dayjs(selectedFirstDate)
            .add(numWeeks * 7 - 1, 'days')
            .format(API_DAY_FORMAT)
        : undefined,
    [selectedFirstDate, numWeeks],
  );

  const packs = useQuery<PacksWithItems>(PACKS_WITH_ITEMS);

  const [queryDishInstances, dishInstances] = useLazyQuery<DishInstances, DishInstancesVariables>(
    DISH_INSTANCES,
  );

  const [menu, setMenu] = useState<Menu>({});

  useEffect(() => {
    if (firstMenuDate && lastMenuDate) {
      queryDishInstances({
        variables: { firstDate: firstMenuDate, lastDate: lastQueryDate ?? lastMenuDate },
      });
    }
  }, [firstMenuDate, lastMenuDate, lastQueryDate, numWeeks, queryDishInstances]);

  useEffect(() => {
    if (firstMenuDate && lastMenuDate && packs.data && dishInstances.data) {
      const newMenu: Menu = {};

      const dishInstancesGrouped = groupBy(dishInstances.data.dishInstances, 'day');

      const diff = getDaysDiff(firstMenuDate, lastQueryDate ?? lastMenuDate);

      for (let i = 0, currDate = firstMenuDate; i <= diff; i++) {
        currDate = dayjs(firstMenuDate, API_DAY_FORMAT, true).add(i, 'day').format(API_DAY_FORMAT);

        newMenu[currDate] = {};
        packs.data.packs.forEach(pack => {
          if (dishInstancesGrouped[currDate]) {
            const dishesForDay: Dish[] = [];
            sortBy(pack.items, instance => instance.menuItem.position).forEach((item, index) => {
              const dish = getDishByIndex(item, dishInstancesGrouped[currDate]);
              if (dish) {
                dishesForDay.push(dish);
              }
            });
            newMenu[currDate][pack.id] = dishesForDay;
          }
        });
        if (!Object.keys(newMenu[currDate]).length) {
          delete newMenu[currDate];
        }
      }
      setMenu(newMenu);
    }
  }, [dishInstances.data, packs.data, firstMenuDate, lastMenuDate, lastQueryDate]);

  return {
    data: menu,
    dishesLoaded: dishInstances.loading ? undefined : !!dishInstances.data?.dishInstances.length,
    packsLoaded: packs.loading ? undefined : !!packs.data?.packs.length,
  } as MenuState;
};

export type MenuHook = ReturnType<typeof useMenu>;

export default useMenu;
