import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { getDefinedOrThrow } from '@mono/shared';
import Spinner from '../../components/spinner/spinner';
import DataStatusContext from '../../context/data-status';
import { OfficeContext } from '../../context/office/office';
import OrderCheckout from './order-checkout';
import OrderPicker from './order-picker';

export default () => {
  const {
    officeUiData: { phoneNumber, instagramUrl },
  } = getDefinedOrThrow(useContext(OfficeContext));
  const { cartAvailable } = useContext(DataStatusContext);

  useEffect(() => {
    document.title = 'Корзина | Happy Food';
    const description = `
      Корзина - Happy Food. Оформление заказов 24/7. 
      Доставка ежедневно с 19:00 до 23:00. Тел. ${'+7 (963) 944-74-74'.replace(' ', '\xa0')}.`;
    document.querySelector('meta[name="description"]')?.setAttribute('content', description);
  }, []);

  return (
    <Styles className="header-padding">
      <div className="container">
        <h1>Оформление заказа</h1>
        <p>
          Доставка заказов на день осуществляется вечером предыдущего дня.
          <br />
          Курьер согласует с Вами время.
        </p>
        {cartAvailable ? (
          <div className="forms-wrap">
            <div className="picker">
              <h4 className="form-title">
                <span className="show-small">Шаг 1. </span>Сформируйте программу{' '}
                <span className="hide-small">питания</span>
              </h4>
              <OrderPicker />
            </div>
            <div className="checkout">
              <h4 className="form-title">
                <span className="show-small">Шаг 2. </span>Оформите заказ
              </h4>
              <OrderCheckout />
            </div>
          </div>
        ) : cartAvailable === undefined ? (
          <div className="spinner-container">
            <Spinner />
          </div>
        ) : (
          <div className="cart-placeholder">
            <span>К сожалению, корзина заказов временно недоступна.&nbsp;</span>
            <span>
              Свяжитесь с нами по телефону <em>{phoneNumber}</em>&nbsp;
            </span>
            <span>
              или закажите еду у нас в{' '}
              <a className="link" href={instagramUrl}>
                instagram
              </a>
            </span>
          </div>
        )}
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  h1,
  h2 {
    margin-top: 30px;
  }
  p,
  h4 {
    margin: 0;
  }
  .container > p {
    margin-bottom: 30px;
  }
  .forms-wrap {
    display: flex;
    .form-title {
      padding-bottom: 20px;
      border-bottom: 1px solid #dfe1e4;
      font-size: 14pt;
      font-weight: 800;
      margin-bottom: 20px;
    }
    > * {
      flex-direction: column;
      flex-wrap: nowrap;
      width: 100%;
      margin-bottom: 21px;
      padding: 25px;
      border-radius: 12px;
      background: var(--ion-background-color-second);
      h4 {
        font-size: 9pt;
        line-height: 15pt;
        font-weight: 800;
      }
    }
  }

  .spinner-container {
  }
  .picker {
    margin-right: 20px;
    max-width: 530px;
  }
  .checkout {
    width: calc(100% - 530px - 20px);
  }
  .picker,
  .checkout {
    > * {
      margin-bottom: 10px;
    }
  }

  .show-small {
    display: none;
  }
  .hide-small {
    display: inline;
  }

  .cart-placeholder {
    display: flex;
    flex-wrap: wrap;
    em {
      font-weight: 600;
      font-style: normal;
      white-space: nowrap;
    }
  }
  @media screen and (max-width: 900px) {
    .forms-wrap {
      flex-wrap: wrap;
      margin: 0 calc(2.5vw - 20px);
      > * {
        margin-right: 0;
        width: 100%;
        max-width: unset;
        padding: 25px min(5vw, 25px);
      }
    }
    .show-small {
      display: inline;
    }
    .hide-small {
      display: none;
    }
  }
`;
