/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LogInByNickname
// ====================================================

export interface LogInByNickname_logIn_user {
  __typename: "User";
  id: string;
  roles: string[];
  firstName: string;
  language: Language | null;
}

export interface LogInByNickname_logIn {
  __typename: "UserAuth";
  jwt: string;
  user: LogInByNickname_logIn_user;
}

export interface LogInByNickname {
  /**
   * Authenticates user by password and identifier.
   */
  logIn: LogInByNickname_logIn;
}

export interface LogInByNicknameVariables {
  nickname: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LogInByContact
// ====================================================

export interface LogInByContact_logInByContact_user {
  __typename: "User";
  id: string;
  roles: string[];
  firstName: string;
  language: Language | null;
}

export interface LogInByContact_logInByContact {
  __typename: "UserAuth";
  jwt: string;
  user: LogInByContact_logInByContact_user;
}

export interface LogInByContact {
  /**
   * Authenticates user by password and confirmed contact.
   * See requestContactConfirmation mutation for authentication by any contact.
   */
  logInByContact: LogInByContact_logInByContact;
}

export interface LogInByContactVariables {
  contactValue: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: QueryLoggedInUser
// ====================================================

export interface QueryLoggedInUser_user {
  __typename: "User";
  id: string;
  roles: string[];
  firstName: string;
  language: Language | null;
}

export interface QueryLoggedInUser {
  user: QueryLoggedInUser_user | null;
}

export interface QueryLoggedInUserVariables {
  userIdentifier: UserIdentifier;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestContactConfirmation
// ====================================================

export interface RequestContactConfirmation_requestContactConfirmation {
  __typename: "Contact";
  id: string;
  value: string;
  confirmationCodeSent: boolean;
}

export interface RequestContactConfirmation {
  /**
   * Sends message with confirmation code to specified contact.
   * 
   * It's first step in two-step process of contact confirmation.
   * 
   * Сontact confirmation process serves two purposes:
   * - confirms contact if it is unconfirmed
   * - allows user to be authenticated by owned contact
   * 
   * See confirmContact mutation for details about second step.
   */
  requestContactConfirmation: RequestContactConfirmation_requestContactConfirmation;
}

export interface RequestContactConfirmationVariables {
  contactIdentifier: ContactIdentifier;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetPassword
// ====================================================

export interface ResetPassword {
  resetPassword: boolean;
}

export interface ResetPasswordVariables {
  contactIdentifier: ContactIdentifier;
  confirmationCode: string;
  newPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignUp
// ====================================================

export interface SignUp_signUp_user {
  __typename: "User";
  id: string;
  roles: string[];
  firstName: string;
  language: Language | null;
}

export interface SignUp_signUp {
  __typename: "UserAuth";
  jwt: string;
  user: SignUp_signUp_user;
}

export interface SignUp {
  /**
   * Creates user and authenticates it.
   * 
   * Each user always has one or more identifiers and must have authentication data.
   * 
   * Identifiers are intended to uniquely identify users.
   * Types of identifiers:
   * - id
   * - nickname
   * - contacts (emails, phones; TODO: identifiers of social networks and messengers)
   * 
   * Authentication data is intended to authenticate users.
   * Different types of authentication data may be used with different types of identifiers:
   * - password may be used with id, nickname or confirmed contact (see logIn mutations)
   * - confirmation code may be used with any contact (see requestContactConfirmation mutation)
   * 
   * Anyway, user cannot be created without a valid data to be authenticated with.
   */
  signUp: SignUp_signUp;
}

export interface SignUpVariables {
  data: UserCreateData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendTelegramMessageToDevelopers
// ====================================================

export interface SendTelegramMessageToDevelopers {
  sendTelegramMessageToDevelopers: boolean;
}

export interface SendTelegramMessageToDevelopersVariables {
  text: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LoggedInUserFragment
// ====================================================

export interface LoggedInUserFragment {
  __typename: "User";
  id: string;
  roles: string[];
  firstName: string;
  language: Language | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
  UNKNOWN = "UNKNOWN",
}

export enum Language {
  en = "en",
  ru = "ru",
}

export interface ContactCompositionCreateData {
  confirmed?: boolean | null;
  value: string;
}

/**
 * Unique by:
 * - value
 */
export interface ContactIdentifier {
  id?: string | null;
  value?: string | null;
}

export interface StorageObjectIdentifier {
  id?: string | null;
}

export interface UserCreateData {
  avatarIdentifier?: StorageObjectIdentifier | null;
  birthday?: MonoGqlDay | null;
  contactsData?: ContactCompositionCreateData[] | null;
  firstName: string;
  gender?: Gender | null;
  language?: Language | null;
  lastName?: string | null;
  middleName?: string | null;
  nickname?: string | null;
  password?: string | null;
  timezone?: string | null;
}

/**
 * Unique by:
 * - nickname
 */
export interface UserIdentifier {
  id?: string | null;
  nickname?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
