import { gql } from '@apollo/client';

export const SEND_TELEGRAM_MESSAGE_TO_OFFICE_CHAT = gql`
  mutation SendTelegramMessageToOfficeChat(
    $officeCode: OfficeCode!
    $officeChat: TelegramOfficeChat!
    $text: String!
  ) {
    sendTelegramMessageToOfficeChat(officeCode: $officeCode, officeChat: $officeChat, text: $text)
  }
`;
