import { useCallback, useState } from 'react';
import { AA } from '@mono/shared';

export const useFetchState = (fetchKey: string) => {
  const [fetchState, setFetchState] = useState<AA<boolean | undefined>>({});
  return {
    fetchedAll: fetchState[fetchKey] === true,
    setFetchedAll: useCallback(
      (fetchedAll: boolean) => setFetchState({ ...fetchState, [fetchKey]: fetchedAll }),
      [fetchKey, fetchState],
    ),
    resetFetchState: () => setFetchState({}),
  };
};
