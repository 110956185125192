import cx from 'classnames';
import { Dispatch, FC, Fragment, ReactElement, SetStateAction, useContext } from 'react';
import { Transition } from '@headlessui/react';
import { BellIcon, CheckCircleIcon, ExclamationIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { chooseBy, Translation } from '@mono/shared';
import useTimeout from '../../hook/use-timeout';
import { LanguageContext } from '../../translation';

type NotificationType = 'notification' | 'warning';

type NotificationAction = {
  title: Translation;
  onClick: () => void | Promise<void>;
};

export type NotificationData = {
  type?: NotificationType;
  title: string;
  text?: string;
  durationInSeconds?: number;
  style?: {
    width?: 'sm' | 'md';
    nowrap?: boolean;
  };
  actions?: NotificationAction[];
};

export type NotificationState = { id: string; show: boolean; data: NotificationData };

export const Notification: FC<
  NotificationState & {
    notifications: NotificationState[];
    setNotifications: Dispatch<SetStateAction<NotificationState[]>>;
  }
> = ({
  id,
  show,
  data: {
    type = 'notification',
    title,
    text,
    durationInSeconds = type === 'notification' ? 3 : 5,
    style = {},
    actions = [],
  },
  notifications,
  setNotifications,
}) => {
  const language = useContext(LanguageContext);
  const width = style.width ?? 'sm';
  const nowrap = style.nowrap ?? false;
  const hide = () => {
    setNotifications(
      [...notifications].map(notification => {
        if (notification.id === id) {
          notification.show = false;
        }
        return notification;
      }),
    );
  };
  useTimeout(() => {
    if (durationInSeconds > 0) {
      // Hide automatically only if duration is positive
      hide();
    }
  }, durationInSeconds * 1000);
  const dismissAction: NotificationAction = {
    title: {
      en: 'Hide',
      ru: 'Скрыть',
    },
    onClick: () => hide(),
  };
  return (
    <Transition
      appear={true}
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={() => {
        setNotifications(notifications.filter(notification => notification.id !== id));
      }}
    >
      <div
        className={cx(
          [
            'w-full',
            chooseBy(width, {
              sm: 'max-w-sm',
              md: 'max-w-md',
            }),
          ],
          'pointer-events-auto overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5',
        )}
      >
        <div className="p-4">
          <div className="flex items-start">
            {(() => {
              const iconByType: Record<NotificationType, ReactElement> = {
                notification: <CheckCircleIcon className="h-6 w-6 text-emerald-400" />,
                warning: <ExclamationIcon className="h-6 w-6 text-red-600" />,
              };
              return <div className="flex-shrink-0">{iconByType[type]}</div>;
            })()}
            <div className="ml-3 w-0 flex-1 overflow-ellipsis pt-0.5">
              <p className="text-sm font-medium text-slate-900">{title}</p>
              {text && (
                <p
                  className={cx(
                    'mt-1 overflow-hidden overflow-ellipsis text-sm text-slate-500',
                    nowrap ? 'whitespace-nowrap' : 'whitespace-pre-line',
                  )}
                >
                  {text}
                </p>
              )}
              {actions.length > 0 && (
                <div className="mt-3 flex space-x-7">
                  {[...actions, dismissAction].map(({ title, onClick }, index) => (
                    <button
                      key={index}
                      type="button"
                      className={cx(
                        'rounded-md bg-white text-sm font-medium',
                        index < actions.length
                          ? 'text-indigo-600 hover:text-indigo-500'
                          : 'text-gray-700 hover:text-gray-500',
                      )}
                      onClick={onClick}
                    >
                      {title[language]}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                className={cx(
                  'group inline-flex h-5 w-5 rounded-md bg-white text-slate-400 hover:text-slate-500',
                  'focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
                )}
                onMouseDown={e => e.stopPropagation()}
                onClick={() => {
                  hide();
                }}
              >
                {durationInSeconds > 0 && <BellIcon className="h-full w-full group-hover:hidden" />}
                <XIcon
                  className={cx('h-full w-full', {
                    'hidden group-hover:inline-block': durationInSeconds > 0,
                  })}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};
