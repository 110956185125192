import { BaseEntityData, DataI18n, i18n } from '@mono/shared';
import { DishData } from './dish';
import { MenuItemData } from './menu-item';

export interface DishInstanceData extends BaseEntityData {
  __typename: 'DishInstance';
  day: string;
  menuItem: MenuItemData;
  dish: DishData;
}

export const dishInstanceI18n = i18n<DataI18n<DishInstanceData>>({
  'DishInstance': {
    en: 'Dish instance',
    ru: 'Экземпляр блюда',
  },
  'DishInstance:Plural': {
    en: 'Dish instances',
    ru: 'Экземпляры блюд',
  },
  'DishInstance.day': {
    en: 'Day',
    ru: 'День',
  },
  'DishInstance.menuItem': {
    en: 'Menu item',
    ru: 'Позиция меню',
  },
  'DishInstance.dish': {
    en: 'Dish',
    ru: 'Блюдо',
  },
});
