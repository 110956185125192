import React, { useCallback, useContext } from 'react';
import { AA, LanguageKey, Translations } from '@mono/shared';

export const LanguageContext = React.createContext<LanguageKey>('en');

type Replacement = string | number;

export type Translator<K extends string> = (
  key: keyof Translations<K>,
  data?: Replacement | AA<Replacement>,
) => string;

export const useTranslator = <K extends string>(
  translations: Translations<K>,
  targetLanguage?: LanguageKey,
): Translator<K> => {
  const language = useContext(LanguageContext);
  return useCallback(
    (key: keyof typeof translations, data: Replacement | AA<Replacement> = {}) => {
      let translation = translations[key]?.[targetLanguage ?? language] ?? key;
      const aa: AA<string | number> = typeof data === 'object' ? data : { it: data };
      for (const key of Object.keys(aa)) {
        translation = translation.replace(new RegExp(`{${key}}`, 'g'), aa[key].toString());
      }
      return translation;
    },
    [translations, targetLanguage, language],
  );
};

export const useI18n = <K extends string>(
  i18n: { translations: Translations<K> },
  targetLanguage?: LanguageKey,
): Translator<K> => useTranslator(i18n.translations, targetLanguage);

export const useErrorI18n = (
  i18n: { translations: Translations<string> },
  targetLanguage?: LanguageKey,
) => {
  const errorTranslator = useI18n(i18n, targetLanguage);
  return (errorKey: string | undefined) => {
    return errorKey === undefined ? undefined : errorTranslator(errorKey);
  };
};
