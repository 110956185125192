import { stringify } from './json';

export const mapValue = <K, V>(map: Map<K, V>, key: K, createOrThrow: () => V | never): V => {
  const value = map.get(key);
  if (value !== undefined) {
    return value;
  }
  const createdValue = createOrThrow();
  map.set(key, createdValue);
  return createdValue;
};

export const mapValueOrThrow = <K, V>(
  map: Map<K, V>,
  key: K,
  errorMessage = `Map has no value by key: ${stringify(key, { format: true })}.`,
) => {
  return mapValue(map, key, () => {
    throw Error(errorMessage);
  });
};
