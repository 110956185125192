import { useCallback, useContext, useState } from 'react';
import * as uuid from 'uuid';
import { useMutation } from '@apollo/client';
import { getDefinedOrThrow } from '@mono/shared';
import { CREATE_ORDER } from '../../apollo/api/queries/create-order';
import { CREATE_PAYMENT_URL } from '../../apollo/api/queries/create-payment-url';
import { FIX_PRICES } from '../../apollo/api/queries/fix-prices';
import { GET_OR_CREATE_CUSTOMER_ADDRESS } from '../../apollo/api/queries/get-or-create-customer-address';
import { UPDATE_USER } from '../../apollo/api/queries/update-user';
import {
  CreateOrder,
  CreateOrderVariables,
  CreatePaymentUrl,
  CreatePaymentUrlVariables,
  CustomerAddressUidInput,
  FixPrices,
  FixPricesVariables,
  GetOrCreateCustomerAddress,
  GetOrCreateCustomerAddressVariables,
  PaymentMethod,
  UpdateUser,
  UpdateUserVariables,
} from '../../apollo/api/types';
import { OfficeContext } from '../../context/office/office';
import OrdersContext from '../../context/orders';
import { FormFields, ValidFormFields } from './utils-form-fields';

export enum OrderCreationStatus {
  creating,
  created,
  addressError,
  userUpdateError,
  orderError,
  deliveryError,
  paymentUrlError,
}

const useOrderCreation = () => {
  const {
    officeUiData: { officeId },
  } = getDefinedOrThrow(useContext(OfficeContext));

  const ordersContext = useContext(OrdersContext);
  const orders = ordersContext[0];
  const clearOrders = ordersContext[5];

  const [orderStatus, setOrderStatus] = useState<OrderCreationStatus>();

  const [doCreateOrder] = useMutation<CreateOrder, CreateOrderVariables>(CREATE_ORDER);

  const [doGetOrCreateCustomerAddress] = useMutation<
    GetOrCreateCustomerAddress,
    GetOrCreateCustomerAddressVariables
  >(GET_OR_CREATE_CUSTOMER_ADDRESS);

  const [doFixPrices] = useMutation<FixPrices, FixPricesVariables>(FIX_PRICES);
  const [doCreatePaymentUrl] = useMutation<CreatePaymentUrl, CreatePaymentUrlVariables>(
    CREATE_PAYMENT_URL,
  );
  const [doUpdateUser] = useMutation<UpdateUser, UpdateUserVariables>(UPDATE_USER);

  const createOrder = useCallback(
    async (formData: ValidFormFields, userId: string, buildingAddressId: string) => {
      if (!userId) {
        setOrderStatus(undefined);
        return;
      }
      setOrderStatus(OrderCreationStatus.creating);

      let customerAddressId: string | undefined;

      try {
        const customerAddressData: CustomerAddressUidInput = {
          userIdentifier: { id: userId },
          buildingAddressIdentifier: { id: buildingAddressId },
          unit: formData[FormFields.flat] ?? '',
          floor: formData[FormFields.floor] ?? '',
          entrance: formData[FormFields.entrance] ?? '',
        };

        const customerAddress = await doGetOrCreateCustomerAddress({
          variables: {
            data: customerAddressData,
          },
        });
        customerAddressId = customerAddress.data?.getOrCreateCustomerAddressBy.id;
      } catch (err) {
        setOrderStatus(OrderCreationStatus.addressError);
        return;
      }

      let orderId: string | undefined;

      if (customerAddressId) {
        try {
          const order = await doCreateOrder({
            variables: {
              data: {
                officeIdentifier: { id: officeId },
                customerProfileIdentifier: { userId },
                paymentMethod: PaymentMethod.CARD,
                commentForChief: formData[FormFields.commentForChief],
                commentForCourier: formData[FormFields.commentForCourier],
                customDiscountRate: null,
                recipientAddressIdentifier: { id: customerAddressId },
                itemsDeliveriesData: orders.map(({ pack, comment, dates }) => ({
                  orderItemId: uuid.v4(),
                  packIdentifier: { id: pack.id },
                  comment,
                  eatingDays: dates,
                })),
                promoCode: formData[FormFields.promocode],
                selfDelivery: false,
              },
            },
          });
          orderId = order.data?.createOrder.id;
        } catch (err) {
          setOrderStatus(OrderCreationStatus.orderError);
          return;
        }
      }

      if (orderId) {
        try {
          await doUpdateUser({
            variables: {
              id: userId,
              data: {
                firstName: formData[FormFields.name],
              },
            },
          });
        } catch (err) {
          setOrderStatus(OrderCreationStatus.userUpdateError);
          return;
        }

        try {
          await doFixPrices({ variables: { id: orderId } });
          const paymentUrl = await doCreatePaymentUrl({ variables: { id: orderId } });
          const url = paymentUrl.data?.generateOrderBalancePaymentUrl?.value;
          if (url) {
            await clearOrders();
            setOrderStatus(OrderCreationStatus.created);
            window.location.href = url;
          }
        } catch (err) {
          setOrderStatus(OrderCreationStatus.paymentUrlError);
          throw err;
        }
      }
    },
    [
      officeId,
      orders,
      clearOrders,
      doGetOrCreateCustomerAddress,
      doUpdateUser,
      doCreateOrder,
      doFixPrices,
      doCreatePaymentUrl,
    ],
  );
  return [orderStatus, createOrder] as [typeof orderStatus, typeof createOrder];
};

export default useOrderCreation;
