import { API_DAY_FORMAT, dayjs } from '@mono/shared';

dayjs.locale('ru');

export interface DateStrings {
  dayShort: string;
  weekdayShort: string;
  weekdayLong: string;
  ISODate: string;
}

export function getLocaleDateStrings(d: dayjs.Dayjs, lang: string): DateStrings {
  const localDate = d.locale(lang);
  const dayShort = localDate.format('D');
  const weekdayShort = localDate.format('dd');
  const weekdayLong = localDate.format('dddd');
  const ISODate = localDate.format('YYYY-MM-DD');
  return { dayShort, weekdayShort, weekdayLong, ISODate };
}

export function getEuWeekday(date: string) {
  const weekday = dayjs(date).day() - 1;
  return weekday < 0 ? 6 : weekday;
}

export function getEuWeekdayFromDayjs(date: dayjs.Dayjs) {
  const weekday = date.day() - 1;
  return weekday < 0 ? 6 : weekday;
}

export const getWeekdays = () => {
  const weekStart = dayjs().startOf('week');
  const weekdays: string[] = [];
  for (let i = 0; i < 7; i++) {
    weekdays.push(weekStart.add(i, 'days').format('dd'));
  }
  return weekdays;
};

export const weekDays = getWeekdays();

export function getWeekFromInterval(firstDay: string) {
  const date = dayjs(firstDay);
  const sortedDates: string[] = [];
  for (let i = 0; i < 7; i++) {
    const currDate = date.add(i, 'day');
    const index = getEuWeekdayFromDayjs(currDate);
    sortedDates[index] = currDate.format(API_DAY_FORMAT);
  }
  return sortedDates;
}

export const getDaysDiff = (firstDate: string, activeDate: string) => {
  return dayjs(activeDate).diff(dayjs(firstDate), 'day');
};
