import { BaseEntityData, DataI18n, i18n, StorageObjectData } from '@mono/shared';

export const DISH_DEFAULT_DESCRIPTION = '';

export interface DishData extends BaseEntityData {
  __typename: 'Dish';
  name: string;
  description: string;
  image?: StorageObjectData | null;
}

export const dishI18n = i18n<DataI18n<DishData, 'instances' | 'configs' | 'ingredients'>>({
  'Dish': {
    en: 'Dish',
    ru: 'Блюдо',
  },
  'Dish:Plural': {
    en: 'Dishes',
    ru: 'Блюда',
  },
  'Dish.name': {
    en: 'Name',
    ru: 'Название',
  },
  'Dish.description': {
    en: 'Description',
    ru: 'Описание',
  },
  'Dish.image': {
    en: 'Image',
    ru: 'Изображение',
  },
  'Dish:instances': {
    en: 'Instances',
    ru: 'Экземпляры',
  },
  'Dish:configs': {
    en: 'Configs',
    ru: 'Конфиги',
  },
  'Dish:ingredients': {
    en: 'Ingredients',
    ru: 'Ингредиенты',
  },
});
