import { stickyHeaderHeight } from '../../components/header/header';
import pageIds from '../../utils/page-ids';
import { sleep } from '../../utils/time';

export enum LandingAnchors {
  calculator = 'calculator',
  menu = 'menu',
  rations = 'rations',
  delivery = 'delivery',
  advantages = 'advantages',
  ask = 'ask',
  instagram = 'instagram',
  reviews = 'reviews',
  videos = 'videos',
}

export async function scrollToIdOnLoad(id: string, speed?: number) {
  if (id in LandingAnchors) {
    const page = document.querySelector('#' + pageIds.landing);
    if (page) {
      let top = page.querySelector('#' + id)?.getBoundingClientRect().top;
      while (!top) {
        await sleep(500);
        top = page.querySelector('#' + id)?.getBoundingClientRect().top;
      }
      await sleep(1000);
      let newTop = page.querySelector('#' + id)?.getBoundingClientRect().top;
      let isNotReady = newTop ? top !== newTop : true;
      while (isNotReady) {
        top = page.querySelector('#' + id)?.getBoundingClientRect().top;
        await sleep(1000);
        newTop = page.querySelector('#' + id)?.getBoundingClientRect().top;
        isNotReady = newTop && top ? top !== newTop : true;
      }
      const scrollBy = (top ? Math.ceil(top) : 0) - (stickyHeaderHeight ?? 0);

      let ionContent = page.querySelector('ion-content');
      while (!ionContent) {
        await sleep(500);
        ionContent = page.querySelector('ion-content');
      }
      ionContent.scrollByPoint(0, scrollBy, speed ?? 0);
    }
  }
}

export function scrollToId(id: string, speed?: number) {
  if (id in LandingAnchors) {
    const page = document.querySelector('#' + pageIds.landing);
    const top = page?.querySelector('#' + id)?.getBoundingClientRect().top;
    const scrollBy = (top ? Math.ceil(top) : 0) - (stickyHeaderHeight ?? 0);
    page?.querySelector('ion-content')?.scrollByPoint(0, scrollBy, speed ?? 0);
  }
}
