import { BaseEntityData, DataI18n, i18n, UserData } from '@mono/shared';
import { BuildingAddressData } from './building-address';

export interface OfficeData extends BaseEntityData {
  __typename: 'Office';
  name: string;
  code: string | null;
  timezone: string;
  buildingAddress: BuildingAddressData;
  deliveryService: UserData;
}

export const officeI18n = i18n<DataI18n<OfficeData, 'selfDeliveryPickupAddress'>>({
  'Office': {
    en: 'Office',
    ru: 'Офис',
  },
  'Office:Plural': {
    en: 'Offices',
    ru: 'Офисы',
  },
  'Office.code': {
    en: 'Code',
    ru: 'Код',
  },
  'Office.name': {
    en: 'Name',
    ru: 'Название',
  },
  'Office.timezone': {
    en: 'Time',
    ru: 'Часовой пояс',
  },
  'Office.buildingAddress': {
    en: 'Building address',
    ru: 'Адрес здания',
  },
  'Office.deliveryService': {
    en: 'Delivery service',
    ru: 'Служба доставки',
  },
  'Office:selfDeliveryPickupAddress': {
    en: 'Pickup address',
    ru: 'Адрес самовывоза',
  },
});
