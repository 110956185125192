import { css } from 'styled-components';

export const ToastStyles = css`
  @media screen and (min-width: 480px) {
    .Toastify__toast-container {
      width: 300px;
      padding-right: 12px;
    }
  }
  .Toastify__toast {
    line-height: 17px;
    font-size: 13px;
    font-family: var(--ion-font-family);
    .bold {
      font-weight: 600;
    }
    .uppercase {
      text-transform: uppercase;
    }
  }
  .Toastify__toast--default {
    color: #383838;
  }
  .Toastify__toast--error {
    color: #ce3121;
    border: 1px solid rgb(245 0 20 / 40%);
    background: #f7ebeb;
  }
  .Toastify__close-button--error {
    color: #848484;
    opacity: 0.5;
  }
`;
