import { PacksForOrder } from '../../apollo/api/types';
import { OrderItem } from '../../hooks/orders/utils';

export const MIN_WEEKS = 1;
export const MAX_WEEKS = 8;

export interface Props {
  item: OrderItem;
  packs: PacksForOrder['packs'];
  discountRate: number;
  deleteDisabled?: boolean;
}
