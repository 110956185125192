export const unique = (value: unknown, index: number, self: Array<unknown>): boolean => {
  return self.indexOf(value) === index;
};

export const uniqueBy =
  <T>(map: (a: T) => string) =>
  (value: T, index: number, self: Array<T>) => {
    return unique(
      map(value),
      index,
      self.map(it => map(it)),
    );
  };

export const asc = (a: string, b: string) => a.normalize().localeCompare(b.normalize());

export const ascBy =
  <T>(map: (a: T) => string) =>
  (a: T, b: T): number => {
    return asc(map(a), map(b));
  };

export const desc = (a: string, b: string) => b.normalize().localeCompare(a.normalize());

export const descBy =
  <T>(map: (a: T) => string) =>
  (a: T, b: T): number => {
    return desc(map(a), map(b));
  };

export const numberAsc = (a: number, b: number) => (a < b ? -1 : 1);

export const numberAscBy =
  <T>(map: (a: T) => number) =>
  (a: T, b: T): number => {
    return numberAsc(map(a), map(b));
  };

export const numberDesc = (a: number, b: number) => (a > b ? -1 : 1);

export const numberDescBy =
  <T>(map: (a: T) => number) =>
  (a: T, b: T): number => {
    return numberDesc(map(a), map(b));
  };
