import { gql } from '@apollo/client';

export const REQUEST_CONTACT_CONFIRMATION = gql`
  mutation RequestContactConfirmation($contactIdentifier: ContactIdentifier!) {
    requestContactConfirmation(contactIdentifier: $contactIdentifier) {
      id
      value
      confirmationCodeSent
    }
  }
`;
