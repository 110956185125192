import { Storage } from '@capacitor/storage';
import { AA, asArray } from '@mono/shared';

export const set = async <T>(key: string, value: T): Promise<T> => {
  await Storage.migrate();
  if (value === undefined) {
    await Storage.remove({ key });
  } else {
    await Storage.set({ key, value: JSON.stringify(value) });
  }
  return value;
};

export const setAll = async (map: AA): Promise<void> => {
  for (const key of Object.keys(map)) {
    await set(key, map[key]);
  }
};

export const getOptional = async <T, K extends string = string>(key: K): Promise<T | undefined> => {
  await Storage.migrate();
  const { value: stringifiedValue } = await Storage.get({ key });
  if (stringifiedValue === null) {
    return undefined;
  }
  return JSON.parse(stringifiedValue);
};

export const get = async <T, K extends string = string>(key: K): Promise<T> => {
  const value = await getOptional<T, K>(key);
  if (value === undefined) {
    throw Error(`No value is stored by '${key}' key`);
  }
  return value;
};

export const remove = async (keyOrKeys: string | string[]): Promise<void> => {
  await Storage.migrate();
  for (const key of asArray(keyOrKeys)) {
    await Storage.remove({ key });
  }
};

export const clear = async (): Promise<void> => {
  await Storage.migrate();
  await Storage.clear();
};

export const storageUtils = {
  set,
  setAll,
  getOptional,
  get,
  remove,
  clear,
};
