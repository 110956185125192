import { i18n } from '@mono/shared';

export const frontendI18n = i18n({
  'Unexpected error': {},
  'Network error': {
    en: 'Network request failed',
    ru: 'Не удалось выполнить запрос',
  },
  'Please, try later': {
    ru: 'Пожалуйста, попробуйте позже',
  },
  'Check data and try again': {
    ru: 'Проверьте данные и попробуйте позже',
  },
});
