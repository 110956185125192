import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { IonButton, IonLabel } from '@ionic/react';
import { getDefinedOrThrow, phoneInternationalToCisString, validation } from '@mono/shared';
import { close as closePng } from '../../../assets/icon';
import { avocadoSpice, basil1, basil2, basil3 } from '../../../assets/images';
import { SEND_TELEGRAM_MESSAGE_TO_OFFICE_CHAT } from '../../apollo/api/queries/send-telegram-message-to-office-chat';
import {
  SendTelegramMessageToOfficeChat,
  SendTelegramMessageToOfficeChatVariables,
  TelegramOfficeChat,
} from '../../apollo/api/types';
import PhoneInput from '../../components/phone-input';
import { PhoneParts } from '../../components/phone-input/phone-input';
import { cisPhoneRegex } from '../../consts';
import ContactContext from '../../context/contact';
import { OfficeContext } from '../../context/office/office';
import { PopupFormStyle } from '../../styles/popup-form-style';

enum formFields {
  name = 'XKjld90sdf-name',
  phone = 'XKjld90sdf-phone',
}

interface FormFields {
  [formFields.name]: string;
  [formFields.phone]: string;
}

const schema: yup.ObjectSchema<FormFields> = yup
  .object()
  .shape({
    [formFields.phone]: yup.string().required('').matches(cisPhoneRegex, 'Неверный формат номера'),
    [formFields.name]: yup
      .string()
      .required('')
      .min(2, 'Не менее 2-х символов')
      .matches(validation.firstName.regex, 'Недопустимые символы'),
  })
  .defined();

const CallMePopup = (props: {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  trigger?: JSX.Element;
}) => {
  const {
    officeUiData: { officeCode, phoneNumber },
  } = getDefinedOrThrow(useContext(OfficeContext));

  const [contact] = useContext(ContactContext);
  const [requestSent, setRequestSent] = useState(false);

  const [doSendTelegramMessageToOfficeChat] = useMutation<
    SendTelegramMessageToOfficeChat,
    SendTelegramMessageToOfficeChatVariables
  >(SEND_TELEGRAM_MESSAGE_TO_OFFICE_CHAT);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onSubmit',
  });

  const handlePhoneChange = useCallback(
    (phone: PhoneParts) => {
      clearErrors(formFields.phone);
      setValue(formFields.phone, phone.int);
      setRequestSent(false);
    },
    [setValue, clearErrors],
  );

  useEffect(() => {
    register(formFields.phone);
  }, [register]);

  const onSubmit = async (data: FormFields) => {
    const target = 'CallMeBack_Submitted';
    globalThis.ym?.(68302765, 'reachGoal', target);
    globalThis.gtag?.('event', target);
    setRequestSent(true);
    const message =
      'Заявка на обратный звонок: ' +
      data[formFields.name] +
      ', ' +
      phoneInternationalToCisString(data[formFields.phone]);
    try {
      const res = await doSendTelegramMessageToOfficeChat({
        variables: {
          officeCode,
          officeChat: TelegramOfficeChat.OPERATOR,
          text: message,
        },
      });
      if (res.data?.sendTelegramMessageToOfficeChat) {
        toast('Заявка успешно отправлена');
      }
    } catch (err) {
      toast('Не удалось отправить заявку', { type: 'error' });
    }
  };

  return (
    <StyledPopup
      modal
      {...props}
      onClose={() => {
        clearErrors();
        props.onClose();
      }}
    >
      <div className="text">
        <h3>Перезвоним в течение 15 минут</h3>
        <h2>
          Оставьте свои <br />
          контакты <br className="mobile-line-break" />и мы <br />
          вам перезвоним
        </h2>
        <div>
          <p>
            Или наберите нам по&nbsp;телефону {phoneNumber.replace(/ /g, '\u00a0')}, мы всегда рады
            помочь вам с выбором
          </p>
          <div className="middle-images">
            <img src={avocadoSpice} alt="Авокадо, посыпаное специями" />
            <img src={basil3} alt="Лист базилика" />
          </div>
        </div>
      </div>

      <div className="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={'input-wrapper' + (errors.name ? ' error' : '')}>
            <IonLabel>{errors.name?.message.length ? errors.name.message : 'Имя'}</IonLabel>
            <input
              {...register(formFields.name)}
              className="input"
              placeholder="Ваше имя"
              autoComplete="off"
              onChange={e => clearErrors('name')}
            />
          </div>
          <div className={'input-wrapper' + (errors.phone ? ' error' : '')}>
            <IonLabel>
              {errors.phone?.message.length ? errors.phone.message : 'Номер телефона'}
            </IonLabel>
            <PhoneInput
              countryCode={contact.countryCode}
              phonePart={contact.phonePart}
              onSetPhone={handlePhoneChange}
            />
          </div>
          <IonButton mode="md" type="submit" disabled={requestSent}>
            Отправить
          </IonButton>
          <p className="privacy">
            Оставляя свои данные в форме, вы подтверждаете свое согласие с{' '}
            <a href="/privacy-policy" target="_blank">
              Политикой&nbsp;конфиденциальности
            </a>
          </p>
        </form>
      </div>
      <div className="basil-leaves">
        <img src={basil1} alt="Лист базилика" />
        <img src={basil2} alt="Лист базилика" />
      </div>
      <img className="close" src={closePng} alt="Закрыть" onClick={props.onClose} />
    </StyledPopup>
  );
};

const StyledPopup = styled(Popup)`
  ${PopupFormStyle}
  &-content {
    @media screen and (min-width: 701px) {
      h1,
      h2 {
        min-width: 370px;
      }
      h1 .mobile-line-break,
      h2 .mobile-line-break {
        display: none;
      }
      .text p {
        max-width: 230px;
      }
    }
    @media screen and (max-width: 1024px) and (min-width: 700px) {
      h1,
      h2 {
        min-width: 320px;
      }
    }

    @media screen and (min-width: 401px) and (max-width: 700px) {
      h1 br,
      h2 br {
        display: none;
      }
      h1 .mobile-line-break,
      h2 .mobile-line-break {
        display: unset;
      }
    }
    @media screen and (max-width: 700px) {
      .text p br {
        display: none;
      }

      h1,
      h2 {
        min-width: unset;
      }
    }
    @media screen and (max-width: 500px) {
      h1,
      h2 {
        font-size: 18pt;
        line-height: 20pt;
      }
    }
    @media screen and (max-width: 400px) {
      h1 .mobile-line-break,
      h2 .mobile-line-break {
        display: none;
      }
    }
  }
`;

export default CallMePopup;
