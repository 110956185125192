import { createContext } from 'react';
import { Subtype } from '@mono/shared';
import { CartFormState } from '../../hooks/cart-form/cart-form';

export type ContactState = Subtype<
  CartFormState,
  {
    name?: string;
    countryCode?: number;
    phonePart?: string;
  }
>;

interface ContactActions {
  updateContact: (payload: ContactState) => void;
  getTransactionsCount: (phone: string) => Promise<number | undefined>;
}
const ContactContext = createContext(
  {} as [ContactState, ContactActions['updateContact'], ContactActions['getTransactionsCount']],
);

export default ContactContext;
