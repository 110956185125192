import { useCallback, useEffect, useState } from 'react';
import { storageUtils } from '@mono/frontend';
import { API_DAY_FORMAT, dayjs } from '@mono/shared';
import { getDaysDiff } from '../../utils/dates';
import { KitchenStoppageDay } from '../stoppage-days/stoppage-days';
import {
  filterInactiveDaysFromOrderItem,
  MAX_ID,
  NewOrderItem,
  OrderIdentifier,
  OrderItem,
  Orders,
  randomNumber,
} from './utils';
import { OrdersStorage, storeOrders } from './utils-storage';

const useOrders = (props: {
  firstMenuDate?: string;
  storageData?: OrdersStorage;
  deliveryStoppageDays?: KitchenStoppageDay[];
}) => {
  const [orders, setOrders] = useState<Orders>([]);
  useEffect(() => {
    props.storageData?.orders && setOrders(props.storageData.orders);
  }, [props.storageData]);

  const addOrder = useCallback(
    (newOrderItem: NewOrderItem) => {
      if (props.deliveryStoppageDays) {
        const newId = randomNumber(MAX_ID);
        const filtered = filterInactiveDaysFromOrderItem(
          { ...newOrderItem, id: newId },
          props.deliveryStoppageDays,
        )[1];
        setOrders(orderItems => {
          return orderItems.concat({ ...newOrderItem, dates: filtered, id: newId });
        });
        return newId;
      }
      return undefined;
    },
    [props.deliveryStoppageDays],
  );

  const updateOrder = useCallback(
    (orderItem: OrderItem) => {
      setOrders(orderItems => {
        const index = orderItems.findIndex(order => order.id === orderItem.id);
        if (index > -1 && props.deliveryStoppageDays) {
          const updatedOrders = [...orderItems];
          const filtered = filterInactiveDaysFromOrderItem(
            orderItem,
            props.deliveryStoppageDays,
          )[1];
          updatedOrders[index] = { ...orderItem, dates: filtered };
          return updatedOrders;
        } else {
          return orderItems;
        }
      });
    },
    [props.deliveryStoppageDays],
  );

  const deleteOrder = useCallback((id: OrderIdentifier) => {
    setOrders(orderItems => {
      const updatedOrders = orderItems.filter(order => order.id !== id);
      return updatedOrders;
    });
  }, []);

  const weeksInOrder = useCallback(
    (id: OrderIdentifier, action: 'add' | 'remove', amount?: number) => {
      setOrders(orderItems => {
        const index = orderItems.findIndex(order => order.id === id);

        if (index > -1) {
          const updatedOrders = [...orderItems];
          let orderToUpdate = orderItems[index];
          switch (action) {
            case 'add': {
              orderToUpdate = {
                ...orderToUpdate,
                numWeeks: orderToUpdate.numWeeks + (amount ?? 1),
              };
              break;
            }
            case 'remove': {
              const newNumWeeks = orderToUpdate.numWeeks - (amount ?? 1);
              if (newNumWeeks > 0) {
                const lastDate = dayjs(props.firstMenuDate)
                  .add(newNumWeeks, 'weeks')
                  .format(API_DAY_FORMAT);

                orderToUpdate = {
                  ...orderToUpdate,
                  numWeeks: newNumWeeks,
                  dates: orderToUpdate.dates.filter(date => getDaysDiff(date, lastDate) >= 0),
                };
              }
              break;
            }
          }
          updatedOrders[index] = orderToUpdate;
          return updatedOrders;
        } else {
          return orderItems;
        }
      });
    },
    [props.firstMenuDate],
  );

  const clearOrders = useCallback(async () => {
    setOrders([]);
    await storageUtils.remove('orders');
  }, []);

  useEffect(() => {
    const selectedFirstDate = dayjs(props.firstMenuDate).format(API_DAY_FORMAT);
    setOrders(orders => {
      const updatedOrders = orders.map(order => {
        const lastDate = dayjs(props.firstMenuDate)
          .add(order.numWeeks, 'weeks')
          .format(API_DAY_FORMAT);
        const filteredDates = order.dates.filter(date => {
          return getDaysDiff(selectedFirstDate, date) >= 0 && getDaysDiff(date, lastDate) >= 0;
        });
        return { ...order, dates: filteredDates };
      });
      return updatedOrders;
    });
  }, [props.firstMenuDate]);

  useEffect(() => {
    storeOrders(orders);
  }, [orders]);

  return [orders, addOrder, deleteOrder, updateOrder, weeksInOrder, clearOrders] as [
    Orders,
    typeof addOrder,
    typeof deleteOrder,
    typeof updateOrder,
    typeof weeksInOrder,
    typeof clearOrders,
  ];
};

export type OrdersHook = ReturnType<typeof useOrders>;

export default useOrders;
