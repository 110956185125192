import { i18n } from '../translation';

export type Entity<T extends string = string> = {
  __typename: T;
  id: string;
};

export type GqlEntity<T extends string = string> = Entity<T> & {
  string: string;
};

export interface BaseEntityData extends Entity {
  creationMoment: string;
  lastUpdateMoment: string;
}

export const baseEntityI18n = i18n<keyof BaseEntityData | 'string'>({
  __typename: {
    en: 'Type',
    ru: 'Тип',
  },
  id: {
    en: 'ID',
    ru: 'ID',
  },
  creationMoment: {
    en: 'Creation moment',
    ru: 'Дата создания',
  },
  lastUpdateMoment: {
    en: 'Last update moment',
    ru: 'Дата обновления',
  },
  string: {
    en: 'String representation',
    ru: 'Строковое представление',
  },
});
