import { gql } from '@apollo/client';
import { LOGGED_IN_USER_FRAGMENT } from './logged-in-user';

export const LOG_IN_BY_NICKNAME = gql`
  ${LOGGED_IN_USER_FRAGMENT}
  mutation LogInByNickname($nickname: String!, $password: String!) {
    logIn(userIdentifier: { nickname: $nickname }, password: $password) {
      jwt
      user {
        ...LoggedInUserFragment
      }
    }
  }
`;

export const LOG_IN_BY_CONTACT = gql`
  ${LOGGED_IN_USER_FRAGMENT}
  mutation LogInByContact($contactValue: String!, $password: String!) {
    logInByContact(contactIdentifier: { value: $contactValue }, password: $password) {
      jwt
      user {
        ...LoggedInUserFragment
      }
    }
  }
`;
