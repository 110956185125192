import { chevronDown } from 'ionicons/icons';
import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { IonIcon } from '@ionic/react';
import { PACKS_FOR_ORDER } from '../../apollo/api/queries/packs';
import { PacksForOrder } from '../../apollo/api/types';
import OrdersContext from '../../context/orders';
import PricesContext from '../../context/prices';
import { OrderItem as TOrderItem } from '../../hooks/orders/utils';
import { OrderItem } from './order-item';
import { ActionIonButton } from './styled-buttons';

const OrderPicker = () => {
  const packs = useQuery<PacksForOrder>(PACKS_FOR_ORDER);
  const [orders, addOrder] = useContext(OrdersContext);
  const [prices] = useContext(PricesContext);

  useEffect(() => {
    if (!orders.length && packs.data) {
      addOrder({
        pack: packs.data.packs[0],
        comment: '',
        dates: [],
        numWeeks: 1,
      });
    }
  }, [addOrder, packs.data, orders.length]);

  if (packs.data) {
    return (
      <Container>
        {orders.map((order: TOrderItem) => (
          <OrderItem
            key={order.id}
            packs={packs.data?.packs ?? []}
            item={order}
            discountRate={prices.food?.discountRate ?? 0}
            deleteDisabled={orders.length <= 1}
          />
        ))}
        <ActionIonButton
          mode="md"
          onClick={() => {
            if (packs.data) {
              const pack = packs.data.packs[0];
              addOrder({
                pack,
                comment: '',
                numWeeks: 1,
                dates: [],
              });
            }
          }}
        >
          Дополнить программу
          <IonIcon icon={chevronDown} />
        </ActionIonButton>
      </Container>
    );
  } else {
    return null;
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding-top: 6px;
  > * {
    margin-bottom: 15px;
  }

  .relative-wrap {
    position: relative;
    width: 0;
  }
  .tooltip {
    position: absolute;
    bottom: unset;
    left: unset;
    right: 5px;
    top: 5px;
    font-size: 14pt;
    --size: 20px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    text-align: center;
    color: white;
    font-weight: 800;
  }
  > ion-button {
    width: fit-content;
    align-self: center;
    margin-bottom: 0;
    margin-top: -25px;
    ion-icon {
      margin-left: 10px;
      margin-right: -7px;
    }
  }

  @media screen and (max-width: 600px) {
    > ion-button {
      height: 40px;
      margin-top: -30px;
      margin-bottom: -5px;
    }
  }
`;

export default OrderPicker;
