import { BaseEntityData, DataI18n, i18n } from '@mono/shared';

export interface KitchenWorkStoppageData extends BaseEntityData {
  __typename: 'KitchenWorkStoppage';
  name: string;
  days: string[];
  warning: string;
  description: string;
}

export const kitchenWorkStoppageI18n = i18n<DataI18n<KitchenWorkStoppageData>>({
  'KitchenWorkStoppage': {
    en: 'Kitchen work stoppage',
    ru: 'Перерыв в работе кухни',
  },
  'KitchenWorkStoppage:Plural': {
    en: 'Kitchen work stoppages',
    ru: 'Перерывы в работе кухни',
  },
  'KitchenWorkStoppage.name': {
    en: 'Name',
    ru: 'Название',
  },
  'KitchenWorkStoppage.days': {
    en: 'Days',
    ru: 'Дни',
  },
  'KitchenWorkStoppage.warning': {
    en: 'Warning',
    ru: 'Предупреждение',
  },
  'KitchenWorkStoppage.description': {
    en: 'Description',
    ru: 'Описание',
  },
});
