import { useRouter } from 'next/dist/client/router';
import { useContext, useEffect } from 'react';
import { ApolloError, FetchResult, useMutation } from '@apollo/client';
import { i18n } from '@mono/shared';
import {
  LogInByContact,
  LogInByContactVariables,
  LogInByNickname,
  LogInByNicknameVariables,
} from '../../../../gql-types';
import { AuthContext } from '../../auth';
import { frontendI18n } from '../../frontend-i18n';
import { LOG_IN_BY_CONTACT, LOG_IN_BY_NICKNAME } from '../../gql/operations/auth/log-in';
import { RootLayoutContext } from '../../layout/root';
import { useI18n } from '../../translation';
import { UsernameScope } from '../../username-type';

const logInByUsernameI18n = i18n({
  ...frontendI18n.translations,
  'LogIn operation failed': {
    en: 'Failed to log in',
    ru: 'Не удалось войти',
  },
});

type LogInByUsernameMutation = ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => Promise<FetchResult<LogInByNickname | LogInByContact>>;

export const useLogInByUsername = ({
  narrowUsernameScope,
  homeRoute,
}: {
  narrowUsernameScope: UsernameScope;
  homeRoute: string;
}): LogInByUsernameMutation => {
  const t = useI18n(logInByUsernameI18n);
  const rootLayoutContext = useContext(RootLayoutContext);
  const authContext = useContext(AuthContext);
  const router = useRouter();
  useEffect(() => {
    if (authContext.principal != null) {
      router.push(homeRoute);
    }
  }, [authContext, router, homeRoute]);
  const handleError = (error: ApolloError) => {
    console.error(error);
    rootLayoutContext?.pushNotification({
      type: 'warning',
      ...(error.networkError
        ? {
            title: t('Network error'),
            text: t('Please, try later'),
          }
        : {
            title: t('LogIn operation failed'),
            text: t('Check data and try again'),
          }),
    });
  };
  const [logInByNickname] = useMutation<LogInByNickname, LogInByNicknameVariables>(
    LOG_IN_BY_NICKNAME,
    {
      onError: handleError,
      onCompleted: async ({ logIn: { jwt, user } }) => {
        await authContext.logIn({ jwt, principal: { id: user.id, roles: user.roles } });
      },
    },
  );
  const [logInByContact] = useMutation<LogInByContact, LogInByContactVariables>(LOG_IN_BY_CONTACT, {
    onError: handleError,
    onCompleted: async ({ logInByContact: { jwt, user } }) => {
      await authContext.logIn({ jwt, principal: { id: user.id, roles: user.roles } });
    },
  });
  const mutation: LogInByUsernameMutation = async ({ username, password }) => {
    if (narrowUsernameScope === 'username' || narrowUsernameScope === 'contact') {
      throw Error(`Narrow username scope (${narrowUsernameScope}) is too wide.`);
    }
    switch (narrowUsernameScope) {
      case 'nickname':
        return logInByNickname({
          variables: {
            nickname: username,
            password,
          },
        });
      case 'email':
      case 'phone':
        return logInByContact({
          variables: {
            contactValue: username,
            password,
          },
        });
    }
  };
  return mutation;
};
