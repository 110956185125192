import styled from 'styled-components';

export default () => {
  return (
    <Styles>
      <div className="container">
        <h3>Видео о нас</h3>
        <iframe
          title="Основатель проекта Дарья"
          src="https://www.youtube.com/embed/5K3yuYM5p44"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
        />
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  iframe {
    width: 420px;
    height: min(236px, 24vw);
    max-width: 420px;
    max-height: 236px;
    &:first-child {
      margin-right: max(20px, 3%);
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      flex-direction: column;
      padding-bottom: 22px;
    }
    iframe {
      width: 100%;
      height: calc(56vw - 28px);
      max-width: 600px;
      max-height: 337px;
      margin: 0 0 40px;
      &:first-child {
        margin-right: 0;
      }
    }
  }
`;
