import basketOutline from './basket-outline.svg';
import basket from './basket.svg';
import car from './car.svg';
import chefHat from './chef-hat.svg';
import close from './close.png';
import dotsBetween from './dots-between.jpg';
import click from './icon-click.png';
import deliveryPerson from './icon-delivery-person.svg';
import foodContainer from './icon-food-containers.png';
import paycard from './icon-paycard.png';
import scientist from './icon-scientist.svg';
import logoFlampLetter from './logo-flamp.svg';
import logoMetro from './logo-metro.svg';
import logoTelegramBw from './logo-telegram-bw.svg';

export {
  basket,
  basketOutline,
  logoTelegramBw,
  logoFlampLetter,
  scientist,
  deliveryPerson,
  click,
  paycard,
  foodContainer,
  dotsBetween,
  chefHat,
  close,
  car,
  logoMetro,
};
