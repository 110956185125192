import { css } from 'styled-components';
import { IonButtonOutlineStyle } from './ion-button-style';

export const IonSegmentStyles = css`
  --margin: 10px;
  display: flex;
  width: fit-content;
  margin: calc(var(--margin) * -1);
  ion-button {
    margin: var(--margin);
  }

  ion-segment-button {
    ${IonButtonOutlineStyle}
    margin: var(--margin);
    overflow: hidden;
    &.segment-button-checked {
      --border-color: #68c63a;
      color: #383838;
    }
    &::part(indicator) {
      display: none;
    }
  }
`;
