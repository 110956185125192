import { gql } from '@apollo/client';

export const DISCOUNTS = gql`
  query Discounts {
    discounts(useCache: true) {
      id
      name
      rate
      cumulative
      packsCountLowerLimit
      packsCountUpperLimit
      priceLowerLimit
      priceUpperLimit
      daysCountLowerLimit
      daysCountUpperLimit
      startMoment
      endMoment
      userHadNoTransactions
      promoCode
      selfDelivery
    }
  }
`;
