export type { Entity, GqlEntity, BaseEntityData } from './lib/data/base-entity';
export { baseEntityI18n } from './lib/data/base-entity';
export type { CbsaCodeData } from './lib/data/geography/cbsa-code';
export { cbsaCodeI18n } from './lib/data/geography/cbsa-code';
export type { ContactData } from './lib/data/core/contact';
export { CONTACT_DEFAULT_CONFIRMED, contactI18n } from './lib/data/core/contact';
export type { ContactTypeKey } from './lib/data/core/contact-type';
export { ContactType, contactTypeI18n } from './lib/data/core/contact-type';
export { getContactTypeOf } from './lib/data/core/contact-type-utils';
export type { CountryData } from './lib/data/geography/country';
export { countryI18n } from './lib/data/geography/country';
export type { GenderKey } from './lib/data/core/gender';
export { Gender, genderI18n } from './lib/data/core/gender';
export type { LocationData } from './lib/data/geography/location';
export { locationI18n } from './lib/data/geography/location';
export type { PostalCodeData } from './lib/data/geography/postal-code';
export { postalCodeI18n } from './lib/data/geography/postal-code';
export type { StorageObjectData } from './lib/data/core/storage-object';
export { storageObjectI18n } from './lib/data/core/storage-object';
export type { StorageObjectTagData } from './lib/data/core/storage-object-tag';
export { storageObjectTagI18n } from './lib/data/core/storage-object-tag';
export type { UserData } from './lib/data/core/user';
export { USER_DEFAULT_GENDER, USER_DEFAULT_CONTACTS, userI18n } from './lib/data/core/user';

export { booleanI18n } from './lib/boolean-i18n';

export type { JobStatusKey } from './lib/queues';
export { JobStatus, jobStatusI18n } from './lib/queues';

export {
  SharedAction,
  SharedRole,
  sharedRoleI18n,
  ANON,
  USER,
  buildAbilityBy,
  getGroupFn,
  coreSubjects,
  storageSubjects,
  geographySubjects,
} from './lib/casl';
export type {
  SharedActionKey,
  SharedRoleKey,
  SharedSubject,
  SharedAbility,
  Principal,
  AuthData,
  GetAbilityOf,
  Permissions,
  CanFn,
} from './lib/casl';

export { distinct, hasValue } from './lib/utils/stream/filter';
export {
  unique,
  uniqueBy,
  asc,
  ascBy,
  desc,
  descBy,
  numberAsc,
  numberAscBy,
  numberDesc,
  numberDescBy,
} from './lib/utils/stream/sort';
export type { ElementOrArray } from './lib/utils/types/element-or-array';
export type { OneOf } from './lib/utils/types/one-of';
export type { PartialBy } from './lib/utils/types/partial-by';
export type { RequiredNonNullable } from './lib/utils/types/required-non-nullable';
export type { O, AA, PromiseOr } from './lib/utils/types/shortcuts';
export type { Writable } from './lib/utils/types/writable';
export { distinctById, isTruthy, asArray, copyArray, eqByElements } from './lib/utils/array';
export { mapValue, mapValueOrThrow } from './lib/utils/map';
export { regExpPattern, escapeRegExp } from './lib/utils/regexp';
export { stringify, eqInJson, parse } from './lib/utils/json';

export type { BuildingAddressUid } from './lib/building-address-uid/building-address-uid';
export type { BuildingAddressUidPartKey } from './lib/building-address-uid/building-address-uid-part';
export {
  BuildingAddressUidPart,
  buildingAddressUidPartI18n,
} from './lib/building-address-uid/building-address-uid-part';
export { buildingAddressUidUtils } from './lib/building-address-uid/building-address-uid-utils';

export type { GeoPoint } from './lib/utils/geo-point/geo-point';
export type { GeoPointOption } from './lib/utils/geo-point/geo-point-option';
export { areGeoPointsEqual } from './lib/utils/geo-point/geo-point-utils';
export {
  isDateStringFormattedStrictlyAs,
  getStrictDateStringFormat,
  isValidDateString,
  createDate,
  areSameDates,
  getLosslessDateStringFormats,
} from './lib/utils/scalar/date-scalar-utils';
export { toDecimalString } from './lib/utils/scalar/decimal-scalar-utils';

export {
  API_DAY_FORMAT,
  API_DAY_UNIT_FORMATS,
  API_TIME_FORMAT,
  API_TIME_UNIT_FORMATS,
  API_MOMENT_FORMAT,
  API_MOMENT_UNIT_FORMATS,
  MAX_ABS_UTC_OFFSET_IN_MINUTES,
} from './lib/consts';

export type { SharedDayjs } from './lib/dayjs';
export { dayjs, Dayjs, Month } from './lib/dayjs';

export { numberEnumKeys, numberEnumValues, stringEnumKeys, stringEnumValues } from './lib/enums';
export type { StringEnumType, NumberEnumType } from './lib/enums';

export { formatContactValue } from './lib/format';

export {
  round,
  phoneInternationalToCisString,
  EPSILON,
  nearlyEqual,
  getDefinedOrThrow,
  getValueOrThrow,
  getKeysOf,
  big,
  removeUndefinedProps,
  isObjectLiteral,
  isEmptyObjectLiteral,
  normalizeWhitespaces,
  replaceNewLinesBySpaces,
  decline,
  formatUtcOffset,
  chooseBy,
} from './lib/misc';

export type { MonoProjectValue, MonoModuleValue, MonoEnvValue } from './lib/mono';
export { MonoProject, MonoModule, MonoEnv } from './lib/mono';

export {
  dasherize,
  decamelize,
  lowerCaseFirstLetter,
  upperCaseFirstLetter,
  joinBy,
  join,
  isEmpty,
  isBlank,
  hasContent,
  toUpperCase,
  split,
  truncate,
} from './lib/strings';

export type { Subtype } from './lib/subtype';

export { i18n } from './lib/translation';
export { Language, languageI18n } from './lib/translation';
export type { LanguageKey, Translation, Translations, DataI18n } from './lib/translation';

export { validation } from './lib/validation';

export type { CoreEntity, GeographyEntity, SharedEntity } from './lib/shared-entity';

export { Criterion } from './lib/criterion';
export type { CriterionValue } from './lib/criterion';
