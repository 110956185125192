import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';
import styled from 'styled-components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IonButton, IonLabel } from '@ionic/react';
import { close as closePng } from '../../../assets/icon';
import { avocadoSpice, basil1, basil2, basil3 } from '../../../assets/images';
import PhoneInput from '../../components/phone-input';
import { PhoneParts } from '../../components/phone-input/phone-input';
import { cisPhoneRegex } from '../../consts';
import ContactContext from '../../context/contact';
import { PopupFormStyle } from '../../styles/popup-form-style';

enum formFields {
  phone = 'phone',
  countryCode = 'country-code',
  phonePart = 'phone-part',
}

interface FormFields {
  phone?: number;
}

const schema = yup
  .object()
  .shape({
    [formFields.phone]: yup.string().required('').matches(cisPhoneRegex, 'Неверный формат номера'),
  })
  .defined();

const NewUserCheckPopup = (props: { discountAmount: number; trigger: JSX.Element }) => {
  const [errorText, setErrorText] = useState('');
  const [contact, updateContact, getTransactionsCount] = useContext(ContactContext);

  const history = useHistory();
  const [requestSent, setRequestSent] = useState(false);
  const popupRef = useRef<PopupActions>(null);

  const closeModal = () => popupRef.current?.close();
  const openModal = () => popupRef.current?.open();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onSubmit',
  });

  const handlePhoneChange = useCallback(
    (phone: PhoneParts) => {
      clearErrors(formFields.phone);
      setValue(formFields.phone, phone.int);
      setValue(formFields.countryCode, phone.cc);
      setValue(formFields.phonePart, phone.pp);
      setRequestSent(false);
    },
    [setValue, clearErrors],
  );

  useEffect(() => {
    register(formFields.phone);
    register(formFields.countryCode);
    register(formFields.phonePart);
  }, [register]);

  const onSubmit = data => {
    const target = 'TryFirstPackAtDiscount_Submitted';
    globalThis.ym?.(68302765, 'reachGoal', target);
    globalThis.gtag?.('event', target);
    setRequestSent(true);
    getTransactionsCount(data[formFields.phone]).then(transactionsCount => {
      if (transactionsCount === undefined || transactionsCount === 0) {
        closeModal();
        const newCart = {
          phonePart: data[formFields.phonePart],
          countryCode: data[formFields.countryCode],
        };
        updateContact(newCart);
        history.push('/cart');
      } else {
        setErrorText('Похоже, Вы у нас не впервые!');
      }
    });
  };

  return (
    <StyledPopup
      ref={popupRef}
      modal
      onOpen={() => {
        const target = 'TryFirstPackAtDiscount_Clicked';
        globalThis.ym?.(68302765, 'reachGoal', target);
        globalThis.gtag?.('event', target);
      }}
      onClose={() => clearErrors()}
      trigger={props.trigger}
    >
      <div className="text">
        <h2>
          Впервые у нас?
          <br />
          Попробуйте рацион <br />
          со скидкой {props.discountAmount}%
        </h2>
        <div>
          <p>Акция действует на один пробный день и на один пакет.</p>
          <div className="middle-images">
            <img src={avocadoSpice} alt="Авокадо, покрытое специями" />
            <img src={basil3} alt="Лист базилика" />
          </div>
        </div>
      </div>

      <div className="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={'input-wrapper' + (errors.phone ? ' error' : '')}>
            <IonLabel>
              {errors.phone?.message.length ? errors.phone.message : 'Номер телефона'}
            </IonLabel>
            <PhoneInput onSetPhone={handlePhoneChange} />
          </div>
          <IonButton mode="md" type="submit" disabled={!!errorText || requestSent}>
            {errorText ? errorText : 'Отправить'}
          </IonButton>
          <p className="privacy">
            Оставляя свои данные в форме, вы подтверждаете свое согласие с{' '}
            <a href="/privacy-policy" target="_blank">
              Политикой&nbsp;конфиденциальности
            </a>
          </p>
        </form>
      </div>
      <div className="basil-leaves">
        <img src={basil1} alt="Лист базилика" />
        <img src={basil2} alt="Лист базилика" />
      </div>
      <img className="close" src={closePng} alt="Закрыть" onClick={closeModal} />
    </StyledPopup>
  );
};

const StyledPopup = styled(Popup)`
  ${PopupFormStyle}
  &-content {
    .text p span {
      border-bottom: 1px solid lightgrey;
    }

    h1,
    h2 {
      min-width: 330px;
    }

    @media screen and (min-width: 901px) {
      h1,
      h2 {
        font-size: 26pt;
        line-height: 28pt;
      }
    }
    @media screen and (max-width: 1024px) {
      /* .middle-images {
        left: -9px;
      } */
    }
    @media screen and (max-width: 900px) {
      /* .middle-images {
        left: 20px;
      } */
    }
    @media screen and (max-width: 700px) {
      h1 br,
      h2 br {
        display: unset;
      }
    }
    @media screen and (max-width: 700px) and (min-width: 501px) {
      .text p {
        width: 290px;
      }
    }
  }
`;

export default NewUserCheckPopup;
