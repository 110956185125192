import { gql } from '@apollo/client';

export const PACK_DATA_FRAGMENT = gql`
  fragment PackData on Pack {
    id
    name
    price
    position
    targetCalorieContent
  }
`;
export const PACK_ITEMS_DATA_FRAGMENT = gql`
  fragment PackItemsData on Pack {
    items {
      menuItem {
        id
        name
        position
      }
      dishConfigType {
        id
        name
      }
    }
  }
`;
