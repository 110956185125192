export enum MonoProject {
  example = 'example',
  hf = 'hf',
}
export type MonoProjectValue = keyof typeof MonoProject;

export enum MonoModule {
  api = 'api',
  web = 'web',
  app = 'app',
}
export type MonoModuleValue = keyof typeof MonoModule;

export enum MonoEnv {
  local = 'local',
  development = 'development',
  staging = 'staging',
  production = 'production',
}
export type MonoEnvValue = keyof typeof MonoEnv;
