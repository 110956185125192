import styled from 'styled-components';
import { click, deliveryPerson, dotsBetween, foodContainer, paycard } from '../../../assets/icon';
import { gradientGreen } from '../../styles/gradient';

export default () => {
  return (
    <Styles>
      <div className="container">
        <h3 className="title">Оплата и доставка</h3>
        <div className="items-container">
          <div className="item">
            <div className="icon-wrap with-spot">
              <img src={click} alt="Иконка с контуром кликающего курсора" />
            </div>
            <h3 className="title">Заказ</h3>
            <p>
              Придёт СМС с номером заказа и датой первой доставки. Перед доставкой курьер с Вами
              свяжется
            </p>
          </div>
          <div className="item">
            <div className="icon-wrap with-spot pay-icon">
              <img src={paycard} alt="Иконка с контуром банковской карты" />
            </div>
            <h3 className="title">Оплата</h3>
            <p>Только онлайн. Заказ нужно оплатить полностью при оформлении на сайте</p>
          </div>
          <div className="item">
            <div className="icon-wrap with-spot">
              <img src={deliveryPerson} alt="Иконка с контуром доставщика еды" />
            </div>
            <h3 className="title">Доставка</h3>
            <p>Ежедневно с 19:00 до 23:00. В большинство районов бесплатно</p>
          </div>
          <div className="item">
            <div className="icon-wrap with-spot">
              <img src={foodContainer} alt="Иконка с контуром контейнеров с едой" />
            </div>
            <h3 className="title">Упаковка</h3>
            <p>
              Блюда герметично упакованы в ЭКО контейнеры. Их можно греть в микроволновке без крышки
            </p>
          </div>
        </div>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  .items-container {
    display: flex;
  }
  .item {
    flex-basis: 1px;
    flex-grow: 1;
    min-height: 300px;
    padding: 45px 30px;
    margin-left: min(30px, 3%);
    background: linear-gradient(to bottom, #f9fbfd 0%, #f5f7fa 50%, #f1f3f7 100%);
    border-radius: 10px;
    img {
      height: 50px;
    }
    .title {
      margin: 0;
      margin-top: 20px;
      font-size: 1.6em;
    }
    p {
      margin-bottom: 0;
    }
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      background: ${gradientGreen};
      color: white;
      img {
        filter: brightness(0) invert(1);
      }
      .icon-wrap::after {
        background: linear-gradient(to bottom, #ffffff70, white);
      }
    }
    &,
    img,
    .icon-wrap::after {
      transition: color 0.25s ease;
    }
  }
  .item {
    z-index: 1;
    position: relative;
    &::before {
      z-index: 0;
      content: '';
      display: block;
      width: min(30px, 10%);
      height: 30px;
      background: url(${dotsBetween}) no-repeat;
      background-size: 100%;
      position: absolute;
      left: -11.3%;
      top: 49%;
    }
    &:first-child::before {
      display: none;
    }
  }
  .icon-wrap {
    width: fit-content;
    &::after {
      right: -8px;
      bottom: -1px;
      height: 20px;
      width: 20px;
    }
    &.pay-icon::after {
      right: 2px;
    }
  }
  @media screen and (max-width: 768px) {
    .items-container {
      flex-wrap: wrap;
      margin-bottom: -4vw;
    }
    .item {
      min-width: 48%;
      margin-left: 4%;
      margin-bottom: 4vw;
      &::before {
        display: none;
      }
      &:nth-child(2n + 1) {
        margin-left: 0;
      }
    }
    @media screen and (max-width: 600px) {
      .item {
        padding: 6%;
        min-height: unset;
        img {
          height: 40px;
        }
        .title {
          font-size: 12pt;
        }
      }
    }
  }
`;
