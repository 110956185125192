import { BuildingAddressCreateData } from '../../apollo/api/types';
import { findKladrObjectParent, KladrObject, KladrObjectNoParents } from './utils-kladr';

export const getBuildingAddressFromKladrObj = (
  region: KladrObjectNoParents<'region'>,
  city: KladrObject<'city'>,
  street: KladrObject<'street'>,
  building: KladrObject<'building'>,
): BuildingAddressCreateData => {
  const streetOwner = findKladrObjectParent(street, 'streetOwner');
  const district = findKladrObjectParent(building, 'district');

  return {
    cityName: city.name,
    cityType: city.type,
    regionName: region.name,
    regionType: region.type,
    districtName: district?.name ?? '',
    districtType: district?.type ?? '',
    streetName: street.name,
    streetType: street.type,
    streetOwnerName: streetOwner?.name ?? '',
    streetOwnerType: streetOwner?.type ?? '',
    buildingName: building.name,
    buildingType: building.type,
    buildingPostalCodeNumber: building.zip.toString(),
  };
};
