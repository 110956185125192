import { useRouter } from 'next/dist/client/router';
import { useContext, useEffect } from 'react';
import { ApolloError, FetchResult, MutationResult, useMutation } from '@apollo/client';
import { i18n } from '@mono/shared';
import { SignUp, SignUpVariables } from '../../../../gql-types';
import { AuthContext } from '../../auth';
import { frontendI18n } from '../../frontend-i18n';
import { SIGN_UP } from '../../gql/operations/auth/sign-up';
import { RootLayoutContext } from '../../layout/root';
import { useI18n } from '../../translation';
import { UsernameScope } from '../../username-type';

const signUpUserByUsernameI18n = i18n({
  ...frontendI18n.translations,
  'SignUp operation failed': {
    en: 'Failed to sign up',
    ru: 'Не удалось зарегистрироваться',
  },
});

export type SignUpUserByUsernameMutationArgs = {
  username: string;
  password: string;
  firstName: string;
  middleName?: string;
  lastName?: string;
};

type SignUpUserByUsernameMutation = (
  args: SignUpUserByUsernameMutationArgs,
) => Promise<FetchResult<SignUp>>;

export type UseSignUpUserByUsernameArgs = {
  narrowUsernameScope: UsernameScope;
  homeRoute: string;
};

export const useSignUpUserByUsername = ({
  narrowUsernameScope,
  homeRoute,
}: UseSignUpUserByUsernameArgs): [SignUpUserByUsernameMutation, MutationResult<SignUp>] => {
  const t = useI18n(signUpUserByUsernameI18n);
  const rootLayoutContext = useContext(RootLayoutContext);
  const authContext = useContext(AuthContext);
  const router = useRouter();
  useEffect(() => {
    if (authContext.principal != null) {
      router.push(homeRoute);
    }
  }, [authContext, router, homeRoute]);
  const [signUp, signUpResult] = useMutation<SignUp, SignUpVariables>(SIGN_UP, {
    onError: (error: ApolloError) => {
      console.error(error);
      rootLayoutContext?.pushNotification({
        type: 'warning',
        ...(error.networkError
          ? {
              title: t('Network error'),
              text: t('Please, try later'),
            }
          : {
              title: t('SignUp operation failed'),
              text: t('Check data and try again'),
            }),
      });
    },
    onCompleted: async ({ signUp: { jwt, user } }) => {
      await authContext.logIn({ jwt, principal: { id: user.id, roles: user.roles } });
    },
  });
  const mutation: SignUpUserByUsernameMutation = async ({
    username,
    password,
    firstName,
    middleName,
    lastName,
  }) => {
    if (narrowUsernameScope === 'username' || narrowUsernameScope === 'contact') {
      throw Error(`Narrow username scope (${narrowUsernameScope}) is too wide.`);
    }
    return signUp({
      variables: {
        data: {
          ...(() => {
            switch (narrowUsernameScope) {
              case 'nickname':
                return { nickname: username };
              case 'email':
              case 'phone':
                return { contactsData: [{ value: username }] };
            }
          })(),
          password,
          firstName,
          middleName,
          lastName,
        },
      },
    });
  };
  return [mutation, signUpResult];
};
