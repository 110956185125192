import { join, joinBy } from '../strings';
import { O } from '../utils/types/shortcuts';
import { BuildingAddressUid } from './building-address-uid';

const copy = (uid: BuildingAddressUid): BuildingAddressUid => {
  return {
    regionType: uid.regionType,
    regionName: uid.regionName,
    districtType: uid.districtType,
    districtName: uid.districtName,
    cityType: uid.cityType,
    cityName: uid.cityName,
    streetOwnerType: uid.streetOwnerType,
    streetOwnerName: uid.streetOwnerName,
    streetType: uid.streetType,
    streetName: uid.streetName,
    buildingType: uid.buildingType,
    buildingName: uid.buildingName,
    buildingPostalCodeNumber: uid.buildingPostalCodeNumber,
  };
};

const areFieldsEqual = (a: O<string>, b: O<string>): boolean => {
  return a?.trim().toUpperCase() === b?.trim().toUpperCase();
};

const areEqual = (a: BuildingAddressUid, b: BuildingAddressUid): boolean => {
  const data: [O<string>, O<string>][] = [
    [a.regionType, b.regionType],
    [a.regionName, b.regionName],
    [a.districtType, b.districtType],
    [a.districtName, b.districtName],
    [a.cityType, b.cityType],
    [a.cityName, b.cityName],
    [a.streetOwnerType, b.streetOwnerType],
    [a.streetOwnerName, b.streetOwnerName],
    [a.streetType, b.streetType],
    [a.streetName, b.streetName],
    [a.buildingType, b.buildingType],
    [a.buildingName, b.buildingName],
    [a.buildingPostalCodeNumber, b.buildingPostalCodeNumber],
  ];
  return data.reduce((result, [a, b]) => result && areFieldsEqual(a, b), true);
};

const formatAsAddressString = (uid: BuildingAddressUid) => {
  const {
    regionType,
    regionName,
    districtType,
    districtName,
    cityType,
    cityName,
    streetOwnerType,
    streetOwnerName,
    streetType,
    streetName,
    buildingType,
    buildingName,
    buildingPostalCodeNumber,
  } = uid;
  return joinBy(', ', [
    join(regionType, regionName),
    join(districtType, districtName),
    join(cityType, cityName),
    join(streetOwnerType, streetOwnerName, streetType, streetName, buildingType, buildingName),
    buildingPostalCodeNumber,
  ]);
};

const formatAsShortRegion = (uid: Partial<BuildingAddressUid>) => {
  const { regionType = '', regionName = '' } = uid;
  return join(regionType.replace('Город', 'г.').replace('Область', 'обл.'), regionName);
};

const formatAsShortCity = (uid: Partial<BuildingAddressUid>) => {
  const { districtType = '', districtName = '', cityType = '', cityName = '' } = uid;
  return joinBy(', ', [
    join(districtType.replace('Район', 'р-н'), districtName),
    join(
      cityType
        .replace('Город', 'г.')
        .replace('Дачный поселок', 'д. п.')
        .replace('Поселок', 'п.')
        .replace('Рабочий поселок', 'р. п.')
        .replace('Село', 'с.'),
      cityName,
    ),
  ]);
};

const formatAsShortStreet = (uid: Partial<BuildingAddressUid>) => {
  const { streetOwnerType = '', streetOwnerName = '', streetType = '', streetName = '' } = uid;
  return join(
    streetOwnerType.replace('Микрорайон', 'мкр.').replace('Территория', 'тер.'),
    streetOwnerName,
    streetType
      .replace('Бульвар', 'б-р')
      .replace('Городок', 'г-к')
      .replace('Квартал', 'кв-л')
      .replace('Микрорайон', 'мкр.')
      .replace('Переулок', 'пер.')
      .replace('Площадь', 'пл.')
      .replace('Проезд', 'проезд')
      .replace('Проспект', 'пр.')
      .replace('Территория', 'тер.')
      .replace('Улица', 'ул.'),
    streetName,
  );
};

const formatAsShortBuilding = (uid: Partial<BuildingAddressUid>) => {
  const { buildingType = '', buildingName = '' } = uid;
  return join(buildingType.replace('дом', ''), buildingName);
};

const formatAsShortAddressString = (uid: BuildingAddressUid) => {
  return joinBy(', ', [
    formatAsShortCity(uid),
    join(formatAsShortStreet(uid), formatAsShortBuilding(uid)),
  ]);
};

export const buildingAddressUidUtils = {
  copy,
  areFieldsEqual,
  areEqual,
  formatAsAddressString,
  formatAsShortRegion,
  formatAsShortCity,
  formatAsShortStreet,
  formatAsShortBuilding,
  formatAsShortAddressString,
};
