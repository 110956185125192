import { Big } from 'big.js';
import { dayjs } from './dayjs';
import { AA, O } from './utils/types/shortcuts';

export const round = (value: number, fractionDigits: number): number => {
  const factor = Math.pow(10, fractionDigits);
  return Math.round((value + Number.EPSILON) * factor) / factor;
};

export const phoneInternationalToCisString = (phone: string) => {
  return (
    phone.substr(0, 2) +
    '\u00a0(' +
    phone.substr(2, 3) +
    ')\u00a0' +
    phone.substr(5, 3) +
    '-' +
    phone.substr(8, 2) +
    '-' +
    phone.substr(10, 2)
  );
};

export const EPSILON = 0.001;

export const nearlyEqual = (a: number, b: number, epsilon = EPSILON) => {
  return Math.abs(Math.abs(a) - Math.abs(b)) < epsilon;
};

export const getDefinedOrThrow = <T>(value: T, ...values: T[]): Exclude<T, undefined> => {
  const firstDefinedValue = [value, ...values].find(it => it !== undefined);
  // if (firstDefinedValue === undefined) {
  //   throw Error('Value is not provided');
  // }
  return firstDefinedValue as Exclude<T, undefined>; // returns never if arg is undefined
};

export const getValueOrThrow = <T>(
  value: T,
  options: { errorMessage?: string } = {},
): Exclude<T, null | undefined> => {
  if (value == null) {
    throw Error(options.errorMessage ?? 'Value is not provided');
  }
  return value as Exclude<T, null | undefined>; // returns never if arg is null or undefined
};

export const getKeysOf = <T extends Record<string, unknown>>(
  object: T,
): Array<keyof T & string> => {
  return Object.keys(object);
};

export function big(value: string): Big;
export function big(value: string | null): Big | null;
export function big(value: string | undefined): Big | undefined;
export function big(value: O<string>): O<Big>;
export function big(value: O<string>): O<Big> {
  return value == null ? value : Big(value);
}

export const removeUndefinedProps = <T>(aa: AA<T>): AA<T> => JSON.parse(JSON.stringify(aa));

export const isObjectLiteral = (v: unknown): v is Record<string | number | symbol, unknown> => {
  if (typeof v !== 'object' || v == null) {
    return false;
  }
  const constructor = v.constructor;
  return constructor === undefined || constructor === Object;
};

export const isEmptyObjectLiteral = (
  value: unknown,
  options: { ignoreUndefinedValues: boolean } = { ignoreUndefinedValues: false },
) => {
  if (!isObjectLiteral(value)) {
    return false;
  }
  const keys = Object.keys(value);
  if (keys.length === 0) {
    return true;
  }
  if (keys.map(key => value[key]).filter(value => value !== undefined).length > 0) {
    return false;
  }
  return options.ignoreUndefinedValues;
};

export const normalizeWhitespaces = (input: string) => {
  return input.trim().replace(/  +/g, ' ').replace(/\n /g, '\n').replace(/ \n/g, '\n');
};

export const replaceNewLinesBySpaces = (input: string) => input.replace(/[\r\n]+/g, ' ');

export const decline = (number: number, cases: [string, string, string]) => {
  if (!Number.isInteger(number)) {
    return cases[1];
  }
  const abs = Math.abs(number);
  return cases[
    abs % 100 > 4 && abs % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][abs % 10 < 5 ? abs % 10 : 5]
  ];
};

export const formatUtcOffset = (minutes: number): string => {
  const sign = minutes >= 0 ? '+' : '-';
  return `${sign}${dayjs().startOf('day').add(Math.abs(minutes), 'minutes').format('HH:mm')}`;
};

export const chooseBy = <K extends string, T>(key: K, records: Record<K, T>) => records[key];
