import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { IonButton, IonPage } from '@ionic/react';
import { gradientGreen } from '../../styles/gradient';
import { IonButtonStyle } from '../../styles/ion-button-style';

export default () => {
  const history = useHistory();

  useEffect(() => {
    window.location.assign('assets/privacy-policy.pdf');
    return () => history.goBack();
  }, [history]);

  return (
    <StyledIonPage>
      <div className="container">
        <h1>Политика конфиденциальности</h1>

        <a href="assets/privacy-policy.pdf">
          <IonButton>Скачать документ</IonButton>
        </a>
      </div>
    </StyledIonPage>
  );
};

const StyledIonPage = styled(IonPage)`
  font-family: var(--ion-font-family);
  color: var(--ion-text-color);
  padding-top: 20px;
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  a {
    align-self: center;
  }
  ion-button {
    ${IonButtonStyle}
    --background: ${gradientGreen};
    --color: white;
  }
`;
