import { Big } from 'big.js';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { orderDataUtils } from '@mono/hf';
import { hasValue, O } from '@mono/shared';
import { DISCOUNTS } from '../../apollo/api/queries/discounts';
import { TRANSACTIONS_COUNT } from '../../apollo/api/queries/transactions-count';
import { Discounts, TransactionsCount, TransactionsCountVariables } from '../../apollo/api/types';
import { cisPhoneRegex } from '../../consts';
import { countDeliveryDates, getOrderItems } from '../../utils/orders';
import { OrderItem } from '../orders/utils';
import { useImperativeQuery } from '../use-imperative-query';

interface FoodPrices {
  discounts: Discounts['discounts'];
  applicableDiscounts: Discounts['discounts'];
  discountRate: number;
  discountPrice: Big;
  fullPrice: Big;
}

export type DeliveryPrice = Big | null;

interface DeliveryPrices {
  numDays: number;
  single?: DeliveryPrice;
  total?: DeliveryPrice;
}

interface Prices {
  food?: FoodPrices;
  delivery: DeliveryPrices;
  total?: Big;
}

const usePrices = (props: { orders?: OrderItem[]; today?: string }) => {
  const discountsQuery = useQuery<Discounts>(DISCOUNTS);
  const queryTransactionsCount = useImperativeQuery<TransactionsCount, TransactionsCountVariables>(
    TRANSACTIONS_COUNT,
  );
  const [userTransactionsCount, setTransactionsCount] = useState<number | undefined>(0);
  const [promoCode, setPromoCode] = useState<string | null>(null);
  const [prices, setPrices] = useState<Prices>({ delivery: { numDays: 0 } });

  useEffect(() => {
    setPrices(it => {
      const numDays = props.orders ? countDeliveryDates(props.orders) : 0;
      const delivery = hasValue(it.delivery)
        ? { ...it.delivery, numDays, total: it.delivery.single?.mul(numDays) }
        : it.delivery;
      return { ...it, delivery };
    });
  }, [props.orders]);

  const getTransactionsCount = useCallback(
    async (phone: string) => {
      if (phone.match(cisPhoneRegex)) {
        const res = await queryTransactionsCount({ value: phone });
        const count = res.data?.contact?.owner.profile.transactionsCount ?? 0;
        setTransactionsCount(count);
        return count;
      } else {
        setTransactionsCount(undefined);
        return undefined;
      }
    },
    [queryTransactionsCount],
  );

  const setDeliveryPrice = useCallback((value: O<Big>) => {
    setPrices(it => {
      const delivery = hasValue(value)
        ? {
            single: value,
            total: it.delivery.numDays > 0 ? value.mul(it.delivery.numDays) : value,
            numDays: it.delivery.numDays,
          }
        : {
            single: value,
            total: value,
            numDays: it.delivery.numDays,
          };

      return { ...it, delivery };
    });
  }, []);

  useEffect(() => {
    let foodPrices: FoodPrices | undefined;
    if (props.today && props.orders) {
      const orderItemsData = getOrderItems(props.orders);
      const basePrice = orderDataUtils.calculateBasePrice(orderItemsData);
      if (discountsQuery.data) {
        const applicableDiscounts = orderDataUtils.filterApplicableDiscounts(
          discountsQuery.data.discounts,
          {
            order: {
              creationMoment: props.today,
              selfDelivery: false,
              promoCode,
            },
            basePrice,
            packsCount: orderDataUtils.calculatePacksCount(orderItemsData),
            daysCount: countDeliveryDates(props.orders),
            userHadNoTransactions: userTransactionsCount === 0,
          },
        );
        const discountRate = orderDataUtils.calculateDiscountRate(
          { customDiscountRate: null },
          applicableDiscounts,
        );
        const discountPrice = orderDataUtils.calculateDiscountedPrice(basePrice, discountRate);

        foodPrices = {
          discounts: discountsQuery.data.discounts,
          applicableDiscounts,
          discountRate,
          discountPrice,
          fullPrice: basePrice,
        };
      } else {
        foodPrices = {
          discounts: [],
          applicableDiscounts: [],
          discountRate: 0,
          discountPrice: basePrice,
          fullPrice: basePrice,
        };
      }
    }
    setPrices(it => ({ ...it, food: foodPrices }));
  }, [props.orders, props.today, userTransactionsCount, discountsQuery.data, promoCode]);

  useEffect(() => {
    let total: Big | undefined;
    if (prices.food) {
      if (prices.food.discountPrice.gt(0)) {
        total = prices.delivery?.total
          ? prices.food.discountPrice.add(prices.delivery.total)
          : prices.food.discountPrice;
      } else if (prices.food.fullPrice.gt(0)) {
        total = prices.delivery?.total
          ? prices.food.fullPrice.add(prices.delivery.total)
          : prices.food.fullPrice;
      }
    }
    setPrices(it => ({
      ...it,
      total,
    }));
  }, [prices.food, prices.delivery?.total]);

  return [prices, setPromoCode, getTransactionsCount, setDeliveryPrice] as [
    typeof prices,
    typeof setPromoCode,
    typeof getTransactionsCount,
    typeof setDeliveryPrice,
  ];
};

export type PricesHook = ReturnType<typeof usePrices>;

export default usePrices;
