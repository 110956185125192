import { BaseEntityData } from './data/base-entity';

export enum Language {
  en = 'en',
  ru = 'ru',
}

export type LanguageKey = keyof typeof Language;

export type Translation = { [k in LanguageKey]?: string };

export type Translations<K extends string> = Record<K, Translation>;

export const i18n = <K extends string>(
  translations: Translations<K>,
): {
  key: (k: K) => string;
  translation: (k: K) => Translations<K>[K];
  en: (k: K) => string;
  throw: (k: K) => never;
} & {
  translations: Translations<K>;
} => {
  return Object.assign(
    {},
    {
      key: (k: K) => k,
      translation: (k: K) => translations[k] ?? k,
      en: (k: K) => translations[k]['en'] ?? k,
      throw: (k: K) => {
        throw new Error(translations[k]['en'] ?? k);
      },
    },
    { translations },
  );
};

export type DataI18n<EP extends BaseEntityData, RP extends string = never> =
  | `${EP['__typename']}`
  | `${EP['__typename']}:Plural`
  | keyof {
      [ep in Exclude<keyof EP, keyof BaseEntityData> as `${EP['__typename']}.${string & ep}`];
    }
  | keyof {
      [rp in RP as `${EP['__typename']}:${string & rp}`];
    };

export const languageI18n = i18n<LanguageKey>({
  [Language.en]: {
    en: 'English',
    ru: 'English (Английский)',
  },
  [Language.ru]: {
    en: 'Русский (Russian)',
    ru: 'Русский',
  },
});
