import { Component, ContextType, ErrorInfo } from 'react';
import { storageUtils } from '@mono/frontend';
import { Error } from '../../components/alert/alert';
import { LoggerContext } from './error-logger-context';
import { ReactError } from './utils-storage';

export const STORAGE_CATCH_TIME = 3000;

interface State {
  hasError: boolean;
  storageError: boolean;
  errorMessage: string | JSX.Element;
}

export class ErrorBoundary extends Component<unknown, State> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      storageError: true,
      errorMessage: 'Страница была перезагружена',
    };
  }
  timeoutId: number;

  // static getDerivedStateFromError(error: Error): Partial<State> {
  //   return { hasError: true, storageError: true };
  // }

  logToService(error: ReactError) {
    return;
  }

  override async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    clearTimeout(this.timeoutId);
    if (this.state.storageError) {
      await storageUtils.clear();
      this.logToService({ error, errorInfo, storageCleared: true });
    } else {
      this.logToService({ error, errorInfo });
    }
    this.setState({ hasError: true, storageError: true });
    this.timeoutId = window.setTimeout(
      () => this.setState({ storageError: false }),
      STORAGE_CATCH_TIME,
    );
  }

  override componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  override render() {
    return (
      <>
        <Error
          open={this.state.hasError}
          onClose={() => this.setState({ hasError: false })}
          title={'Что-то пошло не так'}
          message={this.state.errorMessage}
        />

        {this.props.children}
      </>
    );
  }
}

export class ErrorBoundaryWithLogger extends ErrorBoundary {
  static override contextType = LoggerContext;
  override context!: ContextType<typeof LoggerContext>;

  override logToService(error: ReactError) {
    this.context(error);
  }
}
