import { BaseEntityData, DataI18n, i18n, UserData } from '@mono/shared';
import { OfficeData } from './office';

export const PROFILE_DEFAULT_COMMENT = '';
export const PROFILE_DEFAULT_CUSTOMER_ADDRESSES = [];

export interface ProfileData extends BaseEntityData {
  __typename: 'Profile';
  user: UserData;
  comment: string;
  defaultOffice?: OfficeData | null;
}

export const profileI18n = i18n<
  DataI18n<
    ProfileData,
    | 'customerAddresses'
    | 'ordersCount'
    | 'orders'
    | 'transactionsCount'
    | 'transactions'
    | 'balance'
  >
>({
  'Profile': {
    en: 'Profile',
    ru: 'Клиент',
  },
  'Profile:Plural': {
    en: 'Profiles',
    ru: 'Клиенты',
  },
  'Profile.user': {
    en: 'User',
    ru: 'Пользователь',
  },
  'Profile.comment': {
    en: 'Comment',
    ru: 'Комент',
  },
  'Profile.defaultOffice': {
    en: 'Default office',
    ru: 'Офис',
  },
  'Profile:customerAddresses': {
    en: 'Customer addresses',
    ru: 'Адреса',
  },
  'Profile:ordersCount': {
    en: 'Orders count',
    ru: 'Кол-во заказов',
  },
  'Profile:orders': {
    en: 'Orders',
    ru: 'Заказы',
  },
  'Profile:transactionsCount': {
    en: 'Transactions count',
    ru: 'Кол-во транзакций',
  },
  'Profile:transactions': {
    en: 'Transactions',
    ru: 'Транзакции',
  },
  'Profile:balance': {
    en: 'Balance',
    ru: 'Баланс',
  },
});
