import { BaseEntityData, DataI18n, i18n } from '@mono/shared';

export const DISH_CONFIG_TYPE_DEFAULT_POSITION = 0;

export interface DishConfigTypeData extends BaseEntityData {
  __typename: 'DishConfigType';
  position: number;
  name: string;
}

export const dishConfigTypeI18n = i18n<DataI18n<DishConfigTypeData>>({
  'DishConfigType': {
    en: 'Dish config type',
    ru: 'Тип конфигурации блюда',
  },
  'DishConfigType:Plural': {
    en: 'Dish config types',
    ru: 'Типы конфигураций блюд',
  },
  'DishConfigType.position': {
    en: 'Position',
    ru: 'Положение',
  },
  'DishConfigType.name': {
    en: 'Name',
    ru: 'Название',
  },
});
