import { O } from './utils/types/shortcuts';

export function upperCaseFirstLetter(string: string): string;
export function upperCaseFirstLetter(string: string | undefined): string | undefined;
export function upperCaseFirstLetter(string: string | undefined) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : undefined;
}

export function lowerCaseFirstLetter(string: string): string;
export function lowerCaseFirstLetter(string: string | undefined): string | undefined;
export function lowerCaseFirstLetter(string: string | undefined) {
  return string ? string.charAt(0).toLowerCase() + string.slice(1) : undefined;
}

export function camelize(string: string) {
  return string.replace(/^([A-ZА-Я])|[\s-_]+([A-ZА-Яa-zа-я0-9_])/g, function (_, p1, p2) {
    return p2 ? p2.toUpperCase() : p1.toLowerCase();
  });
}

export function decamelize(string: string, separator = ' ') {
  return string
    .replace(/([a-zа-я\d])([A-ZА-Я])/g, '$1' + separator + '$2')
    .replace(/([A-ZА-Я]+)([A-ZА-Я][a-zа-я\d]+)/g, '$1' + separator + '$2')
    .toLowerCase();
}

export function dasherize(string: string) {
  return decamelize(camelize(string), '-');
}

export const joinBy = (delimiter: string, parts: O<string | false>[]) => {
  return parts
    .map(it => (it && it.trim()) || '')
    .filter(it => it !== '')
    .join(delimiter)
    .trim();
};

export const join = (...parts: O<string | false>[]) => joinBy(' ', parts);

export const isEmpty = (value: string) => value.length === 0;

export const isBlank = (value: string) => value.trim().length === 0;

export const hasContent = (value?: O<string>): value is string => {
  return value != null && !isBlank(value);
};

export function toUpperCase(input: O<string>): O<string>;
export function toUpperCase(input: string[] | string | null): string[] | string | null;
export function toUpperCase(input: string[] | O<string>) {
  if (input == null) {
    return input;
  }
  if (typeof input === 'string') {
    return input.toUpperCase();
  }
  return input.map(string => string.toUpperCase());
}

export const split = (input: string, on: 'onFirst' | 'onLast', separator: RegExp) => {
  const parts = input.split(separator);
  if (parts.length === 1) {
    return parts;
  }
  switch (on) {
    case 'onFirst': {
      const part = parts[0];
      return [part, input.replace(part, '').replace(separator, '')];
    }
    case 'onLast': {
      const part = parts[parts.length - 1];
      return [input.replace(new RegExp(`${separator.source}${part}$`), ''), part];
    }
  }
};

export const truncate = (input: string, limit: number) => {
  if (input.length > limit) {
    return input.trim().substring(0, limit).trim() + '...';
  }
  return input;
};
