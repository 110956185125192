import { BaseEntityData, DataI18n, i18n } from '@mono/shared';
import { DishConfigTypeData } from './dish-config-type';
import { MenuItemData } from './menu-item';

export const PACK_DEFAULT_POSITION = -1;

export interface PackData extends BaseEntityData {
  __typename: 'Pack';
  position: number;
  name: string;
  price: string;
  targetCalorieContent: number | null;
}

export const packI18n = i18n<DataI18n<PackData, 'items'>>({
  'Pack': {
    en: 'Pack',
    ru: 'Пакет',
  },
  'Pack:Plural': {
    en: 'Packs',
    ru: 'Пакеты',
  },
  'Pack.position': {
    en: 'Position',
    ru: 'Положение',
  },
  'Pack.name': {
    en: 'Name',
    ru: 'Название',
  },
  'Pack.price': {
    en: 'Price',
    ru: 'Цена',
  },
  'Pack.targetCalorieContent': {
    en: 'Target calorie content',
    ru: 'Целевой калораж',
  },
  'Pack:items': {
    en: 'Items',
    ru: 'Позиции',
  },
});

export const PACK_ITEM_DEFAULT_QUANTITY = 1;

export interface PackItemData extends BaseEntityData {
  __typename: 'PackItem';
  quantity: number;
  pack: PackData;
  menuItem: MenuItemData;
  dishConfigType: DishConfigTypeData;
}

export const packItemI18n = i18n<DataI18n<PackItemData>>({
  'PackItem': {
    en: 'Pack item',
    ru: 'Позиция пакета',
  },
  'PackItem:Plural': {
    en: 'Pack items',
    ru: 'Позиции пакета',
  },
  'PackItem.quantity': {
    en: 'Quantity',
    ru: 'Количество',
  },
  'PackItem.pack': {
    en: 'Pack',
    ru: 'Пакет',
  },
  'PackItem.menuItem': {
    en: 'Menu item',
    ru: 'Позиция меню',
  },
  'PackItem.dishConfigType': {
    en: 'Dish config type',
    ru: 'Тип конфигурации блюда',
  },
});
