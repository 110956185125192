import { gql } from '@apollo/client';

export const TRANSACTIONS_COUNT = gql`
  query TransactionsCount($value: String!) {
    contact(by: { value: $value }) {
      owner {
        profile {
          transactionsCount
        }
      }
    }
  }
`;
