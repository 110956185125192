import { chevronDown } from 'ionicons/icons';
import plural from 'plural-ru';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { IonIcon, IonSpinner } from '@ionic/react';
import Spoiler from '../../components/spoiler';
import Tooltip from '../../components/tooltip';
import PricesContext from '../../context/prices';
import { getCurrency } from '../../utils/currencies';
import CheckoutForm from './order-checkout-form';

const currency = getCurrency('RUB');

const OrderCheckout = () => {
  const [prices] = useContext(PricesContext);

  const [isDiscountSpoilerOpen, setIsDiscountSpoilerOpen] = useState(false);

  const discountsTip = (
    <Tooltip>
      <DiscountsTip>
        <p>У нас есть следующие скидки:</p>
        <ul>
          {prices.food &&
            prices.food.discounts.map(dis => (
              <li key={dis.name}>
                <span>{dis.rate}%&nbsp;</span>
                {dis.name.toLowerCase()}
              </li>
            ))}
        </ul>
      </DiscountsTip>
    </Tooltip>
  );

  const discountsCount = prices.food?.applicableDiscounts.length ?? 0;
  const hasDiscounts = !!discountsCount;
  const discountsLoaded = !!prices.food?.discounts.length;

  return (
    <Styles>
      <div className="price-info">
        <div>
          <p>Стоимость еды</p>

          {prices.food ? (
            !prices.food.discountRate ||
            (prices.food.discountRate && !parseInt(prices.food.fullPrice.toFixed(0))) ? (
              <span>{prices.food.fullPrice.toString() + ' ' + currency.minimal + '.'}</span>
            ) : (
              prices.food && (
                <span>
                  <span className="line-through">{prices.food.fullPrice.toString()}</span>
                  &nbsp;{prices.food.discountPrice.toString() + ' ' + currency.minimal + '.'}
                </span>
              )
            )
          ) : (
            <span>0 {currency.minimal}.</span>
          )}
        </div>
        <div className="discounts">
          {prices.food && discountsLoaded ? (
            discountsCount > 1 ? (
              <Spoiler
                active={isDiscountSpoilerOpen}
                setActive={active => !!hasDiscounts && setIsDiscountSpoilerOpen(active)}
                headerComponent={
                  <div className="discounts-header">
                    <div>
                      <p>Скидки</p>
                      <IonIcon icon={chevronDown} />
                    </div>
                    <span className="amount">{prices.food.discountRate}%</span>
                  </div>
                }
                contentComponent={
                  <ul className="discounts-content">
                    {prices.food &&
                      prices.food.applicableDiscounts.map(dis => (
                        <li key={dis.name}>
                          <span>{dis.name}</span>
                          <span>-${dis.rate}%</span>
                        </li>
                      ))}
                  </ul>
                }
              />
            ) : hasDiscounts ? (
              <div className="discounts-header">
                <span>Скидка {prices.food.applicableDiscounts[0].name.toLowerCase()}</span>
                <div>
                  <span className="amount" style={{ marginRight: 4 }}>
                    {prices.food.applicableDiscounts[0].rate}%
                  </span>
                  {discountsTip}
                </div>
              </div>
            ) : (
              <div className="discounts-header">
                <span>Скидки</span>
                {discountsTip}
              </div>
            )
          ) : (
            <div className="discounts-header">
              <span>Скидки</span>
              <IonSpinner className="discount-spinner" name="crescent" />
            </div>
          )}
        </div>
        <div className="delivery-price">
          <p className="nbsp" style={{ flexGrow: 1 }}>
            Стоимость доставки
          </p>
          {prices.delivery.single !== undefined ? (
            prices.delivery.single === null || prices.delivery.single.eq(0) ? (
              prices.delivery.numDays === 0 ? (
                'Бесплатно'
              ) : (
                <>
                  <div>0 - {100 * prices.delivery.numDays} р. (от 0 до 100 р. каждая)</div>
                  <Tooltip>
                    <p>
                      Ваш адрес в зоне, где мы доставляем бесплатно при стоимости еды в доставляемом
                      пакете от 1128 рублей. Окончательный расчет будет сделан после нажатия на
                      "Оформить заказ", при выставлении счета на оплату.
                    </p>
                  </Tooltip>
                </>
              )
            ) : (
              <div>
                <div className="price">
                  {prices.delivery.numDays > 1 && prices.delivery.numDays + ' x '}
                  {!!prices.delivery.single &&
                    prices.delivery.single.toString() + ' ' + currency.minimal + '. '}
                  {!!prices.delivery.total && prices.delivery.numDays > 1 && (
                    <span className="nbsp total">
                      {' = ' + prices.delivery.total.toString() + ' ' + currency.minimal + '.'}
                    </span>
                  )}
                </div>
                <Tooltip>
                  {prices.delivery.numDays > 1 ? (
                    <p>
                      Ваш заказ включает
                      {' ' + plural(prices.delivery.numDays, '%d день', '%d дня', '%d дней') + ' '}
                      доставки, каждый по
                      {' ' + prices.delivery.single.toString() + ' ' + currency.minimal + '.'}
                    </p>
                  ) : (
                    <p>
                      Стоимость доставки на ваш адрес - {prices.delivery.single.toString()}{' '}
                      {currency.minimal}.
                      <br />
                      (за один день доставки)
                    </p>
                  )}
                </Tooltip>
              </div>
            )
          ) : (
            <Tooltip>
              <p>Чтобы рассчитать, укажите адрес</p>
            </Tooltip>
          )}
        </div>
        <div>
          <p>Итого</p>
          {prices.total ? (
            <span>{prices.total.toString() + ' ' + currency.minimal + '.'}</span>
          ) : (
            <span>0 {currency.minimal}.</span>
          )}
        </div>
      </div>
      <CheckoutForm />
    </Styles>
  );
};

const DiscountsTip = styled.div`
  > div {
    display: flex;
  }
`;

const Styles = styled.div`
  .price-info {
    color: #848484;
    > div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      > span {
        color: #383838;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    .line-through {
      text-decoration: line-through;
    }
    .discounts-tip {
      width: fit-content;
      align-self: flex-end;
      color: #848484;
      border-bottom: 1px solid #848484;
    }
    .delivery-price {
      .nbsp {
        white-space: nowrap;
      }
      > div {
        display: flex;
        text-align: end;
        padding-left: 10px;
        color: #383838;
      }
      .price {
        margin-right: 5px;
      }
      .total {
        transform: translate(20px);
      }
      @media screen and (max-width: 950px) {
        .total {
          display: block;
        }
      }
    }
  }
  .tooltip {
    margin-top: 3px;
    --size: 14px;
    color: #f1f3f7;
  }

  .discounts {
    display: flex;
    flex-direction: column;
    .amount {
      color: #383838;
    }

    .discounts-header,
    .discounts-content > * {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .discounts-header > div {
      display: flex;
      align-items: center;
      ion-icon {
        margin-bottom: 2px;
      }
    }
    .discounts-header:not(.clickable) {
      cursor: default;
    }
    .discount-spinner {
      --size: 18px;
      height: var(--size);
      width: var(--size);
      --color: black;
    }
    ul {
      background: white;
      border-radius: 10px;
      padding: 5px 10px;
      margin: 3px 0 0;
    }
    .active {
      ion-icon {
        transform: rotate(180deg);
      }
    }
  }
`;

export default OrderCheckout;
