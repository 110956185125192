import { dayjs, getDefinedOrThrow, LanguageKey } from '@mono/shared';

export const createConfig = ({
  apiUrl,
  defaultLanguage,
}: {
  apiUrl: string;
  defaultLanguage?: LanguageKey;
}) => {
  dayjs.locale(defaultLanguage);
  return {
    sourceVersion: getDefinedOrThrow(
      process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
      process.env.NEXT_PUBLIC_CI_GIT_COMMIT_SHA,
      process.env.NEXT_PUBLIC_SOURCE_VERSION,
    ),
    apiUrl: `${process.env.NEXT_PUBLIC_API_URL ?? apiUrl}/graphql`,
    language: (process.env.NEXT_PUBLIC_LANGUAGE as LanguageKey | undefined) ?? defaultLanguage,
  };
};
