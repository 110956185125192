import { useContext } from 'react';
import styled from 'styled-components';
import MenuContext from '../../../../context/menu';
import LandingUiContext from '../../../../context/ui/landing';
import MenuForDay from './menu-for-day';

const Menu = () => {
  const menu = useContext(MenuContext);
  const [landingState] = useContext(LandingUiContext);

  const { pack, activeDate } = landingState;

  return (
    <Styles>
      {activeDate && pack ? (
        menu.data[activeDate] && menu.data[activeDate][pack.id] ? (
          <MenuForDay dishesForDay={menu.data[activeDate][pack.id]} />
        ) : (
          <div className="container">
            <p>Меню на выбранный день отсутствует.</p>
          </div>
        )
      ) : null}
    </Styles>
  );
};

const Styles = styled.div`
  min-height: 400px;
  width: 100%;
`;

export default Menu;
