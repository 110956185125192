import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import DatesContext from '../../../../context/dates';
import LandingUiContext from '../../../../context/ui/landing';
import { getWeekdayStatus } from '../../../../utils/russian-dates';

const MenuTitle = () => {
  const { firstMenuDate, today } = useContext(DatesContext);
  const [landingState, updateLandingState] = useContext(LandingUiContext);

  const [menuTitle, setMenuTitle] = useState<JSX.Element | undefined>(undefined);

  useEffect(() => {
    if (menuTitle) {
      updateLandingState({ titleLoaded: true });
    }
  }, [updateLandingState, menuTitle]);

  useEffect(() => {
    if (firstMenuDate && today && landingState.activeDate && landingState.pack) {
      setMenuTitle(
        <>
          <span className="menu">{'Меню рациона '}</span>
          {`${landingState.pack.name} на ${getWeekdayStatus(
            landingState.activeDate,
            today,
            firstMenuDate,
          )}`}
        </>,
      );
    }
  }, [firstMenuDate, today, landingState.activeDate, landingState.pack]);

  if (menuTitle) {
    return <StyledTitle>{menuTitle}</StyledTitle>;
  } else {
    return null;
  }
};

const StyledTitle = styled.h2`
  font-size: 18pt;
  margin-bottom: 16px;
  width: 100%;
  @media screen and (max-width: 720px) {
    .menu {
      display: none;
    }
  }
`;

export default MenuTitle;
