import { Subtype } from '@mono/shared';
import { PackData } from './pack';

const isCurated = (pack: Subtype<PackData, { targetCalorieContent: number | null }>) => {
  return pack.targetCalorieContent !== null && pack.targetCalorieContent > 0;
};

export const packDataUtils = {
  isCurated,
};
