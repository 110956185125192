import { FC, MouseEvent, useEffect, useState } from 'react';
import { Popup } from 'reactjs-popup';
import styled, { css } from 'styled-components';

interface Props {
  trigger?: JSX.Element;
  pureTrigger?: boolean;
  hoverDisabled?: boolean;
  disabled?: boolean;
  onClick?: (ev: MouseEvent<HTMLDivElement>) => void;
  onOpen?: () => void;
}

const Tooltip: FC<Props> = props => {
  const [isTooltip, setIsTooltip] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (matchMedia('(pointer:fine)').matches) {
      setIsTooltip(true);
    } else {
      setIsTooltip(false);
    }
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      const timeout = window.setTimeout(() => {
        document.getElementById('popup-root')?.firstChild?.addEventListener('touchstart', e => {
          e.stopPropagation();
        });
      }, 100);
      return () => clearTimeout(timeout);
    }
  }, [isModalOpen]);

  return (
    <Styles>
      {isTooltip ? (
        <StyledTooltip
          disabled={props.disabled}
          trigger={
            <div className={props.pureTrigger ? '' : 'tooltip'}>
              <div onClick={props.onClick}>{props.trigger ?? <span>?</span>}</div>
            </div>
          }
          on={props.hoverDisabled ? ['focus', 'click'] : ['focus', 'click', 'hover']}
          position={[
            'top center',
            'top right',
            'top left',
            'bottom center',
            'bottom right',
            'bottom left',
          ]}
          onOpen={props.onOpen}
          closeOnDocumentClick
          keepTooltipInside
        >
          {props.children}
        </StyledTooltip>
      ) : (
        <StyledModal
          disabled={props.disabled}
          trigger={
            <div className={props.pureTrigger ? '' : 'tooltip'}>
              <div
                onClick={e => {
                  setIsModalOpen(true);
                  props.onClick && props.onClick(e);
                }}
              >
                {props.trigger ?? <span>?</span>}
              </div>
            </div>
          }
          modal
          onOpen={props.onOpen}
          onClose={() => setIsModalOpen(false)}
        >
          {props.children}
        </StyledModal>
      )}
    </Styles>
  );
};

const Styles = styled.div`
  position: relative;
  height: 100%;
  .tooltip {
    --size: 16px;
    border-radius: 50%;
    background: #383838;
    height: var(--size);
    width: var(--size);
    color: white;
    font-weight: 800;
    font-size: var(--size);
    line-height: var(--size);
    cursor: default;
    span {
      width: fit-content;
    }
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    .tooltip {
      cursor: pointer;
    }
  }
`;

const PopupStyles = css`
  &-content {
    background: white;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    max-width: min(400px, 90%);
    padding: 20px;
    p {
      margin: 0;
    }
  }
`;
const StyledTooltip = styled(Popup)`
  ${PopupStyles}
`;

const StyledModal = styled(Popup)`
  ${PopupStyles}
  &-overlay {
    background: rgba(0, 0, 0, 0.2);
    /* opacity: 0.1; */
  }
`;

export default Tooltip;
