import { css } from 'styled-components';

export enum Prefix {
  default = 'rs-custom',
}

export const ReactSelectStyles = css`
  --placeholder-color: #aaa;
  .${Prefix.default}__container {
    width: 100%;
  }
  .${Prefix.default}__control {
    border: none;
    box-shadow: none;
    background: none;
  }
  .${Prefix.default}__placeholder {
    color: var(--placeholder-color);
  }
  .${Prefix.default}__indicator-separator {
    display: none;
  }
  .${Prefix.default}__indicator {
    padding: 0;
  }
  .${Prefix.default}__option {
    cursor: pointer;
  }
  .${Prefix.default}__option:active {
    background: none;
  }
  .${Prefix.default}__option--is-focused {
    background: rgb(170 200 20 / 15%);
  }
  .${Prefix.default}__option--is-selected {
    background: rgb(140 200 20 / 50%);
    font-weight: 600;
    &:active {
      background: rgb(140 200 20 / 50%);
    }
  }
`;

export const ContainerStyles = css`
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 25px;
  padding: 0 15px !important;
  box-shadow: 0 0px 3px 0px #212121 inset;
  background: white;
`;
