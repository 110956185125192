import { FC, HTMLProps } from 'react';

interface Props {
  id: string;
  component?: () => JSX.Element;
}

const Anchor: FC<Props & HTMLProps<HTMLDivElement>> = props => {
  const { children, component: Component, ...rest } = props;
  return (
    <div {...rest} className={props.className + ' anchor'}>
      {Component ? <Component /> : children}
    </div>
  );
};

export default Anchor;
