import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { storageUtils } from '@mono/frontend';
import { AA } from '@mono/shared';
import {
  CartFormStorage,
  cartFormStorageKeys,
  DEFAULT_COUNTRY_CODE,
} from '../../pages/cart/utils-form-fields';

export interface CartFormState extends CartFormStorage {
  promocode?: string;
}

const useCartFormState = (storageData?: CartFormStorage) => {
  const [cartFormState, setCartFormState] = useState<CartFormState>({});
  useEffect(() => {
    storageData && setCartFormState(storageData);
  }, [storageData]);

  const updateCartDebounced = useCallback((payload: CartFormState) => {
    setCartFormState(state => ({ ...state, ...payload }));
    debounce(storageUtils.setAll, 1000)(payload as AA);
  }, []);

  const updateCart = useCallback((payload: CartFormState) => {
    setCartFormState(state => {
      return { ...state, ...payload };
    });
    storageUtils.setAll(payload as AA);
  }, []);

  const clearCart = useCallback(async () => {
    setCartFormState({});
    storageUtils.remove([...cartFormStorageKeys]);
  }, []);

  useEffect(() => {
    if (storageData && !storageData.countryCode && !cartFormState.countryCode) {
      updateCart({ countryCode: DEFAULT_COUNTRY_CODE });
    }
  }, [storageData, cartFormState.countryCode, updateCart]);

  return [cartFormState, updateCart, updateCartDebounced, clearCart] as [
    CartFormState,
    typeof updateCart,
    typeof updateCartDebounced,
    typeof clearCart,
  ];
};

export type CartFormHook = ReturnType<typeof useCartFormState>;

export default useCartFormState;
