import { gql } from '@apollo/client';

export const STORAGE_OBJECT = gql`
  query StorageObject($id: ID!, $width: Float, $height: Float) {
    storageObject(by: { id: $id }, useCache: true) {
      id
      url(image: { resize: { height: $height, width: $width } })
      name
    }
  }
`;
