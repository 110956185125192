import Lightbox from 'react-image-lightbox';
import styled from 'styled-components';

export default (props: { isOpen: boolean; setOpen: (open: boolean) => void; image: string }) => {
  if (props.isOpen) {
    return <StyledLightbox mainSrc={props.image} onCloseRequest={() => props.setOpen(false)} />;
  } else {
    return null;
  }
};

const StyledLightbox = styled(Lightbox)``;
