import styled from 'styled-components';
import { OfficeUiKey } from '../../context/office/office';
import {
  getDisplayedKladrParent,
  KladrEntitySearchType,
  KladrObject,
  OptionsData,
} from './utils-kladr';

export enum ContactStatus {
  errorGetContact,
  sending,
  codeSent,
  errorCodeSent,
  confirming,
  confirmed,
  declined,
}

export enum DeliveryPriceStatus {
  loading = 'loading',
  unset = 'unset',
  calculated = 'calculated',
}

export const getSubmitErrors = (props: {
  officeUiKey: OfficeUiKey;
  noOrders: boolean;
  deliveryPriceStatus?: DeliveryPriceStatus;
}) => {
  const { officeUiKey, noOrders, deliveryPriceStatus } = props;
  const errors: JSX.Element[] = [];
  if (officeUiKey === 'spb') {
    errors.push(<p key="office">На данный момент работа в СПб временно приостановлена</p>);
  } else if (noOrders) {
    errors.push(<p key="orders">Корзина пуста. Выберите хотя бы один день.</p>);
  } else {
    switch (deliveryPriceStatus) {
      case DeliveryPriceStatus.unset: {
        errors.push(<p key="deliveryPrice">Цена доставки не определена</p>);
        break;
      }
      case DeliveryPriceStatus.loading: {
        errors.push(<p key="deliveryPrice">Цена доставки определяется</p>);
        break;
      }
    }
  }
  return errors.length ? errors : undefined;
};

export enum FormStatus {
  input,
  contactConfirmation,
  orderCreation,
}

export const Message = styled.div`
  .alert-phone {
    display: block;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 500;
    color: #383838;
  }
`;

export enum ErrorKeys {
  'address',
}

export const codeSentError = (number: string) => (
  <Message>
    Не удалось отправить код подтверждения <br />
    на телефон
    <br />
    <span className="alert-phone">{number}</span>
  </Message>
);

export interface ErrorDialogMessage {
  message: JSX.Element | string;
  title?: string;
}

export interface SelectOption<T extends KladrEntitySearchType> {
  label: JSX.Element;
  value: KladrObject<T>;
}

export const matchOptions = <T extends KladrEntitySearchType>(
  data: OptionsData<T>,
): SelectOption<T>[] => {
  return data.values.map(val => {
    const startIndex = val.name.toLowerCase().indexOf(data.query.toLowerCase());
    const head = val.name.substr(0, startIndex);
    const match = val.name.substr(startIndex, data.query.length);
    const tail = val.name.substr(startIndex + data.query.length);
    const parent = getDisplayedKladrParent(val);
    return {
      label: (
        <p>
          {val.typeShort}. {head}
          <span className="match">{match}</span>
          {tail}
          {parent && (
            <span className="parent">
              ({parent.typeShort}. {parent.name})
            </span>
          )}
        </p>
      ),
      value: val,
    };
  });
};

export const createOption = (
  value: KladrObject<KladrEntitySearchType>,
): SelectOption<KladrEntitySearchType>[] => {
  const parent = getDisplayedKladrParent(value);
  return [
    {
      label: (
        <p>
          {value.typeShort}. {value.name}
          {parent && (
            <span className="parent">
              ({parent.typeShort}. {parent.name})
            </span>
          )}
        </p>
      ),
      value,
    },
  ];
};
