import cx from 'classnames';
import { ComponentType, MouseEventHandler } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { useContextOrThrow } from './hook/use-context-or-throw';
import { RootLayoutContext } from './layout/root';

export const HeadlessInput = ({
  rhf,
  validationError,
  type = 'text',
  placeholder,
  actionIcon,
  className,
}: {
  rhf: UseFormRegisterReturn;
  validationError: string | undefined;
  type?: 'text' | 'password' | 'email' | 'tel';
  placeholder?: string;
  actionIcon?: {
    Component: ComponentType<{ className?: string; onClick?: MouseEventHandler }>;
    onClick?: MouseEventHandler;
  };
  className?: {
    box?: string;
    actionIcon?: (state: { clickable: boolean }) => string;
    input?: string;
    errorIcon?: string;
  };
}) => {
  const { pushNotification } = useContextOrThrow(RootLayoutContext);
  return (
    <div className={className?.box}>
      {actionIcon && (
        <actionIcon.Component
          className={className?.actionIcon?.({ clickable: actionIcon.onClick != null })}
          onClick={actionIcon.onClick}
        />
      )}
      <input {...rhf} type={type} placeholder={placeholder} className={className?.input} />
      {
        <ExclamationCircleIcon
          className={cx(className?.errorIcon, validationError == null && 'invisible')}
          onClick={() => {
            if (validationError != null) {
              pushNotification({ type: 'warning', title: validationError });
            }
          }}
        />
      }
    </div>
  );
};
