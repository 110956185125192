import { API_DAY_FORMAT, dayjs } from '@mono/shared';

export const WORLDTIME_API_URL = 'https://worldtimeapi.org/api/timezone';
export const DEFAULT_TIMEZONE = 'Asia/Novosibirsk';

export const SHOW_NUM_DAYS = 7;
export const TODAY_DELIVERY_TIME_THRESHOLD = '12:00';

export async function fetchLocalIsoStringDate(timezone?: string) {
  const { datetime } = await (
    await fetch(WORLDTIME_API_URL + '/' + (timezone ?? DEFAULT_TIMEZONE))
  ).json();
  if (!datetime) {
    throw new Error(`${WORLDTIME_API_URL} - wrong response!`);
  }
  return datetime as string;
}

export function getDates(
  today: dayjs.Dayjs,
  timeLimit: string,
  numberOfDays: number,
  managerMode: boolean | undefined,
) {
  if (!managerMode) {
    today = today.add(1, 'day');
    const [hoursStr, minutesStr] = timeLimit.split(':');
    const hours = +hoursStr;
    const minutes = +minutesStr;
    const currentHours = +today.format('H');
    const currentMinutes = +today.format('m');

    if (currentHours >= hours || (currentHours === hours && currentMinutes > minutes)) {
      today = today.add(1, 'day');
    }
  }

  const firstDate = today.format(API_DAY_FORMAT);
  const lastDate = today.add(numberOfDays - 1, 'day').format(API_DAY_FORMAT);
  return { firstDate, lastDate };
}
