import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IonSegment, IonSegmentButton } from '@ionic/react';
import { dayjs } from '@mono/shared';
import Tooltip from '../../../../components/tooltip';
import DataStatusContext from '../../../../context/data-status';
import DatesContext from '../../../../context/dates';
import LandingUiContext from '../../../../context/ui/landing';
import { IonSegmentStyles } from '../../../../styles/ion-segment-styles';
import { getLocaleDateStrings, getWeekFromInterval } from '../../../../utils/dates';
import { getWeekdayRusAccusative } from '../../../../utils/russian-dates';

const WeekDaySetter = () => {
  const { firstMenuDate } = useContext(DatesContext);
  const [days, setDays] = useState<Array<JSX.Element>>([]);
  const [landingState, updateLandingState] = useContext(LandingUiContext);
  const dataStatus = useContext(DataStatusContext);

  useEffect(() => {
    const newDays: Array<JSX.Element> = [];
    if (firstMenuDate) {
      const sortedDates = getWeekFromInterval(firstMenuDate);
      for (const currDate of sortedDates) {
        const dateStrings = getLocaleDateStrings(dayjs(currDate), 'ru');
        newDays.push(
          <IonSegmentButton
            mode="md"
            key={currDate}
            value={currDate}
            onClick={() => {
              updateLandingState({
                activeDate: dateStrings.ISODate,
              });
            }}
          >
            <span>{dateStrings && dateStrings.weekdayShort}</span>
          </IonSegmentButton>,
        );
        if (currDate === firstMenuDate) {
          newDays.push(
            <div key="tooltip" className="relative-wrap">
              <Tooltip>
                <p>
                  {`Ближайший день, в который вы сможете питаться по программе. Доставка будет в
                   ${getWeekdayRusAccusative(
                     getLocaleDateStrings(dayjs(currDate).subtract(1, 'day'), 'ru').weekdayLong,
                   )} вечером, сразу после приготовления.`}
                </p>
              </Tooltip>
            </div>,
          );
        }
      }
      setDays(newDays);
    }
  }, [firstMenuDate, updateLandingState]);

  if (landingState.pack && dataStatus.dishesLoaded) {
    return (
      <StyledIonSegment
        value={landingState.activeDate}
        swipeGesture={false}
        onIonChange={e => {
          const target = e.currentTarget as HTMLIonSegmentElement;
          if (e.detail.value === undefined) {
            target.value = landingState.activeDate;
          }
        }}
      >
        {days}
      </StyledIonSegment>
    );
  } else {
    return null;
  }
};

const StyledIonSegment = styled(IonSegment)`
  ${IonSegmentStyles}
  contain: none;
  overflow: visible;
  margin-bottom: 30px;

  ion-segment-button {
    --height: 60px;
    width: 60px;
    min-width: unset;
    font-weight: 800;
    font-size: 12pt;
    text-transform: capitalize;
  }
  .relative-wrap {
    position: relative;
    width: 0;
  }
  .tooltip {
    position: absolute;
    bottom: unset;
    left: unset;
    right: 8px;
    top: 8px;
    font-size: 14pt;
    --size: 20px;
  }

  @media screen and (max-width: 920px) {
    margin-bottom: 0;
  }

  @media screen and (max-width: 600px) {
    width: calc(100% + 20px);
    margin-top: 0;

    ion-segment-button {
      margin: auto;

      height: calc(9vw + 10px);
      min-height: unset;
      max-width: calc(9vw + 10px);
    }
    .tooltip {
      bottom: calc(9vw - 5px);
      left: calc(2vw - 30px);
      right: unset;
      top: unset;
    }
  }
`;

export default WeekDaySetter;
