import { useContext } from 'react';
import styled from 'styled-components';
import Anchor from '../../components/anchor';
import Spinner from '../../components/spinner/spinner';
import DataStatusContext from '../../context/data-status';
import Menu from './rations-components/menu';
import MenuTitle from './rations-components/menu-title';
import OrderButtons from './rations-components/order-buttons';
import PackSetter from './rations-components/pack-setter';
import WeekDaySetter from './rations-components/weekday-setter';
import { LandingAnchors } from './utils';

const SelectMealMenu = () => {
  const dataStatus = useContext(DataStatusContext);

  return (
    <Styles>
      <div className="container">
        <h2>Рационы питания (цена за день)</h2>
      </div>
      {dataStatus.packsLoaded ? (
        dataStatus.dishesLoaded ? (
          <>
            <div className="container">
              <PackSetter />
            </div>
            <StyledAnchor id={LandingAnchors.menu}>
              <div className="with-order-buttons container">
                <MenuTitle />
                <WeekDaySetter />
                <OrderButtons />
              </div>
              <Menu />
            </StyledAnchor>
          </>
        ) : (
          <div className="container">
            <PackSetter />
            <StyledAnchor id={LandingAnchors.menu}>
              <OrderButtons />
              <MenuError>
                {dataStatus.dishesLoaded === false ? (
                  <> Меню блюд временно отсутствует </>
                ) : (
                  <Spinner />
                )}
              </MenuError>
            </StyledAnchor>
          </div>
        )
      ) : (
        <StyledAnchor id={LandingAnchors.menu}>
          <div className="container">
            <MenuError>
              {dataStatus.packsLoaded === false ? (
                <>Рационы временно отсутствуют</>
              ) : (
                <MenuError>
                  <Spinner />
                </MenuError>
              )}
            </MenuError>
          </div>
        </StyledAnchor>
      )}
    </Styles>
  );
};

const StyledAnchor = styled(Anchor)`
  width: 100%;
`;

const MenuError = styled.span`
  width: 100%;
  min-height: 400px;
`;

const Styles = styled.div`
  min-height: 200px;
  padding-bottom: 100px;
  > .container {
    display: flex;
    flex-wrap: wrap;
  }
  .with-order-buttons {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 600px) {
    > .container {
      display: block;
    }
  }
`;

export default SelectMealMenu;
