import { createContext } from 'react';
import { OfficeCode } from '@mono/hf';

export type OfficeUiKey = 'nsk' | 'spb';

export const OFFICE_STORAGE_KEY = 'officeUiKey';

export type OfficeUiData = {
  officeId: string;
  officeCode: OfficeCode;
  regionId: string;
  city: string;
  address: string;
  latitude: string;
  longitude: string;
  metro: string;
  phoneNumber: string;
  emailAddress: string;
  instagramUrl: string;
  vkontakteUrl: string;
  whatsappUrl: string;
  telegramUrl: string;
  flampId: string;
};

export const OfficeContext = createContext<
  | {
      officeUiKey: OfficeUiKey;
      switchOffice: (officeUiKey?: OfficeUiKey) => void;
      officeUiData: OfficeUiData;
    }
  | undefined
>(undefined);
