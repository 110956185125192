import { ObjectSchema, string } from 'yup';
import { i18n } from '@mono/shared';
import { UsernameSchema, usernameSchema, usernameSchemaErrorI18n } from './username-schema';

export type LoginSchema = UsernameSchema & {
  password: string;
};

export const loginSchemaErrorI18n = i18n({
  ...usernameSchemaErrorI18n.translations,
  'Password is required': {},
});

export const loginSchema: ObjectSchema<LoginSchema> = usernameSchema().shape({
  password: string().required(loginSchemaErrorI18n.key('Password is required')),
});
