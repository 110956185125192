import { object, ObjectSchema, string, StringSchema } from 'yup';
import { chooseBy, i18n, validation } from '@mono/shared';
import { UsernameScope } from '../username-type';

export type UsernameSchema = {
  narrowUsernameScope: UsernameScope;
  username: string;
};

export const usernameSchemaErrorI18n = i18n({
  'Username is required': {
    ru: 'Имя пользователя обязательно',
  },
  'Username is invalid': {
    ru: 'Юзернэйм невалиден',
  },
  'Contact is required': {
    ru: 'Контакт обязателен',
  },
  'Contact is invalid': {
    ru: 'Контакт невалиден',
  },
  'Nick is invalid': validation.nickname.error,
  'Email is invalid': validation.email.error,
  'Phone is invalid': validation.phone.error,
});

export const usernameSchema = (scope: UsernameScope = 'username'): ObjectSchema<UsernameSchema> =>
  object()
    .shape({
      narrowUsernameScope: string<UsernameScope>()
        .oneOf(
          (() => {
            switch (scope) {
              case 'username':
                return ['nickname', 'email', 'phone'];
              case 'nickname':
                return ['nickname'];
              case 'contact':
                return ['email', 'phone'];
              case 'email':
                return ['email'];
              case 'phone':
                return ['phone'];
            }
          })(),
        )
        .required(),
      username: string().when(
        'narrowUsernameScope',
        ([narrowUsernameScope]: [UsernameScope], schema: StringSchema) => {
          if (narrowUsernameScope === 'username' || narrowUsernameScope === 'contact') {
            return schema
              .test({
                test: username => username === undefined || username.length === 0,
                message: usernameSchemaErrorI18n.key(
                  chooseBy(narrowUsernameScope, {
                    username: 'Username is invalid',
                    contact: 'Contact is invalid',
                  }),
                ),
              })
              .required(
                usernameSchemaErrorI18n.key(
                  chooseBy(narrowUsernameScope, {
                    username: 'Username is required',
                    contact: 'Contact is required',
                  }),
                ),
              );
          }
          return schema
            .matches(validation[narrowUsernameScope].regex, {
              excludeEmptyString: true,
              message: usernameSchemaErrorI18n.key(
                (() => {
                  switch (narrowUsernameScope) {
                    case 'nickname':
                      return 'Nick is invalid';
                    case 'email':
                      return 'Email is invalid';
                    case 'phone':
                      return 'Phone is invalid';
                  }
                })(),
              ),
            })
            .required(usernameSchemaErrorI18n.key('Username is required'));
        },
      ),
    })
    .defined();
