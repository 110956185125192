import { ContactType, getContactTypeOf, validation } from '@mono/shared';

export type UsernameType = 'nickname' | 'email' | 'phone';
export type UsernameScope = 'username' | 'contact' | UsernameType;

const isTypeAllowedByScope = ({ type, scope }: { type: UsernameType; scope: UsernameScope }) => {
  if (scope === 'username') {
    return true;
  }
  if (scope === 'contact') {
    return type === 'email' || type === 'phone';
  }
  return type === scope;
};

export const determineUsernameType = ({
  scope,
  username = 'username',
}: {
  scope: UsernameScope;
  username: string;
}): UsernameType | undefined => {
  switch (getContactTypeOf(username)) {
    case ContactType.EMAIL:
      return isTypeAllowedByScope({ type: 'email', scope }) ? 'email' : undefined;
    case ContactType.PHONE:
      return isTypeAllowedByScope({ type: 'phone', scope }) ? 'phone' : undefined;
  }
  if (validation.nickname.regex.test(username)) {
    return isTypeAllowedByScope({ type: 'nickname', scope }) ? 'nickname' : undefined;
  }
  return undefined;
};

export const determineNarrowUsernameScope = ({
  scope = 'username',
  username,
}: {
  scope: UsernameScope;
  username: string;
}): UsernameScope => {
  const usernameType = determineUsernameType({ username, scope });
  if (usernameType) {
    return usernameType;
  }
  if (isTypeAllowedByScope({ type: 'email', scope }) && username.includes('@')) {
    return 'email';
  }
  if (isTypeAllowedByScope({ type: 'phone', scope }) && /^[+\d]/.test(username)) {
    return 'phone';
  }
  return scope;
};
