export const API_DAY_FORMAT = 'YYYY-MM-DD' as const;
export const API_DAY_UNIT_FORMATS = ['YYYY', 'YYYY-MM', API_DAY_FORMAT];

export const API_TIME_FORMAT = 'HH:mm:ss' as const;
export const API_TIME_UNIT_FORMATS = ['HH', 'HH:mm', API_TIME_FORMAT];

export const API_MOMENT_FORMAT = `YYYY-MM-DDTHH:mm:ssZ` as const;
export const API_MOMENT_UNIT_FORMATS = [
  'YYYYZ',
  'YYYY-MMZ',
  'YYYY-MM-DDZ',
  'YYYY-MM-DDTHHZ',
  'YYYY-MM-DDTHH:mmZ',
  API_MOMENT_FORMAT,
];

export const MAX_ABS_UTC_OFFSET_IN_MINUTES = 24 * 60 - 1;
