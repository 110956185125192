import { ComponentProps, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import { Dish } from '../../../../hooks/menu/utils';
import MenuLightbox from '../menu-lightbox';
import DishCard from './dish';

interface DishesForDaySlideProps {
  dishesForDay: Dish[];
}

const swiperOptions: ComponentProps<typeof Swiper> = {
  slidesPerView: 1,
  spaceBetween: 25,
  watchOverflow: true,
  freeModeMomentumRatio: 0.75,
  touchMoveStopPropagation: true,
  breakpoints: {
    501: {
      slidesPerView: 2,
    },
    601: {
      slidesPerView: 'auto' as number | 'auto' | undefined,
    },
  },
};

const MenuForDay = (props: DishesForDaySlideProps) => {
  const [swiper, setSwiper] = useState<SwiperClass | undefined>(undefined);
  const [arrows, setArrows] = useState({ left: false, right: true });

  const [showLightbox, setShowLightbox] = useState(false);
  const [lightboxImage, setLightboxImage] = useState<string | undefined>(undefined);

  useEffect(() => {
    setArrows({ left: false, right: true });
  }, []);

  const handleIndexChange = (swiper: SwiperClass) => {
    setArrows({
      left: !swiper.isBeginning,
      right: !swiper.isEnd,
    });
  };

  useEffect(() => {
    swiper?.update();
  }, [props, swiper]);

  return (
    <Styles>
      <div className="swiper-nav container">
        <div
          className={'swiper-button-prev' + (!arrows.left ? ' hidden' : '')}
          onClick={() => swiper?.slidePrev()}
        ></div>

        <div
          className={'swiper-button-next' + (!arrows.right ? ' hidden' : '')}
          onClick={() => swiper?.slideNext()}
        ></div>
      </div>
      <div className="swiper container">
        {!!lightboxImage && (
          <MenuLightbox image={lightboxImage} isOpen={showLightbox} setOpen={setShowLightbox} />
        )}
        <div className="inner-container">
          <StyledSwiper
            {...swiperOptions}
            onSwiper={swiper => setSwiper(swiper)}
            onSnapIndexChange={handleIndexChange}
          >
            {props.dishesForDay.map(
              dish =>
                dish && (
                  <SwiperSlide key={dish.dishInstance.id}>
                    <DishCard
                      setLightboxImage={url => {
                        setShowLightbox(true);
                        setLightboxImage(url);
                      }}
                      dishInstance={dish.dishInstance}
                      dishConfig={dish.dishConfig}
                    />
                  </SwiperSlide>
                ),
            )}
          </StyledSwiper>
        </div>
      </div>
    </Styles>
  );
};

export default MenuForDay;

const StyledSwiper = styled(Swiper)`
  @media screen and (min-width: 601px) {
    .swiper-slide {
      width: 256px !important;
    }
  }
  overflow: visible;
  /* @media screen and (max-width: 1160px) {
  } */
`;

const customSwiperNavStyle = css`
  .swiper-nav {
    position: relative;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 200px;
    cursor: pointer;
    color: #6bcb3c;
    &.hidden {
      color: #bbb;
    }
  }
  .swiper-button-prev {
    left: -40px;
  }
  .swiper-button-next {
    right: -40px;
  }

  @media screen and (max-width: 1280px) {
    .swiper-nav {
      max-width: unset;
    }
    .swiper-button-prev,
    .swiper-button-next {
      top: 140px;
      --swiper-navigation-size: 30px;
      color: inherit;
      &::after {
        background: rgba(255, 255, 255, 0.9);
        box-shadow: -2px 2px 10px 0px rgba(0, 0, 0, 0.2);
      }
    }
    .swiper-button-prev {
      &::after {
        border-radius: 0 100px 100px 0;
        padding: 20px 20px 20px 10px;
      }
      left: 8px;
    }
    .swiper-button-next {
      &::after {
        border-radius: 100px 0 0 100px;
        padding: 20px 10px 20px 20px;
      }
      right: 8px;
    }
  }
  .swiper {
    padding: 0 10px;
  }
  .inner-container {
    overflow: hidden;
    width: 100%;
    padding: 0 10px;
  }

  @media screen and (min-width: 600px) and (max-width: 1140px) {
    .inner-container {
      overflow: visible;
    }
  }
`;

const Styles = styled.div`
  position: relative;
  width: 100%;
  ${customSwiperNavStyle}
`;
