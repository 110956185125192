import { useEffect, useState } from 'react';
import { stringify } from '@mono/shared';

export const useSame = <T extends unknown>(value: T) => {
  const json = stringify(value, { sortKeys: true });
  const [saved, save] = useState<{ value: T; json?: string }>(() => ({ value }));
  const eq = json !== saved.json;
  useEffect(() => {
    if (!eq) {
      save({ value, json });
    }
  }, [value, json, eq]);
  return eq ? saved.value : value;
};
