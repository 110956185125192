import styled from 'styled-components';
import { IonSpinner } from '@ionic/react';

const Spinner = () => <StyledIonSpinner name="crescent" />;

const StyledIonSpinner = styled(IonSpinner)`
  --color: black;
`;

export default Spinner;
