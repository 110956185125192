import { BaseEntityData, DataI18n, i18n, StorageObjectData } from '@mono/shared';
import { MealTypeData } from './meal-type';

export const MENU_ITEM_DEFAULT_POSITION = 0;

export interface MenuItemData extends BaseEntityData {
  __typename: 'MenuItem';
  position: number;
  name: string;
  mealType?: MealTypeData | null;
  image?: StorageObjectData | null;
}

export const menuItemI18n = i18n<DataI18n<MenuItemData>>({
  'MenuItem': {
    en: 'Menu item',
    ru: 'Позиция меню',
  },
  'MenuItem:Plural': {
    en: 'Menu items',
    ru: 'Позиции меню',
  },
  'MenuItem.position': {
    en: 'Position',
    ru: 'Положение',
  },
  'MenuItem.name': {
    en: 'Name',
    ru: 'Название',
  },
  'MenuItem.mealType': {
    en: 'Meal type',
    ru: 'Прием пищи',
  },
  'MenuItem.image': {
    en: 'Image',
    ru: 'Изображение',
  },
});
