import { i18n } from '../../translation';

export enum ContactType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export type ContactTypeKey = keyof typeof ContactType;

export const contactTypeI18n = i18n<ContactTypeKey>({
  EMAIL: {
    en: 'Email',
    ru: 'Email адрес',
  },
  PHONE: {
    en: 'Phone',
    ru: 'Телефон',
  },
});
