import { gql } from '@apollo/client';
import { PACK_DATA_FRAGMENT } from './fragments-pack';

export const CALORIE_CALCULATOR_ADVICE = gql`
  ${PACK_DATA_FRAGMENT}
  query CalorieCalculatorAdvice(
    $weight: Int!
    $height: Int!
    $age: Int!
    $sex: Sex!
    $activityLevel: ActivityLevel!
    $weightGoal: WeightGoal!
  ) {
    calorieCalculatorAdvice(
      weight: $weight
      height: $height
      age: $age
      sex: $sex
      activityLevel: $activityLevel
      weightGoal: $weightGoal
    ) {
      caloricIntake
      packs {
        ...PackData
      }
    }
  }
`;
