import { debounce } from 'lodash';
import { storageUtils } from '@mono/frontend';
import { dayjs } from '@mono/shared';
import { Orders, ordersSchema } from './utils';

export type OrdersStorage = {
  orders?: Orders;
};

export const validateOrders = async (orders: Orders) => {
  try {
    await ordersSchema.validate(orders);
  } catch (err) {
    return false;
  }
  const now = dayjs();
  const earliestAvailableDay = (now.hour() >= 12 ? now.add(1, 'd') : now).add(1, 'd').startOf('d');
  for (const order of orders) {
    if (order.dates.filter(date => earliestAvailableDay.isAfter(date)).length) {
      return false; // one of the selected days is already unavailable
    }
  }
  return true;
};

export const storeOrders = debounce(
  (orders: Orders) => storageUtils.set('orders', orders.length ? orders : undefined),
  2000,
);

export const fetchOrdersStorage = async () => {
  let newOrders: OrdersStorage['orders'];
  let error = false;
  try {
    const orders = await storageUtils.getOptional<OrdersStorage['orders']>('orders');
    if (orders) {
      const valid = await validateOrders(orders);
      if (valid) {
        newOrders = orders;
      } else {
        error = true;
      }
    }
  } catch (err) {
    error = true;
  }
  return { data: newOrders, error };
};
