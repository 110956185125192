import { useContext } from 'react';
import { ApolloError, FetchResult, MutationResult, useMutation } from '@apollo/client';
import { i18n } from '@mono/shared';
import {
  RequestContactConfirmation,
  RequestContactConfirmationVariables,
} from '../../../../gql-types';
import { frontendI18n } from '../../frontend-i18n';
import { REQUEST_CONTACT_CONFIRMATION } from '../../gql/operations/auth/request-contact-confirmation';
import { RootLayoutContext } from '../../layout/root';
import { useI18n } from '../../translation';

const requestContactConfirmationI18n = i18n({
  ...frontendI18n.translations,
  'RequestContactConfirmation operation failed': {
    en: 'Failed to request contact confirmation',
    ru: 'Не удалось запросить подтверждение контакта',
  },
  'Confirmation code is sent to {contact}': {
    ru: 'Код подтверждения отправлен на {contact}',
  },
  'Failed to send confirmation code to {contact}': {
    ru: 'Код подтверждения отправлен на {contact}',
  },
});

type RequestContactConfirmationMutation = (args: {
  contactValue: string;
}) => Promise<FetchResult<RequestContactConfirmation>>;

export const useRequestContactConfirmation = (): [
  RequestContactConfirmationMutation,
  MutationResult<RequestContactConfirmation>,
] => {
  const t = useI18n(requestContactConfirmationI18n);
  const rootLayoutContext = useContext(RootLayoutContext);
  const [requestContactConfirmation, requestContactConfirmationResult] = useMutation<
    RequestContactConfirmation,
    RequestContactConfirmationVariables
  >(REQUEST_CONTACT_CONFIRMATION, {
    onError: (error: ApolloError) => {
      console.error(error);
      rootLayoutContext?.pushNotification({
        type: 'warning',
        ...(error.networkError
          ? {
              title: t('Network error'),
              text: t('Please, try later'),
            }
          : {
              title: t('RequestContactConfirmation operation failed'),
              text: t('Check data and try again'),
            }),
      });
    },
    onCompleted: async ({ requestContactConfirmation: { value, confirmationCodeSent } }) => {
      rootLayoutContext?.pushNotification({
        ...(confirmationCodeSent
          ? {
              title: t('Confirmation code is sent to {contact}', { contact: value }),
            }
          : {
              type: 'warning',
              title: t('Failed to send confirmation code to {contact}', { contact: value }),
            }),
      });
    },
  });
  const mutation: RequestContactConfirmationMutation = async ({ contactValue }) => {
    return requestContactConfirmation({
      variables: {
        contactIdentifier: { value: contactValue },
      },
    });
  };
  return [mutation, requestContactConfirmationResult];
};
