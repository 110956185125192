import { FC, useCallback, useEffect, useState } from 'react';
import { storageUtils } from '@mono/frontend';
import { OfficeCode } from '@mono/hf';
import { Announcements } from '../components/announcements/announcements';
import useCartFormState from '../hooks/cart-form';
import useDates from '../hooks/dates';
import useMenu from '../hooks/menu';
import useOrders from '../hooks/orders';
import usePrices from '../hooks/prices';
import useStoppageDays from '../hooks/stoppage-days/stoppage-days';
import useStorage from '../hooks/use-storage';
import CartFormContext from './cart-form';
import ContactContext from './contact';
import DataStatusContext from './data-status';
import DatesContext from './dates';
import MenuContext from './menu';
import { OFFICE_STORAGE_KEY, OfficeContext, OfficeUiData, OfficeUiKey } from './office/office';
import OrdersContext from './orders';
import PricesContext from './prices';
import StoppageDaysContext from './stoppage-days/stoppage-days';

export const AppContext: FC = props => {
  const phoneNumber = '+7 (963) 944-74-74'.replace(' ', '\xa0');
  const emailAddress = 'www@happyfood.pro';
  const instagramUrl = 'https://www.instagram.com/happyfood54/';
  const vkontakteUrl = 'https://vk.com/happyfood_pro';
  const whatsappUrl = 'https://wa.me/79639447474';
  const telegramUrl = 'https://telegram.me/happyfood54';
  const flampId = '70000001041212305';

  const officeUiMap: Record<OfficeUiKey, OfficeUiData> = {
    nsk: {
      officeId: '766e873c-bf05-44fa-92bb-1e68daa86983',
      officeCode: OfficeCode.RU_NOVOSIBIRSK,
      regionId: '5400000000000',
      city: 'Новосибирск',
      address: 'ул. Восход №1'.replace(' ', '\xa0'),
      latitude: '55.002925306556214',
      longitude: '82.87331633382205',
      metro: 'Речной вокзал, Октябрьская',
      phoneNumber,
      emailAddress,
      instagramUrl,
      vkontakteUrl,
      whatsappUrl,
      telegramUrl,
      flampId,
    },
    spb: {
      officeId: '4ad2d485-430f-4a14-bf8a-7bab8ab499ff',
      officeCode: OfficeCode.RU_SAINT_PETERSBURG,
      regionId: '7800000000000',
      city: 'Санкт-Петербург',
      address: 'ул. Кораблестроителей №30'.replace(' ', '\xa0'),
      latitude: '59.92564426588251',
      longitude: '30.305054538769554',
      metro: 'Приморская',
      phoneNumber,
      emailAddress,
      instagramUrl,
      vkontakteUrl,
      whatsappUrl,
      telegramUrl,
      flampId,
    },
  };

  const [officeUiKey, setOfficeUiKey] = useState<OfficeUiKey>('nsk');
  useEffect(() => {
    storageUtils.getOptional<OfficeUiKey>(OFFICE_STORAGE_KEY).then(officeUiKey => {
      if (officeUiKey) {
        setOfficeUiKey(officeUiKey);
      }
    });
  }, []);
  const officeUiData = officeUiMap[officeUiKey];

  const dates = useDates();
  const { today, firstMenuDate, lastMenuDate } = dates;

  const stoppageDays = useStoppageDays({ today, firstMenuDate });
  const { deliveryStoppageDays } = stoppageDays;

  const [ordersStorageData, cartFormStorageData] = useStorage();
  const orders = useOrders({
    storageData: ordersStorageData,
    firstMenuDate,
    deliveryStoppageDays,
  });
  const cartFormState = useCartFormState(cartFormStorageData);
  const { countryCode, phonePart, name } = cartFormState[0];

  const menu = useMenu({ firstMenuDate: today, lastMenuDate });
  const prices = usePrices({ orders: orders[0], today });

  const dataStatus = {
    packsLoaded: menu.packsLoaded,
    dishesLoaded: menu.dishesLoaded,
    discountsLoaded: !!prices[0].food?.discounts,
    cartAvailable: menu.packsLoaded,
  };

  return (
    <OfficeContext.Provider
      value={{
        officeUiKey,
        switchOffice: useCallback(
          async (officeUiKeyToSet?: OfficeUiKey) => {
            const nextOfficeUiKey = officeUiKeyToSet ?? (officeUiKey === 'nsk' ? 'spb' : 'nsk');
            setOfficeUiKey(nextOfficeUiKey);
            await storageUtils.set(OFFICE_STORAGE_KEY, nextOfficeUiKey);
          },
          [officeUiKey],
        ),
        officeUiData,
      }}
    >
      <DatesContext.Provider value={dates}>
        <StoppageDaysContext.Provider value={stoppageDays}>
          <DataStatusContext.Provider value={dataStatus}>
            <MenuContext.Provider value={menu}>
              <OrdersContext.Provider value={orders}>
                <PricesContext.Provider value={prices}>
                  <CartFormContext.Provider value={cartFormState}>
                    <ContactContext.Provider
                      value={[{ countryCode, phonePart, name }, cartFormState[1], prices[2]]}
                    >
                      {!!today && <Announcements today={today} />}
                      {props.children}
                    </ContactContext.Provider>
                  </CartFormContext.Provider>
                </PricesContext.Provider>
              </OrdersContext.Provider>
            </MenuContext.Provider>
          </DataStatusContext.Provider>
        </StoppageDaysContext.Provider>
      </DatesContext.Provider>
    </OfficeContext.Provider>
  );
};

export default AppContext;
