import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { basket } from '../../../../../assets/icon';
import { gradientGreen } from '../../../../styles/gradient';

const PacksInCartBadge = (props: { count: number }) => {
  if (props.count) {
    return (
      <Styles>
        <IonIcon icon={basket} />
        {props.count > 1 && (
          <>
            <span>x</span>
            <span className="count">{props.count}</span>
          </>
        )}
      </Styles>
    );
  } else {
    return null;
  }
};

const Styles = styled.div`
  height: 22px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 16px;
  top: 2px;
  border-radius: 22px;
  background: ${gradientGreen};
  color: white;
  > * {
    margin-right: 2px;
    &:last-child {
      margin-right: 0;
    }
  }

  ion-icon {
    font-size: 14px;
  }
  .count {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
  }
  span {
    white-space: nowrap;
    font-size: 13px;
    font-weight: 500;
  }
`;

export default PacksInCartBadge;
