import { diffLines } from 'diff';
import { AA, stringify } from '@mono/shared';
import { usePrevious } from './use-previous';

export const useLogOnChange = (args: AA, mode?: boolean | 'old' | 'new' | 'diff') => {
  const key = Object.keys(args)[0];
  const next = args[key];
  const prev = usePrevious(next);
  if (prev === next || mode === false) {
    return;
  }
  console.log(`Value of '${key}' has changed`);
  if (typeof mode !== 'string') {
    return;
  }
  console.log(mode + ':');
  if (mode === 'old') {
    return console.log(stringify(prev, { format: true }));
  }
  if (mode === 'new') {
    return console.log(stringify(next, { format: true }));
  }
  for (const { value, added, removed } of diffLines(
    stringify(prev, { format: true }),
    stringify(next, { format: true }),
  )) {
    console.log(`%c${value.trimEnd()}`, `color: ${added ? 'green' : removed ? 'red' : 'gray'}`);
  }
};
