import { useCallback } from 'react';
import { Resolver } from 'react-hook-form';
import { ObjectSchema, ValidationError } from 'yup';

export const useYupResolver = <SchemaFields, NonFormFields>({
  schema,
  nonFormFields,
}: {
  schema: ObjectSchema<SchemaFields>;
  nonFormFields?: NonFormFields;
}): Resolver<Omit<SchemaFields, keyof NonFormFields>> =>
  useCallback(
    async formFields => {
      try {
        const values = await schema.validate(
          { ...formFields, ...nonFormFields },
          { abortEarly: false },
        );
        return { values, errors: {} };
      } catch (error) {
        if (error instanceof ValidationError) {
          return {
            values: {},
            errors: error.inner.reduce(
              (errors, { path, type, message }) => ({
                ...errors,
                ...(path && { [path]: { type, message } }),
              }),
              {},
            ),
          };
        } else {
          throw error;
        }
      }
    },
    [schema, nonFormFields],
  );
