import { useCallback, useContext, useEffect, useState } from 'react';
import { dayjs } from '@mono/shared';
import DatesContext from '../../context/dates';
import MenuContext from '../../context/menu';
import { getDaysDiff } from '../../utils/dates';
import { OrderItem } from '../orders/utils';

export interface LandingState {
  pack?: OrderItem['pack'];
  activeDate?: string;
  titleLoaded?: boolean;
}

const useLandingState = () => {
  const menu = useContext(MenuContext);
  const { firstMenuDate } = useContext(DatesContext);

  const [landingUiState, setLandingUiState] = useState<LandingState>({ activeDate: firstMenuDate });

  const updateState = useCallback((payload: LandingState) => {
    setLandingUiState(state => {
      const newState = { ...state, ...payload };

      return newState;
    });
  }, []);

  useEffect(() => {
    if (firstMenuDate) {
      if (landingUiState.activeDate) {
        if (getDaysDiff(firstMenuDate, landingUiState.activeDate) > 0) {
          setLandingUiState(state => {
            return { ...state, activeDate: landingUiState.activeDate };
          });
        }
      } else {
        setLandingUiState(state => {
          return { ...state, activeDate: firstMenuDate };
        });
      }
    }
  }, [firstMenuDate, landingUiState.activeDate, menu.data]);

  useEffect(() => {
    // This effect forcefully sets active day to a day with menu, if possible:
    if (landingUiState.activeDate && !menu.data[landingUiState.activeDate]) {
      for (let index = 0; index < 7; index++) {
        const day = dayjs(firstMenuDate).add(index, 'days').format('YYYY-MM-DD');
        if (menu.data[day]) {
          setLandingUiState(state => {
            return { ...state, activeDate: day };
          });
        }
      }
    }
  }, [firstMenuDate, landingUiState.activeDate, menu.data]);

  return [landingUiState, updateState] as [LandingState, typeof updateState];
};

export type LandingUiHook = ReturnType<typeof useLandingState>;

export default useLandingState;
