import { Big } from 'big.js';
import cx from 'classnames';
import { chevronDown, chevronUp, trashOutline } from 'ionicons/icons';
import { sortBy } from 'lodash';
import plural from 'plural-ru';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import ReactSelect, { OptionsType } from 'react-select';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { IonIcon, IonSpinner } from '@ionic/react';
import { API_DAY_FORMAT, dayjs, distinct } from '@mono/shared';
import { chefHat } from '../../../assets/icon';
import Tooltip from '../../components/tooltip';
import DatesContext from '../../context/dates';
import MenuContext from '../../context/menu';
import OrdersContext from '../../context/orders';
import StoppageDaysContext from '../../context/stoppage-days/stoppage-days';
import { TODAY_DELIVERY_TIME_THRESHOLD } from '../../hooks/dates/utils';
import { OrderItem as TOrderItem } from '../../hooks/orders/utils';
import { gradientGreen } from '../../styles/gradient';
import { Prefix, ReactSelectStyles } from '../../styles/react-select';
import { currencies } from '../../utils/currencies';
import { getEuWeekdayFromDayjs, weekDays } from '../../utils/dates';
import MenuForDay from './menu-for-day';
import { ControlIonButton, DayIonButton, DeleteIonButton, PackIonButton } from './styled-buttons';
import { MAX_WEEKS, MIN_WEEKS, Props } from './utils-order-item';

interface PackOption {
  label: JSX.Element;
  value: TOrderItem['pack'];
}

const Toast = styled.div`
  height: 100%;
  em {
    font-weight: 600;
    font-style: normal;
  }
  p {
    margin: 16px 0 0;
  }
  ion-icon {
    font-size: 20px;
    margin-bottom: -4px;
  }
`;

export const OrderItem = (props: Props) => {
  const [orders, addOrder, deleteOrder, updateOrder, weeksInOrder] = useContext(OrdersContext);
  const { deliveryStoppageDays } = useContext(StoppageDaysContext);

  const { firstMenuDate, today } = useContext(DatesContext);

  const menu = useContext(MenuContext);

  const [dateBtns, setDateBtns] = useState<JSX.Element[]>([]);

  const pack = useMemo(
    () => props.packs.find(it => it.id === props.item.pack.id) ?? props.packs[0],
    [props.packs, props.item.pack.id],
  );

  const [menuMode, setMenuMode] = useState(false);
  const [currentPrice, setCurrentPrice] = useState({ discount: 0, full: 0, discountAmount: 0 });
  const selectRef = useRef<HTMLIonSelectElement>(null);
  const packsCaptionPlural = useMemo(() => {
    const amount = props.item.dates.length;
    return plural(amount, 'пакет', 'пакета', 'пакетов');
  }, [props.item.dates.length]);

  const [isPackSelectOpen, setPackSelectOpen] = useState(false);
  const [packOptions, setPackOptions] = useState<OptionsType<PackOption>>([]);

  const [rangeStart, setRangeStart] = useState<string>();

  const handleDateStatusChange = useCallback(
    (date: string, active: boolean, ctrlOrMetaKey: boolean) => {
      const dates = props.item.dates;
      const days: string[] = [];
      if (ctrlOrMetaKey && rangeStart) {
        for (
          let day = dayjs(rangeStart);
          day.isSame(date, 'days') === false;
          day = day.add(day.isBefore(date) ? 1 : -1, 'day')
        ) {
          days.push(day.format(API_DAY_FORMAT));
        }
      }
      days.push(date);
      if (!ctrlOrMetaKey || rangeStart) {
        updateOrder({
          ...props.item,
          dates: active
            ? dates.filter(it => !days.includes(it))
            : [...dates, ...days].filter(distinct),
        });
      }
      setRangeStart(ctrlOrMetaKey && !rangeStart ? date : undefined);
    },
    [props.item, updateOrder, rangeStart],
  );

  const handleDelete = () => {
    if (!props.deleteDisabled) {
      deleteOrder(props.item.id);
    } else {
      updateOrder({ ...props.item, dates: [], numWeeks: 1 });
    }
  };

  const menuModeToast = useRef<ReturnType<typeof toast> | null>(null);

  const toggleMenuMode = useCallback(
    () =>
      setMenuMode(it => {
        if (!it) {
          !menuModeToast.current &&
            (menuModeToast.current = toast(
              <Toast>
                Режим просмотра меню <em>включен</em>
                <p>
                  Нажмите <IonIcon icon={chefHat} /> на дне календаря, чтобы показать его меню
                </p>
              </Toast>,
              {
                toastId: 'menu-mode',
                autoClose: false,
                onClose: () => (menuModeToast.current = null),
              },
            ));
        } else {
          toast.update('menu-mode', {
            render: (
              <Toast>
                Режим просмотра меню <em>выключен</em>
              </Toast>
            ),
            autoClose: 1500,
          });
        }

        return !it;
      }),
    [],
  );

  useEffect(() => {
    if (props.packs) {
      setPackOptions(
        props.packs.map(pack => {
          const { name, price, targetCalorieContent: kcal } = pack;
          const element = (
            <div className="option">
              <span className="name">{name}</span>
              <span className="description">
                {kcal ? `${kcal} ккал. за ` : ''}
                {price} р.
              </span>
            </div>
          );
          return { value: pack, label: element };
        }),
      );
    }
  }, [props.packs]);

  useEffect(() => {
    const packPrice = new Big(props.item.pack.price);
    const fullPrice = packPrice.times(props.item.dates.length);
    const discountPrice = fullPrice.times(1 - props.discountRate / 100);

    setCurrentPrice({
      discountAmount: props.discountRate,
      full: parseFloat(fullPrice.gt(9999) ? fullPrice.toFixed(0) : fullPrice.toFixed(2)),
      discount: parseFloat(
        discountPrice.gt(9999) ? discountPrice.toFixed(0) : discountPrice.toFixed(2),
      ),
    });
  }, [props.item.pack.price, props.item.dates.length, props.discountRate]);

  useEffect(() => {
    const newDays: Array<JSX.Element> = [];

    if (today && firstMenuDate && deliveryStoppageDays && props.item.numWeeks) {
      const fmdInstance = dayjs(firstMenuDate);
      const todayInstance = dayjs(today);
      const numDaysTillWeekend = getEuWeekdayFromDayjs(fmdInstance)
        ? 7 - getEuWeekdayFromDayjs(fmdInstance)
        : 0;
      const numDays = 7 * props.item.numWeeks + numDaysTillWeekend;
      const numDaysBeforeToday = getEuWeekdayFromDayjs(todayInstance);
      const numDaysAfterToday = fmdInstance.diff(todayInstance, 'days');
      const weekStartInstance = todayInstance.startOf('week');

      const numRows =
        1 +
        fmdInstance.add(numDays, 'days').diff(fmdInstance.startOf('week'), 'weeks') +
        fmdInstance.add(numDays, 'days').diff(fmdInstance.startOf('month'), 'months');

      let daysToShowInMonth = fmdInstance.daysInMonth() - fmdInstance.date();
      let nextMonthStart = fmdInstance.add(daysToShowInMonth + 1, 'days');
      let daysFromMonthEndToWeekEnd = 6 - getEuWeekdayFromDayjs(nextMonthStart.subtract(1, 'days'));
      let indexForLabel = daysToShowInMonth + 1;

      const activeDays = sortBy(
        props.item.dates.map(date => dayjs(date).diff(fmdInstance, 'days')),
      );
      let activeCount = 0;

      const stoppageDays = deliveryStoppageDays.map(date =>
        dayjs(date.day).diff(fmdInstance, 'days'),
      );
      let stoppageDaysCount = 0;

      newDays.push(
        <div key="first-label" className="month-label">
          {fmdInstance.format('MMMM, YYYY')}
        </div>,
      );

      const tipMessage = `Каждый день с ${TODAY_DELIVERY_TIME_THRESHOLD} мы готовим еду и доставляем вечером того же дня, чтобы уже на следующий день вы могли ей питаться. Из-за такого формата работы мы не принимаем заказы на сегодня, но можно успеть заказать на завтра, если сделать это до ${TODAY_DELIVERY_TIME_THRESHOLD}.`;

      let currDateInstance = weekStartInstance;

      for (let i = 0; i < numDaysBeforeToday; i++) {
        newDays.push(
          <Tooltip
            key={currDateInstance.format(API_DAY_FORMAT)}
            pureTrigger
            hoverDisabled
            trigger={
              <DayIonButton mode="md" disabled>
                <span>{currDateInstance.format('D')}</span>
              </DayIonButton>
            }
          >
            <p>{tipMessage}</p>
          </Tooltip>,
        );
        currDateInstance = currDateInstance.add(1, 'day');
      }

      const shift = fmdInstance.diff(todayInstance, 'days');
      for (let i = 0; i < numDaysAfterToday; i++) {
        const isDisabled = i === stoppageDays[stoppageDaysCount] + shift;
        newDays.push(
          <Tooltip
            key={currDateInstance.format(API_DAY_FORMAT)}
            pureTrigger
            hoverDisabled
            trigger={
              <DayIonButton mode="md" disabled className={!i ? 'today' : ''}>
                <span>{currDateInstance.format('D')}</span>
              </DayIonButton>
            }
          >
            <p>
              {!i && (
                <span>
                  Сегодня ({currDateInstance.format('D MMMM')})<br />
                  <br />
                </span>
              )}
              {tipMessage}
            </p>
          </Tooltip>,
        );

        if (menuMode && !isDisabled) {
          const currDate = currDateInstance.format(API_DAY_FORMAT);
          if (menu.data[currDate] && menu.data[currDate][pack.id]) {
            newDays.push(
              <div key={currDate + 'tip'} className="relative-wrap menu-tip">
                <Tooltip
                  trigger={<IonIcon icon={chefHat} />}
                  onOpen={() => toast.update('menu-mode', { autoClose: 1 })}
                >
                  <MenuForDay date={currDate} dishesForDay={menu.data[currDate][pack.id]} />
                </Tooltip>
              </div>,
            );
          }
        }
        isDisabled && stoppageDaysCount++;
        currDateInstance = currDateInstance.add(1, 'day');
      }

      for (let i = 0; i < numDays; i++) {
        const weekdayStr = currDateInstance.format('D');
        const currDate = currDateInstance.format(API_DAY_FORMAT);
        const isActive = i === activeDays[activeCount];
        const isDisabled = i === stoppageDays[stoppageDaysCount];
        if (i === indexForLabel) {
          newDays.push(
            <div key={currDate + '/label'} className="month-label">
              {nextMonthStart.format('MMMM, YYYY')}
            </div>,
          );
          for (let j = 0; j < 7 - daysFromMonthEndToWeekEnd; j++) {
            newDays.push(<div key={currDate + '/' + j} className="filler" />);
          }
          daysToShowInMonth = nextMonthStart.daysInMonth();
          nextMonthStart = nextMonthStart.add(daysToShowInMonth, 'days');
          daysFromMonthEndToWeekEnd = 6 - getEuWeekdayFromDayjs(nextMonthStart.subtract(1, 'day'));
          indexForLabel = i + daysToShowInMonth;
        }

        if (!isDisabled) {
          newDays.push(
            <WeekdayButton
              key={currDate}
              date={currDate}
              active={isActive}
              caption={weekdayStr}
              rangeMode={rangeStart !== undefined}
              rangeStart={currDate === rangeStart}
              setDateStatus={handleDateStatusChange}
            />,
          );

          if (menuMode && menu.data[currDate] && menu.data[currDate][pack.id]) {
            newDays.push(
              <div
                key={currDate + 'tip'}
                className={'relative-wrap menu-tip' + (isActive ? ' active' : '')}
              >
                <Tooltip
                  trigger={<IonIcon icon={chefHat} />}
                  onOpen={() => toast.update('menu-mode', { autoClose: 1 })}
                >
                  <MenuForDay date={currDate} dishesForDay={menu.data[currDate][pack.id]} />
                </Tooltip>
              </div>,
            );
          }
        } else {
          newDays.push(
            <Tooltip
              key={currDateInstance.format(API_DAY_FORMAT)}
              pureTrigger
              hoverDisabled
              trigger={
                <DayIonButton mode="md" disabled>
                  <span>{currDateInstance.format('D')}</span>
                </DayIonButton>
              }
            >
              <p style={{ whiteSpace: 'pre-wrap' }}>
                {deliveryStoppageDays[stoppageDaysCount].tip}
              </p>
            </Tooltip>,
          );
        }

        isActive && activeCount++;
        isDisabled && stoppageDaysCount++;
        currDateInstance = currDateInstance.add(1, 'day');
      }
    }
    setDateBtns(newDays);
  }, [
    firstMenuDate,
    today,
    menuMode,
    menu.data,
    pack.id,
    handleDateStatusChange,
    props.item.numWeeks,
    props.item.dates,
    deliveryStoppageDays,
    rangeStart,
  ]);

  if (firstMenuDate) {
    return (
      <OrderItemStyles>
        <div className="pack-editor">
          <ClickAwayListener onClickAway={() => setPackSelectOpen(false)} touchEvent="touchstart">
            <div className="pack-selector">
              <PackIonButton mode="md" onClick={() => setPackSelectOpen(it => !it)}>
                <div className="inner">
                  <span>{pack.name}</span>
                  <span className="small" style={{ minWidth: '160px' }}>
                    {`${pack.targetCalorieContent ? `${pack.targetCalorieContent} ккал за ` : ''}${
                      pack.price
                    } р`}
                  </span>
                </div>
                <IonIcon icon={chevronDown} className={isPackSelectOpen ? ' active' : ''} />
              </PackIonButton>

              <StyledReactSelect
                components={{ Control: () => null }}
                classNamePrefix={Prefix.default}
                placeholder="Не выбрано"
                menuIsOpen={isPackSelectOpen}
                onMenuClose={() => setPackSelectOpen(false)}
                value={packOptions.find(it => it.value.id === pack.id) ?? null}
                onChange={(e: PackOption) => {
                  if (e.value) {
                    updateOrder({
                      ...props.item,
                      pack: { ...e.value },
                    });
                  }
                }}
                options={packOptions}
              />
            </div>
          </ClickAwayListener>

          <div className="sum">
            <div>
              {props.item.dates.length}&nbsp;
              <span>{packsCaptionPlural}</span>&nbsp;
            </div>
            {!!currentPrice.full &&
              (!currentPrice.discountAmount ? (
                <div className="sum-cap">
                  <span>{'на сумму'}&nbsp;</span>
                  <em>{currentPrice.full + ' ' + currencies.RUB.short + '.'}</em>
                </div>
              ) : (
                <div className="sum-cap">
                  <span>{'на сумму'}&nbsp;</span>
                  <s>{currentPrice.full}</s>&nbsp;
                  <em>{currentPrice.discount + ' ' + currencies.RUB.minimal + '.'}&nbsp;</em>
                </div>
              ))}
            {!!currentPrice.discountAmount && (
              <div className="discount-cap">
                <em>{'-' + currentPrice.discountAmount + '%'}</em>
              </div>
            )}
          </div>
          <div className="control-btns">
            <ControlIonButton
              mode="md"
              className={'show-menu' + (menuMode ? ' active' : '')}
              onClick={toggleMenuMode}
            >
              <IonIcon icon={chefHat} />
            </ControlIonButton>
            <DeleteIonButton mode="md" onClick={handleDelete}>
              <IonIcon icon={trashOutline} />
            </DeleteIonButton>
          </div>
        </div>

        <input
          className="order-item-comment"
          placeholder="Комментарий повару (например, без лука)"
          type="text"
          autoComplete="off"
          onChange={e => {
            if (e.target.value) {
              updateOrder({
                ...props.item,
                comment: e.target.value,
              });
            }
          }}
        />

        <div className="calendar">
          <div className="header">
            {weekDays.map(weekday => (
              <div key={weekday} className="weekday-btn">
                {weekday}
              </div>
            ))}
          </div>
          <div className="days">{dateBtns}</div>
        </div>
        <div className="calendar-controls">
          <ControlIonButton
            className="remove-week"
            mode="md"
            onClick={() => props.item.numWeeks > MIN_WEEKS && weeksInOrder(props.item.id, 'remove')}
          >
            Убрать неделю
            <IonIcon icon={chevronUp} />
          </ControlIonButton>
          <ControlIonButton
            className="add-week"
            mode="md"
            onClick={() => props.item.numWeeks < MAX_WEEKS && weeksInOrder(props.item.id, 'add')}
          >
            Добавить неделю
            <IonIcon icon={chevronDown} />
          </ControlIonButton>
        </div>
      </OrderItemStyles>
    );
  } else {
    return (
      <OrderItemStyles>
        <IonSpinner name="crescent" />
      </OrderItemStyles>
    );
  }
};

const StyledReactSelect = styled(ReactSelect)`
  ${ReactSelectStyles}
  position: absolute !important;
  left: 0;
  height: 0;

  .${Prefix.default}__menu {
    min-width: 300px;
    .option {
      display: flex;
      justify-content: space-between;
      .name {
        margin-right: 20px;
        min-width: 80px;
      }
      .description {
        min-width: fit-content;
      }
    }
  }

  .${Prefix.default}__menu-list {
    min-height: fit-content;
    max-height: unset;
  }
`;

const OrderItemStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  color: #848484;
  padding-bottom: 26px;
  --btn-width: min(11vw, 60px);
  --margin: min(0.6vw, 5px);
  width: calc((var(--margin) * 2 + var(--btn-width)) * 7 + 1px);
  margin: calc(var(--margin) * -1);

  &:last-child {
    padding-bottom: 0;
  }
  > * {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &:first-child {
    border-top: none;
  }

  .menu-tip {
    .tooltip {
      background: white;
      color: #66c338;
      border: 1px solid #66c338;
      height: min(30px, 7vw);
      width: min(30px, 7vw);
      font-size: min(20px, 5vw);
      top: min(calc(2vw - 15px), 0px);
      right: min(calc(2vw - 10px), 0px);
    }
    &.active {
      .tooltip {
        background: #66c439;
        box-shadow: 0px 1px 4px -1px #00000040;
        color: white;
        border: none;
      }
    }
  }
  .sum {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    line-height: 16px;
    color: #808080;
    span {
      min-width: 120px;
    }
    em {
      font-style: normal;
      font-weight: 600;
    }
    .sum-cap > span {
      display: none;
    }
  }
  .discount-cap {
    position: absolute;
    top: -5px;
    left: 135px;
    margin-left: 2px;
    em {
      color: white;
      background: ${gradientGreen};
      padding: 2px 5px;
      border-radius: 40px;
    }
  }
  .pack-editor {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .control-btns {
    display: flex;
    > * {
      margin-left: 5px;
      &:first-child {
        margin-left: 8px;
      }
    }
  }

  .order-item-comment {
    border: 1px solid #dfe1e4;
    border-radius: calc(var(--btn-width) / 2);
    height: 45px;
    padding-left: 24px;
    padding-right: 24px;
    color: #383838;
    outline: none;
    &::placeholder {
      color: #aaaaaa;
    }
  }

  .calendar {
    font-weight: 500;

    .header {
      display: flex;
      margin: calc(var(--margin) * -1) 0;
      text-transform: capitalize;
      font-size: 12px;
    }
    .days {
      display: flex;
      flex-wrap: wrap;

      margin-top: 0;
      border-top: 1px solid #afafaf;
      ion-button {
        height: var(--btn-width);
        width: var(--btn-width);
        margin: var(--margin);
        --border-radius: calc(var(--btn-width) / 2);
      }
    }
    .weekday-btn,
    .filler {
      width: var(--btn-width);
      margin: var(--margin);
      text-align: center;
    }
    .today {
      --border-color: #00000000;
      --color: #ffffff;
      --background: #d1dac8;
    }
    .month-label {
      width: 100%;
      text-transform: capitalize;
      text-align: center;
      margin: 6px 0 8px;
    }
  }

  .calendar-controls {
    display: flex;
    flex-wrap: wrap-reverse;
    margin-bottom: 15px;
    ion-button {
      --background: transparent;
      width: unset;
      height: 30px;
      font-size: 12px;
      font-weight: 400;
      text-transform: unset;
      margin: 0 1%;
      --padding-start: 10px;
      --padding-end: 5px;
    }
    .remove-week {
      width: 46%;
    }
    .add-week {
      width: 50%;
    }
    ion-icon {
      margin-left: 5px;
    }
  }
  .first-day {
    .tooltip {
      background: white;
      font-weight: 500;
      font-size: 15px;
      line-height: 17px;
      color: #66c030;
      border: 1px solid #66c030;
    }
  }

  @media screen and (max-width: 500px) {
    .pack-selector {
      width: 96%;
      ion-button {
        width: 100%;
      }
    }
    .discount-cap {
      position: unset;
    }
    .sum {
      position: absolute;
      top: 140px;
      flex-direction: row;
      justify-content: center;
      width: 100%;

      .sum-cap > span {
        display: inline;
      }
    }
    .order-item-comment {
      margin-bottom: 56px;
    }
    .pack-editor {
      margin-bottom: 16px;
      > ion-button {
        width: 100%;
      }
    }
    .calendar-controls ion-button {
      width: 100%;
    }
  }
`;

const WeekdayButton = ({
  date,
  caption,
  active = false,
  rangeMode,
  rangeStart,
  setDateStatus,
}: {
  date: string;
  caption: string;
  active?: boolean;
  rangeMode: boolean;
  rangeStart: boolean;
  setDateStatus: (date: string, active: boolean, ctrlOrMetaKey: boolean) => void;
}) => {
  return (
    <DayIonButton
      mode="md"
      className={cx({
        'active': active,
        'range-mode': rangeMode,
        'range-start': rangeStart,
      })}
      onClick={e => setDateStatus(date, active, e.ctrlKey || e.metaKey)}
    >
      <span>{caption}</span>
    </DayIonButton>
  );
};
