import 'next/app';

export * from '../gql-types';

export { LOG_IN_BY_NICKNAME, LOG_IN_BY_CONTACT } from './lib/gql/operations/auth/log-in';
export { LOGGED_IN_USER_FRAGMENT } from './lib/gql/operations/auth/logged-in-user';
export { SEND_TELEGRAM_MESSAGE_TO_DEVELOPERS } from './lib/gql/operations/send-telegram-message-to-developers';
export {
  DEBOUNCE_KEY,
  DEBOUNCE_TIMEOUT,
  DEBOUNCE_TIMEOUT_DEFAULT_VALUE,
  FindOperation,
  getOrCreateApolloClient,
} from './lib/gql/client';

export { useYupResolver } from './lib/hook/rhf/use-yup-resolver';
export { useRevalidationOnResolverChange } from './lib/hook/rhf/use-revalidation-on-resolver-change';
export { useContextOrThrow } from './lib/hook/use-context-or-throw';
export { useLogInByUsername } from './lib/hook/auth/use-log-in-by-username';
export type {
  SignUpUserByUsernameMutationArgs,
  UseSignUpUserByUsernameArgs,
} from './lib/hook/auth/use-sign-up-user-by-username';
export { useSignUpUserByUsername } from './lib/hook/auth/use-sign-up-user-by-username';
export { useRequestContactConfirmation } from './lib/hook/auth/use-request-contact-confirmation';
export { useResetPassword } from './lib/hook/auth/use-reset-password';
export { useLogOnChange } from './lib/hook/use-log-on-change';
export { useFetchState } from './lib/hook/use-fetch-state';
export { useHotKeyHandler } from './lib/hook/use-hot-key-handler';
export { useMounted } from './lib/hook/use-is-mounted-ref';
export { useLoggedInUser } from './lib/hook/auth/use-logged-in-user';
export { useOnClickStartOutside, useOnClickEndOutside } from './lib/hook/use-on-click-outside';
export { usePrevious } from './lib/hook/use-previous';
export type { OnRect } from './lib/hook/use-rect';
export { useRect } from './lib/hook/use-rect';
export { useSame } from './lib/hook/use-same';
export { useSameArray } from './lib/hook/use-same-array';
export { useScript } from './lib/hook/use-script';
export { useStorageValue } from './lib/hook/use-storage-value';
export { useTimeout } from './lib/hook/use-timeout';
export { useWindowSize } from './lib/hook/use-window-size';

export { RootLayout, RootLayoutContext } from './lib/layout/root';

export type { LoginSchema } from './lib/validation/login-schema';
export { loginSchemaErrorI18n, loginSchema } from './lib/validation/login-schema';
export type {
  PasswordResetStepKey,
  PasswordResetSchema,
} from './lib/validation/password-reset-schema';
export {
  PasswordResetStep,
  passwordResetSchemaErrorI18n,
  passwordResetSchema,
} from './lib/validation/password-reset-schema';
export type { SignupSchema } from './lib/validation/signup-schema';
export { signupSchemaErrorI18n, signupSchema } from './lib/validation/signup-schema';

export { AuthContext, useMe } from './lib/auth';
export { createConfig } from './lib/create-config';
export { DayjsContext } from './lib/dayjs';
export { withDebugLogs } from './lib/hoc';
export { frontendI18n } from './lib/frontend-i18n';
export { HeadlessInput } from './lib/headless-input';
export type { AppAbility, FrontendAuthData, Me } from './lib/auth';
export { ProjectPropsContext, App, SharedPublicRoute } from './lib/app';
export { layOut } from './lib/layout-utils';
export { storageUtils } from './lib/storage-utils';
export { LanguageContext, useTranslator, useI18n, useErrorI18n } from './lib/translation';
export type { Translator } from './lib/translation';
export { TailwindBreakpoint } from './lib/tailwind-breakpoint';
export type { SvgComponent } from './lib/svg-component';
export type { UsernameScope, UsernameType } from './lib/username-type';
export { determineUsernameType, determineNarrowUsernameScope } from './lib/username-type';
