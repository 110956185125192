import { DataI18n, i18n } from '../../translation';
import { BaseEntityData } from '../base-entity';
import { CountryData } from './country';
import { PostalCodeData } from './postal-code';

export interface LocationData extends BaseEntityData {
  __typename: 'Location';
  country?: CountryData | null;
  postalCode?: PostalCodeData | null;
}

export const locationI18n = i18n<DataI18n<LocationData, 'geoPoint'>>({
  'Location': {
    en: 'Location',
    ru: 'Местоположение',
  },
  'Location:Plural': {
    en: 'Locations',
    ru: 'Местоположения',
  },
  'Location.country': {
    en: 'Country',
    ru: 'Страна',
  },
  'Location.postalCode': {
    en: 'Postal code',
    ru: 'Почтовый индекс',
  },
  'Location:geoPoint': {
    en: 'Geo point',
    ru: 'Координаты',
  },
});
