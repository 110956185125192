import { logoInstagram, logoVk, logoWhatsapp } from 'ionicons/icons';
import { useContext } from 'react';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { getDefinedOrThrow } from '@mono/shared';
import { logoFlampLetter, logoTelegramBw } from '../../../assets/icon';
import { OfficeContext } from '../../context/office/office';

export default () => {
  const {
    officeUiData: {
      city,
      address,
      phoneNumber,
      instagramUrl,
      vkontakteUrl,
      whatsappUrl,
      telegramUrl,
      flampId,
    },
  } = getDefinedOrThrow(useContext(OfficeContext));
  return (
    <Styles>
      <a href={'tel:' + phoneNumber} className="phone-link">
        <h4>{phoneNumber} (WhatsApp)</h4>
      </a>
      <span>{`${city}, ${address}`} (самовывоз)</span>
      <span className="order-days">Заказы принимаются 24/7</span>
      <span>Доставка ежедневно с&nbsp;19:00 до&nbsp;23:00</span>

      <div className="social-links">
        <a href={whatsappUrl} target="_blank" rel="noreferrer">
          <IonIcon icon={logoWhatsapp} />
        </a>
        <a href={telegramUrl} target="_blank" rel="noreferrer">
          <IonIcon icon={logoTelegramBw} />
        </a>
        <a href={vkontakteUrl} target="_blank" rel="noreferrer">
          <IonIcon icon={logoVk} />
        </a>
        <a href={instagramUrl} target="_blank" rel="noreferrer">
          <IonIcon icon={logoInstagram} />
        </a>
        <a
          id="flamp-link"
          href={`https://novosibirsk.flamp.ru/firm/${flampId}`}
          target="_blank"
          rel="noreferrer"
        >
          <IonIcon icon={logoFlampLetter} />
        </a>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  .phone-link h4 {
    color: black;
  }
  .privacy-policy {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    a {
      border-bottom: 1px solid var(--ion-text-color);
      width: fit-content;
    }
    > * {
      margin-bottom: 15px;
    }
  }
  .social-links {
    position: relative;
    display: flex;
    font-size: 30px;
    color: black;
    > a {
      height: 30px;
      width: 30px;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    #flamp-link {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      ion-icon {
        font-size: 40px;
        min-width: 40px;
      }
    }
  }
`;
