import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { IonSegment } from '@ionic/react';
import { PACKS_FOR_ORDER } from '../../../../apollo/api/queries/packs';
import { PacksForOrder } from '../../../../apollo/api/types';
import OrdersContext from '../../../../context/orders';
import LandingUiContext from '../../../../context/ui/landing';
import { IonSegmentStyles } from '../../../../styles/ion-segment-styles';
import Pack from './pack';
import PacksInCartBadge from './packs-in-cart-badge';

type PackGroups = (PacksForOrder['packs'][number] | null)[][];

const PackSetter = () => {
  const [packGroups, setPackGroups] = useState<PackGroups>([]);

  const [landingState, updateLandingState] = useContext(LandingUiContext);
  const [orders] = useContext(OrdersContext);

  const { data } = useQuery<PacksForOrder>(PACKS_FOR_ORDER);

  useEffect(() => {
    if (data) {
      const packs = data.packs;
      updateLandingState({
        pack: packs[0],
      });
      const newPackGroups: PackGroups = [[], []];
      packs.forEach(pack => {
        if (!pack.name.endsWith('SeaFood')) {
          newPackGroups[0].push(pack);
          const seaFoodPack = packs.find(({ name }) => name === `${pack.name} SeaFood`);
          newPackGroups[1].push(seaFoodPack ?? null);
        }
      });
      setPackGroups(newPackGroups);
    }
  }, [data, updateLandingState]);

  return (
    <Styles>
      {packGroups.map((packGroup, index) => (
        <IonSegment
          key={index}
          value={landingState.pack?.id}
          swipeGesture={false}
          onIonChange={e => {
            const target = e.currentTarget as HTMLIonSegmentElement;
            if (e.detail.value === undefined) {
              target.value = landingState.pack?.id;
            }
          }}
        >
          {packGroup.map((pack, index) => (
            <div className="relative-wrap" key={index}>
              <Pack pack={pack} />
              {pack && (
                <PacksInCartBadge
                  count={orders
                    .filter(order => order.dates.length && order.pack.id === pack.id)
                    .map(it => it.dates.length)
                    .reduce((a, b) => a + b, 0)}
                />
              )}
            </div>
          ))}
        </IonSegment>
      ))}
    </Styles>
  );
};

export default PackSetter;

const Styles = styled.div`
  overflow-x: auto;
  padding: 10px 0px;
  ion-segment {
    ${IonSegmentStyles}
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: -10px;
    }
  }
  flex-direction: column;
  ion-segment-button {
    width: 160px;
  }

  .relative-wrap {
    position: relative;
    z-index: 999;
  }

  @media screen and (max-width: 560px) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    ion-segment {
      margin: 0;
      flex-direction: column;
      overflow: visible;
      contain: none;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
