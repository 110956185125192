import { groupBy, map, sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { API_DAY_FORMAT, dayjs } from '@mono/shared';
import { KITCHEN_WORK_STOPPAGE } from '../../apollo/api/queries/kitchen-stoppage';
import { KitchenWorkStoppage } from '../../apollo/api/types';

export interface KitchenStoppageDay {
  day: string;
  tip: JSX.Element | string;
}

const getStoppageDays = (
  data: KitchenWorkStoppage['kitchenWorkStoppages'],
  type: 'kitchen' | 'delivery',
  firstDate: dayjs.Dayjs,
): KitchenStoppageDay[] => {
  const stoppages: KitchenStoppageDay[] = data
    .map(stoppage => {
      const shift = type === 'delivery' ? 1 : 0;
      return stoppage.days.map(day => ({
        day: shift ? dayjs(day).add(1, 'day').format(API_DAY_FORMAT) : day,
        tip: stoppage.description,
      }));
    })
    .reduce((a, b) => a.concat(b), []);
  const stoppagesSorted = sortBy(
    stoppages.map(it => ({ diff: dayjs(it.day).diff(firstDate, 'days'), obj: it })),
    'diff',
  )
    .filter(it => it.diff >= 0)
    .map(it => it.obj);
  return map(groupBy(stoppagesSorted, 'day'), day =>
    day.reduce((a, b) => ({ tip: a.tip + '\n' + b.tip, day: a.day })),
  );
};

const useStoppageDays = (props: { today?: string; firstMenuDate?: string }) => {
  const stoppagesQuery = useQuery<KitchenWorkStoppage>(KITCHEN_WORK_STOPPAGE);
  const [kitchenStoppageDays, setKitchenStoppageDays] = useState<
    KitchenStoppageDay[] | undefined
  >();
  const [deliveryStoppageDays, setDeliveryStoppageDays] = useState<
    KitchenStoppageDay[] | undefined
  >();

  useEffect(() => {
    if (props.today && props.firstMenuDate) {
      if (stoppagesQuery.data) {
        const today = dayjs(props.today);
        const stoppages = stoppagesQuery.data.kitchenWorkStoppages;
        setKitchenStoppageDays(getStoppageDays(stoppages, 'kitchen', today));
        setDeliveryStoppageDays(getStoppageDays(stoppages, 'delivery', today));
      } else {
        setKitchenStoppageDays([]);
        setDeliveryStoppageDays([]);
      }
    }
  }, [stoppagesQuery.data, stoppagesQuery.error, props.today, props.firstMenuDate]);

  return { deliveryStoppageDays, kitchenStoppageDays };
};

export default useStoppageDays;
export type StoppageDaysHook = ReturnType<typeof useStoppageDays>;
