import { createContext } from 'react';

interface DataStatus {
  packsLoaded: boolean | undefined;
  dishesLoaded: boolean | undefined;
  discountsLoaded: boolean | undefined;
  cartAvailable: boolean | undefined;
}

const DataStatusContext = createContext({} as DataStatus);

export default DataStatusContext;
