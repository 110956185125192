import { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import * as yup from 'yup';
import { useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { IonBackdrop, IonButton, IonLabel, IonSpinner } from '@ionic/react';
import { getDefinedOrThrow, round } from '@mono/shared';
import { avocado, avocadoFront, paprikaSlice, paprikaSlices } from '../../../assets/images';
import { CALORIE_CALCULATOR_ADVICE } from '../../apollo/api/queries/calorie-calculator';
import {
  ActivityLevel,
  CalorieCalculatorAdvice,
  CalorieCalculatorAdviceVariables,
  Sex,
  WeightGoal,
} from '../../apollo/api/types';
import { IntegerInput } from '../../components/integer-input';
import LandingUiContext from '../../context/ui/landing';
import { FormStyle } from '../../styles/form-style';
import { gradientGreenForm } from '../../styles/gradient';
import { ContainerStyles, Prefix, ReactSelectStyles } from '../../styles/react-select';
import { ShadowStyle } from '../../styles/shadow-style';
import { LandingAnchors } from './utils';

const activityOptions = [
  { label: 'Минимальная', value: ActivityLevel.MINIMAL },
  { label: '~ фитнес 3 раза в неделю', value: ActivityLevel.LOW },
  { label: '~ фитнес 5 раз в неделю', value: ActivityLevel.BELLOW_AVERAGE },
  { label: '~ спорт 5 раз в неделю', value: ActivityLevel.AVERAGE },
  { label: '~ фитнес ежедневно', value: ActivityLevel.ABOVE_AVERAGE },
  { label: '~ спорт ежедневно', value: ActivityLevel.HIGH },
  { label: 'Максимальная', value: ActivityLevel.MAXIMAL },
];

const weightGoalOptions = [
  { label: 'Питаться вкусно и полезно', value: WeightGoal.MAINTENANCE },
  { label: 'Похудеть', value: WeightGoal.LOSS },
  { label: 'Набрать массу', value: WeightGoal.GAIN },
];

const sexOptions = [
  { label: 'Мужской', value: Sex.MALE },
  { label: 'Женский', value: Sex.FEMALE },
];

enum FieldName {
  height = 'height',
  weight = 'weight',
  age = 'age',
  sex = 'sex',
  activityLevel = 'activityLevel',
  weightGoal = 'weightGoal',
}

interface FormFields {
  [FieldName.height]?: number;
  [FieldName.weight]?: number;
  [FieldName.age]?: number;
  [FieldName.sex]?: Sex;
  [FieldName.activityLevel]?: ActivityLevel;
  [FieldName.weightGoal]?: WeightGoal;
}

const formDefaults: FormFields = {
  height: undefined,
  weight: undefined,
  age: undefined,
  sex: undefined,
  activityLevel: ActivityLevel.MINIMAL,
  weightGoal: WeightGoal.MAINTENANCE,
};

const noErrors = {
  sex: undefined,
  age: undefined,
  height: undefined,
  weight: undefined,
  activityLevel: undefined,
  weightGoal: undefined,
};

const schema = yup
  .object()
  .shape({
    [FieldName.height]: yup
      .number()
      .required('')
      .min(100, 'от 100 до 230 см')
      .max(230, 'от 100 до 230 см'),
    [FieldName.weight]: yup
      .number()
      .required('')
      .min(30, 'от 30 до 250 кг')
      .max(250, 'от 30 до 250 кг'),
    [FieldName.age]: yup
      .number()
      .required('')
      .min(13, 'от 13 до 80 лет')
      .max(80, 'от 13 до 80 лет'),
    [FieldName.sex]: yup.mixed<keyof typeof Sex>().oneOf(Object.values(Sex)).required(''),
    [FieldName.activityLevel]: yup
      .mixed<keyof typeof ActivityLevel>()
      .oneOf(Object.values(ActivityLevel))
      .required(''),
    [FieldName.weightGoal]: yup
      .mixed<keyof typeof WeightGoal>()
      .oneOf(Object.values(WeightGoal))
      .required(''),
  })
  .defined();

const Calculator = () => {
  const [calories, setCalories] = useState<number | undefined>(undefined);
  const formRef = useRef<HTMLFormElement>(null);

  const [landingState, updateLandingState] = useContext(LandingUiContext);

  const [queryCalorieIntake, calorieIntake] = useLazyQuery<
    CalorieCalculatorAdvice,
    CalorieCalculatorAdviceVariables
  >(CALORIE_CALCULATOR_ADVICE);

  const numPacks = useMemo(
    () => calorieIntake.data && calorieIntake.data.calorieCalculatorAdvice.packs.length,
    [calorieIntake],
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit = (formData: FormFields) => {
    queryCalorieIntake({
      variables: {
        height: getDefinedOrThrow(formData[FieldName.height]),
        weight: getDefinedOrThrow(formData[FieldName.weight]),
        sex: getDefinedOrThrow(formData[FieldName.sex]),
        age: getDefinedOrThrow(formData[FieldName.age]),
        weightGoal: getDefinedOrThrow(formData[FieldName.weightGoal]),
        activityLevel: getDefinedOrThrow(formData[FieldName.activityLevel]),
      },
    });
  };

  const onError = errs => {
    if (formRef.current) {
      const keys = Object.keys(errs);
      let index = keys.length - 1;
      let el: Element | null = null;
      let inputName = keys[index];
      while (!el && index >= 0) {
        el = formRef.current.querySelector(`.input[name="${inputName}"]`);
        inputName = keys[--index];
      }
      el?.scrollIntoView();
      document.querySelector('ion-content')?.scrollByPoint(0, -160, 0);
      el && (el as HTMLElement).focus();
    }
  };

  useEffect(() => {
    Object.values(FieldName).forEach(it => register(it));
  }, [register]);

  useEffect(() => {
    Object.keys(formDefaults).forEach(
      key => formDefaults[key] && setValue(FieldName[key], formDefaults[key]),
    );
  }, [setValue]);

  useEffect(() => {
    if (calorieIntake.data) {
      setCalories(calorieIntake.data.calorieCalculatorAdvice.caloricIntake);
    }
  }, [calorieIntake]);

  return (
    <Styles>
      <div className="container">
        <div className="text">
          <h5>Как подобрать рацион?</h5>
          <h3>Калькулятор расчета суточной нормы калорий</h3>
          <p>
            Рационы различаются по размеру порций (от XS до XL). Кроме того, есть их варианты для
            тех, кто не ест мясо (SeaFood). И мы не просто убрали мясо - мы заменили его на
            качественные морепродукты. <br />
            Калькулятор поможет подобрать рацион.
          </p>
        </div>
        <div className="form-wrapper">
          <div className="image-block">
            <img className="image-left" src={paprikaSlice} alt="Паприка, нарезаная кольцами" />
            <img className="image-right" src={paprikaSlices} alt="Паприка, нарезаная кольцами" />
          </div>
          <div className="image-block-2">
            <img className="image-left-2" src={avocado} alt="Авокадо цельный" />
            <img
              className="image-right-2"
              src={avocadoFront}
              alt="Авокадо, разрезаный повдоль, с косточкой"
            />
          </div>

          {calorieIntake.loading && <IonBackdrop stopPropagation tappable={false} />}
          <form id="calculator-form" onSubmit={handleSubmit(onSubmit, onError)} ref={formRef}>
            <div className={'half input-wrapper' + (errors[FieldName.sex] ? ' error' : '')}>
              <IonLabel>Пол</IonLabel>
              <div className="select-wrap input">
                <StyledReactSelect
                  name={FieldName.sex}
                  classNamePrefix={Prefix.default}
                  placeholder="Укажите пол"
                  onChange={e => {
                    clearErrors(FieldName.sex);
                    setCalories(undefined);
                    e && e.value && setValue(FieldName.sex, e.value);
                  }}
                  options={sexOptions}
                />
              </div>
              {errors[FieldName.sex] && <span>{errors[FieldName.sex].message}</span>}
            </div>
            <div className={'half input-wrapper' + (errors[FieldName.age] ? ' error' : '')}>
              <IonLabel>Возраст</IonLabel>
              <IntegerInput
                name={FieldName.age}
                className="input"
                placeholder="Введите возраст"
                onSetValue={val => {
                  clearErrors(FieldName.age);
                  setCalories(undefined);
                  setValue(FieldName.age, val);
                }}
              />
              {errors[FieldName.age] && <span>{errors[FieldName.age].message}</span>}
            </div>
            <div className={'half input-wrapper' + (errors[FieldName.weight] ? ' error' : '')}>
              <IonLabel>Вес, кг</IonLabel>
              <IntegerInput
                name={FieldName.weight}
                className="input"
                placeholder="Введите вес"
                onSetValue={val => {
                  clearErrors(FieldName.weight);
                  setCalories(undefined);
                  setValue(FieldName.weight, val);
                }}
              />
              {errors[FieldName.weight] && <span>{errors[FieldName.weight].message}</span>}
            </div>
            <div className={'half input-wrapper' + (errors[FieldName.height] ? ' error' : '')}>
              <IonLabel>Рост, см</IonLabel>
              <IntegerInput
                name={FieldName.height}
                className="input"
                placeholder="Введите рост"
                onSetValue={val => {
                  clearErrors(FieldName.height);
                  setCalories(undefined);
                  setValue(FieldName.height, val);
                }}
              />
              {errors[FieldName.height] && <span>{errors[FieldName.height].message}</span>}
            </div>

            <div className={'input-wrapper' + (errors[FieldName.activityLevel] ? ' error' : '')}>
              <IonLabel>Физическая нагрузка</IonLabel>
              <div className="select-wrap input">
                <StyledReactSelect
                  name={FieldName.activityLevel}
                  classNamePrefix={Prefix.default}
                  placeholder="Не выбрано"
                  defaultValue={activityOptions[0]}
                  onChange={e => {
                    clearErrors(FieldName.activityLevel);
                    setCalories(undefined);
                    e && e.value && setValue(FieldName.activityLevel, e.value);
                  }}
                  options={activityOptions}
                />
              </div>

              {errors[FieldName.activityLevel] && (
                <span>{errors[FieldName.activityLevel].message}</span>
              )}
            </div>
            <div className={'input-wrapper' + (errors[FieldName.weightGoal] ? ' error' : '')}>
              <IonLabel>Ваша цель</IonLabel>
              <div className="select-wrap input">
                <StyledReactSelect
                  name={FieldName.weightGoal}
                  classNamePrefix={Prefix.default}
                  placeholder="Не выбрано"
                  defaultValue={weightGoalOptions[0]}
                  onChange={e => {
                    clearErrors(FieldName.weightGoal);
                    setCalories(undefined);
                    e && e.value && setValue(FieldName.weightGoal, e.value);
                  }}
                  options={weightGoalOptions}
                />
              </div>
              {errors[FieldName.weightGoal] && <span>{errors[FieldName.weightGoal].message}</span>}
            </div>
            <div className="button-block">
              {calorieIntake.loading ? (
                <div className="spinner-wrap">
                  <IonSpinner />
                </div>
              ) : calories ? (
                <div className="results">
                  <span>Вам потребуется {round(calories, 1)} ккал</span>

                  {calorieIntake.data && numPacks && (
                    <div>
                      <p style={{ display: 'inline-block' }}>Попробуйте пакеты:&nbsp;</p>
                      {calorieIntake.data.calorieCalculatorAdvice.packs.map((pack, index) => (
                        <Fragment key={pack.id}>
                          {index !== 0 ? ', ' : ''}
                          <Link
                            key={pack.id}
                            onClick={() => updateLandingState({ pack: pack })}
                            to={'/#' + LandingAnchors.menu}
                            className="pack"
                          >
                            {pack.name}
                          </Link>
                        </Fragment>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <IonButton mode="md" type="submit">
                  Подобрать рацион
                </IonButton>
              )}
            </div>
          </form>
        </div>
      </div>
    </Styles>
  );
};

export default Calculator;

const StyledReactSelect = styled(ReactSelect)`
  ${ReactSelectStyles}
  width: 100%;
`;

const Styles = styled.div`
  .select-wrap {
    ${ContainerStyles}
  }
  .container {
    display: flex;
    justify-content: space-between;
  }
  h5 {
    color: #59ae30;
    font-weight: 800;
  }
  p {
    margin: 0;
  }
  .text {
    max-width: 400px;
    margin-right: 50px;
  }
  .image-block {
    position: absolute;
    bottom: 0;
    right: 100%;
    display: flex;
    margin-right: 20px;
    img {
      max-width: unset;
    }
    .image-left {
      width: 130px;
      margin-right: -30px;
      margin-top: auto;
    }
    .image-right {
      width: 130px;
      margin-top: auto;
    }
  }
  .image-block-2 {
    position: absolute;
    left: 100%;
    display: flex;
    margin-top: -20px;
    img {
      max-width: unset;
    }
    .image-left-2 {
      margin-right: -20px;
      margin-left: 30px;
      height: 140px;
    }
    .image-right-2 {
      margin-top: 80px;
      height: 150px;
    }
  }
  .form-wrapper {
    position: relative;
    width: 60%;
    max-width: 480px;
    min-width: 370px;
    margin-right: max(0px, calc(300px - 15vw));
    #calculator-form {
      position: relative;
      z-index: 1;
    }
  }
  #calculator-form {
    ${FormStyle}
    ${ShadowStyle}
    height: fit-content;
    padding: 26px;
    background: ${gradientGreenForm};
    box-shadow: 3px 3px 8px 0px #8a8a8a;
    > * {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      &.image-block {
        margin-bottom: 0;
      }
    }
    ion-backdrop {
      margin: 0;
      z-index: 9999;
    }
    ion-label {
      color: white;
    }
    .input {
      background: #ddefd0;
      box-shadow: 0 0px 3px 0px #212121 inset;
      &:-webkit-autofill {
        box-shadow: 0 0px 3px 0px #212121 inset, 0 0 0px 1000px #ddefd0 inset;
      }
      --placeholder-color: rgba(56, 56, 56, 0.3);
    }

    .half {
      --space-between: 5%;
    }
    .button-block {
      margin-top: 14px;
      padding-top: 30px;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      ion-button {
        --background: rgba(255, 255, 255, 0.2);
        height: 46px;
      }
      .results {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: rgba(255, 255, 255, 0.8);
        font-size: 11pt;
        font-weight: 800;
        a {
          color: inherit;
        }
      }
      .spinner-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        ion-spinner {
          --color: rgba(255, 255, 255, 0.8);
        }
      }
      .results,
      .spinner-wrap {
        min-height: 50px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .container {
      flex-direction: column;
    }
    .form-wrapper {
      align-self: center;
      margin: 0;
    }
    .text {
      width: unset;
      max-width: unset;
      p {
        margin-bottom: 50px;
      }
    }

    .image-block {
      top: 0;
      bottom: unset;
      .image-left {
        position: absolute;
        top: 150px;
      }
    }
  }
  /* @media screen and (max-width: 768px) {
    .image-block {
      .image-left {
        position: absolute;
        top: 150px;
      }
    }
  } */
  @media screen and (max-width: 600px) {
    .text {
      margin-right: 0;
      p {
        margin-bottom: 110px;
      }
    }
    .form-wrapper {
      width: 100%;
      min-width: unset;
      align-self: center;

      margin-bottom: 120px;
      #calculator-form {
        padding: 20px 5%;
      }
      .image-block {
        top: unset;
        right: 0;
        bottom: 100%;
        .image-left,
        .image-right {
          width: calc(60px + 10vw);
        }
        .image-left {
          position: unset;
        }
        .image-right {
        }
      }
      .image-block-2 {
        left: calc(50% - 100px);
        top: 100%;
        margin-top: 10px;
        .image-right-2 {
          margin-top: 50px;
        }
      }
    }
    @media screen and (max-width: 600px) {
      .form-wrapper {
        margin-bottom: 150px;
      }
    }
    @media screen and (max-width: 400px) {
      #calculator-form {
        .half {
          width: 100% !important;
          margin-left: 0;
        }
      }
    }
  }
`;
