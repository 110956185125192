import { createContext } from 'react';
import { SharedDayjs } from '@mono/shared';

type DayjsContext = {
  timezone: string;
  dayjs: SharedDayjs;
};

/**
 * React components must use dayjs from this context to ensure rerendering on timezone changes
 */
export const DayjsContext = createContext<DayjsContext | undefined>(undefined);
