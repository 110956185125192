import { css } from 'styled-components';

export const CheckboxStyle = css`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;

  --height: 22px;
  --color-checked: #26bb26;
  --background-checked: white;
  --background: white;
  --border-radius: 4px;
  --border: 1px solid #dfe1e4;

  height: var(--height);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: var(--height);
    width: var(--height);
    background-color: var(--background);
    border: var(--border);
    border-radius: var(--border-radius);
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: var(--background);
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: var(--background-checked);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 7px;
    top: 1px;
    width: 6px;
    height: 12px;
    border: solid var(--color-checked);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;
