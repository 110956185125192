import dishPhotoNotFound from './404.jpg';
import avocado from './avocado-1.png';
import avocadoFront from './avocado-2.png';
import avocadoSpice from './avocado-spice.png';
import basil1 from './basil-1.png';
import basil2 from './basil-2.png';
import basil3 from './basil-3.png';
import bottomCurve from './bottom-curve.svg';
import certificate1 from './certificates/certificate-1.jpg';
import certificate2 from './certificates/certificate-2.jpg';
import certificate3 from './certificates/certificate-3.jpg';
import imgCheck from './checkmark.png';
import imgCross from './cross.png';
import mainPhoto from './main-photo.png';
import noDishPhoto from './no-dish-photo.jpg';
import paprikaSlice from './paprika-1.png';
import paprikaSlices from './paprika-2.png';
import paprikaGreen from './paprika-green.png';
import peaPod from './pea-pod.png';
import peas from './peas.png';
import advantagesPhoto from './photo-advantages.png';
import spinachBig from './spinach-big.png';
import spinachSmall from './spinach-small.png';
import vegSketchAdvantages from './veg-sketch-advantages.png';
import vegSketchLeft from './veg-sketch-left.png';
import vegSketchRight from './veg-sketch-right.png';

const certificates = { certificate1, certificate2, certificate3 };

export {
  bottomCurve,
  noDishPhoto,
  dishPhotoNotFound,
  mainPhoto,
  vegSketchLeft,
  vegSketchRight,
  imgCheck,
  imgCross,
  advantagesPhoto,
  vegSketchAdvantages,
  paprikaGreen,
  peaPod,
  peas,
  spinachBig,
  spinachSmall,
  paprikaSlice,
  paprikaSlices,
  avocado,
  avocadoFront,
  avocadoSpice,
  basil1,
  basil2,
  basil3,
  certificates,
};
