import 'tailwindcss/tailwind.css';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { createContext, useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { RefreshIcon } from '@heroicons/react/outline';
import {
  dayjs,
  GetAbilityOf,
  LanguageKey,
  O,
  stringEnumValues,
  Translation,
  Translations,
} from '@mono/shared';
import { AbilityContext, AuthContext, FrontendAuthData } from './auth';
import { DayjsContext } from './dayjs';
import { getOrCreateApolloClient } from './gql/client';
import { useLoggedInUser } from './hook/auth/use-logged-in-user';
import { useContextOrThrow } from './hook/use-context-or-throw';
import { getLayOutFn, getLayoutSettings } from './layout-utils';
import { storageUtils } from './storage-utils';
import { LanguageContext } from './translation';

export enum SharedPublicRoute {
  maintenance = '/maintenance',
  signup = '/signup',
  login = '/login',
  passwordReset = '/password-reset',
}
const sharedPublicRoutes = stringEnumValues(SharedPublicRoute);

const isPublic = (route: string, projectPublicRoutes: string[] | undefined): boolean => {
  return [...sharedPublicRoutes, ...(projectPublicRoutes ?? [])].includes(route);
};

type ProjectProps = {
  sourceVersion: string;
  apiUrl: string;
  roleTranslations: Translations<string>;
  getOperations: string[];
  findOperations: string[];
  getAbilityOf: GetAbilityOf;
  language: LanguageKey | undefined;
  useTimezone?: () => O<string>;
  titleTranslation: Translation;
  publicRoutes?: string[];
};

export const ProjectPropsContext = createContext<ProjectProps | undefined>(undefined);

const ProjectApp = ({ pageProps, Component, router: { route } }: AppProps) => {
  const projectProps = useContextOrThrow(ProjectPropsContext);
  const loggedInUser = useLoggedInUser();
  const language = loggedInUser?.language ?? projectProps.language ?? 'en';
  dayjs.locale(language);
  const timezone = projectProps.useTimezone?.() ?? dayjs.tz.guess();
  dayjs.tz.setDefault(timezone);
  const layOut = getLayOutFn(Component);
  const layoutSettings = getLayoutSettings(Component);
  return (
    <LanguageContext.Provider value={language}>
      <DayjsContext.Provider value={{ timezone, dayjs }}>
        <Head>
          <title>
            {(layoutSettings.pageTitleTranslation ?? projectProps.titleTranslation)[language]}
          </title>
          <link rel="shortcut icon" href="/logo.svg" />
          <link rel="apple-touch-icon" href="/logo-180.svg" />
          <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
        </Head>
        <div className="app">
          <main>
            {isPublic(route, projectProps.publicRoutes) || loggedInUser ? (
              layOut(<Component {...pageProps}></Component>)
            ) : (
              <div className="absolute flex min-h-screen w-full">
                <RefreshIcon className="m-auto h-8 w-8 animate-reverse-spin text-slate-500" />
              </div>
            )}
          </main>
        </div>
      </DayjsContext.Provider>
    </LanguageContext.Provider>
  );
};

const SOURCE_VERSION = 'SOURCE_VERSION';
const AUTH_DATA = 'AUTH_DATA';

export const App = ({
  sourceVersion,
  apiUrl,
  getOperations,
  findOperations,
  getAbilityOf,
  language,
  roleTranslations,
  useTimezone,
  titleTranslation,
  publicRoutes,
  ...appProps
}: ProjectProps & AppProps) => {
  const [authData, setAuthData] = useState<O<FrontendAuthData>>();
  useEffect(() => {
    storageUtils.getOptional<string>(SOURCE_VERSION).then(async storedSourceVersion => {
      if (storedSourceVersion !== sourceVersion) {
        await storageUtils.clear();
        await storageUtils.set(SOURCE_VERSION, sourceVersion);
      }
      await storageUtils
        .getOptional<FrontendAuthData>(AUTH_DATA)
        .then(authData => setAuthData(authData ?? null));
    });
  }, [sourceVersion]);
  useEffect(() => {
    if (!isPublic(appProps.router.route, publicRoutes) && authData === null) {
      appProps.router.push(SharedPublicRoute.login);
    }
  }, [publicRoutes, appProps.router, authData]);
  const principal = authData?.principal ?? null;
  return (
    <AuthContext.Provider
      value={{
        principal,
        getPrincipalOrThrow: () => {
          if (principal === null) {
            throw Error('Not authenticated');
          }
          return principal;
        },
        logIn: async authData => {
          await storageUtils.set(AUTH_DATA, authData);
          setAuthData(authData);
        },
        logOut: async () => {
          await storageUtils.remove(AUTH_DATA);
          setAuthData(null);
        },
      }}
    >
      <AbilityContext.Provider value={getAbilityOf({ principal })}>
        <ApolloProvider
          client={getOrCreateApolloClient({
            uri: apiUrl,
            jwt: authData?.jwt,
            getOperations,
            findOperations,
          })}
        >
          <ProjectPropsContext.Provider
            value={{
              sourceVersion,
              apiUrl,
              getOperations,
              findOperations,
              getAbilityOf,
              language,
              roleTranslations,
              useTimezone,
              titleTranslation,
              publicRoutes,
            }}
          >
            <ProjectApp {...appProps} />
          </ProjectPropsContext.Provider>
        </ApolloProvider>
      </AbilityContext.Provider>
    </AuthContext.Provider>
  );
};
