import { i18n } from './translation';

export const booleanI18n = i18n<'true' | 'false'>({
  false: {
    en: 'No',
    ru: 'Нет',
  },
  true: {
    en: 'Yes',
    ru: 'Да',
  },
});
