import { RefObject, useEffect } from 'react';

export const useOnClickStartOutside = (
  ref: RefObject<Element>,
  handler: (event: Event) => unknown,
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!ref.current || (event.target instanceof Element && ref.current.contains(event.target))) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const useOnClickEndOutside = (
  ref: RefObject<Element>,
  handler: (event: Event) => unknown,
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!ref.current || (event.target instanceof Element && ref.current.contains(event.target))) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mouseup', listener);
    document.addEventListener('touchend', listener);
    return () => {
      document.removeEventListener('mouseup', listener);
      document.removeEventListener('touchend', listener);
    };
  }, [ref, handler]);
};
